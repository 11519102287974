export enum OptionType {
  INPUT = 'input',
}

export enum SaveStatus {
  NONE = 'none',
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'successful',
  ERROR = 'error',
}

export const defaultErrorConfig = {
  duration: 5000,
  message: 'Failed to send your feedback please retry.',
};
