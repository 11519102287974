import classNames from 'classnames';
import React from 'react';

import { ErrorCardBodyProps } from 'root/widgets/error-card/types';

const ErrorCardBody: React.FC<ErrorCardBodyProps> = ({ className, children, ...props }) => (
  <div className={classNames('error-card-body', className)} {...props}>
    {children}
  </div>
);

export default ErrorCardBody;
