const EVENT_NAMES = {
  AGENT_MAP_VIEW: {
    SHOW_MORE_REGION_ON_CLICK: 'agent-map-view.show-more-region:click',
    LISTING_FILTER_ON_CLICK: 'agent-map-view.listing-filter:click',
    LISTING_MARKER_ON_CLICK: 'agent-map-view.listing-marker:click',
    LISTING_POPOVER_ON_CLOSE: 'agent-map-view.listing-popover:close',
  },
  AGENT_PROFILE_INFO: {
    CONTACT_CTA_ON_CLICK: 'contact-cta:click',
    RATE_CTA_ON_CLICK: 'rate-cta:click',
  },
  AGENT_RATING: {
    CLICK: 'agent-rating:click',
  },
  AGENT_REVIEW_MODAL: {
    FORM_CLOSE: 'agent-review-modal-close:click',
    FORM_SUBMIT: 'agent-review-modal-submit:click',
  },
  CONTACT_AGENT_CARD: {
    INTENT_CTA_CLICK: 'intent-cta:click',
  },
  PRE_QUALIFICATION_MODAL: {
    SUBMIT: 'pre-qualification-form-submit:click',
  },
  PROJECT_INFO_CARD: {
    VIEW: 'project-info-card:view',
    CLICK: 'project-info-card:click',
  },
  PROJECT_CARD: {
    CLICK: 'project-card:click',
  },
  SPOTLIGHT_PROJECT_BANNER: {
    CLICK: 'spotlight-project-banner:click',
  },
  MEDIA_CAROUSELL: {
    PREVIOUS_BUTTON: 'listing-card-previous-button:click',
    NEXT_BUTTON: 'listing-card-next-button:click',
  },
  PROJECT_LISTING_CARD: {
    CLICK: 'project-listing-card:click',
  },
  PROJECT_LISTING_CARD_SPOTLIGHTED: {
    CLICK: 'project-listing-card-spotlighted:click',
  },
  PROJECT_LISTING_CARD_BASIC_INDO: {
    CLICK: 'project-listing-card-basic-info:click',
  },
  REDUCED_LISTING_CARD: {
    CLICK: 'reduced-listing-card:click',
  },
  REDUCED_LISTING_CARD_GROUP_CAROUSEL: {
    CLICK_TAB: 'reduced-listing-card-group-carousel-tab-action:click',
    CLICK_SCROLL: 'reduced-listing-card-group-carousel-scroll:click',
    VIEW_MORE_ON_CLICK: 'reduced-listing-card-group-carousel-view-more:click',
  },
  HOME_ALERT_CARD: {
    CLICK: 'home-alert-card:click',
  },
  NEW_HOME_ALERT_CARD: {
    CLICK: 'new-home-alert-card:click',
  },
  ACTIONABLE: {
    CLICK: 'actionable:click',
  },
  CARD_GROUP: {
    SCROLL: 'card-group:scroll',
    VIEW_MORE_ON_CLICK: 'card-group.view-more:click',
    VIEW: 'card-group:view',
  },
  LOCALE_SWITCH: {
    CHANGE: 'locale-switch:change',
  },
  MASTHEAD_VIDEO: {
    CLICK: 'masthead-video:click',
  },
  COMPANION_AD_BLOCK: {
    CLICK: 'companion-ad-block:click',
  },
  CALLOUT_CARD: {
    CLICK: 'callout-card:click',
    VIEW: 'callout-card:view',
  },
  GLOBAL_NAVBAR: {
    HIDE_ON_SCROLL: 'global-navbar-hide:scroll',
    SHOW_ON_SCROLL: 'global-navbar-show:scroll',
    NAV_LINK_ON_CLICK: 'global-nav-link:click',
    SIDENAV_OPENED: 'global-nav-sidenav:open',
    NOTIFICATION_ON_CLICK: 'global-nav-notification:click',
    MORE_DROPDOWN_ON_CLICK: 'global-nav-more-dropdown:click',
    QUICK_LINK_ON_CLICK: 'global-nav-quick-link:click',
    MY_ACTIVITY_LINK_ON_CLICK: 'global-nav-my-activity-link:click',
    LOGIN_ON_CLICK: 'global-nav-login:click',
    MY_ACCOUNT_ON_CLICK: 'global-nav-my-account:click',
    MY_ACCOUNT_ITEM_ON_CLICK: 'global-nav-my-account-item:click',
    LOGOUT_ON_CLICK: 'global-nav-logout:click',
  },
  GLOBAL_FOOTER: {
    SOCIAL_ON_CLICK: 'global-footer.social:click',
    SITEMAP_LINK_ON_CLICK: 'global-footer.sitemap-link:click',
  },
  SEARCH_SEGMENT: {
    TAB_ON_CLICK: 'search-segment-tab:click',
    SEARCH_ON_CLICK: 'search-button:click',
  },
  SEARCH_ACCESSORIES: {
    LOCATION_OPTION_ON_SELECT: 'search-accessory.location-option:click',
    SEARCH_OPTION_ON_SELECT: 'search-accessory.search-option:click',
    RECENT_SEARCH_ON_SELECT: 'search-accessory.recent-search:click',
  },
  SEARCH_FILTER: {
    PROPERTY_TYPE_ON_SELECT: 'search-filter.property-type:click',
    AI_TOGGLE_ON_CLICK: 'search-filter.ai-toggle:click',
    SELECT_REGION: 'interactions:select-region',
    SELECT_SUB_REGION: 'interactions:select-sub-region',
    SEARCH_REGION_LIST: 'interactions:search-region-list',
    SEARCH_SUB_REGION_LIST: 'interactions:search-sub-region-list',
    SELECT_ALL_SUB_REGIONS: 'interactions:select-all-sub-regions',
    SELECT_AREA: 'interactions:select-area',
    UNSELECT_AREA: 'interactions:unselect-area',
    CLEAR_SEARCH_BY_REGION: 'interactions:clear-search-by-region',
  },
  MRT_MODAL: {
    SEARCH_BY_MRT: 'interactions:search-by-mrt',
    SELECT_MRT_LINE: 'interactions:select-mrt-line',
    UNSELECT_MRT_LINE: 'interactions:unselect-mrt-line',
    SELECT_MRT: 'interactions:select-mrt',
    UNSELECT_MRT: 'interactions:unselect-mrt',
    SEARCH_MRT_MAP: 'interactions:search-mrt-map',
    CLEAR_SEARCH_BY_MRT: 'interactions:clear-search-by-mrt',
  },
  MORTGAGE_CALCULATOR: {
    PROP_PRICE_FIELD_EDIT: 'mortgage-calculator.prop-price-field:edit',
    LOAN_AMOUNT_FIELD_EDIT: 'mortgage-calculator.loan-amount-field:edit',
    INTEREST_RATE_FIELD_EDIT: 'mortgage-calculator.interest-rate-field:edit',
    LOAN_TENURE_FIELD_EDIT: 'mortgage-calculator.loan-tenure-field:edit',
    APPLY_BEST_RATE_CLICK: 'mortgage-calculator.apply-best-rate:click',
    CALCULATE_CLICK: 'mortgage-calculator.calculate:calculate',
    MORTGAGE_BANNER_CLICK: 'mortgage-calculator.mortgage-banner:navigate',
    GET_PRE_APPROPVED_NOW_CLICK: 'mortgage-calculator.get-pre-approved-now:navigate',
  },
  PROPERTY_OVERVIEW: {
    GET_PRE_APPROPVED_NOW_CLICK: 'property-overview.get-pre-approved-now:navigate',
    LOCATION_ON_CLICK: 'property-overview.location:navigate',
  },
  DEVELOPER_PROPERTY_OVERVIEW: {
    GET_PRE_APPROPVED_NOW_CLICK: 'developer-property-overview.get-pre-approved-now:navigate',
    GET_UNIT_TYPES_AND_PRICES_AMOUNT_CLICK: 'developer-property-overview.get-unit-types-and-prices:navigate',
    LOCATION_ON_CLICK: 'developer-property-overview.location:navigate',
  },
  ASK_GURU: {
    ASK_QUESTION_ON_CLICK: 'askguru-ask-question:click',
    BROWSE_QUESTIONS_ON_CLICK: 'askguru-browse-questions:click',
    CATEGORY_LINK_ON_CLICK: 'askguru-category-link:click',
  },
  ASK_GURU_MODEL: {
    SUBMIT_ON_CLICK: 'askguru-form.submit: click',
    SUBMIT_WITH_GOOGLE_ON_CLICK: 'askguru-form.submit-with-google: click',
    SUBMIT_SUCCESS: 'askguru-form.submit-success',
  },
  PG_LOGIN: {
    BACK: 'pg-login:back',
    CLOSE: 'pg-login:close',
    LOGIN_ATTEMPT: 'pg-login.login.attempt',
    LOGIN_SUBMIT_ATTEMPT: 'pg-login.login.submit.attempt',
    LOGIN_SUCCESS: 'pg-login.login.success',
    LOGIN_RESEND_OTP: 'pg-login.login.resend.otp',
    LOGIN_WITH_PASSWORD: 'pg-login.login.with.password',
    LOGIN_WITH_OTP: 'pg-login.login.with.otp',
    LOGOUT_SUCCESS: 'pg-login.logout.success',
    REGISTRATION_SUCCESS: 'pg-login.registration.success',
    REGISTRATION_EMAIL_VERIFY: 'pg-login.registration.email.verify',
    REGISTRATION_OTP_VERIFY: 'pg-login.registration.otp.verify',
    REGISTRATION_RESEND_OTP: 'pg-login.registration.resend.otp',
    REGISTRATION_SKIP_PASSWORD: 'pg-login.registration.skip.password',
    REGISTRATION_CREATE_PASSWORD_ATTEMPT: 'pg-login.create.password.attempt',
    REGISTRATION_CREATE_PASSWORD_SUCCESS: 'pg-login.create.password.success',
    RESET_PASSWORD_ATTEMPT: 'pg-login.reset.password.attempt',
    RESET_PASSWORD_SUCCESS: 'pg-login.reset.password.success',
    MODAL_VIEW_ACTIVE: 'pg-login.login.registration.view',
  },
  OTP_VIEW: {
    OTP_VERIFY_ATTEMPT: 'pg-login.otp.verify.attempt',
  },
  SRP_RENT_PROFILE_NUDGE: {
    PREFERENCES_CLICK_SAVE: 'srp-rent.profile-nudge.preferences.save:click',
    PREFERENCES_CLICK_VIEW_MORE: 'srp-rent.profile-nudge.preferences.view-more:click',
    PREFERENCES_ON_LOAD: 'srp-rent.profile-nudge.preferences.on-load',
  },
  HOME_SELLER_VALUATION_SECTION: {
    ACTION_CLICK: 'home-seller-valuation-section.action.:click',
  },
  HOME_SELLERS: {
    LOCATION_ON_SELECT: 'home-sellers.location:click',
    CALCULATE_ON_CLICK: 'home-sellers.address-selector.calculate:click',
    PROPERTY_GUIDE_ON_CLICK: 'home-sellers.property-guide:click',
    SUBMIT_ADDRESS_ON_CLICK: 'home-sellers.submit-address:click',
    MISSING_INFORMATION_ON_CLICK: 'home-sellers.missing-information:click',
  },
  HOME_VALUATION: {
    UNLOCK_ON_CLICK: 'home-valuation.unlock:click',
    USER_CONTINUE_ON_CLICK: 'home-valuation.user-continue:click',
    USER_VERIFY_ON_CLICK: 'home-valuation.user-verify:click',
    SEND_REPORT_ON_CLICK: 'home-valuation.send-report:click',
    SHOW_MORE_ON_CLICK: 'home-valuation.show-more:click',
    REQUEST_CONTACT_INFO_ON_CLICK: 'home-valuation.request-contact-info:click',
    LETS_CHAT_ON_CLICK: 'home-valuation.lets-chat:click',
    SENDHELPER_ON_CLICK: 'home-valuation.sendhelper:click',
    PG_FINANCE_ON_CLICK: 'home-valuation.pg-finance:click',
    VIEW_ALL_ON_CLICK: 'home-valuation.view-all:click',
  },
  NEW_HOMES_CARD_GROUP: {
    SCROLL: 'new-homes.card-group:scroll',
    CLICK: 'new-homes.card-group:click',
    FILTER_SELECT: 'new-homes.card-group:filter-select',
  },
  NEW_HOMES_SPOTLIGHT: {
    SLIDE: 'spotlight-project.section:slide',
  },
  MAP_POI_TAB: {
    CLICK: 'map.poi-tab:click',
  },
  HUI_MAP: {
    MAP_OVERLAY_CLICK: 'hui-map.map-overlay:click',
  },
  DESTINATION_CARD_GROUP: {
    LOCATION_ON_CLICK: 'destination-card-group-location-suggestion:click',
  },
  MOBILE_NUMBER_VERIFICATION: {
    VIEW: 'mobile-number-verification:view',
    SUBMIT_ON_CLICK: 'mobile-number-verification-submit:click',
    VERIFY_SUCCESS: 'mobile-number-verification-verify-success',
  },
  SOCIAL_SHARE_BUTTON_GROUP: {
    CLICK: 'social-share-button-group:click',
  },
  MEDIA_GALLERY: {
    IMAGE_ON_CLICK: 'media-gallery-image:click',
    VIEW_MORE_ON_CLICK: 'media-gallery-view-more:click',
    ACTION_ON_CLICK: 'media-gallery-action:click',
  },
  MEDIA_EXPLORER: {
    CLOSE: 'media-gallery-close:click',
    OPEN: 'media-gallery-open:click',
    PREV_ITEM_ON_CLICK: 'media-explorer-prev-item:click',
    NEXT_ITEM_ON_CLICK: 'media-explorer-next-item:click',
    HEADER_ACTION_ITEM_ON_CLICK: 'media-explorer-header-action-item:click',
    FOOTER_ACTION_ITEM_ON_CLICK: 'media-explorer-footer-action-item:click',
    FOOTER_ACTION_ICON_ON_CLICK: 'media-explorer-footer-action-icon:click',
    FOOTER_ACTION_ITEM_THUMBNAIL_ON_CLICK: 'media-explorer-footer-action-item-thumbnail:click',
    FOOTER_ACTION_ITEM_THUMBNAIL_VIEW_ON_CLICK: 'media-explorer-footer-action-item-thumbnail-view:click',
    PLAY_VIDEO: 'media-explorer-play-video:click',
    STOP_VIDEO: 'media-explorer-stop-video:click',
  },
  CONTEXTUAL_ACTION: {
    ITEM_ON_CLICK: 'contextual-action-item:click',
  },
  LISTING_DETAIL_SECTION: {
    OTHER_LISTING_LINK_ON_CLICK: 'listing-detail-other-listing-link:click',
    PROJECT_DETAIL_LINK_ON_CLICK: 'listing-detail-project-detail-link:click',
    REVIEW_LINK_ON_CLICK: 'listing-detail-review-link:click',
    REPORT_BUTTON_ON_CLICK: 'listing-detail-report-button:click',
  },
  PRICE_INSIGHT: {
    HEADER_ITEM_ON_CLICK: 'price-insight-header-item:click',
    CATEGORY_ITEM_ON_CLICK: 'price-insight-category-item:click',
    BEDROOM_ITEM_ON_CLICK: 'price-insight-bedroom-item:click',
    PERIOD_ITEM_ON_CLICK: 'price-insight-period-item:click',
  },
  LISTING_LOCATION_SECTION: {
    MAP_EXPLORER_ON_CLICK: 'listing-location-section.map-explorer:click',
    POI_WIDGET_ON_CLICK: 'listing-location-section.poi-widget:click',
    POI_PIN_ON_CLICK: 'listing-location-section.poi-pin:click',
  },
  MY_DESTINATION: {
    ADD_DESTINATION_ON_CLICK: 'my-destination.add-destination:click',
    DESTINATION_ON_ADDED: 'my-destination.destination:added',
    EDIT_DESTINATION_ON_SAVED: 'my-destination.destination-edit:saved',
    VIEW_ROUTE_ON_CLICK: 'my-destination.view-route:click',
    EDIT_ON_CLICK: 'my-destination.edit:click',
    REMOVE_ON_CLICK: 'my-destination.remove:click',
    CONFIRM_DELETE_ON_CLICK: 'my-destination.confirm-delete::click',
  },
  SEND_ENQUIRY: {
    MANAGE_PROFILE_ON_CLICK: 'send-enquiry.manage-profile:click',
    SUBMIT_ON_CLICK: 'send-enquiry.submit:click',
    CLOSE_ON_CLICK: 'send-enquiry.close:click',
    SUBMIT_ON_SUCCESS: 'send-enquiry.submit:success',
  },
  DEVELOPER_SEND_ENQUIRY: {
    MANAGE_PROFILE_ON_CLICK: 'developer-send-enquiry.manage-profile:click',
    SUBMIT_ON_CLICK: 'developer-send-enquiry.submit:click',
    CLOSE_ON_CLICK: 'developer-send-enquiry.close:click',
    SUBMIT_ON_SUCCESS: 'developer-send-enquiry.submit:success',
    INTENT_ON_CLICK: 'developer-send-enquiry.intent:click',
    CONTACT_ON_CLICK: 'developer-send-enquiry.contact:click',
    NOTIFY_ON_CLICK: 'developer-send-enquiry.notify:click',
  },
  REVOKE_CONSENT: {
    REVOKE_ON_CLICK: 'revoke-consent:click',
  },
  HIDE_LISTING_MODAL: {
    SUBMIT_ON_SUCCESS: 'hide-listing-modal.submit:success',
  },
  REPORT_LISTING_MODAL: {
    SUBMIT_ON_SUCCESS: 'report-listing-modal.submit:success',
  },
  PROPERTY_UNITS: {
    VIEW: 'property-units:view',
    CATEGORY_ITEM_ON_CLICK: 'property-units-category-item:click',
    UNIT_TYPE_VIEW: 'property-units-unit-type:view',
    UNIT_TYPE_ITEM_ON_CLICK: 'property-units-unit-type-item:click',
    VIEW_FLOORPLAN_ON_CLICK: 'property-units-view-floorplan:click',
    REQUEST_FLOORPLAN_ON_CLICK: 'property-units-request-floorplan:click',
    REQUEST_PRICE_ON_CLICK: 'property-units-request-price:click',
  },
  CONTACT_AGENT: {
    VIEW: 'contact-agent:view',
    REVIEW_CLICK: 'contact-agent-review:click',
    EXTENDED_VIEW_ON_CLOSE: 'contact-agent.extended-view:close',
  },
  CONTACT_DEVELOPER: {
    VIEW: 'contact-developer:view',
    INTENT_ON_CLICK: 'contact-developer.intent:click',
  },
  DESCRIPTION_BLOCK: {
    SHOW_MORE_ON_CLICK: 'description-block.show-more:click',
  },
  PROPERTY_AMENITIES: {
    SHOW_MORE_ON_CLICK: 'property-amenities.show-more:click',
  },
  META_TABLE: {
    SHOW_MORE_ON_CLICK: 'meta-table.show-more:click',
  },
  GUIDES_FEATURED_POST: {
    POST_ON_CLICK: 'guides-featured-post.post:click',
  },
  GREEN_SCORE: {
    VIEW: 'green-score:view',
  },
  FINANCE_ENQUIRY: {
    PREQUAL: 'finance-enquiry.prequal:navigate',
    LOANS: 'finance-enquiry.loans:navigate',
    TALK_TO_US: 'finance-enquiry.talk-to-us:navigate',
    BACK: 'finance-enquiry.back:navigate',
    CONFIRM: 'finance-enquiry.confirm:lead',
  },
  HOME_OWNER: {
    ERROR_PAGE: {
      RETRY_ON_CLICK: 'property-owner.error-page.retry:click',
    },
    PROPERTY_SUMMARY: {
      EDIT_ADDRESS_ON_CLICK: 'home-owner.property-summary.edit-address:click',
      WATCH_POPOVER_ON_TOGGLE: 'home-owner.property-summary.watch-popover-toggle:click',
      WATCH_POPOVER_ON_DISMISS: 'home-owner.property-summary.watch-popover-dismiss:click',
      VALUE_POPOVER_ON_TOGGLE: 'home-owner.property-summary.value-popover-toggle:click',
      VALUE_POPOVER_ON_DISMISS: 'home-owner.property-summary.value-popover-dismiss:click',
      WATCH_ON_TOGGLE: 'home-owner.property-summary.watch-toggle:click',
    },
    PROPERTY_TRANSACTIONS: {
      VIEW_MORE_ON_CLICK: 'home-owner.property-transactions.view-more:click',
    },
    PROPERTY_DETAILS_MODAL: {
      PROPERTY_SELECT: 'home-owner.property-details-modal.property:select',
      SEE_PROPERTY_INSIGHTS_CLICK: 'home-owner.property-details-modal.see-property-insights:click',
      ADDRESS_NOT_FOUND_CLICK: 'home-owner.property-details-modal.address-not-found:click',
      SUBMIT_MISSING_ADDRESS_CLICK: 'home-owner.property-details-modal.submit-missing-address:click',
      UNIT_NOT_FOUND_CLICK: 'home-owner.property-details-modal.unit-not-found:click',
      EDIT_PROPERTY_DETAILS_CLICK: 'home-owner.property-details-modal.edit-property-details:click',
      SUBMIT_MISSING_UNIT_CLICK: 'home-owner.property-details-modal.submit-missing-unit:click',
    },
    MORTGAGE_DETAILS_MODAL: {
      SAVE_CLICK: 'home-owner.mortgage-details-modal.save-mortgage-details:click',
    },
    PROPERTY_INSIGHTS: {
      DATA_QUALITY_POPOVER_ON_TOGGLE: 'home-owner.property-insights.data-quality-popover-toggle:click',
      DATA_QUALITY_POPOVER_ON_DISMISS: 'home-owner.property-insights.data-quality-popover-dismiss:click',
    },
    MORTGAGE_DETAILS_CARD: {
      INPUT_CLICK: 'home-owner.mortgage-details-card-wrapper.input:click',
      TRACK_MORTGAGE_CLICK: 'home-owner.mortgage-details-card-wrapper.track-mortgage:click',
      CONSULT_AN_EXPERT_CLICK: 'home-owner.mortgage-details-card-wrapper.consult-an-expert:click',
      VIEW_LOANS_CLICK: 'home-owner.mortgage-details-card-wrapper.view-loans:click',
      LOCK_IN_CLICK: 'home-owner.mortgage-details-card-wrapper.lock-in:click',
      POPOVER_ON_TOGGLE: 'home-owner.property-summary.popover-toggle:click',
      POPOVER_ON_DISMISS: 'home-owner.property-summary.popover-dismiss:click',
    },
    API_ERROR: {
      REFRESH_CLICK: 'home-owner.api-error.refresh:click',
    },
  },
  PREQUAL_LANDING: {
    MASTHEAD_CTA_ON_CLICK: 'prequal-landing.masthead-cta:click',
    MASTHEAD_FOOTER_CTA_ON_CLICK: 'prequal-landing.masthead-footer-cta:click',
    STEP_EXPLAINER_CTA_ON_CLICK: 'prequal-landing.step-explainer-cta:click',
  },
  RENTING_PROFILE_FORM: {
    INPUT_ON_CHANGE: 'renting-profile-form.input:change',
  },
  SHARE_PROFILE: {
    EDIT_ON_CLICK: 'share-profile.edit-button:click',
    SHARE_ON_TOGGLE: 'share-profile.share-toggle:click',
  },
  COMPLETE_PREFERENCES_NUDGE: {
    BUTTON_CTA_ON_CLICK: 'complete-preferences-nudge.button-cta:click',
  },
  SENDHELPER_PROMO_SECTION: {
    SUBMIT_ON_CLICK: 'sendhelper-promo-section.submit:click',
    COPY_ON_CLICK: 'sendhelper-promo-section.copy:click',
    CTA_ON_CLICK: 'sendhelper-promo-section.cta:click',
  },
  SENDHELPER_SECTION: {
    CTA_ON_CLICK: 'sendhelper-section.cta:click',
  },
  PROPERTY_TOOLKIT_CARD_WRAPPER: {
    REPORT_ON_CLICK: 'property-toolkit-card-wrapper.report:click',
    REPORT_ON_SUBMIT: 'property-toolkit-card-wrapper.report:submit',
    AGENT_ON_CLICK: 'property-toolkit-card-wrapper.agent:click',
  },
  COMPARISON_TABLE_SECTION: {
    PRIMARY_CTA_ON_CLICK: 'comparison-table-section.primary-cta:click',
    SECONDARY_CTA_ON_CLICK: 'comparison-table-section.secondary-cta:click',
    POPOVER_ON_TOGGLE: 'comparison-table-section.popover-toggle:click',
    POPOVER_ON_DISMISS: 'comparison-table-section.popover-dismiss:click',
  },
  ENQUIRY_MODAL: {
    SUBMIT: 'enquiry-modal.submit:click',
    RETURN_HOME: 'enquiry-modal.return-home:click',
  },
  RECENT_TRANSACTIONS_CARD_WRAPPER: {
    PRICE_POPOVER_ON_TOGGLE: 'recent-transactions-card-wrapper.value.popover-toggle:click',
    PRICE_POPOVER_ON_DISMISS: 'recent-transactions-card-wrapper.value.popover-dismiss:click',
    TOGGLE_POPOVER_ON_TOGGLE: 'recent-transactions-card-wrapper.toggle.popover-toggle:click',
    TOGGLE_POPOVER_ON_DISMISS: 'recent-transactions-card-wrapper.toggle.popover-dismiss:click',
    VIEW_ALL_ON_CLICK: 'recent-transactions-card-wrapper.view-all:click',
    VIEW_MORE_MODAL_ON_TOGGLE: 'recent-transactions-card-wrapper.view-more.modal-open:click',
    VIEW_MORE_MODAL_ON_CLOSE: 'recent-transactions-card-wrapper.view-more.modal-close:click',
  },
  PRICE_INSIGHTS_OVERVIEW_SECTION: {
    SALE_EXPLORE_MORE_ON_CLICK: 'price_insights_overview_section.sale.explore-more:click',
    RENTAL_EXPLORE_MORE_ON_CLICK: 'price_insights_overview_section.rental.explore-more:click',
  },
  EXPANDABLE_TABLE: {
    ROW_ON_CLICK: 'expandable-table.row:click',
    BANNER_ON_CLICK: 'expandable-table.banner:click',
  },
  PRICE_HISTORY: {
    INTENT_TOGGLE_ON_CLICK: 'price_history.intent-toggle:click',
    SEE_ALL_CTA_ON_CLICK: 'price_history.see-all-cta:click',
  },
  PAGINATION: {
    PAGE_CHANGE_ON_CLICK: 'pagination.page-change:click',
  },
  PROJECT_DETAILS_SECTION: {
    VIEW: 'project-details-section:view',
    VIEW_PROJECT_DETAILS_LINK_ON_CLICK: 'project-details-section-view-project-details-link:click',
    SEE_MORE_LISTINGS_LINK_ON_CLICK: 'project-details-section-see-more-listings-link:click',
  },
};

export default EVENT_NAMES;
