import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { phone } from 'phone';
import { ChangeEventHandler, FC, FormEventHandler } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { filterMetadata } from 'root/widgets/utils/filter';

import { ContactFormViewProps } from './types';
import { Masthead } from 'root/widgets/common-components/masthead';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Typography } from 'root/common/atom-elements/typography';
import Actionable from 'root/widgets/common-components/actionable';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';

const ContactFormView: FC<ContactFormViewProps> = (props) => {
  const { className, masthead, inputGroup, leadData, onDataChange, action, serverError, metadata, userInfo } = props;
  const { name, email, mobile, consent } = inputGroup;

  const isFormValid =
    leadData.name &&
    leadData.email &&
    phone(`+${mobile.countryCode}${leadData.mobile}`, { strictDetection: true }).isValid;

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    onDataChange({ name: evt.target.value });
  };

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    onDataChange({ email: evt.target.value });
  };

  const handleMobileChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    onDataChange({ mobile: evt.target.value });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    action?.onClick?.();
  };

  const handleConsentCheckChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    onDataChange({ hasConsented: evt.target.checked });
  };

  return (
    <div
      className={classNames('hsv-cv-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <Masthead {...masthead} />
      <Form className="d-flex flex-column hsv-form mt-4" onSubmit={handleSubmit}>
        <Form.Group controlId="cv-name">
          <Form.Label className="mb-1 hsv-label">{name.label}</Form.Label>
          <div className="position-relative">
            <Form.Control
              type="text"
              required
              {...omit(name, ['label', 'invalidFeedback'])}
              name="name"
              value={leadData.name}
              disabled={!isEmpty(userInfo.name)}
              onChange={handleNameChange}
              {...extractDataAutomationId(name.metadata)}
            />
          </div>
        </Form.Group>
        <Form.Group controlId="cv-email">
          <Form.Label className="mb-1 hsv-label">{email.label}</Form.Label>
          <div className="position-relative">
            {userInfo.email && <i className="pgicon-ok-circled hsv-verified-icon" />}

            <Form.Control
              type="email"
              required
              {...omit(email, ['label', 'invalidFeedback'])}
              name="email"
              value={leadData.email}
              disabled={!isEmpty(userInfo.email)}
              onChange={handleEmailChange}
              {...extractDataAutomationId(email.metadata)}
            />
          </div>
        </Form.Group>
        <Form.Group className={classNames({ verified: mobile.isVerified })} controlId="cv-mobile">
          <Form.Label className="mb-1 hsv-label">{mobile.label}</Form.Label>
          <InputGroup>
            <InputGroup.Text>+{mobile.countryCode}</InputGroup.Text>
            {userInfo.isMobileVerified && <i className="pgicon-ok-circled hsv-verified-icon" />}
            <Form.Control
              pattern="[0-9]*"
              type="number"
              inputMode="numeric"
              required
              {...omit(mobile, ['label', 'countryCode', 'isVerified', 'invalidFeedback'])}
              name="mobile"
              value={leadData.mobile}
              disabled={userInfo.isMobileVerified}
              onChange={handleMobileChange}
              {...extractDataAutomationId(mobile.metadata)}
            />
          </InputGroup>
        </Form.Group>
        {!isEmpty(consent) && (
          <Form.Group controlId="cv-consent" className="d-flex gap-2">
            <Checkbox name="consent" checked={leadData.hasConsented} onChange={handleConsentCheckChange} />
            <Typography htmlFor="cv-consent" element="label">
              {consent.label}
            </Typography>
          </Form.Group>
        )}
        <Actionable
          variant="primary"
          type="submit"
          shouldShowLoading={action.shouldShowLoading}
          disabled={!isFormValid || action.isDisabled}
          {...extractDataAutomationId(action.metadata)}
        >
          {action.text}
        </Actionable>
      </Form>
      {!isEmpty(serverError) && (
        <HUIAlert className="pt-3 mt-3" variant="danger">
          {serverError}
        </HUIAlert>
      )}
    </div>
  );
};

ContactFormView.displayName = 'ContactFormView';

export default ContactFormView;
