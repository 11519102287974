import omit from 'lodash/omit';

import { DATA_AUTOMATION_ID } from 'root/widgets/constants';

/* eslint-disable import/prefer-default-export */
export const withTextMatch = (list, text, keys) => {
  if (!text) {
    return list;
  }

  const pattern = new RegExp(text, 'gi');

  return list.filter((item) => keys.some((key) => item[key].match(pattern)));
};

/**
 * This function help to filter unwanted metadata
 *
 * @param metadata - metadata object
 * @returns
 */
export const filterMetadata = (metadata) => omit(metadata, [DATA_AUTOMATION_ID]);
