import classNames from 'classnames';
import React from 'react';
import parse from 'html-react-parser';

import './copyright.scss';
import { CopyrightSegmentProps } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const Copyright: React.FC<CopyrightSegmentProps> = ({ copyrightSegmentData, className }) => (
  <div className={classNames('copyright-strip-root', className)}>
    <div className="links-section">
      {copyrightSegmentData.links.map((item) => (
        <ActionableLink className="term-link" key={`copyright-${item.text}`} href={item.href}>
          {item.text}
        </ActionableLink>
      ))}
    </div>
    <div className="copy-text-section">
      <div className="copy-text">{parse(copyrightSegmentData.content)}</div>
    </div>
  </div>
);

Copyright.displayName = 'Copyright';

export default Copyright;
