import { isBrowser } from 'root/widgets/utils/validation';

import { StorageType } from './enums';

/**
 * `getItem` will get the data from the storage (sessionStorage or localStorage)
 * @param key - The storage's key to get data
 * @param storageType - Optional parameter to get from sessionStorage or localStorage. By default, it use localStorage.
 */
const getItem = (key: string, storageType = StorageType.local) => (isBrowser() ? window[storageType].getItem(key) : '');

/**
 * `setItem` will set the data to the storage (sessionStorage or localStorage)
 * @param key - The storage's key to set data
 * @param value - The storage's value to set data
 * @param type - Optional parameter to get from sessionStorage or localStorage. By default, it use localStorage.
 */
const setItem = (key: string, value: string, type = StorageType.local) => {
  if (isBrowser()) {
    window[type].setItem(key, value);
  }
};

/**
 * `removeItem` will remove the data from the storage (sessionStorage or localStorage)
 * @param key - The storage's key to delete data
 * @param type - Optional parameter to get from sessionStorage or localStorage. By default, it use localStorage.
 */
const removeItem = (key: string, type = StorageType.local): void => {
  if (isBrowser()) {
    window[type].removeItem(key);
  }
};

export default { getItem, setItem, removeItem };
