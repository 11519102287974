import cx from 'classnames';
import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';

import { SvgIconProps } from './types';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const CURRENT_COLOR = 'currentColor'; // currentColor is defined in SVG standard

const SvgIcon: React.FC<SvgIconProps> = ({
  className,
  src,
  title,
  width = 24,
  height = '100%',
  color = CURRENT_COLOR,
  metadata = {
    dataAutomationId: 'svg-icon',
  },
  shouldUseImage,
  isLazyLoadingEnabled,
}) => {
  const styles = {
    backgroundColor: color && color !== CURRENT_COLOR ? color : 'inherit',
    mask: `url(${src}) no-repeat center / contain`,
    WebkitMask: `url(${src}) no-repeat center / contain`,
    width: width,
    height: height,
    minWidth: width,
    alignSelf: 'center',
  };

  const svgProcessor = useCallback(
    (svgCode) => {
      const dataAutomationId = metadata['data-automation-id'];
      if (color !== CURRENT_COLOR) {
        svgCode = svgCode.replace(/fill=".*?"/g, `fill="${color}"`);
      }

      return svgCode.replace(/xmlns="(.*?)"/g, `xmlns="$1" data-automation-id="${dataAutomationId}"`);
    },
    [color, metadata],
  );

  const ImageSVG =
    color && color !== CURRENT_COLOR ? (
      <div
        title={title}
        className={cx('svg-icon-wrapper', className)}
        style={styles}
        {...extractDataAutomationId(metadata)}
      >
        {title}
      </div>
    ) : (
      <img
        loading={isLazyLoadingEnabled ? 'lazy' : 'eager'}
        src={src}
        alt={title}
        className={cx('svg-icon-wrapper', className)}
        height={height}
        width={width}
        {...extractDataAutomationId(metadata)}
      />
    );

  if (shouldUseImage) {
    return ImageSVG;
  }

  return (
    // @ts-expect-error: temporary ignore this TS error after upgrade 'react-inlinesvg' library
    <SVG
      src={src}
      width={width}
      height={height}
      title={title}
      preProcessor={svgProcessor}
      className={cx('svg-icon-wrapper', className)}
      {...extractDataAutomationId(metadata)}
    />
  );
};

SvgIcon.displayName = 'SvgIcon';

export default SvgIcon;
