import React, { useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Hint, Typeahead } from 'react-bootstrap-typeahead';

import { SearchInput } from 'root/widgets/common-components/search-input';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './search-fullscreen.scss';
import type { SearchFullScreenProps } from './types';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import { FoundLocation } from 'root/widgets/common-components/found-locations-view';

const SearchFullScreen: React.FC<SearchFullScreenProps> = (props) => {
  const {
    children,
    placeholder,
    submitButtonText,
    shouldDisallowFreeTextSearch,
    onSearchSubmit,
    onClose,
    onSearchTextChange,
    editInput,
    foundLocations,
    onLocationSelect,
  } = props;

  const { dataAutomationId } = useContext(SearchWithFilterContext);

  return (
    <HUIModal className="search-fullscreen-root" show fullscreen onHide={onClose}>
      <HUIModal.Header closeButton>Search</HUIModal.Header>
      <HUIModal.Body>
        <div className="input-wrap">
          <SearchInput className="search-input" onChange={onSearchTextChange} onSubmit={onSearchSubmit}>
            <Typeahead
              id="search-typeahead-fullscreen"
              className="form-control typeahead"
              options={foundLocations}
              labelKey="displayText"
              placeholder={placeholder}
              onInputChange={editInput.onInputChange}
              onChange={(selected) => onLocationSelect(selected[0] as FoundLocation)}
              defaultInputValue={editInput.value}
              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                <Hint>
                  <Form.Control
                    {...inputProps}
                    ref={(node) => {
                      inputRef(node);
                      referenceElementRef(node);
                    }}
                    {...extractDataAutomationId({ dataAutomationId: dataAutomationId?.input })}
                    value={editInput.value}
                    onClick={editInput.onClick}
                    autoFocus
                  />
                </Hint>
              )}
            >
              {editInput.value && <i className="pgicon-cancel-circled" onClick={editInput.onClear} />}
            </Typeahead>
          </SearchInput>
        </div>
        <div className="items-container">{children}</div>
      </HUIModal.Body>
      {!shouldDisallowFreeTextSearch && (
        <HUIModal.Footer className="modal-footer">
          <Button variant="primary" onClick={onSearchSubmit}>
            {submitButtonText}
          </Button>
        </HUIModal.Footer>
      )}
    </HUIModal>
  );
};

SearchFullScreen.displayName = 'SearchFullScreen';

export default SearchFullScreen;
