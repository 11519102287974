import { GAEcommerceType, GAPageViewType, GATrackingType } from 'root/global-wrappers/types/analytics';
import { default as ga4Helper } from 'root/widgets/utils/ga4';

declare const window: any;

/**
 * `trackPageView` will send pageview events to GA4
 *
 * @param page - The page name or path that will be sent with PageView Event
 * @param dimensions - GA Custom Dimensions
 */
const trackPageView = (data: GAPageViewType) => {
  ga4Helper.trackPageViewEvent(window.gtag, { ...data.dimensions });
};

/**
 * `trackEvent` will send the relevant data to GA4
 */
const trackEvent = (data: GATrackingType) => {
  ga4Helper.trackEvent(window.gtag, data);
};

const trackEventEnhancedEcommerce = (trackingData: GAEcommerceType) => {
  ga4Helper.trackECommerceEvent(window.gtag, trackingData);
};

export default {
  trackPageView,
  trackEvent,
  trackEventEnhancedEcommerce,
};
