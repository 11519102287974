import classNames from 'classnames';

import { PropertyTransactionSummary, PropertyTransactionSummaryProps } from 'root/widgets/property-transaction-summary';

import { PGLegalInfo } from 'root/widgets/pg-legal-info';

import {
  AgentPastTransactionsSummary,
  AgentPastTransactionsSummaryProps,
} from 'root/widgets/agent-past-transactions-summary';

import { EmptyView } from 'root/widgets/empty-view';

import './agent-past-transactions.scss';
import { AgentPastTransactionsProps } from './types';
import { ProgressGroupView, ProgressGroupViewProps } from 'root/widgets/common-components/progress-group-view';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import Title from 'root/widgets/common-components/title';

const AgentPastTransactions = (props: AgentPastTransactionsProps) => {
  const {
    className,
    metadata,
    heading,
    summary,
    agentPastTransactionsSummaryLocale,
    transactionTypeBreakdownLocale,
    propertyTypeBreakdownLocale,
    clientTypeBreakdownLocale,
    disclaimerLocale,
    rankingSummary,
    emptyViewLocale,
  } = props;

  const agentPastTransactionSummaryProps: AgentPastTransactionsSummaryProps = {
    ...agentPastTransactionsSummaryLocale,
    totalPastTransactions:
      summary.transactionCount > 0
        ? summary.transactionCount.toString()
        : agentPastTransactionsSummaryLocale.zeroTransactionText,
    actionable:
      summary.transactionCount > 0 && agentPastTransactionsSummaryLocale.actionable
        ? agentPastTransactionsSummaryLocale.actionable
        : undefined,
  };

  const transactionTypeBreakdownProps: ProgressGroupViewProps = {
    ...transactionTypeBreakdownLocale,
    groupData: summary.transactionTypeBreakdown.map((eachItem) => ({
      label: eachItem.transactionType,
      value: eachItem.transactionCount,
    })),
  };

  const clientTypeBreakdownProps: ProgressGroupViewProps = {
    ...clientTypeBreakdownLocale,
    groupData: summary.clientTypeBreakdown.map((eachItem) => ({
      label: eachItem.clientType,
      value: eachItem.transactionCount,
    })),
  };

  const propertyTypeBreakdownProps: PropertyTransactionSummaryProps = {
    ...propertyTypeBreakdownLocale,
    ...rankingSummary,
    transactions: summary.propertyTypeBreakdown,
  };

  const emptyViewProps = {
    ...emptyViewLocale,
    shouldShowVerticalLayout: true,
  };

  return (
    <div
      className={classNames('agent-past-transactions-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <Title headerTag="h2" className="agent-past-transactions-heading">
        {heading}
      </Title>
      <AgentPastTransactionsSummary {...agentPastTransactionSummaryProps} />
      {summary.transactionCount > 0 ? (
        <>
          <div className="agent-past-transactions-group-view">
            <ProgressGroupView
              {...transactionTypeBreakdownProps}
              {...extractDataAutomationId({ dataAutomationId: 'agent-past-transactions-type-row' })}
            />
            <ProgressGroupView
              {...clientTypeBreakdownProps}
              {...extractDataAutomationId({ dataAutomationId: 'agent-past-transactions-client-type-row' })}
            />
          </div>
          <PropertyTransactionSummary {...propertyTypeBreakdownProps} />
        </>
      ) : (
        <EmptyView {...emptyViewProps} />
      )}
      <PGLegalInfo {...disclaimerLocale} />
    </div>
  );
};

AgentPastTransactions.displayName = 'AgentPastTransactions';

export default AgentPastTransactions;
