const endpoints = {
  legacy: {
    resetPassword: '/auth/reset-password',
    setPassword: '/auth/set-password',
    userExists: '/auth/user/exists',
    generateOtp: '/auth/otp/generate',
    login: '/user/jwt/login',
    appleLoginRedirectURI: '/user/login-social',
  },
  symbiosis: {
    resetPassword: '/api/core/users/password/reset',
    setPassword: '/api/core/users/password/set',
    userExists: '/api/core/users/query',
    generateOtp: '/api/core/auth/otp',
    login: '/api/core/auth/login',
    appleLoginRedirectURI: '/',
  },
};

export default endpoints;
