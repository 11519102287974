import classNames from 'classnames';
import React from 'react';
import { Offcanvas } from 'react-bootstrap';

import { HIVE_STATIC_VERSION } from 'root/widgets/constants';
import { NoticationPanelBodyProps } from 'root/widgets/notification-panel/types';

import { ContentCard } from './content-card';
import Wrapper from 'root/widgets/common-components/wrapper';
import HUIImage from 'root/widgets/common-components/hui-image';
import Title from 'root/widgets/common-components/title';

const NotificationPanelBody: React.FC<NoticationPanelBodyProps> = ({ brazeSDK, config, data }) => {
  const emptyNotificationImage = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/images/default-empty-notification.svg`;

  const { emptyCardTitle, emptyCardDescription, emptyCardImageAltText } = config.defaultTexts;
  const { cards, rawCardMappings } = data;

  return (
    <Offcanvas.Body className={classNames('notification-panel-body')}>
      {cards.length > 0 && (
        <Wrapper className="body-content">
          {cards.map((card) => (
            <ContentCard key={card.id} brazeSDK={brazeSDK} data={card} rawCardMappings={rawCardMappings} />
          ))}
        </Wrapper>
      )}

      {cards.length === 0 && (
        <Wrapper className="empty-content">
          <HUIImage className="empty-image" src={emptyNotificationImage} alt={emptyCardImageAltText} />
          <Title headerTag="h4" className="title">
            {emptyCardTitle}
          </Title>
          <p className="empty-description">{emptyCardDescription}</p>
        </Wrapper>
      )}
    </Offcanvas.Body>
  );
};

NotificationPanelBody.displayName = 'NotificationPanelBody';

export default NotificationPanelBody;
