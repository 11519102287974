import { LOCALE, REGION } from 'root/widgets/constants';

export const CountryMap: Record<REGION, string> = {
  [REGION.SG]: 'singapore',
  [REGION.MY]: 'malaysia',
  [REGION.TH]: 'thailand',
};

export enum NodeEnvironment {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

export const RESPONSE_TIMEOUT = 500;

export const COMMON_TRACKING = 'CommonTrackingConfig';

export const localeRedirectionMap: Partial<Record<REGION, Partial<Record<LOCALE, string>>>> = {
  [REGION.TH]: {
    [LOCALE.TH]: '/',
    [LOCALE.EN]: '/en',
  },
  [REGION.MY]: {
    [LOCALE.EN]: '/',
    [LOCALE.MS]: '/bm',
  },
};

export enum UserType {
  AGENT = 'Agent',
  ADMIN = 'Admin',
  CONSUMER = 'Consumer',
  GUEST = 'Guest',
}

export enum Endpoints {
  LOGOUT = '/api/core/users/logout',
}
