import classNames from 'classnames';
import React from 'react';

import { ICONS } from 'root/widgets/common-components/generic-input-group/constants';
import { IconProps } from 'root/widgets/common-components/generic-input-group/types';

const InputIcon: React.FC<IconProps> = ({ isPasswordInput, isPasswordShown, className, onClick }) => {
  const iconCls = isPasswordInput ? (isPasswordShown ? ICONS.PASSWORD_SHOW : ICONS.PASSWORD_HIDE) : ICONS.WARNING;
  onClick = isPasswordInput ? onClick : () => {};
  return <i className={classNames(className, iconCls, 'input-icon')} onClick={onClick} />;
};

export default InputIcon;
