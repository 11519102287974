import { CountryMap, GACustomDimensions, GA_CLIENT_ID_KEY } from 'root/global-wrappers/config';
import { staticCustomDimensions } from 'root/global-wrappers/services/events/ga/ga-global-data';
import { SegmentPageViewType, SegmentTrackingType } from 'root/global-wrappers/types/analytics';
import { logFrontendException } from 'root/global-wrappers/utils/logging';

import { SegmentStorage } from './constant';
import { REGION, UserIDType } from 'root/widgets/constants';
import storage from 'root/widgets/storage/storage';

declare const window: any;

const name = 'global-wrappers.services.analytics.segment-tracking';

const trackPageView = (data: SegmentPageViewType) => {
  const { category, name: pageName, properties = {}, options = {} } = data;

  if (!window.analytics) {
    return;
  }

  try {
    window.analytics.page(category, pageName, properties, options);
  } catch (error) {
    logFrontendException(name, trackPageView.name, error);
  }
};

const trackEvent = (data: SegmentTrackingType) => {
  const { eventName, parameters = {}, destinations } = data;
  if (!window.analytics) {
    return;
  }

  const page = staticCustomDimensions[GACustomDimensions.DIMENSION_2];
  const locale = staticCustomDimensions[GACustomDimensions.DIMENSION_4];

  try {
    parameters['metaData.Origin'] = page;
    parameters['eventData.Locale'] = locale;
    window.analytics.track(eventName, parameters, { integrations: destinations ?? { All: false } });
  } catch (error) {
    logFrontendException(name, trackEvent.name, error);
  }
};

/**
 * Identify Anonymous user to the segment platform only once
 * Tracks the user with local storage flag
 * For most of the real time users, who do not clear the cookies and localstorage
 * this function gets executed once and only once and identifies the user to Segment
 * This function also Sets the anonymous_id of Segment as PGUTID
 */
const identifyAnonymousUser = (region: REGION) => {
  const pgutId = storage.getItem(UserIDType.PGUTID);
  const gaClientId = storage.getItem(GA_CLIENT_ID_KEY);

  if (!window.analytics || !pgutId || storage.getItem(SegmentStorage.ANONYMOUS_IDENTIFIED)) {
    return;
  }

  storage.setItem(SegmentStorage.ANONYMOUS_IDENTIFIED, 'true');
  window.analytics.setAnonymousId(pgutId);
  window.analytics.identify(
    {
      pgutId,
      region: CountryMap[region],
    },
    {
      externalIds: [
        {
          id: gaClientId,
          type: 'ga_client_id',
          collection: 'users',
          encoding: 'none',
        },
      ],
    },
  );
};

/**
 * Identify the logged-in user to the segment platform only once
 * this function gets executed once and only once upon log in and
 * identifies the user to Segment with UMSTID
 */
const identifyLoggedInUser = (user: any, region: REGION) => {
  const pgutId = storage.getItem(UserIDType.PGUTID);
  const { userId, umstId } = user;

  if (!window.analytics || !umstId || storage.getItem(SegmentStorage.LOGIN_IDENTIFIED)) {
    return;
  }

  storage.setItem(SegmentStorage.LOGIN_IDENTIFIED, 'true');
  window.analytics.identify(umstId, {
    pgutId,
    legacyId: userId,
    region: CountryMap[region],
  });
};

/**
 * Reset the PGUTID and Segment anonymous_id upon logout
 */
const reset = () => {
  if (window && !window.analytics) {
    return;
  }

  try {
    window.analytics.reset();
    storage.removeItem(SegmentStorage.ANONYMOUS_IDENTIFIED);
    storage.removeItem(SegmentStorage.LOGIN_IDENTIFIED);
  } catch (error) {
    logFrontendException(name, reset.name, error);
  }
};

export default { trackEvent, trackPageView, identifyAnonymousUser, identifyLoggedInUser, reset };
