import { YahooTrackingType } from 'root/global-wrappers/types/analytics';

import { yahooProjectId } from './constant';

let pixelId = '';
declare const window: any;

/**
 * `initialize` will set pixel ID for later use
 *
 * @param id - The pixel ID of the Yahoo Pixel property
 */
const initialize = (id: string) => {
  pixelId = id;
};

/**
 * `trackEvent` will send the relevant data to Yahoo Pixel
 */
const trackEvent = (data: YahooTrackingType) => {
  window.dotq = window.dotq || [];
  window.dotq.push({
    projectId: yahooProjectId,
    properties: {
      pixelId,
      qstrings: {
        ...data,
      },
    },
  });
};

export default {
  initialize,
  trackEvent,
};
