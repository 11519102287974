/* eslint-disable no-debugger */
import debounce from 'lodash/debounce';
import React, { useCallback } from 'react';
import { Row } from 'react-bootstrap';

import { VARIANT_CLASS_MAP } from 'root/widgets/card-group/constants';
import { CardGroupProps } from 'root/widgets/card-group/types';
import { SCROLL_DEBOUNCE_DELAY } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';

const Body: React.FC<CardGroupProps> = ({ children, smallScreenVariant, context }) => {
  const captureScrollDebounced = useCallback(
    debounce(() => emit(EVENT_NAMES.CARD_GROUP.SCROLL, context), SCROLL_DEBOUNCE_DELAY),
    [],
  );

  const captureScroll = () => {
    captureScrollDebounced();
  };

  return (
    <Row onScroll={captureScroll} className={VARIANT_CLASS_MAP[smallScreenVariant]}>
      {children}
    </Row>
  );
};

export default Body;
