const staticCustomDimensions: Record<string, string> = {};
const staticEcommerceData: Record<string, string> = {};
// eslint-disable-next-line import/no-mutable-exports
let pageViewDimension20: Record<string, string | Record<string, string | number>> = {};

const setStaticCustomDimension = (key: string, value: string) => {
  staticCustomDimensions[key] = value;
};

const setStaticEcommerceData = (key: string, value: string) => {
  staticEcommerceData[key] = value;
};

const setPageViewDimension20 = (value: typeof pageViewDimension20) => {
  pageViewDimension20 = value;
};

export {
  staticCustomDimensions,
  setStaticCustomDimension,
  staticEcommerceData,
  setStaticEcommerceData,
  pageViewDimension20,
  setPageViewDimension20,
};
