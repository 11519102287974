/* eslint-disable react/jsx-no-constructed-context-values */
import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Modal } from 'react-bootstrap';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import { MULTI_VARIANT_LEVELS, VARIANTS } from './constants';
import LocationFilterModalContext, { LocationStoreContext } from './context';
import './location-filter-modal.scss';
import { initialState, reducer } from './reducer';
import { Body, Footer, Header } from './sub-components';
import { LocationFilterItemsType, LocationFilterModalProps, LocationMapType, SelectedCodesType } from './types';
import { generateLocationMap, processLaunchCodes, processSubmitCodes } from './utils';

const shouldDisplayFooter = (variant, state: { level: string }) =>
  variant === VARIANTS.SINGLE_LEVEL ||
  (variant === VARIANTS.MULTI_LEVEL && state.level === MULTI_VARIANT_LEVELS.SECOND);

const LocationFilterModal: React.FC<LocationFilterModalProps> = (props) => {
  const { className, type, dataResolver, isShown, metadata, onClose, onApply } = props;
  const { fetchLocationItems } = dataResolver;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [locationMap, setLocationMap] = useState<LocationMapType>({});
  const [locationItems, setLocationItems] = useState<LocationFilterItemsType>([]);
  const { data: swfdata, filters, setFilters, region, locale, logError } = useContext(SearchWithFilterContext);
  const { location: configs }: any = swfdata.filters;
  const { location: locationState } = filters;
  const {
    variant,
    title,
    firstLevelCodeType,
    secondLevelCodeType,
    thirdLevelCodeType,
    clearText,
    resetText,
    applyText,
  } = configs[type];
  const applyAction = {
    text: applyText,
    onClick: (data) => {
      const { codes } = data;
      let processedCodes = cloneDeep(codes) as SelectedCodesType;

      if (variant === VARIANTS.MULTI_LEVEL) {
        const { secondLevelItems } = state;
        processedCodes = processSubmitCodes(processedCodes, secondLevelItems, secondLevelCodeType, thirdLevelCodeType);
      }

      const freetext = flatten(Object.values(processedCodes))
        .map((code) => locationMap[code])
        .join(', ');
      setFilters({
        ...filters,
        location: {
          selectedCodes: processedCodes,
          freetext,
        },
        mrt: {},
        autocompleteLocation: {},
      });
      onApply({ freetext, codes: processedCodes });
    },
  };

  useEffect(() => {
    if (fetchLocationItems) {
      fetchLocationItems(region, locale, type)
        .then((items) => {
          setLocationItems(items);
          setLocationMap(generateLocationMap(items));
          return true;
        })
        .catch(() => setLocationItems([]));
    } else {
      logError(
        LocationFilterModal.name,
        'fetchLocationItems',
        'Prop dataResolver need valid function: fetchLocationItems',
      );
    }
  }, [type, locale]);

  useEffect(() => {
    if (isShown && locationItems.length > 0) {
      let selectedCodes: SelectedCodesType = locationState?.selectedCodes
        ? cloneDeep(locationState?.selectedCodes)
        : {};
      const firstLevelCode = selectedCodes[firstLevelCodeType];
      const isFirstLevel = !firstLevelCode;
      const secondLevelItems =
        variant === VARIANTS.MULTI_LEVEL && firstLevelCode
          ? locationItems.find((item) => item.code === firstLevelCode)?.items
          : [];

      if (variant === VARIANTS.SINGLE_LEVEL && !Object.keys(selectedCodes).includes(firstLevelCodeType)) {
        // if switch between district and hdb, clear reducer
        selectedCodes = {};
      } else if (variant === VARIANTS.MULTI_LEVEL && secondLevelItems) {
        selectedCodes = processLaunchCodes(selectedCodes, secondLevelItems, secondLevelCodeType, thirdLevelCodeType);
      }

      dispatch({ type: 'setStateFromUpperLayer', selectedCodes, isFirstLevel, secondLevelItems });
    }
  }, [isShown, locationItems]);

  return (
    <Modal
      className={classnames('location-filter-modal-root', variant, className)}
      show={isShown}
      fullscreen="md-down"
      onHide={onClose}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <LocationFilterModalContext.Provider value={variant}>
        <LocationStoreContext.Provider
          value={{ state, dataAutomationId: metadata?.dataAutomationId as string, dispatch }}
        >
          <Header
            title={title}
            locationMap={locationMap}
            firstLevelCodeType={firstLevelCodeType}
            resetText={resetText}
            onClose={onClose}
          />
          <Body data={configs[type]} items={locationItems} />
          {shouldDisplayFooter(variant, state) && (
            <Footer clearText={clearText} applyAction={applyAction} items={locationItems} />
          )}
        </LocationStoreContext.Provider>
      </LocationFilterModalContext.Provider>
    </Modal>
  );
};

LocationFilterModal.displayName = 'LocationFilterModal';

export default LocationFilterModal;
