import classnames from 'classnames';
import React, { FC, useContext, useMemo } from 'react';
import { MenuItem } from 'react-bootstrap-typeahead';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { HIVE_STATIC_ICON_PATH_V3 } from 'root/widgets/constants';
import { SvgIcon } from 'root/widgets/svg-icon';

import './recent-search.scss';
import { RecentSearchItemProps, RecentSearchesProps } from './types';
import { prepareFloorAreaRange, preparePriceRange } from './utils';
import { RecentSearchType } from './constants';
import { TypeaheadOptionType } from 'root/widgets/common-components/found-locations-view';

const RecentSearchItem: React.FC<RecentSearchItemProps> = (props) => {
  const { className, search, position, metadata, onSearchSelect } = props;
  const context = useContext(SearchWithFilterContext);
  const { data } = context;
  const { filters } = data;

  const handleClick = () => {
    if (onSearchSelect) {
      onSearchSelect(search);
    }
  };

  const texts = useMemo(() => {
    if (search.type === RecentSearchType.V2) {
      const {
        categoryText,
        propertyTypeCode,
        minSize: minSize1,
        bedrooms,
        listingType,
        maxPrice,
        maxSize,
        minPrice,
        minTopYear,
        maxTopYear,
        tenureCode,
        roomType,
        floorLevel,
        tenancyConditions,
        maxTenants,
        tenantGender,
        cookingType,
      } = search.titles;

      const priceText = minPrice || maxPrice;
      const priceRange = minPrice && maxPrice ? `${minPrice} - ${maxPrice}` : priceText;
      const sizeText = minSize1 || maxSize;
      const floorAreaRange = minSize1 && maxSize ? `${minSize1} - ${maxSize}` : sizeText;
      const buildYearRange = minTopYear ?? maxTopYear;

      return {
        listingType: listingType,
        bedrooms: bedrooms,
        categoryText: categoryText,
        propertyTypeCode: propertyTypeCode,
        priceRange,
        floorAreaRange,
        buildYearRange,
        tenureCode,
        roomType,
        floorLevel,
        tenancyConditions,
        maxTenants,
        tenantGender,
        cookingType,
      };
    }

    if (search.type === RecentSearchType.V1_5) {
      const {
        property_type_code: propertyTypeCode,
        minsize: minSize,
        beds: bedrooms,
        listing_type: listingType,
        minprice: minPrice,
        mintop: minTopYear,
        tenure: tenureCode,
        roomType,
        floor_level: floorLevel,
        tenancyConditions,
        maxTenants,
        tenantGender,
        cookingType,
      } = search.titles;

      return {
        listingType: listingType,
        bedrooms,
        categoryText: listingType,
        propertyTypeCode: propertyTypeCode,
        priceRange: minPrice,
        floorAreaRange: minSize,
        buildYearRange: minTopYear,
        tenureCode,
        roomType,
        floorLevel,
        tenancyConditions,
        maxTenants,
        tenantGender,
        cookingType,
      };
    }

    const {
      minprice: minPrice,
      maxprice: maxPrice,
      minsize: minSize,
      maxsize: maxSize,
      property_type_code: propertyTypeCode,
      listing_type: listingType,
      beds,
      categoryText,
      roomType,
      floor_level: floorLevel,
      tenancyConditions,
      maxTenants,
      tenantGender,
      cookingType,
    } = search.titles;

    const recentSearchCategory = listingType?.toLowerCase() || '';
    const priceRangeData = filters['priceRange'];
    const priceRangeinputData = priceRangeData && priceRangeData[recentSearchCategory];
    const priceRange = priceRangeinputData && preparePriceRange(minPrice, maxPrice, priceRangeinputData);
    const floorAreaConfig = filters['floorAreaRange'];
    const floorAreaInputData = floorAreaConfig && floorAreaConfig[recentSearchCategory];
    const floorAreaRange = floorAreaInputData && prepareFloorAreaRange(minSize, maxSize, floorAreaInputData);

    return {
      listingType,
      bedrooms: beds,
      categoryText,
      propertyTypeCode,
      priceRange,
      floorAreaRange,
      roomType,
      floorLevel,
      tenancyConditions,
      maxTenants,
      tenantGender,
      cookingType,
    };
  }, [filters, search.titles, search.type]);

  const listingType = texts.categoryText || texts.listingType;

  return (
    <MenuItem
      className={classnames('recent-searches-item-root', className)}
      option={{ ...search, displayText: '', optionType: TypeaheadOptionType.RecentSearch }}
      position={position}
      onClick={handleClick}
      {...extractDataAutomationId(metadata)}
    >
      <SvgIcon
        className="recent-searches-item__icon"
        src={`${HIVE_STATIC_ICON_PATH_V3}/clock-o.svg`}
        width={16}
        height={16}
        shouldUseImage
      />
      <div className="recent-search-wrapper">
        {search.titles.freetext && (
          <div
            className="primary-title"
            {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-freetext' })}
          >
            <span>{search.titles.freetext}</span>
          </div>
        )}
        {listingType && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-listing-type' })}>
              {`${listingType} ${texts.propertyTypeCode || ''}`}
            </span>
          </div>
        )}
        {texts.priceRange && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-price-range' })}>
              {texts.priceRange}
            </span>
          </div>
        )}
        {texts.roomType && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-room-type' })}>{texts.roomType}</span>
          </div>
        )}
        {texts.bedrooms && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-bedrooms' })}>
              {texts.bedrooms}
            </span>
          </div>
        )}
        {texts.floorAreaRange && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-floor-area-range' })}>
              {texts.floorAreaRange}
            </span>
          </div>
        )}
        {texts.buildYearRange && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-build-year-range' })}>
              {texts.buildYearRange}
            </span>
          </div>
        )}
        {texts.tenureCode && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-tenure-code' })}>
              {texts.tenureCode}
            </span>
          </div>
        )}
        {(texts.tenantGender || texts.maxTenants) && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-tenant-info' })}>
              {texts.maxTenants && texts.tenantGender
                ? `${texts.maxTenants} (${texts.tenantGender})`
                : texts.maxTenants || texts.tenantGender}
            </span>
          </div>
        )}
        {texts.cookingType && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-cooking-type' })}>
              {texts.cookingType}
            </span>
          </div>
        )}
        {texts.tenancyConditions && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-tenancy-condition' })}>
              {texts.tenancyConditions}
            </span>
          </div>
        )}
        {texts.floorLevel && (
          <div className="secondary-title">
            <span {...extractDataAutomationId({ dataAutomationId: 'recent-search-item-floor-level' })}>
              {texts.floorLevel}
            </span>
          </div>
        )}
      </div>
    </MenuItem>
  );
};

const RecentSearches: FC<RecentSearchesProps> = ({ className, items, metadata, onSearchSelect, positions, title }) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div className="recent-search-root d-flex flex-column" {...extractDataAutomationId(metadata)}>
      <div className="recent-search-headline">{title}</div>
      {items.map((item, idx) => (
        <>
          {Boolean(idx) && <div className="recent-search__separator" />}
          <RecentSearchItem
            className={className}
            key={`recent-search-${item.time}`}
            option={item}
            position={positions[idx]}
            search={item}
            onSearchSelect={onSearchSelect}
            metadata={{
              dataAutomationId: metadata?.dataAutomationId ? `${metadata.dataAutomationId}-${idx}` : '',
            }}
          />
        </>
      ))}
    </div>
  );
};

RecentSearches.displayName = 'RecentSearches';

export default RecentSearches;
