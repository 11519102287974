export enum TrackingEventType {
  PAGEVIEW = 'pageview',
  ECOMMERCE = 'ecommerce',
  EVENT = 'event',
}

export const yahooProjectId = '10000';

export enum SegmentStorage {
  ANONYMOUS_IDENTIFIED = 'isSegmentIdentified',
  LOGIN_IDENTIFIED = 'isSegmentLoginIdentified',
  IS_BRAZE_ALIAS_SET = 'isSetBrazeAlias',
}
