import classnames from 'classnames';
import React from 'react';

import { SvgIcon } from 'root/widgets/svg-icon';

import './masthead.scss';
import { MastheadProps } from './types';

const DEFAULT_SIZE = 32;

const Masthead: React.FC<MastheadProps> = ({ metadata, logo, title, description }) => (
  <div className={classnames('pg-login-masthead-root')} {...metadata}>
    <div className={classnames('pg-login-masthead-icon')}>
      <SvgIcon
        className="logo-svg"
        src={logo?.src ?? ''}
        height={logo?.size ?? DEFAULT_SIZE}
        width={logo?.size ?? 'auto'}
        shouldUseImage
      />
    </div>
    {title && <h2 className={classnames('pg-login-masthead-title')}>{title}</h2>}
    <p className={classnames('pg-login-masthead-description')}>{description}</p>
  </div>
);

Masthead.displayName = 'Masthead';

export default Masthead;
