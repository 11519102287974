import classNames from 'classnames';
import omit from 'lodash/omit';
import { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import { SvgIcon } from 'root/widgets/svg-icon';
import { ButtonProps } from './types';
import './contact-button.scss';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const ContactButton: FC<ButtonProps> = (props) => {
  const { className, leftIcon, rightIcon, text, card, shouldShowLoading, ...btnProps } = props;

  if (card) {
    return (
      <div className="contact-card-root">
        <div className="title">{card.title}</div>
        <div className="content">{card.content}</div>
      </div>
    );
  }

  return (
    <ActionableLink
      className={classNames('contact-button-root', className)}
      {...omit(btnProps, ['type', 'qrLayout', 'groupLayout', 'phoneNumber'])}
    >
      {shouldShowLoading ? (
        <Spinner animation="border" />
      ) : (
        <>
          {leftIcon && <SvgIcon height={24} width={24} shouldUseImage color="currentColor" {...leftIcon} />}

          <div className="action-text">{text}</div>

          {rightIcon && <SvgIcon height={24} width={24} shouldUseImage color="currentColor" {...rightIcon} />}
        </>
      )}
    </ActionableLink>
  );
};

ContactButton.displayName = 'ContactButton';

export default ContactButton;
