import { PGLoginTranslationData } from 'root/widgets/pg-login/types';

const translation: PGLoginTranslationData = {
  loginTitle: 'Selamat Datang ke PropertyGuru',
  loginDescription: 'Log masuk atau daftar untuk memanfaatkan sepenuhnya pengalaman di PropertyGuru',
  loginWithGoogle: 'Teruskan dengan Google',
  loginWithFacebook: 'Teruskan dengan Facebook',
  loginWithApple: 'Teruskan dengan Apple',
  emailInputPlaceholder: 'Alamat emel',
  continueBtn: 'Teruskan',
  dividerTitle: 'Atau',
  agentLoginPrompt: {
    label: 'Adakah anda ejen?',
    linkTitle: 'Log masuk dalam AgentNet',
  },
  googleLoginErrorInPrivateMode:
    'Sila kemas kini tetapan penyemak imbas anda untuk membenarkan kuki atau cuba gunakan tanpa mod inkognito/peribadi untuk meneruskan log masuk sosial',
  signUpTitle: 'Daftar Akaun Baru',
  signUpDescription:
    'Nampaknya anda tidak mempunyai akaun dengan alamat emel tersebut, daftar untuk menikmati semua faedah ini:',
  signUpInstructions: [
    'Mudahkan penjejakan pertanyaan hartanah anda yang lepas',
    'Dapatkan akses pantas kepada carian anda yang telah disimpan dan rumah yang disenarai pendek​',
    'Segerakkan pilihan hartanah anda pada semua peranti',
  ],
  verifyBtn: 'Sahkan Emel Anda',
  signupCheckboxPrompts: {
    subscribe: 'Ya, beritahu saya tentang pelancaran baru, tawaran hartanah dan tawaran rakan kongsi.',
  },
  verifyEmailTitle: 'Sahkan Emel Anda',
  verifyEmailDescription: (email) => (
    <>
      Hello <strong>{email}</strong>, kami baru sahaja menghantar kod ke emel anda. Sila semak dan masukkan kod itu di
      sini untuk meneruskan pendaftaran.
    </>
  ),
  resendAction: 'Hantar semula kod',
  setPasswordTitle: 'Akaun Anda Telah Berjaya Didaftarkan!',
  setPasswordModalTitle: 'Akaun Dicipta',
  setPasswordDescription:
    'Kami mengesyorkan anda mencipta kata laluan untuk pengukuhan keselamatan dan memudahkan akses kepada akaun anda pada masa akan datang.',
  passwordInputPlaceholder: 'Kata laluan',
  savePasswordBtn: 'Simpan kata laluan saya',
  skipAction: 'Langkau Buat Masa Ini',
  otpLoginTitle: 'Log masuk menggunakan OTP',
  otpLoginDescription: (email) => (
    <>
      Hello <strong>{email}</strong>, kami baru sahaja menghantar kod ke emel anda. Sila semak dan masukkan kod itu di
      sini untuk meneruskan pendaftaran.
    </>
  ),
  passwordLoginBtn: 'Log Masuk Dengan Kata Laluan',
  otpLoginBtn: 'Log masuk menggunakan OTP.',
  passwordLoginTitle: 'Log Masuk Dengan Kata Laluan',
  passwordLoginDescription: (email) => (
    <>
      Hello <strong>{email}</strong> sila masukkan kata laluan anda untuk log masuk ke PropertyGuru.
    </>
  ),
  invalidPassword: 'Sila masukkan kata laluan yang sah',
  loginBtn: 'Log Masuk',
  forgotPasswordAction: 'Lupa Kata Laluan',
  forgotPasswordTitle: 'Menetapkan semula kata laluan',
  forgotPasswordDescription:
    'Sila masukkan alamat emel anda dalam borang di bawah dan kami akan hantar kata laluan baharu.',
  resetPasswordBtn: 'Set Semula Kata Laluan Saya',
  checkSpamPrompt: (
    <>
      Masih belum menerima emel tersebut?
      <br />
      Sila semak peti kotak spam anda atau pilih untuk hantar semula kod
    </>
  ),
  footer: (
    <>
      Saya bersetuju untuk PropertyGuru&#39;s <a href="/terms-of-service">Syarat Perkhidmatan</a> dan{' '}
      <a href="/privasi">Polisi Privasi</a> termasuk pengumpulan, penggunaan dan pendedahan maklumat peribadi saya.
    </>
  ),
  errorMessages: {
    emailValidation: 'Sila masukkan alamat emel yang sah',
    socialLogin: 'Maaf, kami mengalami masalah log masuk sosial. Sila cuba lagi.',
    server: 'Maaf, kami mengalami masalah gangguan pelayan. Sila cuba lagi.',
    charLengthValidation: 'Mesti sekurang-kurangnya 8 aksara',
    alphaNumericValidation: 'Mesti mengandungi nombor',
    capitalLetterValidation: 'Mesti mengandungi huruf besar',
    specialCharValidation: 'Mesti mengandungi simbol',
    invalidCode: 'Kod tidak sah. Cuba lagi atau minta kod baharu.',
    otpGenerationLimit:
      'Kami mohon maaf tetapi anda tidak boleh memohon kod lain sekarang. Sila cuba dalam beberapa minit lagi',
    otpSubmissionLimit:
      'Kami mohon maaf tetapi kami tidak dapat memproses permohonan anda sekarang. Sila cuba dalam beberapa minit lagi',
    accountDeleted:
      'Maaf, alamat emel ini tidak boleh digunakan sekarang. Sila daftar untuk akaun baru dengan emel berbeza.',
  },
  verifyPhoneTitle: 'Verify Your Mobile Number', // Missing
  verifyEmailConsent: (email) => (
    <>
      We sent you a code at <strong>{email}</strong>. Please enter the code here to continue verifying.
    </>
  ), // Missing
  verifyPhoneConsent: (phone) => (
    <>
      Kod sudah dihantar ke <strong>{phone}</strong> menerusi SMS. Sila masukkan kod itu di sini untuk pengesahan.
    </>
  ),
};

export default translation;
