import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { Sheet as BottomSheet } from 'react-modal-sheet';

import { CompletePreferencesNudge } from 'root/widgets/complete-preferences-nudge';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { ShareProfileCard } from 'root/widgets/share-profile-card';
import { ContactButton } from 'root/widgets/contact-button';
import { SvgIcon } from 'root/widgets/svg-icon';
import './contact-agent-sheet-v2.scss';
import type { ContactAgentSheetV2Props } from './types';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';
import Actionable from 'root/widgets/common-components/actionable';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { HUIBadges } from 'root/widgets/common-components/hui-badges';

const ContactAgentSheetV2: React.FC<ContactAgentSheetV2Props> = ({
  className,
  consent,
  contactChannels,
  metadata,
  userIntent,
  shareProfileCard,
  completePreferencesNudge,
  shouldShow,
  enquiryStatus,
  alert,
  error,
  onHide,
  ...props
}) => {
  const handleIntentClick: MouseEventHandler = (event) => {
    emit(EVENT_NAMES.CONTACT_AGENT_CARD.INTENT_CTA_CLICK, props.context);
    userIntent?.onClick?.(event);
  };

  return (
    <BottomSheet
      isOpen={shouldShow}
      className={classNames('contact-agent-sheet-root-v2 bottom-sheet', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
      {...props}
      onClose={onHide}
      disableScrollLocking
      initialSnap={0}
    >
      <BottomSheet.Container className="bottom-sheet-container">
        {alert && (
          <HUIAlert
            variant={alert.variant || 'success'}
            className="bottom-sheet-alert"
            dismissible
            shouldAutoHide
            icon={{ className: 'pgicon-ok-circled-o' }}
            onClose={alert?.onClose}
          >
            {alert?.title && <div className="alert-title">{alert.title}</div>}
            {alert?.description && <div className="alert-description">{alert.description}</div>}
          </HUIAlert>
        )}
        <BottomSheet.Header />
        <BottomSheet.Content className="bottom-sheet-body">
          <div className="contact-agent-sheet-body">
            {error && (
              <HUIAlert className="mb-3" dismissible variant="danger">
                <Row className="p-0 m-0">
                  <Col className="p-0">
                    <div className="alert-title">{error.text}</div>
                    {error.description}
                    {error.button && (
                      <div className="mt-4">
                        <Actionable className="me-2" size="sm" variant="secondary" onClick={error.button.onClick}>
                          {error.button.text}
                        </Actionable>
                      </div>
                    )}
                  </Col>
                </Row>
              </HUIAlert>
            )}

            {shareProfileCard && (
              <ShareProfileCard
                className="share-profile-card"
                {...shareProfileCard}
                context={{ ...props.context, ...shareProfileCard.context }}
              />
            )}

            {userIntent && <HUIBadges {...userIntent} onClick={handleIntentClick} />}
            {completePreferencesNudge && (
              <CompletePreferencesNudge
                {...completePreferencesNudge}
                context={{ ...props.context, ...completePreferencesNudge.context }}
              />
            )}
            {enquiryStatus && (
              <div className="enquiry-status">
                <div className="content">
                  <SvgIcon className="status-icon" shouldUseImage {...enquiryStatus.icon} />
                  <div className="status-text">{enquiryStatus.text}</div>
                </div>
                {enquiryStatus.description && <div className="status-description">{enquiryStatus.description}</div>}
              </div>
            )}
            <div className={classNames('contact-agent-actions')}>
              {contactChannels.map((action, index) => (
                <ContactButton key={`contact-agent-${index}`} {...action} />
              ))}
            </div>
            {consent && (
              <div className="contact-agent-sheet-bottom">
                {consent.hasCheck ? (
                  <Form.Check
                    id="contact-agent-consent-checkbox"
                    className="contact-agent-consent-checkbox"
                    type="checkbox"
                    label={consent.text}
                    checked={consent.isChecked}
                    onChange={consent.onCheckChange}
                  />
                ) : (
                  <p>{consent.text}</p>
                )}
              </div>
            )}
          </div>
        </BottomSheet.Content>
      </BottomSheet.Container>
      <BottomSheet.Backdrop onTap={onHide} />
    </BottomSheet>
  );
};

export default ContactAgentSheetV2;
