import classNames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';
import Title from 'root/widgets/common-components/title';
import { HUIModalHeaderProps } from 'root/widgets/common-components/hui-modal/types';

import { HIVE_STATIC_ICON_PATH_V3 } from 'root/widgets/constants';
import { SvgIcon } from 'root/widgets/svg-icon';

import ModalContext from './modal-context';

const Header: React.FC<HUIModalHeaderProps> = ({
  backButton,
  backButtonText,
  closeButton,
  children,
  className,
  ...props
}) => {
  const context = React.useContext(ModalContext);

  const handleClose = () => {
    context?.onHide();
  };

  const handleBack = () => {
    context?.onBack();
  };

  return (
    <Modal.Header className={classNames('hui-modal-header', className)} {...props}>
      {backButton && (
        <Actionable className="hui-modal-header-action-btn hui-btn-back" variant="link" onClick={handleBack}>
          <SvgIcon src={`${HIVE_STATIC_ICON_PATH_V3}/arrow-left-o.svg`} height={24} width={24} />
          {backButtonText}
        </Actionable>
      )}
      <Title headerTag="h4" className="hui-modal-heading clip-one-line align-items-center">
        {children}
      </Title>
      {closeButton && (
        <Actionable
          className="hui-modal-header-action-btn hui-btn-close"
          variant="link"
          onClick={handleClose}
          data-automation-id="modal-close-button"
        >
          <SvgIcon src={`${HIVE_STATIC_ICON_PATH_V3}/cross-small.svg`} height={24} width={24} />
        </Actionable>
      )}
    </Modal.Header>
  );
};

export default Header;
