/* eslint-disable @typescript-eslint/no-misused-promises */
import classnames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import React, { useCallback, useEffect, useState } from 'react';

import { INITIAL_OTP_INPUT_STATE, RESEND_OTP_TIMER_DURATION } from 'root/widgets/pg-login/constants';
import { ErrorMessageProps } from 'root/widgets/types';

import './otp-verification-view.scss';
import { OTPVerificationViewProps } from './types';
import { captureExceptionSentry } from 'root/widgets/utils/logger';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { Masthead } from 'root/widgets/common-components/masthead';
import { OTPInputGroup } from 'root/widgets/common-components/otp-input-group';
import { TimedAction } from 'root/widgets/common-components/timed-action';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';

const MODAL_SLIDING_ANIMATION_TIME = 500;

const OTPVerificationView: React.FC<OTPVerificationViewProps> = ({
  className,
  children,
  metadata,
  masthead,
  checkSpamPrompt,
  resendActionText,
  errorTexts,
  isAutomationUser,
  sentry,
  otpId: initialOtpId,
  onOtpIdUpdate,
  sideEffects,
  onSubmit,
}) => {
  const [otpId, setOtpId] = useState(initialOtpId);
  const [otpValue, setOtpValue] = useState(INITIAL_OTP_INPUT_STATE);
  const [otpFocusIndex, setOtpFocusIndex] = useState(-1);
  const [inputError, setInputError] = useState('');
  const [serverError, setServerError] = useState('');

  const captureException = (functionName: string, error) => {
    captureExceptionSentry(sentry, OTPVerificationView.name, functionName, error);
  };

  const resetFocusIndex = () => setOtpFocusIndex(0);

  const sendOTP = async () => {
    try {
      const otpResponse = await sideEffects.generateOTP();

      if (!otpResponse) {
        throw new Error('OTP generation/delivery has failed');
      }

      setOtpId(otpResponse['otp_id']);
      onOtpIdUpdate(otpResponse['otp_id']);
    } catch (error) {
      const errorMessage =
        (error as ErrorMessageProps).statusCode === StatusCodes.TOO_MANY_REQUESTS
          ? errorTexts.otpGenerationLimit
          : errorTexts.server;
      setServerError(errorMessage);
      captureException(sendOTP.name, error);
    }
  };

  const handleResend = async () => {
    setServerError('');
    setInputError('');
    setOtpValue(INITIAL_OTP_INPUT_STATE);
    resetFocusIndex();
    await sendOTP();
  };

  const handleSubmitOTP = useCallback(
    async (otpToken: Array<string>) => {
      try {
        await onSubmit(otpId, otpToken.join(''));
      } catch (error) {
        const errorMessage =
          (error as ErrorMessageProps).statusCode === StatusCodes.FORBIDDEN
            ? errorTexts.otpSubmissionLimit
            : errorTexts.invalidCode;
        setInputError(errorMessage);
        captureException(handleSubmitOTP.name, error);
      }
    },
    [otpId],
  );

  useEffect(() => {
    const timer = setTimeout(resetFocusIndex, MODAL_SLIDING_ANIMATION_TIME);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      onAnimationEnd={resetFocusIndex}
      className={classnames('otp-verification-view-root', className)}
      data-otp-id={isAutomationUser ? otpId : undefined}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <Masthead {...masthead} />
      <div className="otp-verification-body">
        <OTPInputGroup
          value={otpValue}
          onChange={setOtpValue}
          focusIndex={otpFocusIndex}
          setFocusIndex={setOtpFocusIndex}
          onComplete={handleSubmitOTP}
          error={inputError}
          metadata={{ 'data-automation-id': 'otp-input' }}
        />
        {checkSpamPrompt && <p className="receiving-email-prompt">{checkSpamPrompt}</p>}
        <TimedAction
          className="resend-otp-btn"
          timerDuration={RESEND_OTP_TIMER_DURATION}
          text={resendActionText}
          onClick={handleResend}
          metadata={{ 'data-automation-id': 'resend-otp-lnk' }}
        />
        {children}
        {serverError && <HUIAlert variant="danger">{serverError}</HUIAlert>}
      </div>
    </div>
  );
};

OTPVerificationView.displayName = 'OTPVerificationView';

export default OTPVerificationView;
