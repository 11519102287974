export enum View {
  ADDRESS_FORM = 'ADDRESS_FORM',
  INTENT_FORM = 'INTENT_FORM',
  CONTACT_FORM = 'CONTACT_FORM',
  LEAD_VERIFICATION = 'LEAD_VERIFICATION',
  OTP_INPUT = 'OTP_INPUT',
}

export enum ViewSlidingClass {
  FROM_LEFT = 'slide-from-left',
  FROM_RIGHT = 'slide-from-right',
}

export const INTENT_FIELDS = {
  DEFAULT: ['sellBy', 'hasAgent'],
  ADDITIONAL: ['minPrice', 'maxPrice', 'reason'],
};

export const FORM_STEP_COMPLETION_PERCENT = 33.33;
