import {
  DrilldownMenuOptionData,
  DrilldownMenuSelectedOptionProps,
  DrilldownOptionData,
} from 'root/widgets/drilldown-menu/types';

export enum SecondaryActionType {
  MORE_REASONS = 'MORE_REASONS',
  MANAGE_HIDDEN_LISTINGS = 'MANAGE_HIDDEN_LISTINGS',
}

export interface ListingHiddenCardProps {
  reasons: DrilldownOptionData[];
  onClose: (listingId: number) => void;
  onReasonClick: (
    reason: DrilldownMenuSelectedOptionProps | DrilldownMenuOptionData,
    listingId: number,
  ) => Awaited<unknown>;
  errorText?: string;
  successText?: string;
  title: string;
  bodyTitle: string;
  actions: {
    secondary: {
      onClick: (listingId: number) => void;
      text: string;
      type: SecondaryActionType;
      variant?: 'secondary' | 'outline-secondary';
    };
    undo: {
      onClick: (listingId: number) => void;
      text: string;
    };
  };
  moreReasons: Array<DrilldownMenuOptionData>;
  listingId: number;
}
