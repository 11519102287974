import { Typography } from 'root/common/atom-elements/typography';
import { Badge } from 'root/widgets/common-components/badge';

import { LineData, LineToStationsType, MobileLinesRowProps } from 'root/widgets/mrt-search-modal/types';

const sortLines = (selectedLines: LineToStationsType) => (a: LineData, b: LineData) => {
  if (selectedLines[b.id].length - selectedLines[a.id].length !== 0) {
    return selectedLines[b.id].length - selectedLines[a.id].length;
  }
  return a.sortOrder - b.sortOrder;
};

const MobileLinesRow = ({ lines, selectedLines, onMobileLineSelect }: MobileLinesRowProps) => (
  <div className="mobile-lines-row">
    {[...lines].sort(sortLines(selectedLines)).map((line) => (
      <button
        type="button"
        className="mobile-line-btn"
        style={{ background: line.color }}
        key={line.id}
        onClick={onMobileLineSelect(line.id)}
        da-id="mobile-lines-row-btn"
      >
        <Typography variant="label-s">{line.id}</Typography>
        {selectedLines[line.id].length > 0 && (
          <Badge className="ms-1" action="count" type="round" count={selectedLines[line.id].length} />
        )}
      </button>
    ))}
  </div>
);

MobileLinesRow.displayName = 'MobileLinesRow';

export default MobileLinesRow;
