/**
 * Ref: Braze web SDK library link: https://js.appboycdn.com/web-sdk/latest/doc/modules/braze.html
 */
import type { BrazeContentCardProps } from 'root/widgets/types';

/**
 * callback triggered when 'requestContentCardsRefresh' called by SDK
 * @param onRefreshCards - callback func on processing the cards
 */
export const subscribeContentCards = (brazeSDK: any, onRefreshCards: (cards: Array<BrazeContentCardProps>) => void) => {
  if (!brazeSDK) {
    return;
  }

  brazeSDK.subscribeToContentCardsUpdates(({ cards }: { cards: Array<BrazeContentCardProps> }) => {
    onRefreshCards(cards);
  });
};

export const getContentCards = (brazeSDK: any) => brazeSDK?.getCachedContentCards();

export const requestContentCardsRefresh = (brazeSDK: any) => {
  if (!brazeSDK) {
    return;
  }

  brazeSDK.requestContentCardsRefresh();
};

export const logCardImpressions = (brazeSDK: any, card: BrazeContentCardProps, isContentCard = true) => {
  if (!brazeSDK) {
    return;
  }

  brazeSDK.logCardImpressions([card], isContentCard);
};

export const logCardClick = (brazeSDK: any, card: BrazeContentCardProps, isContentCard = true) => {
  if (!brazeSDK) {
    return;
  }

  brazeSDK.logCardClick(card, isContentCard);
};

export const logCardDismissal = (brazeSDK: any, card: BrazeContentCardProps) => {
  if (!brazeSDK) {
    return;
  }

  brazeSDK.logCardDismissal(card);
};
