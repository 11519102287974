import classNames from 'classnames';
import React from 'react';

import ActionableLink from 'root/widgets/common-components/actionable-link';

import AgentInfo from 'root/widgets/agent-info/agent-info';

import './contact-agent-sticky-bar.scss';
import { ContactAgentStickyBarProps } from './types';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { filterMetadata } from 'root/widgets/utils/filter';

const ContactAgentStickyBar: React.FC<ContactAgentStickyBarProps> = ({
  className,
  agentInfoProps,
  contactActions,
  metadata,
}) => (
  <div
    className={classNames('contact-agent-sticky-bar-root', className)}
    {...filterMetadata(metadata)}
    {...extractDataAutomationId(metadata)}
  >
    <div className="agent-info">
      <AgentInfo {...agentInfoProps} />
    </div>
    <div className="actionable-links">
      {contactActions.map((action, index) => (
        <ActionableLink key={`contact-agent-${index}`} className="btn-secondary btn" {...action} />
      ))}
    </div>
  </div>
);

ContactAgentStickyBar.displayName = 'ContactAgentStickyBar';

export default ContactAgentStickyBar;
