import classNames from 'classnames';
import { FC } from 'react';

import './agent-badge.scss';
import BadgePopover from './sub-components/badge-popover';
import { AgentBadgeProps } from './types';
import { SvgIcon } from 'root/widgets/svg-icon';

const AgentBadge: FC<AgentBadgeProps> = ({ className, icon, text, explanation }) => {
  const badge = (
    <div className={classNames('agent-badge-root', className, { 'no-text': !text })}>
      <SvgIcon src={icon} color="#fff" width={14} height={14} shouldUseImage />
      {text && <div className="badge-text">{text}</div>}
    </div>
  );

  if (!explanation) {
    return badge;
  }

  return <BadgePopover {...explanation}>{badge}</BadgePopover>;
};

AgentBadge.displayName = 'AgentBadge';

export default AgentBadge;
