import isNil from 'lodash/isNil';
import snakeCase from 'lodash/snakeCase';

import {
  GA3_GA4_DIMENSION_MAP,
  GA3_GA4_EVENT_NAME_MAP,
  GA4_EVENT_VALUE_LIMIT,
  GA4_KEYS_TO_SLICE,
} from 'root/widgets/constants';
import { GA4ECommerceEventType, GA4TrackingEventType } from 'root/widgets/types';
import { sliceValuesCrossingLimit } from 'root/widgets/utils/object';

// Create a tracking object to keep track of keys for which warnings have been logged
const warnedKeys = new Set();
declare const window: any;

const track = (gtag: any, action: string, payload: Record<string, any>) => {
  if (typeof gtag === 'function') {
    const { transformed, otherKeysCrossingLimit } = sliceValuesCrossingLimit(
      payload,
      GA4_KEYS_TO_SLICE,
      GA4_EVENT_VALUE_LIMIT,
    );

    otherKeysCrossingLimit.forEach((key) => {
      if (!warnedKeys.has(key)) {
        console.warn(
          `The value for key "${key}" exceeds the limit of 100 characters and will be ignored by Google Analytics.`,
        );
        warnedKeys.add(key);
      }
    });

    gtag('event', action, {
      ...transformed,
      debug_mode: 0,
      ...(window?.ga4PropertyId ? { send_to: window.ga4PropertyId } : {}),
    });
  } else {
    console.error('ga4Utils', 'Trigger GA4 Event', 'gtag is not a function');
  }
};

const extractFromGA3Dimensions = (dimensions?: Record<string, any>) => {
  if (!dimensions) {
    return {};
  }

  const ga4Dimensions: Record<string, any> = {};
  const dimensionNames = Object.keys(dimensions);

  Object.entries(GA3_GA4_DIMENSION_MAP).forEach(([ga3Dimension, ga4Dimension]) => {
    if (dimensionNames.includes(ga3Dimension) && dimensions[ga3Dimension]) {
      // if the key is GA3 dimension key,
      // for example 'dimension1', it will be converted to GA4 key 'member_type'
      ga4Dimensions[ga4Dimension] = dimensions[ga3Dimension];
    } else if (dimensionNames.includes(ga4Dimension) && dimensions[ga4Dimension]) {
      // Else if the key is already GA4 dimension key
      // for example, 'member_type', should return the GA4 key and value.
      ga4Dimensions[ga4Dimension] = dimensions[ga4Dimension];
    }
  });

  return ga4Dimensions;
};

const trackPageViewEvent = (gtag, dimensions: Record<string, any>) => {
  track(gtag, 'page_view', extractFromGA3Dimensions(dimensions));
};

const trackEvent = (gtag, data: GA4TrackingEventType) => {
  track(gtag, data.action, {
    event_category: data.category,
    event_action: data.action,
    event_label: data.label,
    ...extractFromGA3Dimensions(data.dimensions),
    is_interaction: data.isNonInteraction ? 'false' : 'true',
  });
};

const trackECommerceEvent = (gtag, data: GA4ECommerceEventType) => {
  const items: Record<string, any> = [];

  if (data?.productsDetail && data?.productsDetail?.length > 0) {
    data.productsDetail?.forEach((productDetail: Record<string, any>) => {
      const productItem = {
        item_id: productDetail?.id,
        item_name: productDetail?.name,
        index: productDetail?.position,
        item_brand: productDetail?.brand,
        item_category: productDetail?.category,
        item_variant: productDetail?.variant,
        price: productDetail?.price,
      };

      items.push(productItem);
    });
  }

  items.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (isNil(item[key])) {
        delete item[key];
      }
    });
  });

  track(gtag, GA3_GA4_EVENT_NAME_MAP[data.action], {
    item_list_id: snakeCase(data.action),
    item_list_name: data.action,
    event_category: data.category || 'EnhancedEcommerce',
    event_action: data.action,
    event_label: data.label,
    ...extractFromGA3Dimensions(data.dimensions),
    items,
  });
};

export default {
  extractFromGA3Dimensions,
  trackPageViewEvent,
  trackEvent,
  trackECommerceEvent,
};
