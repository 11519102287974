import classnames from 'classnames';
import React from 'react';

import Actionable from 'root/widgets/common-components/actionable';

import './social-login-button.scss';
import { SocialLoginButtonProps } from './types';

const SocialLoginButton: React.FC<SocialLoginButtonProps> = ({
  icon,
  title,
  className,
  provider,
  onClick,
  ...actionableProps
}) => {
  const handleClick = () => {
    onClick?.(provider);
  };

  return (
    <Actionable
      {...actionableProps}
      className={classnames('social-login-button-root', { 'without-title': !title }, className)}
      variant="secondary"
      onClick={handleClick}
    >
      <img alt={title} src={icon} />
      {title && <div className="title">{title}</div>}
    </Actionable>
  );
};

SocialLoginButton.displayName = 'SocialLoginButton';

export default SocialLoginButton;
