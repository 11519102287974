import React from 'react';
import './complete-preferences-nudge.scss';

import Actionable from 'root/widgets/common-components/actionable';
import { SvgIcon } from 'root/widgets/svg-icon';
import { CompletePreferencesNudgeProps } from './types';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { useComponentInView } from 'root/widgets/common-components/hooks/use-component-in-view';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const CompletePreferencesNudge: React.FC<CompletePreferencesNudgeProps> = ({
  iconPath,
  title,
  description,
  buttonCTA,
  context,
  metadata,
  additionalEvents,
}) => {
  const componentRef = useComponentInView(additionalEvents?.IN_VIEWPORT, context);

  const handleButtonCTAClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    buttonCTA?.onClick?.(event);
    emit(EVENT_NAMES.COMPLETE_PREFERENCES_NUDGE.BUTTON_CTA_ON_CLICK, context);
  };

  return (
    <div
      className="complete-preferences-nudge-root"
      ref={componentRef}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      {iconPath && (
        <div className="nudge-icon">
          <SvgIcon src={iconPath} shouldUseImage />
        </div>
      )}
      <div className="nudge-content">
        {title && <h2 className="nudge-title">{title}</h2>}
        <p className="nudge-description">{description}</p>
        <Actionable {...buttonCTA} onClick={handleButtonCTAClick} />
      </div>
    </div>
  );
};

CompletePreferencesNudge.displayName = 'CompletePreferencesNudge';

export default CompletePreferencesNudge;
