import classNames from 'classnames';
import React from 'react';

import { GatedViewHeadingProps } from 'root/widgets/gated-view/types';

const GatedViewHeading: React.FC<GatedViewHeadingProps> = ({ className, children, ...props }) => (
  <div className={classNames('gated-view-heading', className)} {...props}>
    {children}
  </div>
);

export default GatedViewHeading;
