import classNames from 'classnames';

import './agent-past-transactions-summary.scss';
import { AgentPastTransactionsSummaryProps } from './types';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import Title from 'root/widgets/common-components/title';
import Actionable from 'root/widgets/common-components/actionable';
import { Divider } from 'root/widgets/common-components/divider';

const AgentPastTransactionsSummary = (props: AgentPastTransactionsSummaryProps) => {
  const { className, metadata, totalPastTransactions, pastTransactionsText, actionable, disclaimerText } = props;

  return (
    <div
      className={classNames('agent-past-transactions-summary-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <div className="agent-past-transactions-summary">
        <div>
          <Title
            headerTag="h2"
            className="agent-past-transactions-summary-total"
            {...extractDataAutomationId({ dataAutomationId: 'agent-past-transactions-summary-total' })}
          >
            {totalPastTransactions}
          </Title>
          <Title headerTag="h4" className="agent-past-transactions-summary-text">
            {pastTransactionsText}
          </Title>
        </div>
        {actionable && (
          <Actionable variant="link" onClick={actionable.onClick}>
            <i className={actionable.iconClass} />
            {actionable.text}
          </Actionable>
        )}
      </div>
      {disclaimerText && (
        <>
          <Divider />
          <p className="agent-past-transactions-summary-disclaimer">{disclaimerText}</p>
        </>
      )}
    </div>
  );
};

AgentPastTransactionsSummary.displayName = 'AgentPastTransactionsSummary';

export default AgentPastTransactionsSummary;
