import classNames from 'classnames';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';

import './hui-progress.scss';
import { HUIProgressProps } from './types';

const HUIProgress: React.FC<HUIProgressProps> = (props) => {
  const { className, percentComplete, variant, ...progressBarProps } = props;

  return (
    <div className={classNames('hui-progress-root', className, variant || 'primary-light-lightest')}>
      <ProgressBar variant={variant} now={percentComplete} {...progressBarProps} />
    </div>
  );
};

export default HUIProgress;
