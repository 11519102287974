import classnames from 'classnames';
import React from 'react';

import { RangeFilter } from 'root/widgets/search-with-filter/sub-components/range-filter';
import type { RangeFilterProps } from 'root/widgets/search-with-filter/sub-components/range-filter/types';

import './floor-area-range-filter.scss';

const FloorAreaRangeFilter: React.FC<RangeFilterProps> = ({ className, onApplyFilter, onClearFilter }) => (
  <RangeFilter
    className={classnames('floor-area-range-filter-root', className)}
    rangeKey="floorAreaRange"
    onApplyFilter={onApplyFilter}
    onClearFilter={onClearFilter}
    metadata={{ 'data-automation-id': 'floor-area-range-filter' }}
  />
);

FloorAreaRangeFilter.displayName = 'FloorAreaRangeFilter';

export default FloorAreaRangeFilter;
