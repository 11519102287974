import parse from 'html-react-parser';
import React, { FC, useMemo } from 'react';
import { Modal } from 'react-bootstrap';

import { ContactAgentCard } from 'root/widgets/contact-agent-card';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import { getContactActionProps } from 'root/symbiosis-widgets/utils';

import './contact-agent-modal.scss';
import { ContactAgentModalProps } from './types';

const ContactAgentModal: FC<ContactAgentModalProps> = ({
  heading,
  isGatingEnabled,
  contactActions,
  agentInfoProps: agentInfo,
  termsAndPolicy,
  onContactActionClick,
  shouldShow,
  onHide,
  onShow,
  ...rest
}) => {
  const actions = useMemo(
    () => contactActions.map((action) => getContactActionProps(action, onContactActionClick, isGatingEnabled)),
    [contactActions, isGatingEnabled],
  );

  const termsAndPolicyElement = useMemo(
    () => (typeof termsAndPolicy === 'string' ? parse(termsAndPolicy) : termsAndPolicy),
    [termsAndPolicy],
  );

  return (
    <HUIModal show={shouldShow} className="contact-agent-modal-root" onHide={onHide} onShow={onShow} centered>
      <HUIModal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </HUIModal.Header>
      <HUIModal.Body>
        <ContactAgentCard
          agentInfoProps={agentInfo}
          contactActions={actions}
          termsAndPolicy={termsAndPolicyElement}
          {...rest}
        />
      </HUIModal.Body>
    </HUIModal>
  );
};

ContactAgentModal.displayName = 'ContactAgentModal';

export default ContactAgentModal;
