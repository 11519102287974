import chunk from 'lodash/chunk';

/**
 * Removes any key with falsy / empty  values in an object
 * example:- input { freetext: '', minprice: 500}, output:- { minprice: 500}
 * @param obj
 * @returns
 */
export const clean = (obj: any) =>
  // eslint-disable-next-line unicorn/no-array-reduce
  Object.keys(obj).reduce((cleaned: any, key: string) => {
    if (
      (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) ||
      (typeof obj[key] !== 'object' && obj[key])
    ) {
      cleaned[key] = obj[key];
    }

    return cleaned;
  }, {});

/**
 * Slices string values of specific keys in an object if they exceed a given limit,
 * and collects other keys with values crossing the limit.
 * @param {Record<string, any>} obj - The input object containing key-value pairs.
 * @param {Array<string>} keys - An array of keys whose string values should be split into smaller chunks.
 * @param {number} limit - The maximum length for a value to be considered.
 * @returns {Record<string, any>} - The transformed object with updated keys and split string values.
 */
export const sliceValuesCrossingLimit = (obj: Record<string, any>, keys: Array<string>, limit: number) => {
  const transformed: Record<string, any> = { ...obj };
  const otherKeysCrossingLimit: Array<string> = [];

  Object.entries(transformed).forEach(([key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      value = JSON.stringify(value);
    }

    if (typeof value === 'string' && value.length > limit) {
      if (keys.includes(key)) {
        const valueChunks = chunk(value, limit);
        valueChunks.forEach((valueChunk, index) => {
          transformed[`${key}_${index + 1}`] = valueChunk.join('');
          delete transformed[key];
        });
      } else {
        otherKeysCrossingLimit.push(key);
      }
    }
  });

  return {
    transformed,
    otherKeysCrossingLimit,
  };
};
