import type { PageType } from 'root/widgets/types';

import { GACustomDimensions } from 'root/global-wrappers/config';
import {
  pageViewDimension20,
  staticCustomDimensions,
  staticEcommerceData,
} from 'root/global-wrappers/services/events/ga/ga-global-data';
import { staticSegmentData } from 'root/global-wrappers/services/events/segment/segment-global-data';
import {
  GATrackingType,
  MetaPixelTrackingType,
  SegmentPageViewType,
  SegmentTrackingType,
  TrackingDataType,
  TrackingEngineType,
} from 'root/global-wrappers/types/analytics';

import { TrackingEventType } from './constant';
import GA4 from './ga4-tracking';
import GA from './ga-tracking';
import Kevel from './kevel-tracking';
import MetaPixel from './meta-pixel-tracking';
import Segment from './segment-tracking';
import Yahoo from './yahoo-tracking';

import { default as ga4Helper } from 'root/widgets/utils/ga4';
import { getOriginPageType } from 'root/widgets/utils/pages';

declare const window: any;

const handleTracking = (
  type: TrackingEngineType,
  trackingData: TrackingDataType,
  trackingFunc: (params: any, additionalConfig?: Record<string, string | undefined>) => void,
  additionalConfig?: Record<string, string | undefined>,
): void => {
  if (!trackingData[type]) {
    return;
  }

  const { analyticsEventStack } = window.symbiosis;

  analyticsEventStack[type].push({ type: trackingData.type, ...trackingData[type] });
  trackingFunc(trackingData[type], additionalConfig);
};

export const initEventStack = () => {
  if (!window || (window.symbiosis && window.symbiosis.analyticsEventStack)) {
    return;
  }

  window.symbiosis = window.symbiosis || {
    analyticsEventStack: {
      ga: [],
      ga4: [],
      segment: [],
      metaPixel: [],
      kevel: [],
      yahoo: [],
    },
    isPageViewed: false,
    pendingEventStack: [],
  };
};

/**
 * `track` will delegate the event data to the respective analytics pipeline
 * @param trackingData - The data object that contains data grouped by specific tool
 * @param additionalConfig - The additional configuration for the tracking function
 */
export const track = (trackingData: TrackingDataType, additionalConfig?: Record<string, string | undefined>): void => {
  if (!window.symbiosis) {
    initEventStack();
  }

  if (window.symbiosis && !window.symbiosis.isPageViewed && trackingData.type !== TrackingEventType.PAGEVIEW) {
    // Postpone any event before PageView events
    window.symbiosis.pendingEventStack = [...window.symbiosis.pendingEventStack, { trackingData, additionalConfig }];

    return;
  }

  const { type } = trackingData;

  switch (type) {
    case TrackingEventType.PAGEVIEW:
      handleTracking('ga', trackingData, GA.trackPageView);
      handleTracking('ga4', trackingData, GA4.trackPageView);
      handleTracking('metaPixel', trackingData, MetaPixel.trackPageView);
      handleTracking('segment', trackingData, Segment.trackPageView);

      // enable all events after PageViewed is triggered
      window.symbiosis.isPageViewed = true;

      // flush the pending event stack
      window.symbiosis.pendingEventStack?.forEach((data) => {
        track(data.trackingData, data.additionalConfig);
      });
      window.symbiosis.pendingEventStack = [];
      return;
    case TrackingEventType.ECOMMERCE:
      handleTracking('ga', trackingData, GA.trackEventEnhancedEcommerce, additionalConfig);
      handleTracking('ga4', trackingData, GA4.trackEventEnhancedEcommerce);
      return;
    default:
      handleTracking('ga', trackingData, GA.trackEvent);
      handleTracking('ga4', trackingData, GA4.trackEvent);
      handleTracking('segment', trackingData, Segment.trackEvent);
      handleTracking('metaPixel', trackingData, MetaPixel.trackEvent);
      handleTracking('kevel', trackingData, Kevel.trackEvent);
      handleTracking('yahoo', trackingData, Yahoo.trackEvent);
  }
};

export const trackEvent = (trackingData: TrackingDataType): void => {
  if (trackingData?.ga) {
    trackingData.ga = {
      ...trackingData.ga,
      dimensions: { ...staticCustomDimensions, ...trackingData.ga?.dimensions },
    };

    trackingData.ga4 = {
      ...trackingData.ga,
      dimensions: ga4Helper.extractFromGA3Dimensions({ ...staticCustomDimensions, ...trackingData.ga?.dimensions }),
    };
  }

  if (trackingData?.segment) {
    trackingData.segment = {
      ...trackingData.segment,
      parameters: {
        ...(trackingData.segment as SegmentTrackingType)?.parameters,
        eventData: {
          ...((trackingData.segment as SegmentTrackingType)?.parameters as any)?.eventData,
          ...staticSegmentData,
        },
      },
    };
  }

  track({ type: TrackingEventType.EVENT, ...trackingData });
};

/**
 * Tracks pageview events
 * @param pageTypes - The array of page types to track
 * @param globalContextGADimensions - The global context GA dimensions
 */
export const trackPageView = (pageTypes: Array<PageType>, globalContextGADimensions: Record<string, string>): void => {
  const dimension20 = {
    viewOriginPageType: getOriginPageType(document.referrer, pageTypes) ?? '',
    viewOriginPage: decodeURI(document.referrer) ?? '',
    ...pageViewDimension20,
  };

  const trackingData: TrackingDataType = {
    type: TrackingEventType.PAGEVIEW,
    ga: {
      page: window.location.pathname,
      dimensions: {
        ...staticCustomDimensions,
        ...globalContextGADimensions,
        dimension20: JSON.stringify(dimension20),
      },
    },
    ga4: {
      page: window.location.pathname,
      dimensions: ga4Helper.extractFromGA3Dimensions({
        ...staticCustomDimensions,
        ...globalContextGADimensions,
        dimension20: JSON.stringify(dimension20),
      }),
    },
    metaPixel: {} as MetaPixelTrackingType,
    segment: { name: staticCustomDimensions[GACustomDimensions.DIMENSION_2] } as SegmentPageViewType,
  };

  track(trackingData);
};

/**
 * Tracks ecommerce data
 * @param command - The command to track
 * @param data - The GA tracking data
 * @param productsData - The array of products data to track
 * @param setActionType - The set action type
 */
export const trackECommerce = (
  command: string,
  data: GATrackingType,
  productsData: Array<any>,
  setActionType?: string,
): void => {
  data.dimensions = { ...staticCustomDimensions, ...data.dimensions };

  productsData.forEach((product) => ({ ...staticEcommerceData, ...product }));

  const trackingData = {
    type: TrackingEventType.ECOMMERCE,
    ga: {
      ...data,
      command,
      productsDetail: productsData,
      dimensions: { ...staticCustomDimensions, ...data.dimensions },
    },
    ga4: {
      ...data,
      command,
      productsDetail: productsData,
      dimensions: ga4Helper.extractFromGA3Dimensions({ ...staticCustomDimensions, ...data.dimensions }),
    },
  };

  track(trackingData, { setActionType });
};
