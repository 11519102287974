import { FC } from 'react';
import { ActionGroupProps } from 'root/widgets/contact-agent-card/types';
import ContactButton from './contact-button';

const ActionGroup: FC<ActionGroupProps> = ({ actions }) => (
  <div className="action-group-root">
    {actions.map((button, index) => (
      <ContactButton key={`extended-action-${index}`} {...button} />
    ))}
  </div>
);

ActionGroup.displayName = 'ActionGroup';

export default ActionGroup;
