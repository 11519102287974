import { ChangeEvent, useCallback, useState } from 'react';

import Actionable from 'root/widgets/common-components/actionable';

import { SaveStatus } from 'root/widgets/drilldown-menu/constants';
import { DrilldownMenuInputOptionProps } from 'root/widgets/drilldown-menu/types';

import './input-option.scss';

const InputOption = ({
  componentId,
  className,
  label,
  submitButtonText = 'Submit',
  onSubmitButtonClick: onButtonClick,
  inputProps,
  buttonProps,
  status,
}: DrilldownMenuInputOptionProps) => {
  const [value, setValue] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleClick = useCallback(() => {
    onButtonClick(value);
  }, [value, onButtonClick]);

  return (
    <div id={componentId} className={className}>
      <div className="input-component--content">
        <div className="input-component--content-text">{label}</div>
        <textarea {...inputProps} className="input-component--content-input" onChange={handleChange} />
      </div>
      <div className="input-component--footer">
        <div className="input-component--footer-content">
          <Actionable
            variant="primary"
            disabled={!value || status === SaveStatus.IN_PROGRESS || status === SaveStatus.SUCCESS}
            onClick={handleClick}
            {...buttonProps}
          >
            {submitButtonText}
          </Actionable>
        </div>
      </div>
    </div>
  );
};

export default InputOption;
