import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';

import { VARIANTS } from 'root/widgets/location-filter-modal/constants';
import LocationFilterModalContext, { LocationStoreContext } from 'root/widgets/location-filter-modal/context';
import { FooterProps } from 'root/widgets/location-filter-modal/types';

import './footer.scss';

const Footer: React.FC<FooterProps> = ({ clearText, applyAction }) => {
  const variant = useContext(LocationFilterModalContext);
  const { state, dataAutomationId, dispatch } = useContext(LocationStoreContext);

  const handleApply = () => {
    if (applyAction.onClick) {
      applyAction.onClick({ codes: state.selectedCodes });
    }
  };

  const handleClear = () => {
    dispatch({ type: 'clearAll' });
  };

  const isSingleVariant = variant === VARIANTS.SINGLE_LEVEL;

  const isClearDisabled = Object.values(state.selectedCodes).length === 0;

  return (
    <Modal.Footer className="location-filter-modal-footer-root">
      <Actionable
        className={isSingleVariant ? '' : 'hidden'}
        disabled={isClearDisabled}
        onClick={handleClear}
        variant="link primary icon-suffix"
        metadata={{
          dataAutomationId: dataAutomationId ? `${dataAutomationId}-clear-btn` : '',
        }}
      >
        {clearText}
      </Actionable>
      <Actionable
        onClick={handleApply}
        metadata={{
          dataAutomationId: dataAutomationId ? `${dataAutomationId}-apply-btn` : '',
        }}
      >
        {applyAction.text}
      </Actionable>
    </Modal.Footer>
  );
};

Footer.displayName = 'Footer';

export default Footer;
