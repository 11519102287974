import { EVENT_NAMES } from 'root/widgets/events';
import * as eventHandlers from './event-handlers';

const EVENT_MAP: Record<string, (context: any) => void> = {
  [EVENT_NAMES.GLOBAL_NAVBAR.NAV_LINK_ON_CLICK]: eventHandlers.handleGlobalNavLinkClick,
  [EVENT_NAMES.GLOBAL_NAVBAR.MORE_DROPDOWN_ON_CLICK]: eventHandlers.handleGlobalNavMoreClick,
  [EVENT_NAMES.GLOBAL_NAVBAR.NOTIFICATION_ON_CLICK]: eventHandlers.handleGlobalNavNotificationClick,
  [EVENT_NAMES.GLOBAL_NAVBAR.QUICK_LINK_ON_CLICK]: eventHandlers.handleGlobalNavToggleClick,
  [EVENT_NAMES.GLOBAL_NAVBAR.MY_ACTIVITY_LINK_ON_CLICK]: eventHandlers.handleGlobalNavMyActivityClick,
  [EVENT_NAMES.GLOBAL_NAVBAR.LOGIN_ON_CLICK]: eventHandlers.handleGlobalLoginClick,
  [EVENT_NAMES.GLOBAL_NAVBAR.MY_ACCOUNT_ON_CLICK]: eventHandlers.handleGlobalNavToggleClick,
  [EVENT_NAMES.GLOBAL_NAVBAR.MY_ACCOUNT_ITEM_ON_CLICK]: eventHandlers.handleGlobalNavToggleClick,
  [EVENT_NAMES.LOCALE_SWITCH.CHANGE]: eventHandlers.handleLocaleChange,
};

export { EVENT_MAP };
