import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import { FC } from 'react';

import ActionableLink from 'root/widgets/common-components/actionable-link';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './agent-rating.scss';
import { AgentRatingProps } from './types';
import { EVENT_NAMES, emit } from 'root/widgets/events';

const AgentRating: FC<AgentRatingProps> = (props) => {
  const { className, context, metadata, href, score, text, onClick, shouldOpenUrlInNewTab = true } = props;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    emit(EVENT_NAMES.AGENT_RATING.CLICK, context);
    if (onClick) {
      onClick(evt);
    }
  };

  return (
    <ActionableLink
      className={classNames('agent-rating-root', className, { 'disabled-view': score === 0 })}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
      href={href}
      target={shouldOpenUrlInNewTab ? '_blank' : '_self'}
      onClick={handleClick}
    >
      {!isUndefined(score) && (
        <>
          <i className="pgicon-star" />
          <span className="agent-rating-score">{score === 0 ? 0 : score.toFixed(1)}</span>
        </>
      )}
      {text && <span className="agent-rating-text">{text}</span>}
      {score !== 0 && <i className="pgicon-arrow-right" />}
    </ActionableLink>
  );
};

AgentRating.displayName = 'AgentRating';

export default AgentRating;
