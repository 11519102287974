import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';

import { Footer, Header } from './sub-components';
import ModalContext from './sub-components/modal-context';
import { HUIModalProps } from './types';
import './hui-modal.scss';

const HUIModal: React.FC<HUIModalProps> = ({ className, onHide, onBack, ...props }) => {
  const handleHide = useCallback(() => onHide?.(), [onHide]);
  const handleBack = useCallback(() => onBack?.(), [onBack]);

  const modalContext = React.useMemo(
    () => ({
      onHide: handleHide,
      onBack: handleBack,
    }),
    [handleHide, handleBack],
  );

  return (
    <ModalContext.Provider value={modalContext}>
      <Modal className={classNames('hui-modal-root', className)} onHide={onHide} {...props} />
    </ModalContext.Provider>
  );
};

export default Object.assign(HUIModal, {
  Header,
  Body: Modal.Body,
  Footer,
});
