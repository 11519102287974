import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { FC, FormEventHandler } from 'react';
import { Badge, Form, InputGroup } from 'react-bootstrap';

import './intent-form-view.scss';
import { IntentFormViewProps } from './types';
import Actionable from 'root/widgets/common-components/actionable';
import { parseNumber } from 'root/widgets/utils/number';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { Masthead } from 'root/widgets/common-components/masthead';
import { HUIRangeInput } from 'root/widgets/common-components/hui-range-input';
import { DropdownSegment } from 'root/widgets/common-components/dropdown-segment';

const IntentFormView: FC<IntentFormViewProps> = (props) => {
  const { className, masthead, inputGroup, leadData, onDataChange, action, metadata } = props;
  const { sellByIntent, priceRange, reasonForSelling } = inputGroup;

  const shouldShowAdditionalFields = leadData.intent.sellBy && leadData.intent.sellBy !== sellByIntent.researchIntent;
  const hasPriceRangeError =
    !isEmpty(leadData.intent.minPrice) &&
    !isEmpty(leadData.intent.maxPrice) &&
    parseNumber(leadData.intent.minPrice) > parseNumber(leadData.intent.maxPrice);
  const isFormValid = shouldShowAdditionalFields
    ? leadData.intent.minPrice && leadData.intent.maxPrice && !hasPriceRangeError
    : leadData.intent.sellBy;

  const { label: priceRangeLabel, ...priceRangeProps } = priceRange;

  const handleChangeIntent = (value: string) => {
    onDataChange({ sellBy: value });
  };

  const handlePriceRangeChange = (value: { min?: string; max?: string }) => {
    onDataChange({ minPrice: value.min, maxPrice: value.max });
  };

  const handleReasonChange = (value: string) => {
    onDataChange({ reason: value });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
  };

  return (
    <div
      className={classNames('hsv-iv-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <Masthead {...masthead} />
      <Form className="d-flex flex-column hsv-form mt-4" onSubmit={handleSubmit}>
        <Form.Group
          className="hsv-intent-root"
          controlId="iv-intent"
          {...extractDataAutomationId(sellByIntent.metadata)}
        >
          <Form.Label className="mb-1 hsv-label">{sellByIntent.label}</Form.Label>
          <div className="d-inline-flex gap-1 flex-wrap">
            {sellByIntent.options.map((value) => (
              <Actionable variant="link" key={value} onClick={() => handleChangeIntent(value)}>
                <Badge
                  bg="light"
                  className={classNames('px-3 py-2 m-0 rounded-pill', { selected: value === leadData.intent.sellBy })}
                >
                  {value}
                </Badge>
              </Actionable>
            ))}
          </div>
        </Form.Group>
        {shouldShowAdditionalFields && (
          <>
            <Form.Group controlId="iv-mobile">
              <Form.Label className="mb-1 hsv-label">{priceRangeLabel}</Form.Label>
              <InputGroup>
                <HUIRangeInput
                  {...priceRangeProps}
                  min={{
                    ...priceRangeProps.min,
                    value: leadData.intent.minPrice,
                    hasError: hasPriceRangeError,
                  }}
                  max={{
                    ...priceRangeProps.max,
                    value: leadData.intent.maxPrice,
                    hasError: hasPriceRangeError,
                  }}
                  onChange={handlePriceRangeChange}
                />
              </InputGroup>
            </Form.Group>
            <DropdownSegment
              dropDirection="up"
              {...reasonForSelling}
              selectedOption={
                leadData.intent.reason ? { text: leadData.intent.reason, value: leadData.intent.reason } : undefined
              }
              onSelectionChange={handleReasonChange}
            />
          </>
        )}
        <Actionable
          variant="primary"
          type="submit"
          shouldShowLoading={action.shouldShowLoading}
          disabled={!isFormValid || action.isDisabled}
          onClick={action.onClick}
          {...extractDataAutomationId(action.metadata)}
        >
          {action.text}
        </Actionable>
      </Form>
    </div>
  );
};

IntentFormView.displayName = 'IntentFormView';

export default IntentFormView;
