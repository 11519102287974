import { Fragment, useCallback, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { HIVE_STATIC_VERSION } from 'root/widgets/constants';
import { SvgIcon } from 'root/widgets/svg-icon';
import Actionable from 'root/widgets/common-components/actionable';

import { DrilldownMenuSelectedOptionProps } from 'root/widgets/drilldown-menu';
import { DrilldownOptionData } from 'root/widgets/drilldown-menu/types';

import { ListingFeedbackReasons } from 'root/symbiosis-widgets/wrappers/feedback-reasons-view';

import './listing-hidden-card.scss';
import { Reason } from './sub-components/reason';
import { ListingHiddenCardProps, SecondaryActionType } from './types';

const undoIconURL = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/undo.svg`;

const ListingHiddenCard = ({
  reasons,
  onClose,
  onReasonClick,
  errorText,
  successText,
  title,
  bodyTitle,
  actions,
  moreReasons,
  listingId,
}: ListingHiddenCardProps) => {
  const { secondary, undo } = actions;

  const [shouldShow, setShouldShow] = useState(false);

  const onSecondaryClick = useCallback(() => {
    if (secondary.type === SecondaryActionType.MORE_REASONS) {
      setShouldShow(true);
    }
    secondary.onClick(listingId);
  }, [listingId, secondary]);

  const onSave = (item: DrilldownMenuSelectedOptionProps) => onReasonClick(item, listingId);

  const handleReasonFeedbackClose = () => setShouldShow(false);

  const onReasonClickCallback = useCallback(
    (reason: DrilldownOptionData) => () => onReasonClick(reason, listingId),
    [listingId, onReasonClick],
  );

  const onCloseCallback = useCallback(() => {
    onClose(listingId);
  }, [listingId, onClose]);

  const onUndoClick = useCallback(() => {
    undo.onClick(listingId);
  }, [listingId, undo]);

  return (
    <Fragment key={listingId}>
      {shouldShow && (
        <ListingFeedbackReasons
          drillDownMenuProps={{
            title: bodyTitle,
            isOpen: shouldShow,
            data: moreReasons,
            onSave,
            onClose: handleReasonFeedbackClose,
          }}
        />
      )}
      <div className="listing-hidden-card">
        <div className="listing-hidden-card__header">
          <div className="listing-hidden-card__header-title">{title}</div>
          <Actionable
            variant="outline-secondary icon-square"
            className="listing-hidden-card__cancel ghost-button"
            onClick={onCloseCallback}
          >
            <i className="pgicon pgicon-cancel" />
          </Actionable>
        </div>

        <div className="listing-hidden-card__body">
          {successText ? (
            <div className="listing-hidden-card__alert-container">
              <Alert variant="success" className="listing-hidden-card__alert">
                <i className="pgicon pgicon-ok-circled" />
                {successText}
              </Alert>
            </div>
          ) : (
            <>
              {errorText && (
                <div className="listing-hidden-card__alert-container">
                  <Alert variant="warning" className="listing-hidden-card__alert">
                    <i className="pgicon pgicon-warning" />
                    {errorText}
                  </Alert>
                </div>
              )}
              {bodyTitle && <div className="listing-hidden-card__body-title">{bodyTitle}</div>}
              <div className="listing-hidden-card__reasons">
                {reasons.map((reason) => (
                  <Reason key={reason.id} onClick={onReasonClickCallback(reason)} text={reason.label} />
                ))}
              </div>
            </>
          )}
          <div className="listing-hidden-card__footer">
            {secondary && (
              <Actionable
                variant={secondary?.variant || 'secondary'}
                onClick={onSecondaryClick}
                className="listing-hidden-card__action-button"
              >
                {secondary.text}
              </Actionable>
            )}

            {undo && (
              <Actionable
                variant="outline-secondary icon-prefix"
                onClick={onUndoClick}
                className="listing-hidden-card__undo ghost-button"
              >
                <SvgIcon src={undoIconURL} width={16} height={16} />
                {undo.text}
              </Actionable>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ListingHiddenCard.displayName = 'ListingHiddenCard';

export default ListingHiddenCard;
