import classNames from 'classnames';
import { FC } from 'react';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './index.scss';
import { DeleteAccountModalProps } from './types';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import Title from 'root/widgets/common-components/title';
import HUIImage from 'root/widgets/common-components/hui-image';
import Actionable from 'root/widgets/common-components/actionable';

const DeleteAccountModal: FC<DeleteAccountModalProps> = (props) => {
  const {
    className,
    metadata,
    config: { shouldShow, onClose },
    heading,
    confirmationStep,
    successStep,
  } = props;

  return (
    <HUIModal
      className={classNames('delete-account-modal-root', className)}
      show={shouldShow}
      onHide={onClose}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
      centered
    >
      <HUIModal.Header closeButton>
        <Title headerTag="h4">{heading}</Title>
      </HUIModal.Header>
      {confirmationStep ? (
        <>
          <HUIModal.Body>
            <HUIImage src={confirmationStep.image.src} alt={confirmationStep.image.alt} />
            <p>{confirmationStep.message}</p>
          </HUIModal.Body>
          <HUIModal.Footer>
            <Actionable onClick={confirmationStep.cta.onClick}>{confirmationStep.cta.text}</Actionable>
          </HUIModal.Footer>
        </>
      ) : (
        successStep && (
          <>
            <HUIModal.Body>
              <i className="pgicon-ok-circled" />
              <Title headerTag="h4">{successStep.message}</Title>
              <p>{successStep.info}</p>
            </HUIModal.Body>
            <HUIModal.Footer>
              <Actionable onClick={successStep.cta.onClick} variant="outline-secondary">
                {successStep.cta.text}
              </Actionable>
            </HUIModal.Footer>
          </>
        )
      )}
    </HUIModal>
  );
};

export default DeleteAccountModal;
