import classNames from 'classnames';
import omit from 'lodash/omit';
import { FC } from 'react';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import HUIImage from 'root/widgets/common-components/hui-image';
import { ActionIconProps, ButtonProps } from 'root/widgets/contact-agent-card/types';

const Icon: FC<ActionIconProps & { alt: string }> = ({ className, src, alt }) => {
  if (src) {
    return <HUIImage className={className} src={src} alt={alt} />;
  }

  return <i className={classNames('pgicon', className)} />;
};

const ContactButton: FC<ButtonProps> = (props) => {
  const { leftIcon, rightIcon, text, description, ...btnProps } = props;

  return (
    <ActionableLink
      className="contact-button-root"
      {...omit(btnProps, ['type', 'qrLayout', 'groupLayout', 'phoneNumber'])}
    >
      {leftIcon && <Icon {...leftIcon} alt={text} />}

      <div className="action-text">{text}</div>

      {rightIcon && <Icon {...rightIcon} className={classNames('icon-right', rightIcon.className)} alt={text} />}

      {description && <div className="action-description">{description}</div>}
    </ActionableLink>
  );
};

ContactButton.displayName = 'ContactButton';

export default ContactButton;
