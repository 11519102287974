import isEmpty from 'lodash/isEmpty';
import { RecentSearchFiltersConfigProps } from 'root/widgets/search-with-filter/types';
import { RecentSearchTitleV1Props, RecentSearchValueV1Props } from './types';

export const preparePriceRange = (minValue, maxValue, inputData) => {
  const min = minValue && minValue.trim().split(/\s+/);
  const max = maxValue && maxValue.trim().split(/\s+/);

  const unit = min && min.length > 0 ? min[0] : max && max.length > 0 ? max[0] : '';

  if (!unit) return '';

  let minVal = min && min.length > 1 && min[1].replaceAll(',', '');
  let maxVal = max && max.length > 1 && max[1].replaceAll(',', '');

  if (minVal && minVal / 1000 > 1) {
    minVal = `${Math.floor(minVal / 1000)}K`;
  }
  if (maxVal && maxVal / 1000 > 1) {
    maxVal = `${Math.floor(maxVal / 1000)}K`;
  }

  if (!minVal) {
    return `${inputData.maxprice.placeholderText} ${unit} ${maxVal}`;
  }
  if (!maxVal) {
    return `${inputData.minprice.placeholderText} ${unit} ${minVal}`;
  }

  return `${unit} ${minVal} - ${maxVal}`;
};

export const prepareFloorAreaRange = (minValue, maxValue, inputData) => {
  if (!inputData) {
    return '';
  }

  const min = minValue && minValue.trim().split(/\s+/);
  const max = maxValue && maxValue.trim().split(/\s+/);

  const {
    min: { unit },
  } = inputData;

  const minVal = min && min.length > 0 && min[0].replaceAll(',', '');
  const maxVal = max && max.length > 0 && max[0].replaceAll(',', '');

  if (!minVal && !maxVal) {
    return '';
  }

  if (!minVal) {
    return `${inputData.max.placeholderText} ${maxVal} ${unit}`;
  }
  if (!maxVal) {
    return `${inputData.min.placeholderText} ${minVal} ${unit}`;
  }

  return `${minVal} - ${maxVal} ${unit}`;
};
