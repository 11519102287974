import classNames from 'classnames';
import React from 'react';
import { Card } from 'react-bootstrap';

import { EVENT_NAMES, emit } from 'root/widgets/events';

import './home-alert-card.scss';
import InfoItem from './sub-components/info-item';
import { HomeAlertCardProps } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const HomeAlertCard: React.FC<HomeAlertCardProps> = (props) => {
  const { title, metadata, link, area, details, onClick, context } = props;

  const handleClick = (evt: React.SyntheticEvent) => {
    emit(EVENT_NAMES.HOME_ALERT_CARD.CLICK, context);
    if (onClick) {
      onClick(evt);
    }
  };

  const renderDetails = () =>
    details.map((info) => {
      const isArray = Array.isArray(info);
      return (
        <span
          key={isArray ? info.map((item) => `${item.text}${item.icon?.name}`).join('-') : info.text}
          className="info"
        >
          {isArray ? (
            info.map((item) => (
              <InfoItem
                key={`${item.text}${item.icon?.name}`}
                {...item}
                {...(item.icon?.name ? { icon: { ...item.icon, placement: 'left' } } : {})}
              />
            ))
          ) : (
            <InfoItem {...info} {...(info.icon?.name ? { icon: { ...info.icon, placement: 'left' } } : {})} />
          )}
        </span>
      );
    });

  return (
    <ActionableLink className="home-alert-card-root" {...metadata} {...link}>
      <Card className="home-alert-card" onClick={handleClick}>
        {title && <div className="card-title">{title}</div>}
        <div className="card-details">
          <div className="property-area">
            {area.icon && <i className={classNames('pgicon', area.icon)} />}
            {area.text}
          </div>
          <div className="property-details">
            <p className="property-info">{renderDetails()}</p>
          </div>
        </div>
      </Card>
    </ActionableLink>
  );
};

HomeAlertCard.displayName = 'HomeAlertCard';

export default HomeAlertCard;
