import classNames from 'classnames';
import React from 'react';
import { Stack } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';

import { ErrorCardButtonProps } from 'root/widgets/error-card/types';

const ErrorCardButton: React.FC<ErrorCardButtonProps> = ({ className, children, variant, onClick, ...props }) => (
  <Stack>
    <Actionable
      variant={variant || 'primary'}
      className={classNames('error-card-button', className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </Actionable>
  </Stack>
);

export default ErrorCardButton;
