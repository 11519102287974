/* eslint-disable @typescript-eslint/no-misused-promises */
import { StatusCodes } from 'http-status-codes';
import React, { useContext, useMemo, useState } from 'react';

import { INPUT_TYPES } from 'root/widgets/common-components/generic-input-group/constants';

import { EMAIL_VALIDATION_PATTERN } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { SERVICE, VIEW, WIDGET_NAME } from 'root/widgets/pg-login/constants';
import PGLoginContext from 'root/widgets/pg-login/context';
import { getService } from 'root/widgets/pg-login/services';
import { Footer } from 'root/widgets/pg-login/sub-components';
import { ConsentChecksProps } from 'root/widgets/pg-login/types';

import './sign-up-view.scss';
import { SignUpViewProps } from './types';
import { captureExceptionSentry } from 'root/widgets/utils/logger';
import { GenericInputGroup, GenericInputGroupProps } from 'root/widgets/common-components/generic-input-group';
import { Masthead } from 'root/widgets/common-components/masthead';
import { Checklist } from 'root/widgets/common-components/checklist';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Typography } from 'root/common/atom-elements/typography';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';

const SignUpView: React.FC<SignUpViewProps> = ({
  masthead,
  emailInput,
  verifyBtnText,
  instructions,
  checkboxPrompts,
  errorTexts,
  footer,
}) => {
  const bffService = getService(SERVICE.BFF);
  const [serverError, setServerError] = useState('');
  const { otpRecipientId, email, consentChecks, context, sentry, changeView, setConsentChecks, setOtpId } =
    useContext(PGLoginContext);

  const captureException = (functionName: string, error) => {
    captureExceptionSentry(sentry, `${WIDGET_NAME}.${SignUpView.name}`, functionName, error);
  };

  const inputGroup: GenericInputGroupProps = {
    input: {
      value: email,
      disabled: true,
      placeholder: emailInput.placeholder,
      type: INPUT_TYPES.EMAIL,
      checkValidity: (text: string) => ({
        isInvalid: !new RegExp(EMAIL_VALIDATION_PATTERN).test(text),
        error: emailInput.validationMessage,
      }),
      metadata: {
        'data-automation-id': 'email-fld',
      },
    },
    button: {
      text: verifyBtnText,
      metadata: {
        'data-automation-id': 'verify-email-btn',
      },
    },
    onSubmit: async () => {
      emit(EVENT_NAMES.PG_LOGIN.REGISTRATION_EMAIL_VERIFY, context);
      try {
        setServerError('');
        const otpResponse = await bffService.generateOTP(otpRecipientId, email);
        setOtpId(otpResponse['otp_id']);
        changeView(VIEW.VERIFY_EMAIL);
      } catch (error) {
        captureException(inputGroup.onSubmit.name, error);
        const errorMessage =
          (error as { statusCode?: StatusCodes }).statusCode === StatusCodes.TOO_MANY_REQUESTS
            ? errorTexts.otpGenerationLimit
            : errorTexts.server;
        setServerError(errorMessage);
      }
    },
  };

  const checkboxes = useMemo(
    () =>
      Object.keys(checkboxPrompts).map((checkboxKey) => ({
        key: checkboxKey,
        isChecked: Boolean(consentChecks[checkboxKey]),
        text: checkboxPrompts[checkboxKey],
        onCheckChange: () => {
          setConsentChecks((checks: ConsentChecksProps) => ({ ...checks, [checkboxKey]: !consentChecks[checkboxKey] }));
        },
      })),
    [checkboxPrompts, consentChecks, setConsentChecks],
  );

  return (
    <div className="sign-up-view-root">
      <div className="pglogin-slide">
        <Masthead {...masthead} />
        <Checklist items={instructions} />
        <div className="pglogin-viewport">
          <GenericInputGroup {...inputGroup} />
          {checkboxes.map((prompt) => (
            <div className="d-flex gap-2 mt-2" key={`sign-up-checkbox-${prompt.key}`}>
              <Checkbox
                id={`sign-up-checkbox-${prompt.key}`}
                className="sign-up-checkbox"
                checked={prompt.isChecked}
                onChange={prompt.onCheckChange}
                data-automation-id={`signup-checkbox-automation-${prompt.key}`}
              />
              <Typography htmlFor={`sign-up-checkbox-${prompt.key}`}>{prompt.text}</Typography>
            </div>
          ))}
          {serverError && <HUIAlert variant="danger">{serverError}</HUIAlert>}
        </div>
      </div>
      <Footer>{footer}</Footer>
    </div>
  );
};

export default SignUpView;
