import { forwardRef } from 'react';
import { ExtendedContactViewProps } from 'root/widgets/contact-agent-card/types';
import Actionable from 'root/widgets/common-components/actionable';

const ExtendedContactView = forwardRef<HTMLDivElement, ExtendedContactViewProps>((props, ref) => {
  const { title, body, onClose } = props;

  return (
    <div ref={ref} className="extended-contact-view-root">
      <div className="view-header">
        <div className="view-title">{title}</div>
        <Actionable variant="default" onClick={onClose}>
          <i className="pgicon pgicon-cancel" />
        </Actionable>
      </div>
      <div className="view-body">{body}</div>
    </div>
  );
});

ExtendedContactView.displayName = 'ExtendedContactView';

export default ExtendedContactView;
