import { Form } from 'react-bootstrap';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { ColorBadge } from 'root/widgets/common-components/color-badge';

import { StationRowProps } from 'root/widgets/mrt-search-modal/types';

const StationRow = ({ station, onStationCheck, onStationClick, checked }: StationRowProps) => (
  <Form.Group className="station-row" onClick={onStationClick(station.id, checked)} da-id="mrt-station-row">
    <div className="d-flex align-items-center">
      <ColorBadge className="station-badge" colors={station.colors} />
      <span className="station-name" da-id="mrt-station-name">
        {station.name}
      </span>
    </div>
    <Checkbox
      onChange={onStationCheck(station.id)}
      checked={checked}
      id={station.id}
      metadata={{ dataAutomationId: 'mrt-station-checkbox' }}
    />
  </Form.Group>
);

StationRow.displayName = 'StationRow';

export default StationRow;
