import classNames from 'classnames';
import parse from 'html-react-parser';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React, { useEffect, useRef, useState } from 'react';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { OTPVerificationView } from 'root/widgets/otp-verification-view';

import { FORM_STEP_COMPLETION_PERCENT, INTENT_FIELDS, View, ViewSlidingClass } from './constants';
import './home-seller-verification-modal.scss';
import { AddressFormView, ContactFormView, IntentFormView, LeadVerificationView } from './sub-components';
import { AddressData, HomeSellerVerificationModalProps, IntentData, LeadData } from './types';
import { isValidEmail, validateDependencies } from 'root/widgets/utils/validation';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { HUIProgress } from 'root/widgets/common-components/hui-progress';
import { Divider } from 'root/widgets/common-components/divider';

const HomeSellerVerificationModalDependencies = ['generateOTP', 'verifyLead', 'logError'];

const HomeSellerVerificationModal: React.FC<HomeSellerVerificationModalProps> = (props) => {
  const { context, shouldShow, leadView, otpView, disclaimer, dependencies, metadata, userInfo, onClose } = props;
  const { action, inputGroup, masthead, serverErrorText, shouldCollectAdditionalIntent } = leadView;

  validateDependencies(dependencies, HomeSellerVerificationModalDependencies);

  const [leadData, setLeadData] = useState<LeadData>({
    name: userInfo.name || '',
    email: userInfo.email || '',
    mobile: userInfo.mobile || '',
    hasConsented: true,
    intent: {
      reason: inputGroup.reasonForSelling.selectedOption?.value || '',
      sellBy: inputGroup.sellByIntent.value || '',
      minPrice: inputGroup.priceRange.min.value || inputGroup.priceRange.min.suggestions[0] || '',
      maxPrice: inputGroup.priceRange.max.value || inputGroup.priceRange.max.suggestions[0] || '',
      hasAgent: false,
    },
    address: {
      propertyAddress: (inputGroup.propertyAddress?.value as string) || '',
      details: (inputGroup.propertyDetails?.value as string) || '',
    },
  });
  const firstView = isEmpty(inputGroup.propertyDetails)
    ? shouldCollectAdditionalIntent
      ? View.INTENT_FORM
      : View.LEAD_VERIFICATION
    : View.ADDRESS_FORM;

  const [serverError, setServerError] = useState<string>();
  const [otpId, setOTPId] = useState<string>('');
  const [slidingClass, setSlidingClass] = useState('');
  const [isLeadSubmitting, setLeadSubmitting] = useState(false);
  const [currentView, setCurrentView] = useState(firstView);

  const viewStack = useRef([firstView]);

  const isFirstView = viewStack.current.length === 1;
  const mobile = `${inputGroup.mobile.countryCode}${leadData.mobile}`;

  const updateCurrentView = () => {
    setCurrentView(viewStack.current[viewStack.current.length - 1]);
  };

  const handleBack = () => {
    viewStack.current.pop();
    setSlidingClass(ViewSlidingClass.FROM_RIGHT);
    updateCurrentView();
  };

  const handleHide = () => {
    onClose?.();
  };

  const handleContinue = () => {
    const { sellBy, ...intentData } = leadData.intent;

    let leadIntentData = {
      ...intentData,
      sellingPeriod: sellBy,
    };

    if (sellBy === inputGroup.sellByIntent.researchIntent) {
      leadIntentData = {
        sellingPeriod: sellBy,
      };
    }

    emit(EVENT_NAMES.HOME_VALUATION.USER_CONTINUE_ON_CLICK, {
      ...context,
      intent: leadIntentData,
    });
    viewStack.current.push(View.CONTACT_FORM);
    setSlidingClass(ViewSlidingClass.FROM_LEFT);
    updateCurrentView();
  };

  const handleAddressFormContinue = () => {
    const { address } = leadData;

    emit(EVENT_NAMES.HOME_VALUATION.USER_CONTINUE_ON_CLICK, {
      ...context,
      address,
    });
    viewStack.current.push(shouldCollectAdditionalIntent ? View.INTENT_FORM : View.LEAD_VERIFICATION);
    setSlidingClass(ViewSlidingClass.FROM_LEFT);
    updateCurrentView();
  };

  const handleVerificationComplete = () => {
    handleHide();
    dependencies.handleVerificationComplete({
      ...leadData,
    });
  };

  const handleLeadSubmit = () => {
    setLeadSubmitting(true);
    (async () => {
      try {
        setServerError('');
        const { sellBy, ...intentData } = leadData.intent;
        emit(EVENT_NAMES.HOME_VALUATION.USER_VERIFY_ON_CLICK, {
          ...context,
          intent: {
            ...intentData,
            sellingPeriod: sellBy,
          },
          consent: leadData.hasConsented,
        });

        if (isEmpty(otpView)) {
          handleVerificationComplete();
        } else {
          const res = await dependencies.generateOTP(mobile);

          if (!res) {
            throw new Error('OTP generation/delivery has failed');
          }

          setOTPId(res.otpId);
          viewStack.current.push(View.OTP_INPUT);
          setSlidingClass(ViewSlidingClass.FROM_LEFT);
          updateCurrentView();
        }
      } catch (error) {
        setServerError(serverErrorText);
        dependencies.logError(HomeSellerVerificationModal.name, handleLeadSubmit.name, error);
      } finally {
        setLeadSubmitting(false);
      }
    })();
  };

  const handleOTPSubmit = async (id: string, token: string) => {
    await dependencies.verifyLead({
      otp: {
        id,
        token,
      },
      userData: {
        name: leadData.name || '',
        mobile: leadData.mobile || '',
        countryCode: inputGroup.mobile.countryCode,
      },
    });
    handleVerificationComplete();
  };

  const retryOTP = async () => {
    const res = await dependencies.generateOTP(mobile);

    if (!res) {
      return null;
    }

    return {
      otp_id: res.otpId,
      status: res.status,
    };
  };

  const handleIntentDataChange = (intentData: IntentData) => {
    setLeadData({
      ...leadData,
      intent: {
        ...leadData.intent,
        ...intentData,
      },
    });
  };

  const handleContactDataChange = (contact: {
    name?: string;
    email?: string;
    mobile?: string;
    hasConsented?: boolean;
  }) => {
    setLeadData({
      ...leadData,
      ...contact,
    });
  };

  const handleAddressDataChange = (address: AddressData) => {
    setLeadData({
      ...leadData,
      address: {
        ...leadData.address,
        ...address,
      },
    });
  };

  useEffect(() => {
    viewStack.current = [firstView];
    setSlidingClass('');
  }, [shouldShow]);

  const getCompletionProgress = () => {
    switch (currentView) {
      case View.ADDRESS_FORM: {
        return (isEmpty(leadData.address?.propertyAddress) ? 0 : 1) * FORM_STEP_COMPLETION_PERCENT;
      }
      case View.INTENT_FORM: {
        const { ADDITIONAL: additionalIntentFields, DEFAULT: defaultIntentFields } = INTENT_FIELDS;
        const isShowingAdditionalFields =
          leadData.intent.sellBy && leadData.intent.sellBy !== inputGroup.sellByIntent.researchIntent;
        const totalFields = isShowingAdditionalFields
          ? additionalIntentFields.length + defaultIntentFields.length
          : defaultIntentFields.length;
        let completedFields = 0;

        defaultIntentFields.forEach((field) => {
          if (!isEmpty(leadData.intent[field]) || isBoolean(leadData.intent[field])) {
            completedFields += 1;
          }
        });

        if (isShowingAdditionalFields) {
          additionalIntentFields.forEach((field) => {
            if (!isEmpty(leadData.intent[field]) || isBoolean(leadData.intent[field])) {
              completedFields += 1;
            }
          });
        }

        return FORM_STEP_COMPLETION_PERCENT + (completedFields / totalFields) * FORM_STEP_COMPLETION_PERCENT;
      }
      case View.CONTACT_FORM: {
        const contactFields = Object.keys(omit(leadData, ['intent', 'hasConsented']));
        const totalFields = contactFields.length;
        let completedFields = 0;

        contactFields.forEach((field) => {
          if (field === 'email') {
            completedFields += isValidEmail(leadData[field] || '') ? 1 : 0;
          } else if (!isEmpty(leadData[field]) || isBoolean(leadData.intent[field])) {
            completedFields += 1;
          }
        });

        return 2 * FORM_STEP_COMPLETION_PERCENT + (completedFields / totalFields) * FORM_STEP_COMPLETION_PERCENT;
      }
      default:
        return 0;
    }
  };

  const getViewLayout = (view: View) => {
    switch (view) {
      case View.ADDRESS_FORM:
        return (
          <AddressFormView
            masthead={masthead}
            inputGroup={inputGroup}
            action={{
              ...action.continue,
              onClick: handleAddressFormContinue,
            }}
            leadData={leadData}
            onDataChange={handleAddressDataChange}
          />
        );
      case View.INTENT_FORM:
        return (
          <IntentFormView
            masthead={masthead}
            inputGroup={inputGroup}
            action={{
              ...action.continue,
              onClick: handleContinue,
            }}
            leadData={leadData}
            onDataChange={handleIntentDataChange}
          />
        );
      case View.CONTACT_FORM: {
        const contactFormAction = {
          isDisabled: isLeadSubmitting,
          shouldShowLoading: isLeadSubmitting,
          onClick: userInfo.isMobileVerified ? handleVerificationComplete : handleLeadSubmit,
          ...(userInfo.isMobileVerified && !isEmpty(otpView) ? action.continue : action.verify),
        };

        return (
          <ContactFormView
            masthead={masthead}
            inputGroup={inputGroup}
            userInfo={userInfo}
            action={contactFormAction}
            serverError={serverError}
            leadData={leadData}
            onDataChange={handleContactDataChange}
          />
        );
      }
      case View.LEAD_VERIFICATION:
        return (
          <LeadVerificationView
            masthead={masthead}
            inputGroup={inputGroup}
            action={{
              ...action.verify,
              isDisabled: isLeadSubmitting,
              shouldShowLoading: isLeadSubmitting,
              onClick: handleLeadSubmit,
            }}
            serverError={serverError}
            leadData={leadData}
            onIntentDataChange={handleIntentDataChange}
            onContactDataChange={handleContactDataChange}
          />
        );
      case View.OTP_INPUT:
        return (
          !isEmpty(otpView) && (
            <OTPVerificationView
              {...otpView}
              masthead={{
                ...otpView.masthead,
                description: parse(
                  (otpView.masthead.description as string).replace('{{phoneNumber}}', leadData.mobile as string),
                ) as JSX.Element,
              }}
              otpId={otpId}
              onOtpIdUpdate={setOTPId}
              sideEffects={{ generateOTP: retryOTP }}
              onSubmit={handleOTPSubmit}
            />
          )
        );
      default:
        return null;
    }
  };

  return (
    <HUIModal
      className="hsv-root"
      show={shouldShow}
      onBack={handleBack}
      onHide={handleHide}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <HUIModal.Header backButton={!isFirstView} closeButton={isFirstView} />
      <HUIModal.Body className="p-0">
        {currentView !== View.OTP_INPUT && <HUIProgress className="mb-4" percentComplete={getCompletionProgress()} />}
        <div key={currentView} className={classNames('hsv-body px-4', slidingClass)}>
          {getViewLayout(currentView)}
        </div>
        {disclaimer && currentView === View.CONTACT_FORM && (
          <div className="hsv-footer-root">
            <Divider />
            <div className="text-center hsv-footer-desc">
              <p className="mb-0">{disclaimer}</p>
            </div>
          </div>
        )}
      </HUIModal.Body>
    </HUIModal>
  );
};

export default HomeSellerVerificationModal;
