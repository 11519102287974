import { TitleRowProps } from 'root/widgets/mrt-search-modal/types';

const TitleRow = ({ sectionTitle }: TitleRowProps) => (
  <div className="title-row">
    <p className="section-title m-0">{sectionTitle}</p>
  </div>
);

TitleRow.displayName = 'TitleRow';

export default TitleRow;
