import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { FC, FormEventHandler } from 'react';
import { Form } from 'react-bootstrap';

import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { filterMetadata } from 'root/widgets/utils/filter';

import './address-form-view.scss';
import { AddressFormViewProps } from './types';
import { Masthead } from 'root/widgets/common-components/masthead';
import Actionable from 'root/widgets/common-components/actionable';

const AddressFormView: FC<AddressFormViewProps> = (props) => {
  const { className, masthead, inputGroup, leadData, action, metadata, onDataChange } = props;
  const { propertyAddress, propertyDetails } = inputGroup;
  const isFormValid = Boolean(leadData?.address?.propertyAddress);

  const handlePropertyAddressChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange({ propertyAddress: evt.target.value });
  };

  const handlePropertyDetailsChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onDataChange({ details: evt.target.value });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    action?.onClick?.();
  };

  return (
    <div
      className={classNames('hsv-address-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <Masthead {...masthead} />
      <Form className="d-flex flex-column hsv-form mt-4" onSubmit={handleSubmit}>
        {!isEmpty(propertyAddress) && (
          <Form.Group className="hsv-property-address-root" controlId="hsv-property-address">
            <Form.Label className="mb-1 hsv-label">{propertyAddress.label}</Form.Label>
            <Form.Control
              as="textarea"
              maxLength={propertyAddress.maxLength}
              className="hsv-property-address-textarea"
              placeholder={propertyAddress.placeholder}
              value={leadData.address?.propertyAddress || ''}
              onChange={handlePropertyAddressChange}
              required
            />
          </Form.Group>
        )}
        {!isEmpty(propertyDetails) && (
          <Form.Group className="hsv-property-details-root" controlId="hsv-property-details">
            <Form.Label className="mb-1 hsv-label">{propertyDetails.label}</Form.Label>
            <Form.Control
              type="text"
              maxLength={propertyDetails.maxLength}
              placeholder={propertyDetails.placeholder}
              value={leadData.address?.details || ''}
              onChange={handlePropertyDetailsChange}
            />
          </Form.Group>
        )}
        <Actionable
          variant="primary"
          type="submit"
          shouldShowLoading={action.shouldShowLoading}
          disabled={!isFormValid || action.isDisabled}
          {...extractDataAutomationId(action.metadata)}
        >
          {action.text}
        </Actionable>
      </Form>
    </div>
  );
};

AddressFormView.displayName = 'AddressFormView';

export default AddressFormView;
