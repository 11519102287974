import isEmpty from 'lodash/isEmpty';
import {
  RecentSearchTitleV1Props,
  RecentSearchValueV1Props,
} from 'root/widgets/search-with-filter/sub-components/recent-search/types';
import { RecentSearchFiltersConfigProps } from 'root/widgets/search-with-filter/types';

// Format number to comma after 1000 place if not greater than 9999 and to K if greater than 9999
const formatNumber = (value: number | string): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 2,
  });

  if (Number(value) < 10000) {
    return value.toLocaleString();
  }

  return formatter.format(Number(value));
};

const cleanSearchParams = (searchParams: RecentSearchValueV1Props) => {
  Object.keys(searchParams).forEach((key) => {
    const cleanedKey = key.replace(/\[]/g, '');
    if (!searchParams[key] || searchParams[key] === '0' || searchParams[key] === '' || searchParams[key] === 0) {
      delete searchParams[key];
    }

    if (cleanedKey !== key) {
      searchParams[cleanedKey] = searchParams[key];
      delete searchParams[key];
    }
  });
};

const prepareRangeTitle = (
  min: number | string | undefined,
  max: number | string | undefined,
  minConfig: { unit?: string; placeholderText?: string },
  maxConfig: { unit?: string; placeholderText?: string },
  key: string,
  preparedTitles: Record<string, string>,
  isUnitSuffix = false,
  shouldFormat = true,
) => {
  const formatValue = (value: number | string) => (shouldFormat ? formatNumber(value) : value);

  if (min && max) {
    preparedTitles[key] = isUnitSuffix
      ? `${formatValue(min)} - ${formatValue(max)} ${minConfig.unit}`
      : `${minConfig.unit} ${formatValue(min)} - ${formatValue(max)}`;
  } else if (min) {
    preparedTitles[key] = isUnitSuffix
      ? `${minConfig.placeholderText} ${formatValue(min)} ${minConfig.unit}`
      : `${minConfig.placeholderText} ${minConfig.unit} ${formatValue(min)}`;
  } else if (max) {
    preparedTitles[key] = isUnitSuffix
      ? `${minConfig.placeholderText} ${formatValue(max)} ${maxConfig.unit}`
      : `${maxConfig.placeholderText} ${maxConfig.unit} ${formatValue(max)}`;
  }
};

const prepareTenureTitle = (
  tenure: string[] | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
) => {
  if (tenure) {
    preparedTitles['tenure'] = filtersConfig['tenure']?.typeOptions
      ?.filter((option) => tenure.includes(option.value))
      .map((option) => option.label)
      .filter(Boolean)
      .join(' / ');
  }
};

const prepareListingTypeTitle = (
  listingType: string | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
) => {
  if (listingType) {
    preparedTitles['listing_type'] = filtersConfig['listing_type']?.typeOptions
      ?.filter((option) => listingType === option.value)
      .map((option) => option.label)
      .filter(Boolean)
      .join(', ');
  }
};

const prepareBedsTitle = (
  beds: string[] | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
) => {
  if (beds) {
    const studioText = beds.includes('0')
      ? filtersConfig['beds']?.typeOptions?.find((option) => option.value === '0')?.label
      : '';
    const roomText = beds.includes('-1')
      ? filtersConfig['beds']?.typeOptions?.find((option) => option.value === '-1')?.label
      : '';
    const selectedBeds = beds
      .filter((bed) => bed !== '0' && bed !== '-1')
      .map((bed) => filtersConfig['beds']?.typeOptions?.find((option) => option.value === bed)?.label)
      .filter(Boolean)
      .join(' / ');
    const bedsText =
      selectedBeds === '' ? undefined : `${selectedBeds}${filtersConfig['beds']?.suffixPlaceholderText ?? ''}`;

    preparedTitles['beds'] = [studioText, bedsText, roomText].filter(Boolean).join(', ');
  }
};

const preparePropertyTypeTitle = (
  propertyTypeCode: string[] | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
  listing_type: string | undefined,
) => {
  if (propertyTypeCode) {
    const labelPrefix = listing_type ? filtersConfig['property_type_code']?.labelPrefix?.[listing_type] ?? '' : '';
    const selectedCodes = filtersConfig['property_type_code']?.typeOptions
      ?.filter((option) => propertyTypeCode.includes(option.value))
      .map((option) => option.label);
    const parentCode =
      (selectedCodes &&
        filtersConfig['property_type_code']?.typeOptions?.find((option) => option.label === selectedCodes[0])
          ?.parent) ||
      false;
    const allSubTypeSelected =
      parentCode &&
      selectedCodes?.length ===
        filtersConfig['property_type_code']?.typeOptions?.filter((option) => option.parent === parentCode).length;

    preparedTitles['property_type_code'] = `${labelPrefix}${
      allSubTypeSelected
        ? filtersConfig['property_type_group']?.typeOptions?.find((option) => option.value === parentCode).label
        : selectedCodes?.join(' / ')
    }`;
  }
};

const prepareRoomTypeTitle = (
  roomType: string[] | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
) => {
  if (roomType && filtersConfig['roomType']?.typeOptions) {
    const selectedCodes = filtersConfig['roomType']?.typeOptions
      ?.filter((option) => roomType.includes(option.value))
      .map((option) => option.label);

    if (filtersConfig['roomType']?.typeOptions.length !== selectedCodes?.length) {
      preparedTitles['roomType'] = selectedCodes?.join(' / ');
    }
  }
};

const prepareTenantsTitle = (
  maxTenants: string[] | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
) => {
  if (maxTenants && filtersConfig['maxTenants']?.typeOptions) {
    const labelSuffix = filtersConfig['maxTenants']?.labelSuffix ?? '';
    const selectedCodes = filtersConfig['maxTenants']?.typeOptions
      ?.filter((option) => maxTenants.includes(option.value))
      .map((option) => option.label);

    if (filtersConfig['maxTenants']?.typeOptions.length !== selectedCodes?.length) {
      preparedTitles['maxTenants'] = `${selectedCodes?.join(' / ')}${labelSuffix}`;
    }
  }
};

const prepareTenantGenderTitle = (
  tenantGender: string[] | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
) => {
  if (tenantGender && filtersConfig['tenantGender']?.typeOptions) {
    const selectedCodes = filtersConfig['tenantGender']?.typeOptions
      ?.filter((option) => tenantGender.includes(option.value))
      .map((option) => option.label);

    if (selectedCodes.length !== selectedCodes?.length) {
      preparedTitles['tenantGender'] = selectedCodes?.join(' / ');
    }
  }
};

const prepareCookingTypeTitle = (
  cookingType: string[] | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
) => {
  if (cookingType && filtersConfig['cookingType']?.typeOptions) {
    const selectedCodes = filtersConfig['cookingType']?.typeOptions
      ?.filter((option) => cookingType.includes(option.value))
      .map((option) => option.label);

    if (filtersConfig['cookingType']?.typeOptions.length !== selectedCodes?.length) {
      preparedTitles['cookingType'] = selectedCodes?.join(' / ');
    }
  }
};

const prepareTenancyConditionsTitle = (
  tenancyConditions: string[] | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
) => {
  if (tenancyConditions && filtersConfig['tenancyConditions']?.typeOptions) {
    const selectedCodes = filtersConfig['tenancyConditions']?.typeOptions
      ?.filter((option) => tenancyConditions.includes(option.value))
      .map((option) => option.label);

    if (selectedCodes.length > 0) {
      preparedTitles['tenancyConditions'] = selectedCodes?.join(' / ');
    }
  }
};

const prepareFloorLevelTitle = (
  floorLevel: string[] | undefined,
  filtersConfig: RecentSearchFiltersConfigProps['filters'],
  preparedTitles: Record<string, string>,
) => {
  if (floorLevel && filtersConfig['floorLevel']?.typeOptions) {
    const labelSuffix = filtersConfig['floorLevel']?.labelSuffix ?? '';
    const selectedCodes = filtersConfig['floorLevel']?.typeOptions
      ?.filter((option) => floorLevel.includes(option.value))
      .map((option) => option.label);

    if (selectedCodes.length > 0) {
      preparedTitles['floorLevel'] = `${selectedCodes?.join(' / ')}${labelSuffix}`;
    }
  }
};

// Function to prepare recent search titles using RecentSearchType V1_5
export const prepareRecentSearchTitles = (
  searchParams: RecentSearchValueV1Props,
  options: RecentSearchFiltersConfigProps | undefined,
) => {
  if (options === undefined) return null;

  cleanSearchParams(searchParams);

  const filtersConfig = options?.filters || {};
  const preparedTitles: Record<string, string> = {};
  const {
    minprice,
    maxprice,
    minsize,
    maxsize,
    minpsf,
    maxpsf,
    mintop: mintopyear,
    maxtop: maxtopyear,
    tenure,
    listing_type: listingType,
    property_type_code: propertyTypeCode,
    beds,
    roomType,
    maxTenants,
    tenantGender,
    floor_level: floorLevel,
    tenancyConditions,
    cookingType,
  } = searchParams;

  prepareRangeTitle(
    minprice,
    maxprice,
    filtersConfig['minprice'],
    filtersConfig['maxprice'],
    'minprice',
    preparedTitles,
  );
  prepareRangeTitle(
    minsize,
    maxsize,
    filtersConfig['minsize'],
    filtersConfig['maxsize'],
    'minsize',
    preparedTitles,
    true,
  );
  prepareRangeTitle(
    mintopyear,
    maxtopyear,
    filtersConfig['mintop'],
    filtersConfig['maxtop'],
    'mintop',
    preparedTitles,
    false,
    false,
  );
  prepareRangeTitle(minpsf, maxpsf, filtersConfig['minpsf'], filtersConfig['maxpsf'], 'minpsf', preparedTitles, true);

  prepareTenureTitle(tenure, filtersConfig, preparedTitles);
  prepareListingTypeTitle(listingType, filtersConfig, preparedTitles);
  prepareBedsTitle(beds, filtersConfig, preparedTitles);
  preparePropertyTypeTitle(propertyTypeCode, filtersConfig, preparedTitles, listingType);
  prepareRoomTypeTitle(roomType, filtersConfig, preparedTitles);
  prepareTenantsTitle(maxTenants, filtersConfig, preparedTitles);
  prepareTenantGenderTitle(tenantGender, filtersConfig, preparedTitles);
  prepareCookingTypeTitle(cookingType, filtersConfig, preparedTitles);
  prepareTenancyConditionsTitle(tenancyConditions, filtersConfig, preparedTitles);
  prepareFloorLevelTitle(floorLevel, filtersConfig, preparedTitles);

  // Prepare Property freetext
  if (searchParams['freetext'] || searchParams['_freetextDisplay']) {
    preparedTitles['freetext'] = searchParams['freetext'] || searchParams['_freetextDisplay'];
  }

  return isEmpty(preparedTitles) ? undefined : (preparedTitles as unknown as RecentSearchTitleV1Props);
};
