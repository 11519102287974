import classNames from 'classnames';
import { FC } from 'react';
import { Badge as BSBadge } from 'react-bootstrap';

import { HIVE_STATIC_VERSION } from 'root/widgets/constants';
import { SvgIcon } from 'root/widgets/svg-icon';
import './badge.scss';
import type { BadgeProps } from './types';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { isValidUrl } from 'root/widgets/utils/validation';

const MAX_COUNT_NUMBER = 99;
const MAX_COUNT_NUMBER_DISPLAY = '99+';

const Badge: FC<BadgeProps> = ({ className, metadata, ...props }) => {
  switch (props.type) {
    case 'round': {
      const { action } = props;
      const renderTypography = () => {
        if (action !== 'count' || props?.count === undefined) {
          return false;
        }
        const { count } = props;

        return count > MAX_COUNT_NUMBER ? MAX_COUNT_NUMBER_DISPLAY : count;
      };

      return (
        <BSBadge
          onClick={props.onClick}
          className={classNames(
            'badge-root',
            'badge-round',
            'py-0 px-1',
            `action--${props.count ? action : 'indicator'}`,
            className,
          )}
          {...filterMetadata(metadata)}
          {...extractDataAutomationId(metadata)}
          bg="reset"
        >
          {renderTypography()}
        </BSBadge>
      );
    }
    case 'small': {
      const { children, bg = 'neutral', variant = 'primary', shouldDisable = false, onClick } = props;
      return (
        <BSBadge
          onClick={onClick}
          className={classNames(
            'badge-root px-2 py-1',
            'badge-small',
            { 'badge-small--disabled': shouldDisable },
            className,
          )}
          {...filterMetadata(metadata)}
          {...extractDataAutomationId(metadata)}
          bg={`${variant}-${bg}`}
        >
          {children}
        </BSBadge>
      );
    }
    default: {
      const { children, icon, variant = 'primary', bg = 'neutral', shouldDisable = false, onClick } = props;

      return (
        <BSBadge
          className={classNames('badge-root', 'badge-default', { 'badge-default--disabled': shouldDisable }, className)}
          {...filterMetadata(metadata)}
          {...extractDataAutomationId(metadata)}
          onClick={onClick}
          bg={`${variant}-${bg}`}
        >
          {icon && (
            <div className="me-1" style={{ height: 16, width: 16 }}>
              <SvgIcon
                src={
                  isValidUrl(icon)
                    ? icon
                    : `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/font/v2/${icon}.svg`
                }
                width={16}
                height={16}
                title={icon}
                className="badge-icon"
              />
            </div>
          )}
          {children}
        </BSBadge>
      );
    }
  }
};

export default Badge;
