/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext } from 'react';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { OTPVerificationView } from 'root/widgets/otp-verification-view';
import { SERVICE, VIEW } from 'root/widgets/pg-login/constants';
import PGLoginContext from 'root/widgets/pg-login/context';
import { getService } from 'root/widgets/pg-login/services';

import { VerifyEmailViewProps } from './types';

const VerifyEmailView: React.FC<VerifyEmailViewProps> = ({
  masthead,
  checkSpamPrompt,
  resendActionText,
  errorTexts,
  isAutomationUser,
  checkboxPrompts,
}) => {
  const bffService = getService(SERVICE.BFF);
  const {
    email,
    otpRecipientId,
    otpId: initialOtpId,
    consentChecks,
    shouldUseShortFlow,
    context,
    sentry,
    changeView,
    setOtpId,
    setUserInfo,
    onSuccess,
  } = useContext(PGLoginContext);

  const handleGenerateOTP = () => {
    emit(EVENT_NAMES.PG_LOGIN.REGISTRATION_RESEND_OTP, context);
    return bffService.generateOTP(otpRecipientId, email);
  };

  const handleSubmitOTP = async (otpId, otpToken) => {
    emit(EVENT_NAMES.PG_LOGIN.REGISTRATION_OTP_VERIFY, context);
    Object.keys(checkboxPrompts).forEach((key) => {
      consentChecks[key] = Boolean(consentChecks[key]);
    });
    const res = await bffService.loginWithOTP(email, otpId, otpToken, consentChecks);
    const token = { accessToken: res.accessToken, refreshToken: res.refreshToken };
    setUserInfo(res);
    emit(EVENT_NAMES.PG_LOGIN.REGISTRATION_SUCCESS, { ...context, OTP: true });
    if (shouldUseShortFlow) {
      await onSuccess(token, { ...res, email });
    } else {
      changeView(VIEW.SET_PASSWORD);
    }
  };

  return (
    <div className="verify-email-view-root">
      <OTPVerificationView
        className="pglogin-slide"
        masthead={masthead}
        errorTexts={errorTexts}
        isAutomationUser={isAutomationUser}
        checkSpamPrompt={checkSpamPrompt}
        resendActionText={resendActionText}
        sentry={sentry}
        otpId={initialOtpId}
        onOtpIdUpdate={setOtpId}
        sideEffects={{
          generateOTP: handleGenerateOTP,
        }}
        onSubmit={handleSubmitOTP}
      />
    </div>
  );
};

VerifyEmailView.displayName = 'VerifyEmailView';

export default VerifyEmailView;
