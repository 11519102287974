import classNames from 'classnames';
import React from 'react';
import { Button, OverlayTrigger } from 'react-bootstrap';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import { LoaderPlacement } from './constants';
import { ActionableProps } from './types';

const Actionable: React.FC<ActionableProps> = (props) => {
  const {
    metadata,
    context,
    popover,
    className,
    children,
    shouldShowLoading,
    loaderPlacement = LoaderPlacement.RIGHT,
    onClick,
    title,
    ...rest
  } = props;
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    emit(EVENT_NAMES.ACTIONABLE.CLICK, context);
    if (onClick) {
      onClick(evt);
    }
  };

  const button = (
    <Button
      onClick={handleClick}
      className={classNames('actionable', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
      {...rest}
      title={title}
    >
      {loaderPlacement === LoaderPlacement.LEFT && shouldShowLoading && (
        <span className="animate-spin pgicon pgicon-loading" />
      )}
      {children}
      {loaderPlacement === LoaderPlacement.RIGHT && shouldShowLoading && (
        <span className="animate-spin pgicon pgicon-loading" />
      )}
    </Button>
  );

  if (popover) {
    return <OverlayTrigger {...popover}>{button}</OverlayTrigger>;
  }

  return button;
};

Actionable.displayName = 'Actionable';

export default Actionable;
