import classnames from 'classnames';
import React from 'react';

import { KEYBOARD } from 'root/widgets/constants';

import './otp-input-group.scss';
import BoxInput from './sub-components/box-input';
import { OTPInputGroupProps } from './types';

const OTPInputGroup: React.FC<OTPInputGroupProps> = ({
  value,
  focusIndex = -1,
  error,
  metadata,
  onChange,
  setFocusIndex,
  onComplete,
}) => {
  const MAX_INDEX = value.length - 1;

  const changeDigit = (index: number, digit: string) => {
    const newDigits = [...value];
    newDigits[index] = digit;
    onChange(newDigits);
    if (onComplete && newDigits.every(Boolean) && index === MAX_INDEX) {
      onComplete(newDigits);
    }
  };

  const handleSpecialKeys = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const moveToPrevInput = () => {
      setFocusIndex(Math.max(0, index - 1));
    };
    const moveToNextInput = () => {
      setFocusIndex(Math.min(index + 1, MAX_INDEX));
    };
    switch (event.key) {
      case KEYBOARD.DELETE:
      case KEYBOARD.BACKSPACE:
        if ((event.target as HTMLInputElement).value) {
          changeDigit(index, '');
        } else {
          moveToPrevInput();
        }
        break;
      case KEYBOARD.ARROW_LEFT:
        moveToPrevInput();
        break;
      case KEYBOARD.ARROW_RIGHT:
        moveToNextInput();
        break;
      case KEYBOARD.TAB:
        if (event.shiftKey) {
          moveToPrevInput();
        } else {
          moveToNextInput();
        }
        break;
      default:
        break;
    }
  };

  const handleInputChange = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (/^\d+$/g.test(event.key)) {
      changeDigit(index, event.key);
      setFocusIndex(Math.min(index + 1, MAX_INDEX));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    event.preventDefault();
    handleSpecialKeys(event, index);
    handleInputChange(event, index);
  };

  return (
    <div className={classnames('otp-input-group-root', { error: Boolean(error) })} {...metadata}>
      <div className="otp-input-group">
        {value.map((digit, index) => (
          <BoxInput
            key={index}
            index={index}
            value={digit}
            isFocused={index === focusIndex}
            onKeyDown={handleKeyDown}
            onFocus={setFocusIndex}
          />
        ))}
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default OTPInputGroup;
