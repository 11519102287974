import React from 'react';
import { Modal } from 'react-bootstrap';

import { FinanceEnquiryView } from 'root/widgets/finance-enquiry-view';

import './agent-enquiry-modal.scss';
import { View } from './constants';
import { AgentEnquiryModalProps } from './types';
import { EnquiryFormView } from './views';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const AgentEnquiryModal: React.FC<AgentEnquiryModalProps> = (props) => {
  const {
    shouldShow,
    title,
    enquiryFormView,
    metadata,
    onClose,
    isFinanceEnquiryEnabled,
    financeEnquiryView,
    view = View.ENQUIRY_FORM,
  } = props;

  const handleFormSubmitSuccess = () => {
    enquiryFormView?.onSuccess?.();
  };

  const getViewLayout = () => {
    if (view === View.ENQUIRY_FORM) {
      return <EnquiryFormView {...enquiryFormView} onSuccess={handleFormSubmitSuccess} />;
    }
    if (view === View.SUCCESS_VIEW && isFinanceEnquiryEnabled && financeEnquiryView) {
      return <FinanceEnquiryView {...financeEnquiryView} />;
    }
    return null;
  };

  return (
    <HUIModal
      className="agent-enquiry-modal-root"
      fullscreen="sm-down"
      show={shouldShow}
      onHide={onClose}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <HUIModal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </HUIModal.Header>
      <HUIModal.Body>{getViewLayout()}</HUIModal.Body>
    </HUIModal>
  );
};

AgentEnquiryModal.displayName = 'AgentEnquiryModal';

export default AgentEnquiryModal;
