import axios, { AxiosError } from 'axios';
import { captureExceptionSentry } from 'root/widgets/utils/logger';

declare const window: any;

export const getSentryInstance = () => {
  if (!window.Sentry) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return {
    instance: window.Sentry,
    project: {
      name: 'symbiosis-frontend',
    },
  };
};

/**
 * Will try to parse the string into json, will return back if not successful
 * @param data
 */
const getJsonOrOriginal = (data: any) => {
  if (!data) {
    return;
  }

  let result = data;

  /* eslint-disable no-empty */
  try {
    result = typeof data === 'string' ? JSON.parse(data) : data;
  } catch {}

  // eslint-disable-next-line consistent-return
  return result;
};

const formatError = (src: string, err: AxiosError | Error | string) => {
  let formatted: Record<string, any> = { source: src };

  if (typeof err === 'string') {
    formatted.message = err;

    return formatted;
  }
  const { stack, message } = err;
  formatted.stack = stack;
  formatted.message = message;

  if (axios.isAxiosError(err)) {
    const { code, config, response } = err;
    formatted.errorCode = code;

    if (response) {
      formatted.status = response.status;
      formatted.responseBody = getJsonOrOriginal(response.data);
    } else {
      formatted.additionalErrorDetails = 'Request was made, but no response';
    }

    if (config) {
      const { url, method, headers } = config;
      formatted = { ...formatted, url, method, headers };
    }
  }

  return formatted;
};

/**
 * @param fileType - path of the file, ex: 'services.listings'
 * @param funcName - name of method, ex: 'getLatestProjectsListings'
 * @param error - error object, pass from "catch" block
 * @param level - the error level e.g. info, warn, error default is error
 * @param hasSensitive - to denote if the error is of AxiosError type and have any sensitive info in config.data
 * @returns `services.listings.getLatestProjectsListings,Error: test {"label":"Symbiosis API","timestamp":"2022-08-26T05:04:13.620Z"}`
 */
export const logFrontendException = (fileType: string, funcName: string, error: any, hasSensitive = false) => {
  if (hasSensitive && axios.isAxiosError(error)) {
    delete error.config?.data;
  }

  const source = `${fileType}.${funcName}`;
  const formattedError = formatError(source, error);

  captureExceptionSentry(getSentryInstance(), fileType, funcName, formattedError);
};
