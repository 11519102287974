import {
  GenerateLocationMapFuncType,
  LocationFilterItemsType,
  ModifySelectedCodesFuncType,
  SelectedCodesType,
} from './types';

/*
TODO: locationMap type enhance:
in SRP, there is condition to handle url parsing, and type missing
ex. only get area_code, then need find out its parent state, and district_code

in that phase, change locationMap interface to
{
  [code: string]: {
    value: string;
    parentCode?: string // code
    codeType: string
  }
}
then we can use locationMap to find parent code and codeType
*/

const setItems = (items, dict) => {
  items.forEach((item) => {
    dict[item.code] = item.value;

    if (item?.items?.length) {
      setItems(item.items, dict);
    }
  });
  return dict;
};

export const generateLocationMap: GenerateLocationMapFuncType = (items) => {
  const locationMap = setItems(items, {});

  return locationMap;
};

/**
 * process 3rd layer codes before submit,
 * if 3rd layer options are all selected,
 * remove all 3rd layer selection (area_code[])
 * only send 1st, 2nd layer codes (region_code, district_code)
 * example of secondLevelItem
 * <2nd-layer-item>:
 * code: <second-layer-code, district_code>
 * items: Array<3rd-layer-item>, each one own a 3rd layer code(area_code[])
 *
 * @param codes selectedCodes
 * @param secondLevelItems
 * @param secondLevelCodeType district_code
 * @param thirdLevelCodeType area_code[]
 * @returns
 */
export const processSubmitCodes: ModifySelectedCodesFuncType = (
  codes,
  secondLevelItems,
  secondLevelCodeType,
  thirdLevelCodeType,
) => {
  if (codes[thirdLevelCodeType]?.length > 0) {
    const selectedSecondLevelCode = codes[secondLevelCodeType];
    const thirdLevelItems = secondLevelItems.find((item) => item.code === selectedSecondLevelCode)?.items || [];
    if (thirdLevelItems.length === codes[thirdLevelCodeType].length) {
      delete codes[thirdLevelCodeType];
    }
  }
  return codes;
};

/**
 * process 3rd layer codes when modal launch,
 * if there is no 3rd layer selection, and there is 2nd layer selection
 * select all 3rd layer codes from 2nd layer items
 *
 * @param codes selectedCodes
 * @param secondLevelItems
 * @param secondLevelCodeType district_code
 * @param thirdLevelCodeType area_code[]
 * @returns
 */
export const processLaunchCodes: ModifySelectedCodesFuncType = (
  codes,
  secondLevelItems,
  secondLevelCodeType,
  thirdLevelCodeType,
) => {
  if (!codes[thirdLevelCodeType] && codes[secondLevelCodeType]) {
    const selectedSecondLevelCode = codes[secondLevelCodeType];
    const thirdLevelCodes = secondLevelItems
      ?.find((item) => item.code === selectedSecondLevelCode)
      ?.items?.map((item) => item.code);
    if (thirdLevelCodes) {
      codes[thirdLevelCodeType] = thirdLevelCodes;
    }
  }
  return codes;
};
