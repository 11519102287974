import { useControllableValue } from 'ahooks';
import { useState, FC, ChangeEventHandler } from 'react';

import { Checkbox } from 'root/common/atom-elements/checkbox';
import { HUIInput } from 'root/widgets/common-components/hui-input';
import { CheckboxListProps, ColumnCount } from './types';

import './checkbox-list.scss';
import { Typography } from 'root/common/atom-elements/typography';
import classNames from 'classnames';
import Divider from 'root/widgets/common-components/divider/divider';

const CheckboxList: FC<CheckboxListProps> = ({
  id,
  items,
  selectAllLabel,
  shouldShowFilter,
  filterPlaceholderText,
  columnCount = ColumnCount.TWO_COLUMN,
  enableSelectAllThreshold = true,
  isRevertLabel = false,
  isHidden = false,
  shouldShowDivider = true,
  defaultValue = [],
  ...props
}) => {
  const [value, onSelectionChange] = useControllableValue<string[]>(props, {
    trigger: 'onSelectionChange',
    defaultValue: defaultValue,
  });

  const isIndeterminate = value.length > 0 && value.length < items.length;
  const [keyword, setKeyword] = useState('');

  const handleSelectAll: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (isIndeterminate) {
      onSelectionChange?.([]);
      props?.onSelectAll?.(false);
    } else {
      props?.onSelectAll?.(event.target.checked);
      onSelectionChange?.(event.target.checked ? items.map((item) => item.value) : []);
    }
  };

  const getItemChangeHandler =
    (text: string): ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      if (event.target.checked) {
        onSelectionChange?.([...value, text]);
      } else {
        onSelectionChange?.(value.filter((item) => item !== text));
      }
    };

  const isAllSelected = items.length === value.length;

  const handleSearchOnChange = (searchKey: string) => {
    setKeyword(searchKey);
    props.onSearch?.(searchKey);
  };

  const filteredItems = items.filter((item) => item.label.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()));

  return (
    <div da-id="checkbox-list-root" className={classNames('checkbox-list-root', { hidden: isHidden })}>
      {shouldShowFilter && (
        <HUIInput
          value={keyword}
          placeholder={filterPlaceholderText ?? ''}
          prefix={<i className="pgicon-search" />}
          onInputChange={handleSearchOnChange}
          className="mb-2"
          isClearable
        />
      )}
      {enableSelectAllThreshold &&
        items.length >= (typeof enableSelectAllThreshold === 'number' ? enableSelectAllThreshold : 0) &&
        !keyword &&
        selectAllLabel && (
          <div>
            <Typography
              variant="body-s"
              htmlFor={`select-all${id ? `-${id}` : ''}`}
              element="label"
              className={classNames('checkbox-label d-flex gap-2 py-3 ', {
                'justify-content-between': isRevertLabel,
              })}
              metadata={{ dataAutomationId: 'select-all-checkbox-label' }}
            >
              <div className={classNames('checkbox-wrapper', 'left-align', { 'order-1': isRevertLabel })}>
                <Checkbox
                  className="left-align"
                  id={`select-all${id ? `-${id}` : ''}`}
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  indeterminate={isIndeterminate}
                  metadata={{ dataAutomationId: 'select-all-checkbox-input' }}
                />
              </div>
              {selectAllLabel}
            </Typography>
            {shouldShowDivider && <Divider />}
          </div>
        )}
      <div className="items row">
        {filteredItems.length === 0 && props.noResultsFoundText ? (
          <div className="no-filters-found">
            <Typography variant="body-s" element="p">
              {props.noResultsFoundText}
            </Typography>
          </div>
        ) : (
          filteredItems.map((item: CheckboxListProps['items'][number]) => (
            <div key={item.value} className={classNames(columnCount)}>
              <Typography
                variant="body-s"
                htmlFor={item.value}
                element="label"
                className={classNames('checkbox-label d-flex gap-2 py-3', { 'justify-content-between': isRevertLabel })}
                metadata={{ dataAutomationId: 'checkbox-label' }}
              >
                <div className={classNames('checkbox-wrapper', { 'order-1': isRevertLabel })}>
                  <Checkbox
                    id={item.value}
                    checked={value.includes(item.value)}
                    onChange={getItemChangeHandler(item.value)}
                    metadata={{ dataAutomationId: 'checkbox-input' }}
                  />
                </div>
                {item.label}
              </Typography>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

CheckboxList.displayName = 'CheckboxList';

export default CheckboxList;
