import classNames from 'classnames';
import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Actionable from 'root/widgets/common-components/actionable';
import HUIImage from 'root/widgets/common-components/hui-image';
import Title from 'root/widgets/common-components/title';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './hui-hero-banner.scss';
import { HUIHeroBannerProps } from './types';

const HUIHeroBanner: FC<HUIHeroBannerProps> = (props) => {
  const {
    className,
    metadata,
    heading,
    subheading,
    image: { src, alt },
    callToAction,
    isBackgroundImageVariant = true,
  } = props;

  return (
    <div
      className={classNames('hui-hero-banner-root', className, {
        'background-image-variant': isBackgroundImageVariant,
      })}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      {isBackgroundImageVariant ? (
        <>
          <HUIImage src={src} alt={alt || heading} />
          <div className="hui-hero-banner-overlay">
            <Container>
              <Title headerTag="h1" className="hui-hero-banner-heading">
                {heading}
              </Title>
              <Title headerTag="h4" className="hui-hero-banner-sub-heading">
                {subheading}
              </Title>
              {callToAction && (
                <Actionable
                  className="hui-hero-banner-cta"
                  onClick={callToAction.onClick}
                  href={callToAction.href}
                  variant="light"
                >
                  {callToAction.text}
                </Actionable>
              )}
            </Container>
          </div>
        </>
      ) : (
        <Container className="h-100">
          <Row className="h-100 align-items-center justify-content-between">
            <Col md={8} lg={7}>
              <Title headerTag="h1" className="hui-hero-banner-heading">
                {heading}
              </Title>
              <Title headerTag="h4" className="hui-hero-banner-sub-heading">
                {subheading}
              </Title>
              {callToAction && (
                <Actionable
                  className="hui-hero-banner-cta"
                  onClick={callToAction.onClick}
                  href={callToAction.href}
                  variant="light"
                >
                  {callToAction.text}
                </Actionable>
              )}
            </Col>
            <Col md={4} className="align-self-end">
              <HUIImage src={src} alt={alt || heading} />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

HUIHeroBanner.displayName = 'HUIHeroBanner';

export default HUIHeroBanner;
