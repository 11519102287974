import classNames from 'classnames';
import { FC } from 'react';

import { isMobile } from 'root/widgets/utils/validation';

import './feedback-submitted-view.scss';
import { FeedbackSubmittedViewProps } from './types';
import Actionable from 'root/widgets/common-components/actionable';
import { HUIBottomSheet } from 'root/widgets/common-components/hui-bottom-sheet';
import { HUIModal } from 'root/widgets/common-components/hui-modal';

const FeedbackSubmittedView: FC<FeedbackSubmittedViewProps> = ({
  title,
  description,
  shouldShow = false,
  actions,
}: FeedbackSubmittedViewProps) => {
  const hasSubmitButton = Boolean(actions.submit);

  const renderContent = () => (
    <>
      <i className="pgicon pgicon-cancel view__close" onClick={actions.close.onClick} />
      <div className="view__body">
        <i className="view__checked-icon pgicon pgicon-ok-circled" />
        <div className="view__content">
          <span className="view__content-heading">{title}</span>
          <span className="view__content-description">{description}</span>
          <div className="view__footer">
            {hasSubmitButton && (
              <Actionable className="view__actionable" variant="outline-secondary" onClick={actions.submit?.onClick}>
                {actions.submit?.text}
              </Actionable>
            )}
          </div>
        </div>
      </div>
    </>
  );

  if (!shouldShow) {
    return null;
  }

  if (isMobile()) {
    return (
      <HUIBottomSheet
        onClose={actions.close.onClick}
        shouldShow={shouldShow}
        className={classNames('feedback-submitted-view-root', 'feedback-submitted-view--bottomsheet', {
          'feedback-submitted-view--no-button': !hasSubmitButton,
        })}
      >
        {renderContent()}
      </HUIBottomSheet>
    );
  }

  return (
    <HUIModal
      className={classNames('feedback-submitted-view-root', { 'feedback-submitted-view--no-button': !hasSubmitButton })}
      show={shouldShow}
      onHide={actions.close.onClick}
      centered
    >
      {renderContent()}
    </HUIModal>
  );
};

export default FeedbackSubmittedView;
