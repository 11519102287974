import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState } from 'react';
import { Form, InputGroup, ListGroup } from 'react-bootstrap';

import { RangeSegmentInputProps } from 'root/widgets/common-components/hui-range-input/types';
import { SearchInput } from 'root/widgets/common-components/search-input';

import { isNaN, parseNumber } from 'root/widgets/utils/number';

const displayValue = (value) => (value ? (isNaN(value) ? value : String(parseNumber(value))) : '');
const validateRegex = /^\d+$/;

const RangeSegmentInput: React.FC<RangeSegmentInputProps> = ({
  prefix,
  placeholderText,
  labelText,
  suggestions,
  value,
  hasError,
  onChange,
}) => {
  const [isFocused, setFocused] = useState(false);
  const listRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (evt) => {
    if (listRef?.current && !listRef?.current?.contains(evt.relatedTarget)) {
      setFocused(false);
    }
  };

  const handleTextChange = (text) => {
    if (isEmpty(text) || validateRegex.test(text)) {
      onChange(text);
    }
  };

  const handleSuggestionClick = (item) => {
    onChange(item);
    setFocused(false);
  };

  return (
    <div className={classnames('range-segment-input-root', { focused: isFocused })}>
      <Form.Group>
        {labelText && <Form.Label>{labelText}</Form.Label>}
        <InputGroup className={classnames({ error: hasError })}>
          <SearchInput.Peripheral>{prefix}</SearchInput.Peripheral>
          <SearchInput.Editable
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={displayValue(value)}
            onChange={handleTextChange}
            placeholder={placeholderText}
          />
        </InputGroup>
      </Form.Group>
      <ListGroup ref={listRef} className={classnames({ show: isFocused })}>
        {suggestions?.map((item) => (
          <ListGroup.Item
            key={item}
            action
            className={classnames({ active: item === value })}
            onClick={() => handleSuggestionClick(item)}
          >
            <>
              {!isNaN(item) && prefix} {item}
            </>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

RangeSegmentInput.displayName = 'RangeSegmentInput';

export default RangeSegmentInput;
