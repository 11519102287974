import { useInViewport } from 'ahooks';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { CONTENT_CARD_TYPE } from 'root/widgets/notification-panel/constant';

import './content-card.scss';
import { ContentCardProps } from './types';
import { logCardClick, logCardDismissal, logCardImpressions } from 'root/widgets/utils/braze';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import HUIImage from 'root/widgets/common-components/hui-image';
import Wrapper from 'root/widgets/common-components/wrapper';

const ContentCard: React.FC<ContentCardProps> = ({ brazeSDK, data, rawCardMappings }) => {
  const { id, title, imageUrl, description, linkText, url, isViewed, isDismissed, cardType } = data;

  const [isCardDismissed, setCardDismissed] = useState<boolean>(isDismissed || false);

  const cardRef = useRef(null);

  const [isCardInViewport] = useInViewport(cardRef);

  useEffect(() => {
    if (isCardInViewport) {
      logCardImpressions(brazeSDK, rawCardMappings[id]);
    }
  }, [isCardInViewport]);

  const handleCardClick = () => {
    logCardClick(brazeSDK, rawCardMappings[id]);
  };

  const handleCardDismiss = () => {
    setCardDismissed(true);
    logCardDismissal(brazeSDK, rawCardMappings[id]);
  };

  const renderBannerType = () => (
    <div
      ref={cardRef}
      className={classNames('content-card-root banner', { unviewed: !isViewed }, { dismissed: isCardDismissed })}
    >
      {url && imageUrl && (
        <ActionableLink href={url} onClick={handleCardClick}>
          <HUIImage className="card-image" src={imageUrl} alt="content-card-img" />
        </ActionableLink>
      )}
      <i className="pgicon-cancel" onClick={handleCardDismiss} />
    </div>
  );

  const renderDefault = () => (
    <div
      ref={cardRef}
      className={classNames(
        'content-card-root',
        { unviewed: !isViewed },
        { dismissed: isCardDismissed },
        { captioned: cardType === CONTENT_CARD_TYPE.CAPTIONED_IMAGE },
      )}
    >
      {imageUrl && <HUIImage className="card-image" src={imageUrl} alt="content-card-img" />}
      <Wrapper className="card-info">
        {title && url && (
          <ActionableLink className="card-title" href={url} onClick={handleCardClick}>
            {title}
          </ActionableLink>
        )}

        {description && <p className="card-description">{description}</p>}

        {linkText && url && (
          <ActionableLink className="view-link" href={url} onClick={handleCardClick}>
            {linkText}
          </ActionableLink>
        )}
      </Wrapper>
      <i className="pgicon-cancel" onClick={handleCardDismiss} />
    </div>
  );

  return <>{cardType === CONTENT_CARD_TYPE.BANNER ? renderBannerType() : renderDefault()}</>;
};

ContentCard.displayName = 'ContentCard';

export default ContentCard;
