import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';

import { LineData, LineToStationsType, StationToDetailType } from './types';

/**
 *
 * @param lineAndStationsConfig - Lines and Stations config data from API
 * @returns
 * lineToStations - Map to find stations in specific line, key is line-id
 * stationToDetail - Map to find station detail, key is staion-id
 */
export const createMRTDictionaries = (
  lineAndStationsConfig: Array<LineData>,
): { lineToStations: LineToStationsType; stationToDetail: StationToDetailType } => {
  const lineToStations = {} as LineToStationsType;
  const stationToDetail = {} as StationToDetailType;

  lineAndStationsConfig.forEach((line) => {
    const { id: lineId, stations } = line;

    lineToStations[lineId] = stations.map((station) => {
      if (stationToDetail[station.id]) {
        stationToDetail[station.id].lines.push(lineId);
      } else {
        stationToDetail[station.id] = {
          ...station,
          lines: [lineId],
        };
      }

      return station.id;
    });
  });

  return { lineToStations, stationToDetail };
};

/*
 * normallize upper layer stationId to inner stationId, especially intersection stationId
 * input: ['DT37','TE31', 'EW1', 'EW3']
 * output: ['DT37/TE31', 'CP1/CR5/EW1', 'EW3']
 * normalize method will find stationId from stationToDetailMappingMap, then remove duplicated ids (unique)
 */
export const normalizeStationIds = (ids: Array<string>, stationToDetailMapping: StationToDetailType) => {
  const stationIds = Object.keys(stationToDetailMapping);
  const normalizedIds = ids
    .map((id) => stationIds.find((stationId) => stationId.split('/').includes(id)))
    .filter((id) => id !== undefined) as Array<string>;
  return uniq(normalizedIds);
};

/**
 * before we apply mrt-search selection, we need explode station ids to fit API interface
 * the output will be use in route query like MRT_STATIONS[]=DT37&MRT_STATIONS[]=TE31&...
 * input: ['DT37/TE31', 'CP1/CR5/EW1']
 * output: ['DT37','TE31', 'CP1', 'CR5', 'EW1']
 * @param ids station ids of selected staions
 * @returns
 */
export const explodeStaionIds = (ids: Array<string>) => ids.flatMap((id) => id.split('/'));

export const initSelectedLines = (lineAndStationsConfig: Array<LineData>): LineToStationsType => {
  const initLines = {} as LineToStationsType;

  lineAndStationsConfig.forEach((line) => {
    initLines[line.id] = [];
  });

  return initLines;
};

export const addStationsToLines = (
  selectedLines: Record<string, Array<string>>,
  stationIds: Array<string>,
  stationToDetailMapping: StationToDetailType,
) => {
  const newSelectedLines = cloneDeep(selectedLines);

  stationIds.forEach((stationsId) => {
    const { lines } = stationToDetailMapping[stationsId];
    lines.forEach((lineId) => {
      newSelectedLines[lineId].push(stationsId);
    });
  });
  Object.keys(newSelectedLines).forEach((lineId) => {
    newSelectedLines[lineId] = uniq(newSelectedLines[lineId]);
  });

  return newSelectedLines;
};
