import classnames from 'classnames';
import React from 'react';
import { Popover } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';

import Actionable from 'root/widgets/common-components/actionable';
import { ActionableProps } from 'root/widgets/common-components/types';

export { default as ListingInfo } from './listing-info';

export const getOnboardingPopover =
  (title, description, buttonText, buttonIconClass, buttonProps?: ActionableProps): React.FC<OverlayInjectedProps> =>
  // eslint-disable-next-line react/function-component-definition
  (props: OverlayInjectedProps) => (
    <Popover {...props} className="onboarding-popover-root">
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body>
        <div>{description}</div>
        <Actionable {...buttonProps} className={classnames('btn-notify btn-outline-secondary', buttonProps?.className)}>
          <i className={classnames('pgicon', buttonIconClass)} />
          {buttonText}
        </Actionable>
      </Popover.Body>
    </Popover>
  );
