/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';

import { SaveStatus } from 'root/widgets/drilldown-menu/constants';
import { DrilldownMenuOptionProps } from 'root/widgets/drilldown-menu/types';

const MenuOption = (props: DrilldownMenuOptionProps) => {
  const { componentId, className, label, status, hasChildOptions, onSelect } = props;

  return (
    <div
      id={componentId}
      className={classNames(
        'drill-down-menu--item',
        className,
        {
          'drill-down-menu--item-selected': status === SaveStatus.SUCCESS,
        },
        {
          'drill-down-menu--item-focus': status === SaveStatus.IN_PROGRESS,
        },
      )}
      onClick={onSelect}
    >
      <div className="drill-down-menu--item-content">
        <span className="drill-down-menu--item-title">{label}</span>
        <span className="drill-down-menu--item-status">
          {status === SaveStatus.IN_PROGRESS && (
            <i className="drill-down-menu--item-loader pgicon-loading animate-spin spinner" />
          )}
          {hasChildOptions && <i className="drill-down-menu--item-status pgicon pgicon-arrow-right" />}
          <i
            className={classNames(
              'drill-down-menu--item-status-ok',
              'pgicon pgicon-ok',
              { 'drill-down-menu--item-status-ok--hover': !hasChildOptions && status !== SaveStatus.IN_PROGRESS },
              { 'drill-down-menu--item-status-ok--visible': !hasChildOptions && status === SaveStatus.SUCCESS },
            )}
          />
        </span>
      </div>
    </div>
  );
};

export default MenuOption;
