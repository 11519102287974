import { Button } from 'react-bootstrap';
import { HIVE_STATIC_ICON_PATH_V3 } from 'root/widgets/constants';

import { MapZoomButtonsProps } from 'root/widgets/mrt-search-modal/types';
import { SvgIcon } from 'root/widgets/svg-icon';

const MapZoomButtons = ({ onZoomIn, onZoomOut }: MapZoomButtonsProps) => (
  <div className="map-zoom-buttons">
    <Button variant="light icon-square" onClick={onZoomIn}>
      <SvgIcon src={`${HIVE_STATIC_ICON_PATH_V3}/plus_large.svg`} height={24} width={24} color="#0D1011" />
    </Button>
    <Button variant="light icon-square" onClick={onZoomOut}>
      <SvgIcon src={`${HIVE_STATIC_ICON_PATH_V3}/minus_large.svg`} height={24} width={24} color="#0D1011" />
    </Button>
  </div>
);

MapZoomButtons.displayName = 'MapZoomButtons';

export default MapZoomButtons;
