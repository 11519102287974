import React from 'react';
import { Offcanvas } from 'react-bootstrap';

import { HUIBottomSheetBodyProps } from 'root/widgets/common-components/hui-bottom-sheet/types';

const Body: React.FC<HUIBottomSheetBodyProps> = ({ children, ...props }) => (
  <Offcanvas.Body {...props}>{children}</Offcanvas.Body>
);

export default Body;
