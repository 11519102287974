import { MetaPixelTrackingType } from 'root/global-wrappers/types/analytics';

declare const window: any;

const trackPageView = () => {
  if (!window.fbq) {
    return;
  }

  window.fbq('track', 'PageView');
};

const trackEvent = (data: MetaPixelTrackingType) => {
  if (!window.fbq) {
    return;
  }

  window.fbq('track', data.eventName, data.options);
};

export default { trackPageView, trackEvent };
