/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext } from 'react';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { OTPVerificationView } from 'root/widgets/otp-verification-view';
import { PGLoginCustomHeaderValues, SERVICE } from 'root/widgets/pg-login/constants';
import PGLoginContext from 'root/widgets/pg-login/context';
import { getService } from 'root/widgets/pg-login/services';
import { Footer } from 'root/widgets/pg-login/sub-components';

import { OTPLoginViewProps } from './types';

const OTPLoginView: React.FC<OTPLoginViewProps> = ({
  masthead,
  checkSpamPrompt,
  resendActionText,
  errorTexts,
  footer,
  isAutomationUser,
}) => {
  const bffService = getService(SERVICE.BFF);
  const {
    otpRecipientId,
    email,
    otpId: initialOtpId,
    consentChecks,
    shouldShowLoginConsent,
    context,
    sentry,
    setOtpId,
    setUserInfo,
    onSuccess,
  } = useContext(PGLoginContext);

  const handleGenerateOTP = () => {
    emit(EVENT_NAMES.PG_LOGIN.LOGIN_RESEND_OTP, context);
    return bffService.generateOTP(otpRecipientId, email);
  };

  const handleSubmitOTP = async (otpId, otpToken) => {
    emit(EVENT_NAMES.PG_LOGIN.LOGIN_SUBMIT_ATTEMPT, { ...context, OTP: true });
    const res = await bffService.loginWithOTP(email, otpId, otpToken, shouldShowLoginConsent ? consentChecks : {});
    const token = { accessToken: res.accessToken, refreshToken: res.refreshToken };
    setUserInfo(res);
    await onSuccess(token, { ...res, email }, PGLoginCustomHeaderValues.OTP_LOGIN, { ...context, OTP: true });
    emit(EVENT_NAMES.PG_LOGIN.LOGIN_SUCCESS, { ...context, OTP: true });
  };

  return (
    <div className="otp-login-view-root">
      <OTPVerificationView
        className="pglogin-slide"
        masthead={masthead}
        errorTexts={errorTexts}
        isAutomationUser={isAutomationUser}
        checkSpamPrompt={checkSpamPrompt}
        resendActionText={resendActionText}
        sentry={sentry}
        otpId={initialOtpId}
        onOtpIdUpdate={setOtpId}
        sideEffects={{
          generateOTP: handleGenerateOTP,
        }}
        onSubmit={handleSubmitOTP}
      />
      <Footer>{footer}</Footer>
    </div>
  );
};

OTPLoginView.displayName = 'OTPLoginView';

export default OTPLoginView;
