/* eslint-disable @typescript-eslint/no-misused-promises */
import { StatusCodes } from 'http-status-codes';
import React, { useContext, useState } from 'react';

import { INPUT_TYPES } from 'root/widgets/common-components/generic-input-group/constants';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { PGLoginCustomHeaderValues, SERVICE, VIEW, WIDGET_NAME } from 'root/widgets/pg-login/constants';
import PGLoginContext from 'root/widgets/pg-login/context';
import { getService } from 'root/widgets/pg-login/services';
import { Footer } from 'root/widgets/pg-login/sub-components';
import type { UserInfoLoggedInData } from 'root/widgets/pg-login/types';

import './password-login-view.scss';
import { PasswordLoginViewProps } from './types';
import { captureExceptionSentry } from 'root/widgets/utils/logger';
import { Masthead } from 'root/widgets/common-components/masthead';
import { GenericInputGroup } from 'root/widgets/common-components/generic-input-group';
import Actionable from 'root/widgets/common-components/actionable';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';
import { Divider } from 'root/widgets/common-components/divider';
import type { ActionableProps } from 'root/widgets/common-components/types';

const PasswordLoginView: React.FC<PasswordLoginViewProps> = ({
  masthead,
  dividerTitle,
  otpLoginBtnText,
  invalidPassword,
  passwordInputPlaceholder,
  loginBtnText,
  forgotPasswordActionText,
  forgotPasswordUrl,
  errorTexts,
  footer,
}) => {
  const bffService = getService(SERVICE.BFF);
  const {
    email,
    otpRecipientId,
    context,
    consentChecks,
    shouldShowLoginConsent,
    sentry,
    changeView,
    setOtpId,
    setUserInfo,
    onSuccess,
  } = useContext(PGLoginContext);

  const [inputError, setInputError] = useState('');
  const [serverError, setServerError] = useState('');

  const captureException = (functionName: string, error) => {
    captureExceptionSentry(sentry, `${WIDGET_NAME}.${PasswordLoginView.name}`, functionName, error);
  };

  const generateOTP = async (emailAddress: string) => {
    const otpResponse = await bffService.generateOTP(otpRecipientId, emailAddress);
    setOtpId(otpResponse['otp_id']);
  };

  const inputGroup = {
    input: {
      placeholder: passwordInputPlaceholder,
      type: INPUT_TYPES.PASSWORD,
      checkValidity: () => ({
        isInvalid: false,
        error: '',
      }),
      metadata: {
        'data-automation-id': 'password-fld',
      },
    },
    button: {
      text: loginBtnText,
      metadata: {
        'data-automation-id': 'login-btn',
      },
    },
    inputError,
    onSubmit: async (password) => {
      emit(EVENT_NAMES.PG_LOGIN.LOGIN_SUBMIT_ATTEMPT, { ...context, password: true });
      try {
        setInputError('');
        setServerError('');
        const res: UserInfoLoggedInData = await bffService.loginWithPassword(
          email,
          password as string,
          shouldShowLoginConsent ? consentChecks : {},
        );
        const token = { accessToken: res.accessToken, refreshToken: res.refreshToken };
        setUserInfo(res);
        await onSuccess(token, { ...res, email }, PGLoginCustomHeaderValues.PASSWORD_LOGIN, {
          ...context,
          password: true,
        });
        emit(EVENT_NAMES.PG_LOGIN.LOGIN_SUCCESS, { ...context, password: true });
      } catch (error) {
        captureException(inputGroup.onSubmit.name, error);
        if ((error as { statusCode?: StatusCodes }).statusCode === StatusCodes.INTERNAL_SERVER_ERROR) {
          setServerError(errorTexts.server);
          return;
        }

        setInputError(invalidPassword);
      }
    },
  };

  const handleForgotPassword = () => {
    changeView(VIEW.FORGOT_PASSWORD);
  };

  const handleLoginWithOTP = async () => {
    emit(EVENT_NAMES.PG_LOGIN.LOGIN_WITH_OTP, context);
    try {
      await generateOTP(email);
      changeView(VIEW.OTP_LOGIN);
    } catch (error) {
      const errorMessage =
        (error as { statusCode?: StatusCodes }).statusCode === StatusCodes.TOO_MANY_REQUESTS
          ? errorTexts.otpGenerationLimit
          : errorTexts.server;
      setServerError(errorMessage);
    }
  };

  const forgotPasswordButtonProps: ActionableProps = {
    className: 'forgot-password-btn',
    variant: 'link primary',
    metadata: { 'data-automation-id': 'forgot-password-lnk' },
    children: forgotPasswordActionText,
  };

  if (forgotPasswordUrl) {
    forgotPasswordButtonProps.href = forgotPasswordUrl;
    forgotPasswordButtonProps.target = '_blank';
  } else {
    forgotPasswordButtonProps.onClick = handleForgotPassword;
  }

  return (
    <div className="password-login-view-root">
      <div className="pglogin-slide">
        <Masthead {...masthead} />
        <div className="pglogin-viewport">
          <GenericInputGroup {...inputGroup} />
          <Actionable {...forgotPasswordButtonProps} />
          <Divider title={dividerTitle} />
          <Actionable
            className="login-with-otp-btn"
            variant="secondary"
            onClick={handleLoginWithOTP}
            metadata={{ 'data-automation-id': 'login-with-otp-btn' }}
          >
            {otpLoginBtnText}
          </Actionable>
          {serverError && <HUIAlert variant="danger">{serverError}</HUIAlert>}
        </div>
      </div>
      <Footer>{footer}</Footer>
    </div>
  );
};

PasswordLoginView.displayName = 'PasswordLoginView';

export default PasswordLoginView;
