import omit from 'lodash/omit';

import { COMMON_TRACKING, ProductSource } from 'root/global-wrappers/config';
import { getTrackingConfig, trackEvent } from 'root/global-wrappers/services/analytics';
import { GATrackingType } from 'root/global-wrappers/types/analytics';

export const handleGlobalNavLinkClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleGlobalNavLinkClick) {
    return;
  }

  const { data, gaCustomDimensions } = context.detail;

  const { label, dimensions } = ga.handleGlobalNavLinkClick as GATrackingType;
  const { dimension20 } = dimensions as Record<string, Record<string, string>>;

  const gaDimension20 = {
    [dimension20.mainNav]: document.title,
    [dimension20.loc]: data,
    ProductSource: data === 'Sell' ? ProductSource.SELLER_LEADS : undefined,
  };

  trackEvent({
    ga: {
      ...(ga.handleGlobalNavLinkClick as GATrackingType),
      label: `${label}=${JSON.stringify(data) ?? ''}`,
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify(gaDimension20) },
    },
  });
};

export const handleGlobalNavMoreClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleGlobalNavMoreClick) {
    return;
  }

  const { gaCustomDimensions, data } = context.detail;

  const { dimension20 } = (ga.handleGlobalNavMoreClick as GATrackingType).dimensions as Record<
    string,
    Record<string, string>
  >;

  trackEvent({
    ga: {
      ...(ga.handleGlobalNavMoreClick as GATrackingType),
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify({ ...dimension20, Dropdown: data ?? '' }) },
    },
  });
};

export const handleGlobalNavNotificationClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleGlobalNavNotificationClick) {
    return;
  }

  const { gaCustomDimensions } = context.detail;

  const { label } = ga.handleGlobalNavNotificationClick as GATrackingType;

  trackEvent({
    ga: {
      ...(ga.handleGlobalNavNotificationClick as GATrackingType),
      label: `${label}=${window.location.pathname}`,
      dimensions: gaCustomDimensions,
    },
  });
};

export const handleGlobalNavToggleClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleGlobalNavToggleClick) {
    return;
  }

  const { gaCustomDimensions, data } = context.detail;

  const { dimension20 } = (ga.handleGlobalNavToggleClick as GATrackingType).dimensions as Record<
    string,
    Record<string, string>
  >;

  trackEvent({
    ga: {
      ...(ga.handleGlobalNavToggleClick as GATrackingType),
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify({ ...dimension20, Dropdown: data ?? '' }) },
    },
  });
};

export const handleGlobalNavMyActivityClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleGlobalNavMyActivityClick) {
    return;
  }

  const { gaCustomDimensions } = context.detail;

  const { dimension20 } = (ga.handleGlobalNavMyActivityClick as GATrackingType).dimensions as Record<
    string,
    Record<string, string>
  >;

  const gaDimension20 = {
    ...omit(dimension20, 'mainNav'),
    [dimension20.mainNav]: document.title,
  };

  trackEvent({
    ga: {
      ...(ga.handleGlobalNavMyActivityClick as GATrackingType),
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify(gaDimension20) },
    },
  });
};

export const handleMyAccountClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleMyAccountClick) {
    return;
  }

  const { gaCustomDimensions } = context.detail;

  const { dimension20 } = (ga.handleMyAccountClick as GATrackingType).dimensions as Record<
    string,
    Record<string, string>
  >;

  trackEvent({
    ga: {
      ...(ga.handleMyAccountClick as GATrackingType),
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify(dimension20) },
    },
  });
};

export const handleMyAccountItemClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleMyAccountItemClick) {
    return;
  }

  const { gaCustomDimensions } = context.detail;

  const { dimension20 } = (ga.handleMyAccountItemClick as GATrackingType).dimensions as Record<
    string,
    Record<string, string>
  >;

  const gaDimension20 = {
    ...omit(dimension20, 'mainNav'),
    [dimension20.mainNav]: document.title,
  };

  trackEvent({
    ga: {
      ...(ga.handleMyAccountItemClick as GATrackingType),
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify(gaDimension20) },
    },
  });
};

export const handleGlobalLoginClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleGlobalLoginClick) {
    return;
  }

  const { gaCustomDimensions } = context.detail;

  const { dimension20 } = (ga.handleGlobalLoginClick as GATrackingType).dimensions as Record<
    string,
    Record<string, string>
  >;

  const gaDimension20 = {
    ...omit(dimension20, 'mainNav'),
    [dimension20.mainNav]: document.title,
  };

  trackEvent({
    ga: {
      ...(ga.handleGlobalLoginClick as GATrackingType),
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify(gaDimension20) },
    },
  });
};

export const handleLocaleChange = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleLocaleChange) {
    return;
  }

  const { gaCustomDimensions } = context.detail;

  const { dimension20 } = (ga.handleLocaleChange as GATrackingType).dimensions as Record<
    string,
    Record<string, string>
  >;

  const gaDimension20 = {
    ...omit(dimension20, 'mainNav'),
    [dimension20.mainNav]: document.title,
  };

  trackEvent({
    ga: {
      ...(ga.handleLocaleChange as GATrackingType),
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify(gaDimension20) },
    },
  });
};
