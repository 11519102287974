import { PropertyTypeMarket } from 'root/widgets/constants';

import { categoryFilters } from './constants';

export const initFilters = (selectedFilters, data) => {
  const filterKeys = Object.keys(data.filters);
  const initialFilters = {};
  filterKeys.forEach((filterKey) => {
    initialFilters[filterKey] = {};
  });
  initialFilters['query'] = '';

  return { ...initialFilters, ...selectedFilters };
};

export const processFilterStates = (filterStates, setFilters) => {
  const updatedStates = { ...filterStates };
  const { propertyType } = filterStates;
  if (
    !updatedStates.bedroom ||
    (propertyType.market !== PropertyTypeMarket.RESIDENTIAL && updatedStates.bedroom.selected)
  ) {
    updatedStates.bedroom = {};
    setFilters(updatedStates);
  }

  return updatedStates;
};

export const getCategoryFilters = (filters) => {
  const result = {};

  Object.keys(filters).forEach((key) => {
    if (categoryFilters.includes(key)) {
      result[key] = filters[key];
    }
  });

  return result;
};

export const getPaddingStatus = (
  foundLocationsLength: number,
  searchOptionsLength: number,
  recentSearchesLength: number,
  searchKeywordLength: number,
) => {
  const hasNoItem = foundLocationsLength === 0 && searchOptionsLength === 0 && recentSearchesLength === 0;
  const hasSearchKeyword = searchKeywordLength > 0 && foundLocationsLength === 0 && searchOptionsLength === 0;
  return hasNoItem || hasSearchKeyword;
};
