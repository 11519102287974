import { MutableRefObject, useEffect } from 'react';

/**
 * Hook to auto-scroll the container to active element based on the index
 *
 * @param activeIndex - the index of the active element
 * @param containerRef - the container ref object which wraps the children
 * @param gapClassName - the clasname of the gap for padding on the left and right container
 */
export const useAutoScrollToElement = (
  activeIndex: number | null,
  containerRef: MutableRefObject<HTMLElement | null>,
  gapClassName?: string,
) => {
  useEffect(() => {
    if (activeIndex !== null) {
      const container = containerRef.current;
      const gap = container?.querySelector(`.${gapClassName}`) as HTMLElement;
      const element = container?.querySelectorAll('.nav-list-item')?.[activeIndex] as HTMLElement;
      if (container && element) {
        const gapWidth = gap?.offsetWidth || 0;
        // DON'T USE element.scrollIntoView({ behavior: 'smooth' }), it doesn't work with Chrome
        // Use container.scrollTo()
        container?.scrollTo?.({
          left: element.offsetLeft - gapWidth,
          behavior: 'smooth',
        });
      }
    }
  }, [activeIndex]);
};
