import classnames from 'classnames';
import React from 'react';

import './checklist.scss';
import { ChecklistProps } from './types';

const Checklist: React.FC<ChecklistProps> = ({ items, metadata, className }) => (
  <div className={classnames('checklist-root', className)} {...metadata}>
    {items.map((item) => (
      <span key={item} className="item">
        <i className="pgicon pgicon-ok" />
        {item}
      </span>
    ))}
  </div>
);

export default Checklist;
