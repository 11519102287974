import classNames from 'classnames';
import parse from 'html-react-parser';
import { FC } from 'react';

import { AgentBadge } from 'root/widgets/agent-badge';
import { CERTIFIED_AGENT_ICON as certifiedIcon, HIVE_UI_CORE_URL } from 'root/widgets/constants';

import './agent-info.scss';
import { AgentInfoProps } from './types';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { Badge } from 'root/widgets/common-components/badge';
import { SvgIcon } from 'root/widgets/svg-icon';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import HUIImage from 'root/widgets/common-components/hui-image';

const AgentInfo: FC<AgentInfoProps> = ({ agent, verification, rating, certificate, metadata }) => {
  const shouldTruncateName = agent.description || rating || certificate?.isCertified;
  const profileLinkObject = agent.shouldShowProfile ? { href: agent.profileUrl, onClick: agent.onClick } : {};

  return (
    <div
      className={classNames('agent-info-root', { corporate: agent.isCorporate })}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <div className="avatar-wrapper">
        <ActionableLink
          {...profileLinkObject}
          className={classNames(
            'avatar-link',
            { 'no-link': !agent.shouldShowProfile },
            { 'featured-agent': agent.isFeaturedAgent },
          )}
        >
          {agent.avatar ? (
            <HUIImage className="avatar" src={agent.avatar} alt={agent.name} />
          ) : (
            <SvgIcon
              src={`${HIVE_UI_CORE_URL}icons/svgs/images-1-o.svg`}
              height={16}
              width={16}
              color="var(--icon-inactive-primary)"
              shouldUseImage
            />
          )}
        </ActionableLink>
        {agent.isFeaturedAgent && (
          <div className="crown-icon">
            <SvgIcon
              src={`${HIVE_UI_CORE_URL}icons/svgs/crown-f.svg`}
              height={16}
              width={16}
              color="var(--icon-active-tertiary)"
              shouldUseImage
            />
          </div>
        )}
      </div>
      <div className="details-wrapper">
        <div className="agent-name-wrapper">
          {verification?.isVerified && (
            <Badge type="small" bg="light-green" className="verification">
              {verification.text}
            </Badge>
          )}
          <ActionableLink
            {...profileLinkObject}
            className={classNames('agent-name', {
              'truncate-line': shouldTruncateName,
              'no-link': !agent.shouldShowProfile,
            })}
          >
            {agent.name}
          </ActionableLink>
        </div>
        {agent.description && (
          <div className="agent-description">
            {typeof agent.description === 'string' ? parse(agent.description) : agent.description}
          </div>
        )}
        {rating && (
          <div className={classNames('agent-rating', { 'has-review': rating.hasReview })}>
            <i className="pgicon-star" />
            {rating.score}
            {rating.text && <span className="rating-text">{rating.text}</span>}
          </div>
        )}
        {certificate?.isCertified && (
          <AgentBadge
            className="agent-certificate"
            icon={certifiedIcon}
            text={certificate.text}
            explanation={certificate.explanation}
          />
        )}
      </div>
    </div>
  );
};

AgentInfo.displayName = 'AgentInfo';

export default AgentInfo;
