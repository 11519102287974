import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import './search-with-filter-section.scss';

import { PropertyTypeMarket, SearchCategory, LOCALE, REGION } from 'root/widgets/constants';

import {
  FoundLocation,
  FoundLocationData,
  TypeaheadOptionType,
} from 'root/widgets/common-components/found-locations-view';

import { SearchSegment } from 'root/widgets/search-segment';

import {
  SearchWithFilter,
  FloorAreaRangeFilter,
  BedroomFilter,
  FilterPanel,
  PriceRangeFilter,
  PropertyTypeFilter,
  SearchPanel,
  AIFilter,
  RoomTypeFilter,
} from 'root/widgets/search-with-filter';

import {
  RecentSearchProps,
  RecentSearchValueProps,
} from 'root/widgets/search-with-filter/sub-components/recent-search/types';
import { validateDependencies } from 'root/widgets/utils/validation';

import { Listing, RECENT_SEARCHES_STORAGE_KEY, MAX_RECENT_SEARCH_COUNT } from 'root/symbiosis-widgets/constants';
import {
  addToRecentSearches,
  convertQueryObjectToString,
  encodeSearch,
  formatQuery,
  generateRecentSearchTitles,
  generateSearchKey,
  getRecentSearches,
} from 'root/symbiosis-widgets/utils';

import { QueryOptionsType, SearchWithFilterSectionProps, SearchWithFilterStatesType } from './types';
import { RecentSearchType } from 'root/widgets/search-with-filter/sub-components/recent-search/constants';
import Actionable from 'root/widgets/common-components/actionable';
import omitBy from 'lodash/omitBy';
import { prepareRecentSearchTitles } from 'root/widgets/utils/recent-search';

const searchWithFilterSectionDependencies = ['projectService', 'logError'];

export const SearchWithFilterSection: React.FC<SearchWithFilterSectionProps> = (props) => {
  const {
    data,
    region,
    locale,
    shouldHideFilterPanel,
    shouldIncludeAISearchFilter,
    selectedFilters,
    searchCategory,
    dataAutomationId,
    recentSearchesStorageKey = RECENT_SEARCHES_STORAGE_KEY,
    onSearch,
    onLocationSelect,
    onApplyLocationAccessory,
    learnMore,
    dependencies,
    context,
    aiSearch,
  } = props;

  validateDependencies(dependencies, searchWithFilterSectionDependencies);

  const {
    searchPanel: { searchDropdown },
    recentSearchFiltersConfig,
  } = cloneDeep(data);
  const [foundLocations, setFoundLocations] = useState<Array<FoundLocation>>([]);
  const [recentSearchesItems, setRecentSearchItems] = useState([]);
  const [shouldShowBedroomFilter, setShowBedroomFilter] = useState(!isEmpty(data.filters.bedroom));
  const [shouldShowFloorAreaRangeFilter, setShowFloorAreaRangeFilter] = useState(!isEmpty(data.filters.floorAreaRange));
  const { selectedSearchCategory: selectedCategory, setSelectedSearchCategory: setSelectedCategory } = searchCategory;
  const { projectService, propertyTransactionService, logError } = dependencies;

  const { isAISearchEnabled = false, setAISearchEnabled = () => {} } = aiSearch ?? {};

  const filterPanelRef = useRef<HTMLDivElement>(null);

  const updateRecentSearchItemsState = useCallback(() => {
    setRecentSearchItems(
      getRecentSearches(recentSearchesStorageKey)
        ?.filter((search) => {
          const currentListingType = selectedCategory === SearchCategory.BUY ? Listing.Type.Sale : selectedCategory;
          const listingType =
            search.type === RecentSearchType.V2 ? search.values.listingType : search.values.listing_type;

          return listingType === currentListingType;
        })
        .slice(0, MAX_RECENT_SEARCH_COUNT),
    );
  }, [recentSearchesStorageKey, selectedCategory]);

  useEffect(() => {
    updateRecentSearchItemsState();
  }, [updateRecentSearchItemsState]);

  const handleTabClick = (activeTab: SearchCategory) => {
    setSelectedCategory(activeTab);
  };

  const getSegmentPath = (listingType: string = selectedCategory) =>
    data.segmentTypes
      ? data.segmentTypes.find(
          (tab) =>
            tab.tabKey === listingType?.toLowerCase() ||
            (listingType?.toLowerCase() === Listing.Type.Sale && SearchCategory.BUY === tab.tabKey),
        )?.path
      : '';

  const getSearchPathname = (listingType?: string) => {
    const currentPathname = window?.location?.pathname ?? '';
    return `${currentPathname === '/' ? '' : currentPathname}${getSegmentPath(listingType)}`;
  };

  const handleFilterChange = (filterStates: SearchWithFilterStatesType) => {
    const { propertyType } = filterStates;
    setShowBedroomFilter(propertyType.market === PropertyTypeMarket.RESIDENTIAL);

    if (filterStates?.isAISearchEnabled !== isAISearchEnabled) {
      setAISearchEnabled(filterStates.isAISearchEnabled ?? false);
    }
  };

  const formatLocationData = (location: FoundLocationData) => ({
    id: location.objectId,
    ...location,
  });

  const findLocations = async (queryOptions: QueryOptionsType): Promise<Array<FoundLocation>> => {
    const { query } = queryOptions;
    let locations = [];

    if (query.length > 0) {
      try {
        const options = {
          data: {
            params: queryOptions,
          },
        };
        const resp = await projectService.search(options);

        locations = resp?.data.map((location) => formatLocationData(location)) ?? [];
      } catch (error) {
        logError(SearchWithFilterSection.name, findLocations.name, error);
      }
    }

    return locations;
  };

  const getHomeSellersProperties = async (query: string): Promise<Array<FoundLocation>> => {
    try {
      const properties = await propertyTransactionService.searchLocations(query);

      return properties
        .filter((property) => Boolean(property.id) && Boolean(property.tag) && Boolean(property.postcode))
        .map((property) => propertyTransactionService.getLocationObject(property));
    } catch (error) {
      logError(SearchWithFilterSection.name, getHomeSellersProperties.name, error);

      return [];
    }
  };

  const handleQueryChange = async (query: string, propertyMarket = PropertyTypeMarket.RESIDENTIAL) => {
    const { propertyTypeGroupExclude, objectType } = data?.searchPanel?.searchInput || {};

    if (query.length === 0) {
      setFoundLocations((prevState) => (prevState.length > 0 ? [] : prevState));
      return;
    }

    if (query.length > 0) {
      try {
        const queryOptions = {
          region,
          locale,
          limit: 20,
          propertyTypeGroupExclude: propertyTypeGroupExclude[propertyMarket] || [],
          objectType,
          query,
        } as QueryOptionsType;

        const locations =
          selectedCategory === SearchCategory.SELL
            ? await getHomeSellersProperties(query)
            : await findLocations(queryOptions);
        setFoundLocations(locations);
      } catch (error) {
        logError(SearchWithFilterSection.name, findLocations.name, error);
        setFoundLocations([]);
      }
    }
  };

  const handleSearch = (value: Record<string, any>) => {
    let query = '';

    const { filters, segmentTypes } = data;

    if (shouldHideFilterPanel) {
      if (value.propertyLocation) {
        const propertyLocation: Record<string, string> = {
          ...value.propertyLocation,
          properties: value.propertyLocation.properties ? JSON.stringify(value.propertyLocation.properties) : '',
        };

        query = `?${new URLSearchParams(propertyLocation).toString()}`;
      }
    } else {
      query = formatQuery(value, selectedCategory, shouldIncludeAISearchFilter);
    }

    const searchSegment = segmentTypes?.find((segment) => segment.tabKey === selectedCategory);

    if (!isAISearchEnabled) {
      const encodedSearch = encodeSearch({
        ...value,
        selectedCategory,
        query,
        isFilterPanelHidden: shouldHideFilterPanel,
        findAgentIntent: value.selectedSearchDropdown,
      }) as RecentSearchValueProps;

      const preparedTitles = prepareRecentSearchTitles(cloneDeep(encodedSearch), recentSearchFiltersConfig);
      const currentSearchTitles =
        preparedTitles ||
        generateRecentSearchTitles({
          ...value,
          selectedCategory: searchSegment?.title ?? selectedCategory,
          currency: filters.priceRange[selectedCategory]?.maxprice?.unit || '',
          filterData: filters,
          isFilterPanelHidden: shouldHideFilterPanel,
          categoryText: searchSegment?.recentSearchCategoryText,
        });

      const currentSearchState: RecentSearchProps = {
        time: Date.now(),
        key: generateSearchKey(cloneDeep(encodedSearch), Boolean(preparedTitles)),
        titles: currentSearchTitles,
        values: omitBy(encodedSearch, (fitler) => fitler === (undefined || '' || '0')),
        optionType: TypeaheadOptionType.RecentSearch,
        type: preparedTitles ? RecentSearchType.V1_5 : RecentSearchType.V1,
        ...value,
      };

      addToRecentSearches(currentSearchState, recentSearchesStorageKey, {
        listingType: encodedSearch.listing_type,
      });
      updateRecentSearchItemsState();
    }

    onSearch({
      pathname: getSearchPathname(),
      search: query,
      ...value,
    });
  };

  const handleRecentSearchSelect = (search: RecentSearchProps) => {
    if (search.type === RecentSearchType.V2) {
      onSearch({
        pathname: getSearchPathname(search.values.listingType),
        search: convertQueryObjectToString(search.values),
        ...search,
      });
    } else {
      onSearch({
        pathname: getSearchPathname(search.values.listing_type),
        search: search.values?.query || convertQueryObjectToString(search.values),
        ...search,
      });
    }
  };

  const handleFetchLocationItems = async (inputRegion: REGION, inputLocale: LOCALE, type: string) => {
    const options = {
      params: {
        region: inputRegion,
        locale: inputLocale,
        type,
      },
    };

    try {
      const resp = await projectService.getLocationItems(options);

      return resp?.data || [];
    } catch (error) {
      logError(SearchWithFilterSection.name, handleFetchLocationItems.name, error);
      return [];
    }
  };

  const handleFetchMRTItems = async (inputRegion: REGION, inputLocale: LOCALE) => {
    const options = {
      params: {
        region: inputRegion,
        locale: inputLocale,
      },
    };

    try {
      const resp = await projectService.getMrtItems(options);

      return resp?.data || [];
    } catch (error) {
      logError(SearchWithFilterSection.name, handleFetchMRTItems.name, error);
      return [];
    }
  };

  const handleSearchDropdownSelect = (selectedItem: string) => {
    setFoundLocations([]);

    searchDropdown?.onSelect?.(selectedItem);
  };

  if (data.searchPanel?.searchDropdown) {
    data.searchPanel.searchDropdown.onSelect = handleSearchDropdownSelect;
  }

  return (
    <div className="search-with-filter-section-root">
      <SearchSegment
        tabs={data.segmentTypes ?? []}
        activeTab={selectedCategory}
        handleTabClick={handleTabClick}
        metadata={data.metadata}
        context={context}
      >
        <SearchWithFilter
          stack="vertical"
          region={region}
          locale={locale}
          data={data}
          dataAutomationId={dataAutomationId}
          onFilterChange={handleFilterChange}
          onQueryChange={handleQueryChange}
          onSubmit={handleSearch}
          selectedFilters={selectedFilters}
          isFilterPanelHidden={shouldHideFilterPanel}
          foundLocations={foundLocations}
          onLocationSelect={onLocationSelect}
          recentSearchesItems={recentSearchesItems}
          onRecentSearchSelect={handleRecentSearchSelect}
          onApplyLocationAccessory={onApplyLocationAccessory}
          dataResolver={{ fetchLocationItems: handleFetchLocationItems, fetchMRTItems: handleFetchMRTItems }}
          category={selectedCategory}
          logError={logError}
          context={context}
        >
          <SearchPanel shouldIncludeAISearchFilter={shouldIncludeAISearchFilter} />
          {!shouldHideFilterPanel && (
            <FilterPanel
              containerRef={filterPanelRef}
              className={shouldIncludeAISearchFilter ? 'ai-filter-included' : ''}
            >
              {shouldIncludeAISearchFilter && <AIFilter filterPanelRef={filterPanelRef} />}
              {!isAISearchEnabled && (
                <div
                  className={
                    shouldIncludeAISearchFilter ? 'scrollable-filters ai-filter-included' : 'scrollable-filters'
                  }
                >
                  <PropertyTypeFilter />
                  <PriceRangeFilter />
                  {shouldShowBedroomFilter && selectedCategory !== SearchCategory.ROOM && <BedroomFilter />}
                  {shouldShowFloorAreaRangeFilter && <FloorAreaRangeFilter rangeKey="floorAreaRange" />}
                  {selectedCategory === SearchCategory.ROOM && <RoomTypeFilter />}
                </div>
              )}
            </FilterPanel>
          )}
          {learnMore && (
            <div className="search-with-filter-section-learn-more-link-container">
              <Actionable variant="link" href={learnMore.url} onClick={learnMore.onClick}>
                <span>{learnMore.text}</span>
                <i className="pgicon-arrow-right" />
              </Actionable>
            </div>
          )}
        </SearchWithFilter>
      </SearchSegment>
    </div>
  );
};

SearchWithFilterSection.displayName = 'SearchWithFilterSection';

export default SearchWithFilterSection;
