import React from 'react';
import { Offcanvas } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';
import HUIBottomSheetContext from 'root/widgets/common-components/hui-bottom-sheet/hui-bottom-sheet-context';
import { HUIBottomSheetHeaderProps } from 'root/widgets/common-components/hui-bottom-sheet/types';

const Header: React.FC<HUIBottomSheetHeaderProps> = ({ hasCloseButton, children, ...props }) => {
  const context = React.useContext(HUIBottomSheetContext);

  const handleClose = () => {
    context?.onClose();
  };

  return (
    <Offcanvas.Header {...props}>
      <div>{children}</div>
      {hasCloseButton && (
        <Actionable className="hui-btn-close" variant="link" onClick={handleClose}>
          <i className="pgicon pgicon-cancel" />
        </Actionable>
      )}
    </Offcanvas.Header>
  );
};

export default Header;
