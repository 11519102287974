import classNames from 'classnames';
import React from 'react';
import { Badge } from 'react-bootstrap';

import './actionable-link.scss';
import { ActionableLinkProps } from './types';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const ActionableLink: React.FC<ActionableLinkProps> = (props) => {
  const { href, target, variant, rel, badge, children, className, metadata, ...rest } = props;
  const shouldShowBadge = Boolean(badge && badge?.text);

  const renderContent = () => (
    <>
      {children}
      {shouldShowBadge && (
        <Badge text={badge?.textColor} bg={badge?.bgColor}>
          {badge?.text}
        </Badge>
      )}
    </>
  );

  if (!href) {
    return (
      <div
        className={classNames('actionable-link', { [`btn-${variant}`]: variant }, className)}
        {...filterMetadata(metadata)}
        {...extractDataAutomationId(metadata)}
        {...rest}
      >
        {renderContent()}
      </div>
    );
  }

  let sanitizedRel = rel ?? undefined;

  // According to the security concern, when we found target is _blank, we should add explicitly add "noopener" to rel
  //
  // By default when "noopener" is not present, some browser will imply "noopener" to rel
  // Ref: https://mathiasbynens.github.io/rel-noopener/
  if (target === '_blank' && !rel?.includes('noopener')) {
    sanitizedRel = sanitizedRel ? `${sanitizedRel} noopener` : 'noopener';
  }

  return (
    <a
      href={href}
      className={classNames('actionable-link', { [`btn-${variant}`]: variant }, className)}
      target={target}
      rel={sanitizedRel}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
      {...rest}
    >
      {renderContent()}
    </a>
  );
};

export default ActionableLink;
