import { FC, Fragment, useMemo } from 'react';

import './agent-meta-info.scss';
import { AgentMetaInfoProps } from './types';
import { AgentMetaInfoType } from './constants';
import Title from 'root/widgets/common-components/title';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const AgentMetaInfo: FC<AgentMetaInfoProps> = (props) => {
  const { caption, iconClass, href, label, shouldOpenUrlInNewTab = false, type } = props;

  const metaInfo = useMemo(
    () => (
      <>
        <Title headerTag="h5" className="agent-meta-info-details-label">
          {Array.isArray(label)
            ? label.map((value, index) => (
                <Fragment key={value}>
                  <span>{value}</span>
                  {index !== label.length - 1 && <div className="vertical-divider" />}
                </Fragment>
              ))
            : label}
        </Title>
        <p className="agent-meta-info-details-caption">{caption}</p>
      </>
    ),
    [label, caption],
  );

  if (type !== AgentMetaInfoType.MEDIAN_PSF_AND_LISTING_PRICE) {
    return (
      <div className="agent-meta-info-cta">
        <p className="agent-meta-info-cta-caption">
          {iconClass && <i className={`pgicon ${iconClass}`} />}
          <span>
            {href && (
              <ActionableLink
                href={href}
                target={shouldOpenUrlInNewTab ? '_blank' : '_self'}
                rel="noopener noreferrer"
                className="agent-meta-info-cta-link"
              >
                {label}
              </ActionableLink>
            )}
            {caption}
          </span>
        </p>
      </div>
    );
  }

  return <div className="agent-meta-info-details">{metaInfo}</div>;
};

AgentMetaInfo.displayName = 'AgentMetaInfo';

export default AgentMetaInfo;
