import { FC } from 'react';

import { LinkStack } from 'root/widgets/global-components/footer/sub-components/link-stack';

import './sitemap.scss';
import { SiteMapProps } from './types';

const Sitemap: FC<SiteMapProps> = ({ context, sitemapSegmentData, commonData }) => (
  <div className="sitemap-block-root">
    <div className="footer-container">
      {sitemapSegmentData.map((link, index) => (
        <div className="sitemap-links-wrapper" key={`sitemap-${index}`}>
          <LinkStack links={link} context={context} viewMoreLabel={commonData.viewMoreLabel} />
        </div>
      ))}
    </div>
  </div>
);

export default Sitemap;
