import classNames from 'classnames';
import { FC } from 'react';

import { HIVE_STATIC_VERSION } from 'root/widgets/constants';

import { SvgIcon } from 'root/widgets/svg-icon';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './finance-banner.scss';
import type { FinanceBannerProps } from './types';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const FinanceBanner: FC<FinanceBannerProps> = (props) => {
  const { className, metadata, alertMsg, link } = props;

  return (
    <div className={classNames('finance-banner-root', className)}>
      <HUIAlert
        variant="teal"
        className="finance-banner"
        {...filterMetadata(metadata)}
        {...extractDataAutomationId(metadata)}
      >
        <SvgIcon
          src={`https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/verified.svg`}
          width={24}
          height={24}
          title="timeline node"
          className="finance-banner__icon"
        />
        <span>{alertMsg}</span>&nbsp;
        <ActionableLink
          className="finance-banner__link"
          variant="link primary"
          target="_blank"
          rel="noopener noreferrer"
          href={link.href}
          onClick={link.onClick}
        >
          {link.text}
        </ActionableLink>
      </HUIAlert>
    </div>
  );
};

FinanceBanner.displayName = 'FinanceBanner';

export default FinanceBanner;
