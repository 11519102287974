import classnames from 'classnames';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';
import type { BaseProps } from 'root/widgets/types';

import './price-range-filter.scss';
import PriceRangeItem from './price-range-item';
import { parseNumber, isNaN } from 'root/widgets/utils/number';
import { Filter } from 'root/widgets/common-components/filter';

const PriceRangeFilter: FC<BaseProps> = ({ className }) => {
  const context = useContext(SearchWithFilterContext);
  const { setFilters, data, filters: selectedFilters, category } = context;
  const { filters } = data;
  const priceRangeData = filters['priceRange'];
  const inputData = priceRangeData[category];
  const selectedInputValues = selectedFilters['priceRange']['selectedValues'];

  const [selectedValues, setSelectedValues] = useState({
    minprice: selectedInputValues?.minprice ?? '',
    maxprice: selectedInputValues?.maxprice ?? '',
  });
  const [hasPriceRangeError, setPriceRangeError] = useState(false);
  const filterDropdownRef = useRef<HTMLInputElement>(null);

  const displayText = useMemo(() => {
    const isMinInvalid = !selectedInputValues.minprice || isNaN(selectedInputValues.minprice);
    const isMaxInvalid = !selectedInputValues.maxprice || isNaN(selectedInputValues.maxprice);
    if (isMaxInvalid && isMinInvalid) {
      return '';
    }
    if (isMaxInvalid) {
      return `${inputData.minprice.rangeText} ${inputData.minprice.unit} ${selectedInputValues.minprice}`;
    }
    if (isMinInvalid) {
      return `${inputData.maxprice.rangeText} ${inputData.maxprice.unit} ${selectedInputValues.maxprice}`;
    }

    return `${inputData.maxprice.unit} ${selectedInputValues.minprice} - ${selectedInputValues.maxprice}`;
  }, [selectedInputValues]);

  const onClearCaptured = () => {
    setSelectedValues({ minprice: '', maxprice: '' });
    setPriceRangeError(false);
  };

  const checkValidity = () =>
    selectedValues.minprice === '' ||
    selectedValues.maxprice === '' ||
    isNaN(selectedValues.minprice) ||
    isNaN(selectedValues.maxprice) ||
    parseNumber(selectedValues.minprice) <= parseNumber(selectedValues.maxprice);

  const onApplyCaptured = () => {
    const isValid = checkValidity();
    if (isValid) {
      const updatedPriceRange = { ...selectedFilters.priceRange };
      updatedPriceRange.selectedValues = { ...selectedValues };

      if (updatedPriceRange.selectedValues && isNaN(updatedPriceRange.selectedValues.minprice)) {
        updatedPriceRange.selectedValues.minprice = '';
      }

      if (updatedPriceRange.selectedValues && isNaN(updatedPriceRange.selectedValues.maxprice)) {
        updatedPriceRange.selectedValues.maxprice = '';
      }

      setFilters({
        ...selectedFilters,
        priceRange: updatedPriceRange,
      });

      if (hasPriceRangeError) {
        setPriceRangeError(false);
      }
      filterDropdownRef?.current?.click(); // closing the filter dropdown when applied successfully
    } else {
      setPriceRangeError(true);
    }
  };

  const handleToggleDropdown = (isOpen) => {
    if (!isOpen) {
      setSelectedValues({
        minprice: selectedInputValues?.minprice ?? '',
        maxprice: selectedInputValues?.maxprice ?? '',
      });
    }
  };

  const setMinSelected = (value) => {
    setSelectedValues({ ...selectedValues, minprice: value });
  };

  const setMaxSelected = (value) => {
    setSelectedValues({ ...selectedValues, maxprice: value });
  };

  const shouldBeDisabled = selectedValues.minprice === '' && selectedValues.maxprice === '';

  useEffect(() => {
    setSelectedValues({
      minprice: selectedInputValues?.minprice ?? '',
      maxprice: selectedInputValues?.maxprice ?? '',
    });
  }, [selectedInputValues]);

  return (
    <div className={classnames('price-range-filter-root', className)}>
      <Filter
        dropdownTitle={priceRangeData.dropdownTitle}
        selectedValueStr={displayText}
        toggleBtnText={priceRangeData.toggleBtnText}
        clearAction={{ text: priceRangeData.clearButtonText, onClick: onClearCaptured, isDisabled: shouldBeDisabled }}
        applyAction={{ text: priceRangeData.applyButtonText, onClick: onApplyCaptured }}
        dropdownRef={filterDropdownRef}
        onToggle={handleToggleDropdown}
      >
        <Row>
          <Col>
            <PriceRangeItem
              prefix={inputData.minprice.unit}
              placeholderText={inputData.minprice.placeholderText}
              labelText={inputData.minprice.labelText}
              data={inputData.minprice.items}
              setSelected={setMinSelected}
              selectedInput={selectedValues.minprice}
              error={hasPriceRangeError}
            />
          </Col>
          <Col>
            <PriceRangeItem
              prefix={inputData.maxprice.unit}
              placeholderText={inputData.maxprice.placeholderText}
              labelText={inputData.maxprice.labelText}
              data={inputData.maxprice.items}
              setSelected={setMaxSelected}
              selectedInput={selectedValues.maxprice}
              error={hasPriceRangeError}
            />
          </Col>
        </Row>
      </Filter>
    </div>
  );
};

PriceRangeFilter.displayName = 'PriceRangeFilter';

export default PriceRangeFilter;
