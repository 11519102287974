import classnames from 'classnames';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './cookie-banner.scss';
import { CookieBannerProps } from './types';

const CookieBanner: React.FC<CookieBannerProps> = (props) => {
  const { text, proceedAction, className, metadata } = props;
  return (
    <div
      className={classnames('cookie-banner-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <Container fluid>
        <Row>
          <Col className="content">
            <p className="text-block">{text}</p>
            <div className="cookie-banner-action">
              <Actionable variant="dark" onClick={proceedAction.onClick}>
                {proceedAction.text}
              </Actionable>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

CookieBanner.displayName = 'CookieBanner';

export default CookieBanner;
