import classNames from 'classnames';
import React, { FC } from 'react';
import { MenuItem } from 'react-bootstrap-typeahead';
import Highlighter from 'react-highlight-words';

import { HighlightKeyword } from 'root/widgets/common-components/highlight-keyword';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import Badge from 'root/widgets/common-components/badge/badge';

import { TypeaheadOptionType } from './constants';
import './found-locations-view.scss';
import { FoundLocationItemProps, FoundLocationsViewProps } from './types';

const FoundLocationItem: React.FC<FoundLocationItemProps> = (props) => {
  const { className, position, searchKeyword, location, metadata, onLocationSelect } = props;
  const displayDescription = React.useMemo(() => {
    if (typeof location.displayDescription === 'string') {
      return location.displayDescription ? [location.displayDescription] : [];
    }

    if (Array.isArray(location.displayDescription)) {
      return location.displayDescription;
    }

    return [];
  }, [location.displayDescription]);

  const handleClick = () => {
    if (onLocationSelect) {
      onLocationSelect(location);
    }
  };
  const searchWords = searchKeyword.split(' ');

  return (
    <MenuItem
      className={classNames('found-locations-view-item-root', className)}
      option={{ ...location, optionType: TypeaheadOptionType.FoundLocation }}
      position={position}
      onClick={handleClick}
      {...extractDataAutomationId(metadata)}
    >
      <div className="found-location-content">
        <div className="found-location-header">
          <div className="found-location-view">
            <div className="found-location-view-title" da-id="found-location-view-title">
              <Highlighter
                highlightClassName="highlight-search"
                highlightTag={HighlightKeyword}
                searchWords={searchWords}
                autoEscape
                textToHighlight={location.displayText}
              />
            </div>
            {displayDescription.length > 0 && (
              <div className="found-location-view-meta" da-id="found-location-view-meta">
                {displayDescription.map((description, index) => (
                  <>
                    <span className={classNames('pgicon', 'pgicon-circle', { hide: !index })} />
                    <Highlighter
                      highlightClassName="highlight-search"
                      highlightTag={HighlightKeyword}
                      searchWords={searchWords}
                      autoEscape
                      textToHighlight={description}
                    />
                  </>
                ))}
              </div>
            )}
          </div>
          {location.displayType && (
            <div className="found-location-view-label" da-id="found-location-view-label">
              <Badge type="small" bg="neutral" variant="secondary">
                {location.displayType}
              </Badge>
            </div>
          )}
        </div>
      </div>
    </MenuItem>
  );
};

const FoundLocationsView: FC<FoundLocationsViewProps> = ({
  className,
  foundLocations,
  metadata,
  onLocationSelect,
  positions,
  searchKeyword,
}) => (
  <div className="found-locations-root" {...extractDataAutomationId(metadata)}>
    {foundLocations.length > 0 &&
      foundLocations.map((location, idx) => (
        <>
          {idx > 0 && <div className="found-locations__separator" />}
          <FoundLocationItem
            className={className}
            position={positions[idx]}
            option={location}
            key={location.objectId}
            searchKeyword={searchKeyword}
            location={location}
            onLocationSelect={onLocationSelect}
            metadata={{
              dataAutomationId: metadata?.dataAutomationId ? `${metadata?.dataAutomationId}-${idx}` : '',
            }}
          />
        </>
      ))}
  </div>
);

FoundLocationsView.displayName = 'FoundLocationsView';

export default FoundLocationsView;
