import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import phone from 'phone';
import { ChangeEventHandler, FC, FormEventHandler } from 'react';
import { Badge, Form, InputGroup } from 'react-bootstrap';

import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { filterMetadata } from 'root/widgets/utils/filter';

import './lead-verification-view.scss';
import { LeadVerificationViewProps } from './types';
import { Checkbox, Typography } from 'root/common/atom-elements';
import { Masthead } from 'root/widgets/common-components/masthead';
import Actionable from 'root/widgets/common-components/actionable';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';

const LeadVerificationView: FC<LeadVerificationViewProps> = (props) => {
  const {
    className,
    masthead,
    inputGroup,
    leadData,
    onContactDataChange,
    onIntentDataChange,
    action,
    serverError,
    metadata,
  } = props;
  const { email, mobile, sellByIntent, consent } = inputGroup;

  const isFormValid =
    leadData.email &&
    leadData.intent.sellBy &&
    leadData.mobile &&
    phone(`+${mobile.countryCode}${leadData.mobile}`, { strictDetection: true }).isValid;

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    onContactDataChange({ email: evt.target.value });
  };

  const handleMobileChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    onContactDataChange({ mobile: evt.target.value });
  };

  const handleSellByIntentChange = (value: string) => {
    onIntentDataChange({ sellBy: value });
  };

  const handleConsentCheckChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    onContactDataChange({ hasConsented: evt.target.checked });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    action?.onClick?.();
  };

  return (
    <div
      className={classNames('hsv-lvv-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <Masthead {...masthead} />
      <Form className="d-flex flex-column hsv-form" onSubmit={handleSubmit}>
        <Form.Group controlId="lvv-email">
          <Form.Label className="mb-1 hsv-label">{email.label}</Form.Label>
          <div className="position-relative">
            <Form.Control
              type="email"
              required
              {...omit(email, ['label', 'invalidFeedback'])}
              name="email"
              value={leadData.email}
              onChange={handleEmailChange}
              {...extractDataAutomationId(email.metadata)}
            />
          </div>
        </Form.Group>
        <Form.Group className={classNames({ verified: mobile.isVerified })} controlId="lvv-mobile">
          <Form.Label className="mb-1 hsv-label">{mobile.label}</Form.Label>
          <InputGroup>
            <InputGroup.Text>+{mobile.countryCode}</InputGroup.Text>
            {mobile.isVerified && <i className="pgicon pgicon-ok-circled hsv-verified-icon" />}
            <Form.Control
              pattern="[0-9]*"
              type="number"
              inputMode="numeric"
              required
              {...omit(mobile, ['label', 'countryCode', 'isVerified', 'invalidFeedback'])}
              name="mobile"
              value={leadData.mobile}
              onChange={handleMobileChange}
              {...extractDataAutomationId(mobile.metadata)}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group
          className="hsv-intent-root"
          controlId="lvv-intent"
          {...extractDataAutomationId(sellByIntent.metadata)}
        >
          <Form.Label className="mb-1 hsv-label">{sellByIntent.label}</Form.Label>
          <div className="d-inline-flex gap-1 flex-wrap">
            {sellByIntent.options.map((value) => (
              <Actionable variant="link" key={value} onClick={() => handleSellByIntentChange(value)}>
                <Badge
                  bg="light"
                  className={classNames('px-3 py-2 m-0 rounded-pill', { selected: value === leadData.intent.sellBy })}
                >
                  {value}
                </Badge>
              </Actionable>
            ))}
          </div>
        </Form.Group>
        {!isEmpty(consent) && (
          <Form.Group controlId="cv-consent" className="d-flex gap-2">
            <Checkbox name="consent" checked={leadData.hasConsented} onChange={handleConsentCheckChange} />
            <Typography htmlFor="cv-consent" element="label">
              {consent.label}
            </Typography>
          </Form.Group>
        )}
        <Actionable
          variant="primary"
          type="submit"
          shouldShowLoading={action.shouldShowLoading}
          disabled={!isFormValid || action.isDisabled}
          {...extractDataAutomationId(action.metadata)}
        >
          {action.text}
        </Actionable>
      </Form>
      {!isEmpty(serverError) && (
        <HUIAlert className="pt-3" variant="danger">
          {serverError}
        </HUIAlert>
      )}
    </div>
  );
};

export default LeadVerificationView;
