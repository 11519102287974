import { TRANSPORTATION_SPECIAL_TYPES } from './constants';
import { FoundLocationType, LocationPropertiesType } from './types';

export const getIconClass = (objectType) => {
  let iconClass = '';
  switch (objectType) {
    case 'AGENT':
      iconClass = 'pgicon-user';
      break;
    case 'TRANSPORTATION':
    case 'MRT_STATION':
      iconClass = 'pgicon-train';
      break;
    case 'SCHOOL':
      iconClass = 'pgicon-school';
      break;
    case 'PROPERTY':
    case 'PROJECT':
    case 'HDB_ESTATE':
      iconClass = 'pgicon-building';
      break;
    case 'DISTRICT':
    case 'REGION':
    case 'AREA':
    case 'NEIGHBOURHOOD':
    case 'SUBNEIGHBOURHOOD':
    case 'STREET':
      iconClass = 'pgicon-location-o';
      break;
    default:
      iconClass = 'pgicon-location-o';
      break;
  }
  return iconClass;
};

export const convertLocationToSearchObject = (location: FoundLocationType, isSG = false) => {
  const { objectType, objectId, properties, displayType } = location;
  const autocompleteLocation = {};
  let searchKey: string;
  switch (objectType) {
    case 'DISTRICT':
      searchKey = isSG ? 'district_code[]' : 'district_code';
      break;
    case 'PROPERTY':
      searchKey = 'property_id';
      break;
    case 'REGION':
      searchKey = 'region_code';
      break;
    case 'PROJECT':
      searchKey = 'dlid';
      break;
    case 'AREA':
      searchKey = 'area_code[]';
      break;
    case 'HDB_ESTATE':
      searchKey = 'hdb_estate[]';
      break;
    case 'TRANSPORTATION':
      // @TODO: Introduce a new objectType different from TRANSPORTATION for these special sub-types
      // Handle Transportation special types separately
      searchKey = TRANSPORTATION_SPECIAL_TYPES.includes(displayType) ? 'MRT_STATION' : 'mrt_id';
      break;
    case 'NEIGHBOURHOOD':
      searchKey = 'zone_ids';
      break;
    case 'SUBNEIGHBOURHOOD':
      searchKey = 'sub_zone_ids';
      break;
    default:
      searchKey = objectType;
      break;
  }
  autocompleteLocation[searchKey] = objectId;

  const prop = properties as LocationPropertiesType;
  if (prop?.latitude) {
    autocompleteLocation['center_lat'] = prop.latitude;
  }

  if (prop?.longitude) {
    autocompleteLocation['center_long'] = prop.longitude;
  }

  return autocompleteLocation;
};
