import { parse, serialize } from 'cookie';

import { CookieData } from 'root/widgets/types';

export const getBrowserCookies = () => {
  if (typeof document === 'undefined') {
    return null;
  }

  return parse(document.cookie);
};

export const getBrowserCookie = (key: string) => {
  const browserCookies = getBrowserCookies();

  return browserCookies?.[key] || '';
};

export const setBrowserCookie = (cookie: CookieData) => {
  if (typeof document === 'undefined') {
    return;
  }

  const { key, value, ...cookieOptions } = cookie;
  const stringifiedValue = typeof value === 'string' ? value : JSON.stringify(value);

  const cookieStr = serialize(key, stringifiedValue, { path: '/', ...cookieOptions });

  // eslint-disable-next-line unicorn/no-document-cookie
  document.cookie = cookieStr;
};
