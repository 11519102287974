/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback, useState } from 'react';

import { OTPVerificationView } from 'root/widgets/otp-verification-view';

import './otp-verification-modal.scss';
import { OTPVerificationModalProps } from './types';
import { HUIModal } from 'root/widgets/common-components/hui-modal';

const OTPVerificationModal: React.FC<OTPVerificationModalProps> = ({ shouldShow, onClose, ...props }) => (
  <HUIModal className="otp-verification-modal" show={shouldShow} onHide={onClose}>
    <HUIModal.Header closeButton />
    <HUIModal.Body>
      <OTPVerificationView {...props} />
    </HUIModal.Body>
  </HUIModal>
);

OTPVerificationModal.displayName = 'OTPVerificationModal';

export default OTPVerificationModal;
