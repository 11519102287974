import classnames from 'classnames';
import React from 'react';

import Actionable from 'root/widgets/common-components/actionable';

import './sharing-button.scss';
import getSharingPopover from './sub-components';
import { SharingButtonProps } from './types';

const SharingButton: React.FC<SharingButtonProps> = ({
  iconClass,
  text,
  buttonProps,
  sharingTitle,
  links,
  className,
  metadata,
}) => (
  <Actionable
    {...buttonProps}
    {...metadata}
    className={classnames('share-button', className)}
    popover={{
      placement: 'auto',
      trigger: 'click',
      rootClose: true,
      overlay: getSharingPopover(sharingTitle, links),
    }}
  >
    {iconClass && <i className={classnames('pgicon', iconClass)} />}
    <span>{text}</span>
  </Actionable>
);

SharingButton.displayName = 'SharingButton';

export default Object.assign(SharingButton, {
  getSharingPopover,
});
