import classnames from 'classnames';
import { FC, useState } from 'react';

import './seo-segment.scss';
import { SEOSegmentProps } from './types';

const SEOSegment: FC<SEOSegmentProps> = ({ seoSegmentData, className }) => {
  const [isSecondaryVisible, setSecondaryVisible] = useState(false);
  const handleOnClick = () => setSecondaryVisible(!isSecondaryVisible);

  return (
    <div className={classnames('seo-segment-root', className)}>
      <div className="footer-container">
        <p className="seo-segment seo-segment-primary">{seoSegmentData.primary}</p>
        {seoSegmentData.secondary && seoSegmentData.actionableText && (
          <>
            <div
              className={`seo-segment seo-segment-secondary read-more ${isSecondaryVisible ? 'visible' : ''}`}
              onClick={handleOnClick}
            >
              {seoSegmentData.actionableText}
            </div>
            <p className={`seo-segment seo-segment-secondary ${isSecondaryVisible ? 'visible' : ''}`}>
              {seoSegmentData.secondary}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default SEOSegment;
