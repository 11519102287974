import classnames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';
import { TextArea } from 'root/widgets/common-components/textarea';

import './hide-listing-popup.scss';
import { HideListingPopupProps } from './types';

const HideListingPopup: React.FC<HideListingPopupProps> = ({ isVisible, header, body, footer, onSubmit, metadata }) => {
  const [isFullView, setFullView] = React.useState(false);
  const [hasReachedCharLimit, setHasReachedCharLimit] = React.useState(false);
  const [selectedReasons, setSelectedReasons] = React.useState([...body.reasons]);

  const freeTextRef = React.useRef<HTMLTextAreaElement>(null);

  const reasonListMinimum = selectedReasons.slice(0, body.minVisibleReasonCount);
  const visibleReasonList = isFullView ? selectedReasons : reasonListMinimum;

  const handleReasonOnClick = (index) => {
    setSelectedReasons(
      selectedReasons.map((reason, idx) => ({
        ...reason,
        isSelected: idx === index ? !reason.isSelected : reason.isSelected,
      })),
    );
  };

  const handleSubmit = () => {
    onSubmit(
      selectedReasons.filter((reason) => reason.isSelected).map((reason) => reason.key),
      freeTextRef.current?.value || '',
    );
  };

  React.useEffect(() => {
    setSelectedReasons([...body.reasons]);
  }, [isVisible]);

  return (
    <Modal {...metadata} className="hide-listing-popup-root in" show={isVisible}>
      <div className="hide-listing-popup-header">
        <div className="title">{header.title}</div>
        <Actionable {...header.closeButtonProps} className={classnames('close', header.closeButtonProps.className)}>
          <i className="pgicon pgicon-cancel" />
        </Actionable>
      </div>
      <div className="hide-listing-popup-body">
        {body.error && <div className="error-message">{body.error}</div>}
        <div className="title">{body.title}</div>
        <div className="badges-wrapper">
          {visibleReasonList.map((reason, index) => (
            <span
              key={reason.key}
              className={classnames('badge', reason.isSelected ? 'selected' : '')}
              onClick={() => handleReasonOnClick(index)}
            >
              <i className="pgicon pgicon-ok" />
              {reason.title}
            </span>
          ))}
        </div>
        {!isFullView && (
          <a href="#showMoreReasons" className="show-more-reasons" onClick={() => setFullView(true)}>
            <i className="pgicon pgicon-arrow-down" /> {body.showMorePrompt}
          </a>
        )}
        {isFullView && (
          <div className="hide-listing-feedback-additional-reason">
            <p>{body.reasonFreeText.title}</p>
            <TextArea
              textAreaRef={freeTextRef}
              charLimit={body.reasonFreeText.charLimit}
              placeholder={body.reasonFreeText.placeHolder}
              onLimitReach={setHasReachedCharLimit}
              debounceTime={500}
            />
            {hasReachedCharLimit && <p className="character-limit-message">{body.reasonFreeText.limitError}</p>}
          </div>
        )}
      </div>
      <div className="hide-listing-popup-footer">
        <Actionable
          {...footer.submitButtonProps}
          className={classnames('submit', footer.submitButtonProps.className)}
          onClick={handleSubmit}
        >
          {footer.submitText}
        </Actionable>
      </div>
    </Modal>
  );
};

export default HideListingPopup;
