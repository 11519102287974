import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';

import type { BrazeContentCardProps } from 'root/widgets/types';

import './notification-panel.scss';
import type { ContentCardData } from './sub-components/content-card';
import NotificationPanelBody from './sub-components/notification-panel-body';
import NotificationPanelHeader from './sub-components/notification-panel-header';
import type { NotificationPanelProps } from './types';
import { filterContentCards, mapContentCards, processContentCards } from './utils';
import { getContentCards, subscribeContentCards } from 'root/widgets/utils/braze';

const NotificationPanel: React.FC<NotificationPanelProps> = (props) => {
  const { brazeSDK, config, region, locale } = props;
  const { offcanvasProps, shouldShow, defaultTexts, onHide } = config;
  const headerConfig = { defaultTexts, onHide };
  const bodyConfig = { defaultTexts };

  const [cards, setCards] = useState<Array<ContentCardData> | []>([]);
  const [rawCardMappings, setRawCardMappings] = useState<Record<string, BrazeContentCardProps>>({});

  const handleClose = () => {
    // @TODO Check production and define event for GA
    onHide();
  };

  const handleRawCards = (rawCards: Array<BrazeContentCardProps> | []) => {
    const filteredCards = filterContentCards(region, locale, rawCards);
    setRawCardMappings(mapContentCards(filteredCards));

    const contentCards = processContentCards(filteredCards);
    setCards(contentCards);
  };

  useEffect(() => {
    if (brazeSDK) {
      subscribeContentCards(brazeSDK, handleRawCards);
    }
  }, [brazeSDK]);

  useEffect(() => {
    if (shouldShow) {
      handleRawCards(getContentCards(brazeSDK)?.cards || []);
    }
  }, [shouldShow]);

  return (
    <Offcanvas
      className={classNames('notification-panel-root')}
      backdrop={false}
      show={shouldShow}
      onHide={handleClose}
      {...offcanvasProps}
    >
      <NotificationPanelHeader brazeSDK={brazeSDK} config={headerConfig} />
      <NotificationPanelBody brazeSDK={brazeSDK} config={bodyConfig} data={{ cards, rawCardMappings }} />
    </Offcanvas>
  );
};

NotificationPanel.displayName = 'NotificationPanel';

export default NotificationPanel;
