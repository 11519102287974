import React from 'react';

import ActionableLink from 'root/widgets/common-components/actionable-link';

import LocaleSwitch from 'root/widgets/global-components/navbar/sub-components/common/locale-switch';
import { type RightButtonSetProps } from 'root/widgets/global-components/navbar/types';
import { SvgIcon } from 'root/widgets/svg-icon';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import MyAccountDropdown from './my-account-dropdown';

const RightButtonSet: React.FC<RightButtonSetProps> = (props) => {
  const {
    notificationButton,
    myActivityConfig,
    loginDetail,
    localeDetail,
    myAccountLinks,
    onNotificationToggleClick,
    onMyActivityLinkClick,
    onMyAccountClick,
    onMyAccountItemClick,
    onLogoutClick,
    onLoginClick,
    context,
  } = props;

  const renderLoginBtn = () => (
    <button
      type="button"
      className="login-button nav-button wide"
      onClick={onLoginClick}
      {...extractDataAutomationId(loginDetail?.login)}
    >
      {loginDetail?.login?.defaultText}
    </button>
  );

  return (
    <div className="right-button-set">
      {notificationButton && (
        <button type="button" className="notification-toggle square" onClick={onNotificationToggleClick}>
          <i className="pgicon-notification-o" />
          {notificationButton.hasNewFeed && <div className="pulse-dot highlight" />}
        </button>
      )}
      {myActivityConfig && (
        <ActionableLink
          className="my-activity-link"
          href={myActivityConfig.href}
          onClick={onMyActivityLinkClick}
          {...extractDataAutomationId(myActivityConfig)}
        >
          <SvgIcon className="my-activity-icon" src={myActivityConfig.icon} width={16} height={16} shouldUseImage />
          <div className="my-activity-text">{myActivityConfig.text}</div>
        </ActionableLink>
      )}
      {loginDetail && myAccountLinks && loginDetail.isLoggedIn && (
        <MyAccountDropdown
          myAccountLinks={myAccountLinks}
          onItemClick={onMyAccountItemClick}
          onMyAccountClick={onMyAccountClick}
          loginDetail={loginDetail}
          onLogoutClick={onLogoutClick}
        />
      )}
      {loginDetail && !loginDetail.isLoggedIn && renderLoginBtn()}
      {localeDetail && <LocaleSwitch {...localeDetail} context={context} />}
    </div>
  );
};

export default RightButtonSet;
