import React, { useState } from 'react';
import { Col, Overlay, Popover, Row } from 'react-bootstrap';

import { RatingInput } from 'root/widgets/common-components/rating-input';

import './rating-factor.scss';
import { RatingFactorProps } from './types';

const RatingFactor: React.FC<RatingFactorProps> = ({
  title,
  code,
  tooltipText,
  ratingInputConfig,
  onChange,
  metadata,
}) => {
  const [shouldShowTooltip, setShowTooltip] = useState(false);
  const tooltipRef = React.useRef(null);

  const showToolTip = () => setShowTooltip(true);
  const hideToolTip = () => setShowTooltip(false);

  const updateRating = (rating: number) => {
    onChange({
      factorCode: code,
      ratingValue: rating,
    });
  };

  return (
    <div className="rating-factor-container">
      <Row className="rating-title-row">
        <Col className="title">{title}</Col>
        <i
          className="tooltip-icon pgicon-info-o"
          ref={tooltipRef}
          onMouseEnter={showToolTip}
          onMouseLeave={hideToolTip}
        />
        <Overlay show={shouldShowTooltip} placement="bottom" target={tooltipRef}>
          <Popover id={`popover-container-${code}`}>
            <Popover.Body>{tooltipText}</Popover.Body>
          </Popover>
        </Overlay>
      </Row>
      <Row>
        <Col>
          <RatingInput {...ratingInputConfig} onChange={updateRating} metadata={metadata} />
        </Col>
      </Row>
    </div>
  );
};

RatingFactor.displayName = 'RatingFactor';

export default RatingFactor;
