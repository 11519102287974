import React from 'react';

interface HUIBottomSheetContextType {
  onClose: () => void;
}

const HUIBottomSheetContext = React.createContext<HUIBottomSheetContextType>({
  onClose() {},
});

export default HUIBottomSheetContext;
