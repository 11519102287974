import capitalize from 'lodash/capitalize';
import { ChangeEvent } from 'react';
import { storage } from 'root/widgets/storage';
import { BrazeContentCardProps } from 'root/widgets/types';
import { LOCALE, PropertyTypeMarket } from 'root/widgets/constants';
import { AlertCardInfoData } from 'root/widgets/home-alert-card';

import { Listing } from 'root/symbiosis-widgets/constants';

import {
  BRAZE_NEW_HOME_ALERTS_STORAGE_KEY,
  BRAZE_NEW_HOME_ALERT_CARD_TARGET,
  BRAZE_NEW_HOME_ALERT_CARD_TYPE,
  BREAKOUT_PRICE_K,
  BREAKOUT_PRICE_M,
} from './constant';
import {
  AlertTypes,
  type NewHomeAlertCardGroupProps,
  type RawNewHomeAlertData,
  type RawNewHomeAlertBagProps,
} from './types';
import { getContentCards } from 'root/widgets/utils/braze';

const widgetName = 'NewHomeAlertCardGroup';

/**
 * Each home alert card needs a proper SRP URL to redirect to, when clicked.
 * This function attempts to inject those URLs into respective cards.
 *
 * @param rawHomeAlerts
 * @param dependencies
 *
 * @internal
 *
 * @returns
 */
async function generateAndInjectSearchUrls(rawHomeAlerts, dependencies) {
  const { searchService, logError, locale = LOCALE.EN } = dependencies;

  if (rawHomeAlerts.length === 0) {
    return rawHomeAlerts;
  }

  const validAlerts: Array<BrazeContentCardProps> = [];

  try {
    const options = {
      data: JSON.stringify({ searches: rawHomeAlerts, locale }),
    };

    const response = await searchService.generateUrl(options);

    if (response) {
      rawHomeAlerts.forEach((alert: any, index: number) => {
        if (response.data[index]) {
          alert.redirectUrl = response.data[index];
          validAlerts.push(alert);
        }
      });
    }
  } catch (error) {
    logError(widgetName, generateAndInjectSearchUrls.name, error);
  }

  return validAlerts;
}

export const findCardWithHomeAlertsById = (brazeSdk, id) => {
  if (!brazeSdk) {
    return null;
  }

  const cards = getContentCards(brazeSdk).cards;
  return cards.find((card) => card.id === id);
};

/**
 * In case data is not found in cookie, this function will help to populate that.
 *
 * @param brazeAPIKey
 * @param dependencies
 * @returns
 */
export const populateRawHomeAlertBag = async (dependencies) => {
  const { brazeSdk } = dependencies;

  if (!brazeSdk) {
    return;
  }

  const brazeData = getContentCards(brazeSdk);

  if (!brazeData) {
    return;
  }

  const cardWithHomeAlerts = brazeData.cards.find(
    (card) =>
      card?.extras?.new_home_alert_card &&
      card?.extras?.widgetType === BRAZE_NEW_HOME_ALERT_CARD_TYPE &&
      card?.extras?.bu === BRAZE_NEW_HOME_ALERT_CARD_TARGET &&
      card?.extras?.web === 'true',
  );

  if (!cardWithHomeAlerts) {
    storage.removeItem(BRAZE_NEW_HOME_ALERTS_STORAGE_KEY);
    return;
  }

  const rawHomeAlerts = JSON.parse(cardWithHomeAlerts.extras?.new_home_alert_card);
  rawHomeAlerts.forEach((alert: any) => {
    alert.searchParams = {
      ...alert.searchParams,
      sort: 'date',
      order: 'desc',
      recommendationSource: 'new-home-alerts',
      market: PropertyTypeMarket.RESIDENTIAL,
    };
  });

  // Generate the search URLs by calling API and inject to respective alert objects
  const validAlerts = await generateAndInjectSearchUrls(rawHomeAlerts, dependencies);

  if (validAlerts.length === 0) {
    return;
  }

  storage.setItem(
    BRAZE_NEW_HOME_ALERTS_STORAGE_KEY,
    JSON.stringify({
      alerts: validAlerts,
      cardWithHomeAlerts: { id: cardWithHomeAlerts.id },
    }),
  );
};

/**
 * Get raw home alert cards from cookie, or parse it from braze data - if does not exist.
 * Also, send other data as one single data bag, which is also saved.
 *
 * @param brazeAPIKey
 * @param logError
 * @returns
 */
export const getRawHomeAlertBag = (logError?) => {
  const databagString = storage.getItem(BRAZE_NEW_HOME_ALERTS_STORAGE_KEY);

  if (databagString) {
    try {
      const preSavedAlertBag: RawNewHomeAlertBagProps = JSON.parse(databagString);
      return preSavedAlertBag;
    } catch (error) {
      const log = logError || console.error;
      log(widgetName, getRawHomeAlertBag.name, error);
    }
  }

  return null;
};

export const formatPrice = (price: number) => {
  if (price >= BREAKOUT_PRICE_M) {
    return `${Math.round((price * 10) / BREAKOUT_PRICE_M) / 10}M`;
  }

  if (price >= BREAKOUT_PRICE_K) {
    return `${Math.round((price * 10) / BREAKOUT_PRICE_K) / 10}K`;
  }

  return price;
};

/**
 * Creates the actual alert card data for rendering the home alert component.
 */
export const createAlertCards = (
  rawHomeAlerts: RawNewHomeAlertData[],
  onCardClick: (evt: ChangeEvent<HTMLInputElement>, alert: RawNewHomeAlertData, index: number) => void,
  config: {
    cardConfig: NewHomeAlertCardGroupProps['cardConfig'];
    districtConfig?: NewHomeAlertCardGroupProps['districtConfig'];
    regionConfig?: NewHomeAlertCardGroupProps['regionConfig'];
  },
) => {
  const { cardConfig, districtConfig = {}, regionConfig = {} } = config;

  return rawHomeAlerts.map((alert, index) => {
    const {
      newListings: newListingsAmount = 0,
      redirectUrl,
      alertId,
      searchParams = {},
      searchText = '',
      alertType,
    } = alert;

    const {
      mrtCode,
      propertyId,
      listingType: searchParamListingType = '',
      minPrice: searchParamMinPrice,
      maxPrice: searchParamMaxPrice,
      distance: searchParamDistance,
      districtCode,
      regionCode,
      minBed,
      minBath,
    } = searchParams;

    let pgIcon = 'pgicon-district';

    if (mrtCode) {
      pgIcon = 'pgicon-train';
    }

    if (propertyId) {
      pgIcon = 'pgicon-building';
    }

    const listingType = capitalize(searchParamListingType?.toLowerCase());
    const minPrice = Number.isInteger(searchParamMinPrice)
      ? formatPrice(searchParamMinPrice as unknown as number)
      : null;
    const maxPrice = Number.isInteger(searchParamMaxPrice)
      ? formatPrice(searchParamMaxPrice as unknown as number)
      : null;

    let alertTitle = searchText.split(',').pop();

    if (regionConfig && regionCode && regionCode in regionConfig) {
      alertTitle = regionConfig[regionCode];
    }

    if (districtCode && districtConfig && districtCode?.toUpperCase() in districtConfig) {
      alertTitle = districtConfig[districtCode.toUpperCase()];
    }

    const isSaleListingType = listingType.toLowerCase() === Listing.Type.Sale;
    let details: Array<AlertCardInfoData> = [{ text: isSaleListingType ? 'Buy' : 'Rent' }];

    if (searchParamDistance) {
      const distance = `${cardConfig.distanceText}`.replace('{{distance}}', searchParamDistance);
      details.push({ text: distance });
    }

    if (minBed) {
      details.push({
        text: minBed,
        icon: { name: 'pgicon-bedroom' },
      });
    }

    if (minBath) {
      details.push({
        text: minBath,
        icon: { name: 'pgicon-bathroom' },
      });
    }

    if (minPrice && maxPrice) {
      const price = isSaleListingType ? `${minPrice}-${maxPrice}` : `${minPrice}-${maxPrice}${cardConfig.monthlyText}`;
      const priceWithCurrency = `${cardConfig.currency} ${price}`;
      details.push({ text: priceWithCurrency });
    }

    let alertHeader = newListingsAmount > 0 ? `${cardConfig.title.plural}` : `${cardConfig.title.single}`;

    const isDistrictOrStateNewLaunchAlert =
      alertType === AlertTypes.DISTRICT_NEW_LAUNCH || alertType === AlertTypes.REGION_NEW_LAUNCH;

    if (isDistrictOrStateNewLaunchAlert && cardConfig.newHomesTitle) {
      alertHeader = cardConfig.newHomesTitle;
    }

    if (isDistrictOrStateNewLaunchAlert && cardConfig.newHomesDetail) {
      details = [{ text: cardConfig.newHomesDetail }];
    }

    return {
      id: alertId,
      title: `${newListingsAmount} ${alertHeader}`,
      area: {
        icon: pgIcon,
        text: alertTitle ?? '',
      },
      details,
      link: {
        href: redirectUrl,
        rel: 'noreferrer noopener',
      },
      onClick: (evt: ChangeEvent<HTMLInputElement>) => onCardClick(evt, alert, index),
    };
  });
};
