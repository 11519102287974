import { useResponsive } from 'ahooks';
import uniq from 'lodash/uniq';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import classnames from 'classnames';

import { EVENT_NAMES, Events } from 'root/widgets/events';

import { DEFAULT_MAP_PORTION, MOBILE_PAGE } from './constants';
import './mrt-search-modal.scss';
import { LineRow, Map, MobileLinesRow, SearchBar, StationRow, TitleRow } from './sub-components';
import { LineData, LineToStationsType, MRTSearchModalProps, StationToDetailType } from './types';
import {
  addStationsToLines,
  createMRTDictionaries,
  explodeStaionIds,
  initSelectedLines,
  normalizeStationIds,
} from './util';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import Actionable from 'root/widgets/common-components/actionable';

const MRTSearchModal: FC<MRTSearchModalProps> = ({ metadata, config, data, context }) => {
  const {
    mapSvg,
    svgWidth,
    svgHeight,
    mapPortionMobile,
    mapPortionDeskTop,
    futureConfig,
    sectionTitle,
    searchPlaceholder,
    clearText,
    applyText,
    modalTitle,
    dataResolver,
    region,
    locale,
    logError,
    onClose,
    onApply,
  } = config;
  const { isShown, selectedIds } = data;
  const { futureLines, futureStations, futureLabel } = futureConfig || {};
  const { fetchMRTItems } = dataResolver;

  const [linesAndStationsConfig, setLinesAndStationsConfig] = useState<Array<LineData>>([]);

  /**
   * "lineToStationsMapping" is a dictionary used to keep stationIds under a line {<lineId>: [...stationIds] }, used to select/unselect the whole line
   */
  const [lineToStationsMapping, setLineToStationsMapping] = useState<LineToStationsType>({});
  /**
   * "stationToDetailMapping" is a dictionary used to check station info from stationId {[stationId]: {lines:[...lineIds], <the-rest-station-props>}}
   * prop "lines" will be used when select/unselect station
   */
  const [stationToDetailMapping, setStationToDetailMapping] = useState<StationToDetailType>({});
  /**
   * "selectedStations" is used to keep stationIds be selected [...stationIds];
   */
  const [selectedStations, setSelectedStations] = useState<Array<string>>([]);
  /**
   * "selectedLines" is used to keep selected stationIds under a line is selected {<line-id>: [...stationIds]}
   * this state be used to check whether the whole line is selected,
   * also in mobile mode to display how many stations is selected under a line
   */
  const [selectedLines, setSelectedLines] = useState<LineToStationsType>({});

  const [searchQuery, setSearchQuery] = useState('');
  const [searchedLinesAndStations, setSearchedLinesAndStations] = useState<Array<LineData>>([]);

  /** responsive would affect "isMobileSize", then decide render desktop or mobile mode */
  const responsive = useResponsive();
  const [isMobileSize, setIsMobileSize] = useState(true);
  const [defaultMapPortion, setDefaultMapPortion] = useState(DEFAULT_MAP_PORTION);

  /**
   * in mobilde mode, select "line" would change view to mobile-list
   * then mobile-list will scroll to the line, and expned its stations list
   */
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [expandLine, setExpandedLine] = useState<string>('');
  const [mobilePage, setMobilePage] = useState<string>(MOBILE_PAGE.MAP);

  const userHasTypedRef = useRef(false);

  /**
   * future lines is optional(now in SG only),
   * useMemo to keep cache of linesAndStations as subset of  linesAndStationsConfig
   */
  const [shouldShowFutureLines, setShowFutureLines] = useState(true);
  const shouldFilterFutureStation = useMemo(
    () => futureStations?.length && !shouldShowFutureLines,
    [shouldShowFutureLines, futureStations],
  );
  const linesAndStations = useMemo(() => {
    if (!shouldShowFutureLines && futureLines) {
      return linesAndStationsConfig.filter((line) => !futureLines.includes(line.id));
    }
    return linesAndStationsConfig;
  }, [shouldShowFutureLines, linesAndStationsConfig]);

  const initSelectedLineAndStations = (
    stationToDetailMap: StationToDetailType,
    MRTLinesAndStationsConfig: Array<LineData>,
  ) => {
    if (Object.keys(stationToDetailMap).length === 0) {
      return;
    }

    const initSelectedLinesData = initSelectedLines(MRTLinesAndStationsConfig);

    const normalizedIds = normalizeStationIds(selectedIds, stationToDetailMap);
    setSelectedStations(normalizedIds);

    const newSelectedLines = addStationsToLines(initSelectedLinesData, normalizedIds, stationToDetailMap);
    setSelectedLines(newSelectedLines);
  };

  // initialize dictionaries: lineToStationsMapping, stationToDetailMapping, states: selectedStations, selectedLines
  useEffect(() => {
    if (fetchMRTItems) {
      fetchMRTItems(region, locale)
        .then((mrtItems) => {
          setLinesAndStationsConfig(mrtItems);
          const { lineToStations, stationToDetail } = createMRTDictionaries(mrtItems);
          setLineToStationsMapping(lineToStations);
          setStationToDetailMapping(stationToDetail);

          initSelectedLineAndStations(stationToDetail, mrtItems);
          return true;
        })
        .catch(() => setLinesAndStationsConfig([]));
    } else {
      logError(MRTSearchModal.name, 'fetchMRTItems', 'Prop dataResolver need valid function: fetchMRTItems');
    }
  }, []);

  useEffect(() => {
    if (isShown) {
      initSelectedLineAndStations(stationToDetailMapping, linesAndStationsConfig);
      setMobilePage(MOBILE_PAGE.MAP);
    }
  }, [isShown]);

  useEffect(() => {
    if (responsive?.lg || responsive?.xl || responsive?.md) {
      setIsMobileSize(false);
      setDefaultMapPortion(mapPortionDeskTop ?? DEFAULT_MAP_PORTION);
    } else {
      setIsMobileSize(true);
      setDefaultMapPortion(mapPortionMobile ?? DEFAULT_MAP_PORTION);
    }
  }, [responsive]);

  useEffect(() => {
    if (mobilePage === MOBILE_PAGE.LIST && scrollToRef?.current) {
      scrollToRef.current.scrollIntoView(true);
    }
  }, [mobilePage]);

  const onDebounceSearchChange = (query = '') => {
    const uniqStationIds = {};
    const newSearchedLinsAndStations = linesAndStations
      .map((line) => {
        const newStations = line.stations.filter((station) => {
          const isMatched = station.name.toLowerCase().includes(query.toLowerCase());
          if (isMatched && !uniqStationIds[station.id]) {
            // prevent duplicated station in different lines
            uniqStationIds[station.id] = true;
            return true;
          }
          return false;
        });
        return { ...line, stations: newStations };
      })
      .filter((line) => line.stations.length > 0);

    setSearchedLinesAndStations(newSearchedLinsAndStations);
    setSearchQuery(query);
  };

  const handleClearQuery = () => {
    setSearchQuery('');
  };

  const handleStationsSelect = (ids: Array<string>) => {
    let newSelectedStations = [...selectedStations, ...ids];
    newSelectedStations = uniq(newSelectedStations);
    setSelectedStations(newSelectedStations);

    const newSelectedLines = addStationsToLines(selectedLines, ids, stationToDetailMapping);
    setSelectedLines(newSelectedLines);
  };

  const handleStationsUnselect = (ids: Array<string>) => {
    const newSelectedStations = selectedStations.filter((id) => !ids.includes(id));
    setSelectedStations(newSelectedStations);

    const newSelectedLines = { ...selectedLines };

    ids.forEach((stationsId) => {
      const { lines } = stationToDetailMapping[stationsId] || {};
      lines?.forEach((lineId) => {
        newSelectedLines[lineId] = newSelectedLines[lineId].filter((id) => id !== stationsId);
      });
    });
    setSelectedLines(newSelectedLines);
  };

  const handleMapStationClick = (stationId: string, checked: boolean) => () => {
    if (checked) {
      if (context?.ga) {
        Events.emit(EVENT_NAMES.MRT_MODAL.UNSELECT_MRT, context?.ga);
      }
      handleStationsUnselect([stationId]);
    } else {
      if (context?.ga) {
        Events.emit(EVENT_NAMES.MRT_MODAL.SELECT_MRT, context?.ga);
      }
      handleStationsSelect([stationId]);
    }
  };

  const handleLineSelect = (lineId: string, lineChecked: boolean) => {
    if (shouldFilterFutureStation && !lineChecked) {
      const nonFutureStations = lineToStationsMapping[lineId].filter(
        (stationId) => !futureStations?.includes(stationId),
      );
      return handleStationsSelect(nonFutureStations);
    }

    if (context)
      Events.emit(
        lineChecked ? EVENT_NAMES.MRT_MODAL.SELECT_MRT_LINE : EVENT_NAMES.MRT_MODAL.UNSELECT_MRT_LINE,
        context.ga,
      );

    return lineChecked
      ? handleStationsUnselect(lineToStationsMapping[lineId])
      : handleStationsSelect(lineToStationsMapping[lineId]);
  };

  const handleStationCheck = (stationId: string) => (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.checked) {
      if (context?.ga) {
        Events.emit(EVENT_NAMES.MRT_MODAL.SELECT_MRT, context?.ga);
      }
      return handleStationsSelect([stationId]);
    }
    if (context?.ga) {
      Events.emit(EVENT_NAMES.MRT_MODAL.UNSELECT_MRT, context?.ga);
    }
    return handleStationsUnselect([stationId]);
  };

  const handleStationClick = (stationId: string, checked: boolean) => () =>
    checked ? handleStationsUnselect([stationId]) : handleStationsSelect([stationId]);

  const handleFutureLinesToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = Boolean(event?.target?.checked);
    setShowFutureLines(isChecked);
    if (!isChecked && futureStations?.length) {
      handleStationsUnselect(futureStations);
    }
  };

  const handleMobileLineSelect = (lineId: string) => () => {
    setMobilePage(MOBILE_PAGE.LIST);
    setExpandedLine(lineId);
  };

  const handleMobileBack = () => {
    if (MOBILE_PAGE.LIST === mobilePage) {
      setMobilePage(MOBILE_PAGE.MAP);
    } else {
      onClose();
      userHasTypedRef.current = false;
    }
  };

  const handleClear = () => {
    setSelectedStations([]);
    setSelectedLines(initSelectedLines(linesAndStationsConfig));
    if (context?.ga) {
      Events.emit(EVENT_NAMES.MRT_MODAL.CLEAR_SEARCH_BY_MRT, context?.ga);
    }
  };

  const handleApply = () => {
    const stationIds = explodeStaionIds(selectedStations);
    const freetext = selectedStations.map((stationId) => stationToDetailMapping[stationId].name).join(',');
    onApply({ ids: stationIds, freetext });
  };

  const onSearchQueryChange = (query: string) => {
    if (!userHasTypedRef.current && context?.ga) {
      Events.emit(EVENT_NAMES.MRT_MODAL.SEARCH_MRT_MAP, context?.ga);
    }

    userHasTypedRef.current = true;

    setSearchQuery(query);
  };

  const renderLinesAndStations = () =>
    linesAndStations.map((line) => {
      let lineStations = [...line.stations];
      if (shouldFilterFutureStation) {
        lineStations = lineStations.filter((station) => !futureStations?.includes(station.id));
      }
      const isLineChecked = lineStations.length === selectedLines[line.id]?.length;

      return (
        <LineRow
          ref={isMobileSize && line.id === expandLine ? scrollToRef : undefined}
          key={line.id}
          line={line}
          checked={isLineChecked}
          onLineSelect={handleLineSelect}
          onStationCheck={handleStationCheck}
          onStationClick={handleStationClick}
          selectedStations={selectedLines[line.id] || []}
          shouldShowFutureLines={shouldShowFutureLines}
          futureStations={futureStations}
        />
      );
    });

  const renderSearchedStations = () =>
    searchedLinesAndStations.map((line) => (
      <div key={line.id} className="searched-station-group">
        {line.stations.map((station) => (
          <StationRow
            key={station.id}
            station={station}
            onStationCheck={handleStationCheck}
            onStationClick={handleStationClick}
            checked={selectedStations.includes(station.id)}
          />
        ))}
      </div>
    ));

  const renderMobile = () => (
    <>
      {mobilePage === MOBILE_PAGE.MAP ? (
        <div className="mobile-map">
          <Map
            shouldShowFutureLines={shouldShowFutureLines}
            mapSvg={mapSvg}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            defaultMapPortion={defaultMapPortion}
            stationToDetailMapping={stationToDetailMapping}
            selectedStations={selectedStations}
            onMapStationClick={handleMapStationClick}
          />
          <div className="elevated-notch">
            <SearchBar
              searchQuery=""
              searchPlaceholder={searchPlaceholder}
              onDebounceSearchChange={onDebounceSearchChange}
              onClearQuery={handleClearQuery}
              onFocus={() => {
                handleMobileLineSelect(linesAndStations[0].id.toString())();
              }}
              onQueryChange={onSearchQueryChange}
            />
            <MobileLinesRow
              lines={linesAndStations}
              selectedLines={selectedLines}
              onMobileLineSelect={handleMobileLineSelect}
            />
          </div>
        </div>
      ) : (
        <div className="mobile-list">
          <SearchBar
            searchQuery={searchQuery}
            searchPlaceholder={searchPlaceholder}
            onDebounceSearchChange={onDebounceSearchChange}
            onClearQuery={handleClearQuery}
            onQueryChange={onSearchQueryChange}
          />
          <Accordion defaultActiveKey={expandLine} className="px-4">
            {searchQuery.length > 0 ? renderSearchedStations() : renderLinesAndStations()}
          </Accordion>
        </div>
      )}
    </>
  );

  const renderDesktop = () => (
    <>
      <div className="sidebar">
        <SearchBar
          searchQuery={searchQuery}
          searchPlaceholder={searchPlaceholder}
          onDebounceSearchChange={onDebounceSearchChange}
          onClearQuery={handleClearQuery}
          onQueryChange={onSearchQueryChange}
        />
        <TitleRow
          sectionTitle={sectionTitle}
          futureConfig={futureConfig}
          onFutureLinesToggle={handleFutureLinesToggle}
          shouldShowFutureLines={shouldShowFutureLines}
          futureLabel={futureLabel}
        />
        <Accordion defaultActiveKey="0">
          {searchQuery.length > 0 ? renderSearchedStations() : renderLinesAndStations()}
        </Accordion>
      </div>
      <div className="vertical-divider" />
      <Map
        shouldShowFutureLines={shouldShowFutureLines}
        mapSvg={mapSvg}
        svgWidth={svgWidth}
        svgHeight={svgHeight}
        defaultMapPortion={defaultMapPortion}
        stationToDetailMapping={stationToDetailMapping}
        selectedStations={selectedStations}
        onMapStationClick={handleMapStationClick}
      />
    </>
  );

  const handleOnClose = useCallback(() => {
    userHasTypedRef.current = false;
    onClose();
  }, [onClose]);

  return (
    <HUIModal
      scrollable
      className={classnames('mrt-search-modal-root', {
        'mrt-search-modal-root--second-level': mobilePage === MOBILE_PAGE.LIST,
      })}
      show={isShown}
      fullscreen="md-down"
      onHide={handleOnClose}
      onBack={handleMobileBack}
      {...metadata}
    >
      <HUIModal.Header className="mrt-search-modal-header" backButton={isMobileSize} closeButton={!isMobileSize}>
        <span className="modal-title-current">{mobilePage === MOBILE_PAGE.LIST ? sectionTitle : modalTitle}</span>
        <span className="modal-title-prev">{mobilePage === MOBILE_PAGE.LIST ? modalTitle : sectionTitle}</span>
      </HUIModal.Header>
      <HUIModal.Body className="p-0 mrt-search-modal-body">
        <div className="mrt-search-content">{isMobileSize ? renderMobile() : renderDesktop()}</div>
      </HUIModal.Body>
      <HUIModal.Footer className="mrt-search-modal-footer button-container">
        <Actionable
          className="clear flex-grow-1 flex-sm-grow-0"
          disabled={selectedStations.length === 0}
          onClick={handleClear}
          variant="secondary"
        >
          {clearText}
        </Actionable>
        <Actionable className="flex-grow-1 flex-sm-grow-0" onClick={handleApply}>
          {applyText}
        </Actionable>
      </HUIModal.Footer>
    </HUIModal>
  );
};

MRTSearchModal.displayName = 'MRTSearchModal';

export default MRTSearchModal;
