import React from 'react';

import './hui-ticker-text.scss';
import { HUITickerTextProps } from './types';

const HUITickerText: React.FC<HUITickerTextProps> = (props) => {
  const { prefix, changeables } = props;

  return (
    <div className="hui-ticker-text-root">
      <div className="hui-ticker-text-root__container">
        <p className="hui-ticker-text-root__container__text">{prefix}</p>
        <ul className="hui-ticker-text-root__container__list">
          {changeables.map((changeable, index) => (
            <li className="hui-ticker-text-root__container__list__item" key={`${changeable}-${index}`}>
              {changeable}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

HUITickerText.displayName = 'HUITickerText';

export default HUITickerText;
