import classnames from 'classnames';
import { FC, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';

import { SearchInput } from 'root/widgets/common-components/search-input';

import { LinkStack } from 'root/widgets/global-components/footer/sub-components/link-stack';
import { SvgIcon } from 'root/widgets/svg-icon';

import './contact.scss';
import { ContactProps } from './types';

const Contact: FC<ContactProps> = ({ contactSegmentData, countrySelectSegmentData, commonData, className }) => {
  const [searchText, setSearchText] = useState('');

  const onSubmitHandler = () => contactSegmentData.searchItems.onSubmit(searchText);
  return (
    <div className={classnames('contact-block-root', className)}>
      <div className="left-pane">
        {contactSegmentData.contactLinks.map((link, index) => (
          <div key={`contact-${index}`} className="parent-column">
            <LinkStack links={link} viewMoreLabel={commonData.viewMoreLabel} />
          </div>
        ))}
      </div>
      <div className="right-pane contact-interaction">
        <Form className="dropdown-root">
          <Form.Label bsPrefix="region-selector-dropdown-label">{countrySelectSegmentData.label}</Form.Label>
          <Form.Group className="mb-3">
            <Dropdown bsPrefix="region-selector-dropdown">
              <Dropdown.Toggle bsPrefix="region-selector-dropdown-button" variant="form-select">
                <div className="region-selector-dropdown-button__text">
                  <SvgIcon src={countrySelectSegmentData.current.image} shouldUseImage />
                  <div className="dropdown-button-text">{countrySelectSegmentData.current.text}</div>
                </div>
                <i className="pgicon-arrow-down" />
              </Dropdown.Toggle>
              <Dropdown.Menu bsPrefix="region-selector-dropdown-menu">
                {countrySelectSegmentData.links.map((link) => (
                  <Dropdown.Item
                    bsPrefix="region-selector-dropdown-item"
                    key={`link-${link.text}`}
                    target={link.target}
                    href={link.href}
                    rel={link.rel}
                  >
                    <SvgIcon src={link.image} shouldUseImage />
                    <div className="dropdown-button-text">{link.text}</div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
        <SearchInput
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitHandler();
          }}
        >
          <SearchInput.Editable
            placeholder={contactSegmentData.searchItems.placeholder}
            value={searchText}
            onChange={setSearchText}
          />
          <SearchInput.Peripheral>
            <i className="pgicon-search" onClick={onSubmitHandler} />
          </SearchInput.Peripheral>
        </SearchInput>
      </div>
    </div>
  );
};

export default Contact;
