import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { isMobile } from 'root/widgets/utils/validation';

import { DrilldownMenu } from 'root/widgets/drilldown-menu';

import './feedback-reasons-view.scss';
import { ListingFeedbackReasonsProps } from './types';
import { HUIBottomSheet } from 'root/widgets/common-components/hui-bottom-sheet';

const ListingFeedbackReasons: FC<ListingFeedbackReasonsProps> = ({
  drillDownMenuProps,
}: ListingFeedbackReasonsProps) => {
  const { title, data, onSave, onSubmenuBack, onClose, isOpen, ...restDrilldownProps } = drillDownMenuProps;

  const renderDrilldownMenu = (props?) => (
    <DrilldownMenu
      title={title}
      isOpen={isOpen}
      data={data}
      onSave={onSave}
      onSubmenuBack={onSubmenuBack}
      onClose={onClose}
      {...restDrilldownProps}
      {...props}
    />
  );

  if (isMobile()) {
    return (
      <HUIBottomSheet onClose={onClose} shouldShow={isOpen} className="reasons-bottomsheet-root">
        {renderDrilldownMenu({ className: 'bottomsheet-drilldown-popup' })}
      </HUIBottomSheet>
    );
  }

  return (
    <Modal className="reasons-popover-modal-root" show={isOpen} onHide={onClose} centered>
      {renderDrilldownMenu()}
    </Modal>
  );
};

export default ListingFeedbackReasons;
