import classNames from 'classnames';
import { FC } from 'react';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import { HIVE_STATIC_ICON_PATH_V3 } from 'root/widgets/constants';
import { SvgIcon } from 'root/widgets/svg-icon';
import './share-profile-card.scss';
import { ShareProfileCardProps } from './types';
import { useComponentInView } from 'root/widgets/common-components/hooks';
import Actionable from 'root/widgets/common-components/actionable';
import { HUIProgress } from 'root/widgets/common-components/hui-progress';
import { Divider } from 'root/widgets/common-components/divider';
import { ToggleSwitch } from 'root/widgets/common-components/toggle-switch';

const DEFAULT_INITIAL_PROGRESS = 2;

const ShareProfileCard: FC<ShareProfileCardProps> = (props) => {
  const {
    className,
    metadata,
    context,
    heading,
    description,
    progress,
    editAction,
    buttonCTA,
    profileSharing,
    additionalEvents,
  } = props;

  const componentRef = useComponentInView(additionalEvents?.IN_VIEWPORT, context);

  const handleEditClick = () => {
    editAction?.onClick?.();
    emit(EVENT_NAMES.SHARE_PROFILE.EDIT_ON_CLICK, context);
  };

  const handleButtonCTAClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    buttonCTA?.onClick?.(event);
    emit(EVENT_NAMES.COMPLETE_PREFERENCES_NUDGE.BUTTON_CTA_ON_CLICK, context);
  };

  const handleIsProfileSharedChange = () => {
    profileSharing?.onChange?.(!profileSharing.isToggled);

    emit(EVENT_NAMES.SHARE_PROFILE.SHARE_ON_TOGGLE, { ...context, isShareProfileToggled: !profileSharing?.isToggled });
  };

  return (
    <div
      className={classNames('share-profile-card-root', className)}
      ref={componentRef}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <div className="header-group">
        <div className="heading">{heading}</div>

        {editAction && (
          <Actionable
            className="profile-edit-btn"
            variant="link primary"
            onClick={handleEditClick}
            data-automation-id="edit-lnk"
          >
            <SvgIcon src={`${HIVE_STATIC_ICON_PATH_V3}/edit-pencil-o.svg`} height={24} width={24} shouldUseImage />
            {editAction?.text}
          </Actionable>
        )}
      </div>

      <div className="description">{description}</div>

      <div className="completion-progress">
        <HUIProgress
          variant="success"
          percentComplete={progress.percentage === 0 ? DEFAULT_INITIAL_PROGRESS : progress.percentage}
        />{' '}
        <div>
          {progress.percentage}% {progress.text}
        </div>
      </div>

      {profileSharing?.title && (
        <>
          <Divider />

          <div className="profile-sharing-toggle">
            <div className="toggle-group">
              <div className="toggle-label">{profileSharing.title}</div>
              <ToggleSwitch
                isToggled={profileSharing.isToggled}
                onChange={handleIsProfileSharedChange}
                metadata={{ 'da-id': 'share-profie-toggle' }}
              />
            </div>
          </div>
        </>
      )}

      {buttonCTA && (
        <Actionable da-id="complete-nudge-btn" className="bottom-cta" {...buttonCTA} onClick={handleButtonCTAClick} />
      )}
    </div>
  );
};

ShareProfileCard.displayName = 'ShareProfileCard';

export default ShareProfileCard;
