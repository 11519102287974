import { ObjectType, PGLoginParamType } from 'root/widgets/pg-login/types';

const request = async (
  url: string,
  params: PGLoginParamType,
  data?: unknown,
  headers: HeadersInit = {},
  method = 'GET',
): Promise<ObjectType> => {
  const fetchConfig = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'x-hui-widget': 'pglogin',
      ...headers,
    },
  };

  if (params) {
    const queryParams = new URLSearchParams(params);
    url = `${url}?${queryParams.toString()}`;
  }

  if (data) {
    fetchConfig['body'] = JSON.stringify(data);
  }

  const response = await fetch(url, fetchConfig);
  const responseData = (await response.json()) as Promise<ObjectType>;

  if (response.ok) {
    return responseData as ObjectType;
  }

  throw { ...(await responseData), statusCode: response.status };
};

const get = (url: string, params: PGLoginParamType, headers?: HeadersInit): Promise<any> =>
  request(url, params, headers);

const post = (url: string, data: unknown, headers?: HeadersInit, params?: PGLoginParamType) =>
  request(url, params, data, headers, 'POST');

export { get, post };
