import { RequestMethod } from 'root/global-wrappers/config/http';
import { request } from 'root/global-wrappers/services/http';
import { KevelTrackingType } from 'root/global-wrappers/types/analytics';
import { logFrontendException } from 'root/global-wrappers/utils/logging';

const name = 'global-wrappers.services.analytics.kevel-tracking';

const trackEvent = ({ trackUrl: url, ...data }: KevelTrackingType) => {
  try {
    request({
      url,
      method: RequestMethod.POST,
      options: { data },
    });
  } catch (error) {
    logFrontendException(name, trackEvent.name, error);
  }
};

export default { trackEvent };
