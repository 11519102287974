import React, { useContext, useMemo } from 'react';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';
import { RecentSearches } from 'root/widgets/search-with-filter/sub-components/recent-search';
import { SearchOptions } from 'root/widgets/search-with-filter/sub-components/search-options';
import './search-accessory.scss';
import type { SearchAccessoryProps } from './types';
import classNames from 'classnames';
import { getPaddingStatus } from 'root/widgets/search-with-filter/utils';
import { FoundLocationsView } from 'root/widgets/common-components/found-locations-view';

const SearchAccessory: React.FC<SearchAccessoryProps> = ({ data, onSelectEvents, className }) => {
  const { foundLocations, searchOptions, recentSearches, searchKeyword, recentSearchFiltersConfig } = data;
  const context = useContext(SearchWithFilterContext);
  const { dataAutomationId } = context;
  /**
   * here the indexes created for typeahead. Under the hood typeahead use `position` as index to track
   * "active" item, therefore, we can navigate/select by keyborad and enable menu scrolling
   * position doc (https://github.com/ericgio/react-bootstrap-typeahead/blob/main/docs/API.md#menuitem)
   */
  const { locationItemIndexes, searchOptionIndexes, recentSearchIndexes } = useMemo(() => {
    const locationIdxs: Array<number> = Array.from({ length: foundLocations.length }, (_, idx) => idx);
    const optionIdxs: Array<number> = Array.from(
      { length: searchOptions.length },
      (_, idx) => idx + locationIdxs.length,
    );
    const recentIdxs: Array<number> = Array.from(
      { length: recentSearches.items.length },
      (_, idx) => idx + locationIdxs.length + optionIdxs.length,
    );
    return {
      locationItemIndexes: locationIdxs,
      searchOptionIndexes: optionIdxs,
      recentSearchIndexes: recentIdxs,
    };
  }, [foundLocations, searchOptions, recentSearches.items]);

  const shouldShowRecentSearches = searchKeyword.length === 0 && recentSearches.items.length > 0;

  const shouldHidePadding = getPaddingStatus(
    foundLocations.length,
    searchOptions.length,
    recentSearches.items.length,
    searchKeyword.length,
  );

  return (
    <div
      className={classNames('search-accessory-root', shouldHidePadding && 'search-accessory--no-padding', className)}
    >
      {foundLocations.length > 0 && (
        <FoundLocationsView
          searchKeyword={searchKeyword}
          positions={locationItemIndexes}
          foundLocations={foundLocations}
          onLocationSelect={onSelectEvents?.onLocationSelect}
          metadata={{
            dataAutomationId: dataAutomationId?.foundLocationsView || '',
          }}
        />
      )}
      {foundLocations.length > 0 && (searchOptions.length > 0 || shouldShowRecentSearches) && (
        <div className="search-accessory__separator" />
      )}
      {searchOptions.length > 0 && (
        <SearchOptions
          searchOptions={searchOptions}
          positions={searchOptionIndexes}
          onOptionSelect={onSelectEvents?.onOptionSelect}
          metadata={{
            dataAutomationId: dataAutomationId?.searchOptions || '',
          }}
        />
      )}
      {shouldShowRecentSearches && (
        <>
          {searchOptions.length > 0 && <div className="search-accessory__separator" />}
          <RecentSearches
            title={recentSearches.title}
            positions={recentSearchIndexes}
            items={recentSearches.items}
            recentSearchFiltersConfig={recentSearchFiltersConfig}
            onSearchSelect={onSelectEvents?.onSearchSelect}
            metadata={{
              dataAutomationId: dataAutomationId?.recentSearches || 'recent-searches',
            }}
          />
        </>
      )}
    </div>
  );
};

SearchAccessory.displayName = 'SearchAccessory';

export default SearchAccessory;
