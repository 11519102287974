import { HIVE_CORE_URL } from 'root/widgets/constants';
import { Variant } from 'react-bootstrap/esm/types';

export const ALERT_DISMISS_TIME_IN_MILLISECONDS = 5000;

export const DEFAULT_ALERT_ICONS: Record<Variant, string> = {
  warning: `${HIVE_CORE_URL}/icons/svgs/bubble-7-o.svg`,
  danger: `${HIVE_CORE_URL}/icons/svgs/error-o.svg`,
  success: `${HIVE_CORE_URL}/icons/svgs/check-radio-o.svg`,
  info: `${HIVE_CORE_URL}/icons/svgs/info-circle-o.svg`,
  dark: `${HIVE_CORE_URL}/icons/svgs/info-circle-o.svg`,
  light: `${HIVE_CORE_URL}/icons/svgs/info-circle-o.svg`,
};
