import { AuthGrantType } from 'root/widgets/pg-login/constants';
import type {
  AuthTokenProps,
  ConsentChecksProps,
  GenerateOTPResponseType,
  UserExistenceResponseType,
  UserInfoLoggedInData,
} from 'root/widgets/pg-login/types';

import BaseService from './base';
import { get, post } from './http';

class BffService extends BaseService {
  requestResetPassword = (email: string) => post(`${this.host}${this.endpoints.resetPassword}`, { email });

  checkUserExistence = (email: string) =>
    get(`${this.host}${this.endpoints.userExists}`, { email }) as Promise<UserExistenceResponseType>;

  generateOTP = (pgutid: string, email: string) =>
    post(`${this.host}${this.endpoints.generateOtp}`, {
      id: pgutid,
      recipient: email,
    }) as Promise<GenerateOTPResponseType>;

  loginWithOTP = (email: string, otpId: string, otpToken: string, consentChecks: ConsentChecksProps = {}) => {
    const data = {
      username: email,
    };
    data[this.shouldUseProxy ? 'grantType' : 'grant_type'] = AuthGrantType.OTP_LOGIN;
    data[this.shouldUseProxy ? 'otpId' : 'otp_id'] = otpId;
    data[this.shouldUseProxy ? 'otpToken' : 'otp_token'] = otpToken;

    return post(`${this.host}${this.endpoints.login}`, {
      ...data,
      ...consentChecks,
    }) as Promise<UserInfoLoggedInData>;
  };

  loginWithPassword = (email: string, password: string, consentChecks: ConsentChecksProps = {}) => {
    const data = {
      username: email,
      password,
    };
    data[this.shouldUseProxy ? 'grantType' : 'grant_type'] = AuthGrantType.PASSWORD_LOGIN;

    return post(`${this.host}${this.endpoints.login}`, { ...data, ...consentChecks }) as Promise<UserInfoLoggedInData>;
  };

  setPassword = (token: AuthTokenProps, password: string, userApiId?: string) =>
    post(`${this.host}${this.endpoints.setPassword}`, {
      ...token,
      password,
      userApiId,
    });
}

export default BffService;
