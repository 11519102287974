import { createContext } from 'react';

import { VARIANTS } from './constants';
import { initialState } from './reducer';

const LocationFilterModalContext = createContext(VARIANTS.SINGLE_LEVEL);
export const LocationStoreContext = createContext<{
  state: any;
  dataAutomationId?: string;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export default LocationFilterModalContext;
