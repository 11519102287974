import classnames from 'classnames';
import React from 'react';
import ReactPhoneInputModule from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './hui-phone-input.scss';
import { HUIPhoneInputProps } from './types';

/* Fix ref -  https://github.com/vitejs/vite/issues/2139#issuecomment-1270617398 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PhoneInput = ReactPhoneInputModule.default || ReactPhoneInputModule;

const HUIPhoneInput: React.FC<HUIPhoneInputProps> = ({
  isDisabled,
  onCountryChange,
  onNumberChange,
  className,
  metadata,
  ...props
}) => {
  const handleChange = (
    _value: string,
    data: { countryCode: string; dialCode: string },
    _event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => {
    const [code, ...rest] = formattedValue.split(' ');
    onCountryChange?.(data.countryCode, data.dialCode);
    onNumberChange?.(`${code}${rest.join('').split('-').join('')}`);
  };

  return (
    <fieldset className={classnames('hui-phone-input-root', className)} disabled={isDisabled} {...metadata}>
      <PhoneInput
        disableDropdown={isDisabled}
        countryCodeEditable={false}
        onChange={handleChange}
        inputClass="form-control"
        {...props}
      />
    </fieldset>
  );
};

export default HUIPhoneInput;
