import classNames from 'classnames';
import { FC } from 'react';
import Actionable from 'root/widgets/common-components/actionable';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './hui-badges.scss';
import { HUIBadgesProps } from './types';

const HUIBadges: FC<HUIBadgesProps> = (props) => {
  const { className, metadata, label, badges, selected, onClick } = props;

  if (!badges || badges.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames('hui-badges-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <p className="hui-badges-label">{label}</p>
      <div className="badges-group">
        {badges.map((badge) => (
          <Actionable
            key={badge.key}
            className={classNames({ selected: selected === badge.key })}
            onClick={onClick}
            data-key={badge.key}
            variant={badge.variant || 'secondary'}
          >
            {badge.value}
          </Actionable>
        ))}
      </div>
    </div>
  );
};

HUIBadges.displayName = 'HUIBadges';

export default HUIBadges;
