import { LOCALE, MARKETPLACE, REGION } from 'root/widgets/constants';
import { PGLoginTranslationConfigData } from 'root/widgets/pg-login/types';

import myEnTranslation from './my/locales/en';
import myMsTranslation from './my/locales/ms';
import myIPPEnTranslation from './my-ipp/locales/en';
import myIPPMsTranslation from './my-ipp/locales/ms';
import sgCgEnTranslation from './sg-cg/locales/en';
import sgEnTranslation from './sg/locales/en';
import thEnTranslation from './th/locales/en';
import thThTranslation from './th/locales/th';

const config: { [key: string]: PGLoginTranslationConfigData } = {
  [`${REGION.SG}-${MARKETPLACE.PG}`]: {
    [LOCALE.EN]: sgEnTranslation,
  },
  [`${REGION.SG}-${MARKETPLACE.CG}`]: {
    [LOCALE.EN]: sgCgEnTranslation,
  },
  [`${REGION.MY}-${MARKETPLACE.PG}`]: {
    [LOCALE.EN]: myEnTranslation,
    [LOCALE.MS]: myMsTranslation,
  },
  [`${REGION.MY}-${MARKETPLACE.IPP}`]: {
    [LOCALE.EN]: myIPPEnTranslation,
    [LOCALE.MS]: myIPPMsTranslation,
  },
  [`${REGION.TH}-${MARKETPLACE.PG}`]: {
    [LOCALE.EN]: thEnTranslation,
    [LOCALE.TH]: thThTranslation,
  },
};

export const getTranslation = (marketplaceKey, locale) => config[marketplaceKey][locale];

export default config;
