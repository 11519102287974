/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';

import { MULTI_VARIANT_LEVELS } from 'root/widgets/location-filter-modal/constants';
import { LocationStoreContext } from 'root/widgets/location-filter-modal/context';
import SearchableInput from 'root/widgets/location-filter-modal/sub-components/body/search-section/search-section';
import { BodyProps } from 'root/widgets/location-filter-modal/types';
import { withTextMatch } from 'root/widgets/utils/filter';

import ExpandableList from './expandable-list';
import './multi-level-variant.scss';
import Wrapper from 'root/widgets/common-components/wrapper';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const FirstLevelItemsView: React.FC<any> = (props) => {
  const { state, items, handleItemSelect } = props;
  const { dataAutomationId } = useContext(LocationStoreContext);

  return (
    <Wrapper
      className={classnames('filter-modal-content', { 'move-left': state.level === MULTI_VARIANT_LEVELS.SECOND })}
    >
      <ListGroup>
        {items.map((item, index) => (
          <ListGroup.Item
            key={item.code}
            onClick={() => handleItemSelect(item)}
            {...extractDataAutomationId({
              dataAutomationId: dataAutomationId ? `${dataAutomationId}-dbl-${index}` : '',
            })}
          >
            {item.value}
            <i className="pgicon-arrow-right" />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Wrapper>
  );
};

const SecondLevelItemsView: React.FC<any> = ({ state, items, thirdLevelCodeType }) => (
  <Wrapper className={classnames('filter-modal-content', { 'move-right': state.level === MULTI_VARIANT_LEVELS.FIRST })}>
    <ListGroup>
      {items?.map((item) => <ExpandableList key={item.code} item={item} thirdLevelCodeType={thirdLevelCodeType} />)}
    </ListGroup>
  </Wrapper>
);

const SelectAllInput: React.FC<any> = (props) => {
  const { selectAllText, isSelectedAll, searchText, selectAll } = props;
  const { dataAutomationId } = useContext(LocationStoreContext);

  return (
    <Form.Group>
      <div className="clickable" onClick={selectAll}>
        <Form.Check
          className={searchText === '' ? '' : 'hidden'}
          key={selectAllText}
          type="radio"
          onChange={selectAll}
          label={selectAllText}
          checked={isSelectedAll}
          onClick={selectAll}
          {...extractDataAutomationId({
            dataAutomationId: dataAutomationId ? `${dataAutomationId}-select-all-chk` : '',
          })}
        />
      </div>
    </Form.Group>
  );
};

const MultiLevelVariant: React.FC<BodyProps> = ({ data, items }) => {
  const { selectAllText, firstLevelCodeType, secondLevelCodeType, thirdLevelCodeType, searchPlaceholder } = data;
  const [searchText, setSearchText] = useState('');
  const { state, dataAutomationId, dispatch } = useContext(LocationStoreContext);
  const secondCodeType = secondLevelCodeType as string;
  const isSelectedAllSecondLevel = !state.selectedCodes[secondCodeType];

  useEffect(() => {
    setSearchText('');
  }, [state.level]);

  const handleItemSelect = (item) => {
    dispatch({ type: 'level', value: MULTI_VARIANT_LEVELS.SECOND });
    dispatch({ type: 'selectedItem', item });
    dispatch({ type: 'setSecondLevelItems', secondLevelItems: item.items });
  };

  const matchedItemList = withTextMatch(items, searchText, ['value']);
  const matchedItemListNested = withTextMatch(state.secondLevelItems || [], searchText, ['value']);

  const selectAll = () => {
    dispatch({
      type: 'keepFirstLevel',
      firstLevelCodeType,
    });
  };

  return (
    <div className="multi-level-variant-root">
      <Wrapper className="filter-modal-search-section">
        <SearchableInput
          value={searchText}
          placeholder={searchPlaceholder}
          onChange={setSearchText}
          onSearchTextChange={setSearchText}
        />
        {state?.level === MULTI_VARIANT_LEVELS.SECOND && (
          <SelectAllInput
            isSelectedAll={isSelectedAllSecondLevel}
            selectAllText={selectAllText}
            selectAll={selectAll}
            searchText={searchText}
          />
        )}
      </Wrapper>
      <Wrapper className="multi-level-body-section">
        <FirstLevelItemsView state={state} items={matchedItemList} handleItemSelect={handleItemSelect} />
        <SecondLevelItemsView state={state} items={matchedItemListNested} thirdLevelCodeType={thirdLevelCodeType} />
      </Wrapper>
    </div>
  );
};

MultiLevelVariant.displayName = 'MultiLevelVariant';

export default MultiLevelVariant;
