import classNames from 'classnames';
import React from 'react';

import { ListingInfoProps } from 'root/widgets/common-components/types';

import { HIVE_STATIC_VERSION } from 'root/widgets/constants';
import Title from 'root/widgets/common-components/title';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const ListingInfo: React.FC<ListingInfoProps> = ({ image, title, price, availableData, rooms, area, financeLink }) => {
  const defaultImage = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/images/default-property-card.svg`;

  return (
    <div className="listing-info-root">
      <img src={image || defaultImage} alt={title} />
      <div className="info-wrapper">
        {title && <Title headerTag="h4">{title}</Title>}
        <div className={classNames('info-price-availability', { 'mobile-column': financeLink })}>
          {price && <Title headerTag="h5">{price} </Title>}
          {price && availableData && <span className="list-type-icon" />}
          {availableData && <Title headerTag="h5">{availableData}</Title>}
          {financeLink && (
            <ActionableLink
              className="finance-ipa-link"
              variant="link primary"
              target="_blank"
              rel="noopener noreferrer"
              href={financeLink.href}
              onClick={financeLink.onClick}
            >
              {financeLink.text}
            </ActionableLink>
          )}
        </div>
      </div>
      <div className="info-wrapper md-full">
        <p>
          {rooms?.beds && (
            <span>
              {rooms.beds} <i className="pgicon pgicon-bedroom" />
            </span>
          )}
          {rooms?.baths && (
            <span>
              {rooms.baths} <i className="pgicon pgicon-bathroom" />
            </span>
          )}
          {area?.floor && (
            <>
              <span className="list-type-icon" />
              <span>{area.floor}</span>
            </>
          )}
          {area?.pricePerArea && (
            <>
              <span className="list-type-icon" />
              <span>{area.pricePerArea}</span>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default ListingInfo;
