import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';

import { INPUT_TYPES } from './constants';
import './generic-input-group.scss';
import InputAcceptanceRules from './sub-components/input-acceptance-rules';
import InputIcon from './sub-components/input-icon';
import { GenericInputGroupProps } from './types';

const GenericInputGroup: React.FC<GenericInputGroupProps> = ({
  isDisabled,
  button,
  input,
  inputError,
  onSubmit,
  children,
}) => {
  const { checkValidity, acceptanceRules, metadata, ...inputProps } = input;

  const [inputValue, setInputValue] = useState(String(input.value || ''));
  const [isInputFocused, setInputFocused] = useState(false);
  const [error, setError] = useState('');
  const [isPasswordRevealed, setPasswordRevealed] = useState(false);
  const isPasswordInput = input.type === INPUT_TYPES.PASSWORD;
  const inputType = isPasswordInput && isPasswordRevealed ? INPUT_TYPES.TEXT : input.type;

  const handleInputChange = (evt) => {
    if (inputProps.onChange) {
      inputProps.onChange(evt);
    }
    setInputValue(evt.target.value);
    const { isInvalid } = checkValidity(evt.target.value);
    if (!isInvalid && error) {
      setError('');
    }
  };

  const handleIconClick = () => {
    setPasswordRevealed(!isPasswordRevealed);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const { isInvalid: isInputInvalid, error: errorText } = checkValidity(inputValue);
    if (isInputInvalid) {
      setError(errorText);
    } else {
      setError('');
      onSubmit(inputValue);
    }
  };

  const handleInputFocus = (event) => {
    setInputFocused(true);
    inputProps?.onFocus?.(event);
  };

  const handleInputBlur = (event) => {
    setInputFocused(false);
    inputProps?.onBlur?.(event);
  };

  const isInputAcceptable = () => acceptanceRules && !acceptanceRules.every((rule) => rule.isAcceptable(inputValue));

  useEffect(() => {
    setInputValue(String(input.value || ''));
  }, [inputType]);

  useEffect(() => {
    setError(inputError || '');
  }, [inputError, inputType]);

  return (
    <Form onSubmit={handleSubmit} className="generic-input-group-root">
      <Form.Group className={classnames({ error: error }, 'form-group')}>
        <InputIcon isPasswordInput={isPasswordInput} isPasswordShown={isPasswordRevealed} onClick={handleIconClick} />
        <InputGroup className={classnames({ 'input-focus': isInputFocused })}>
          {input.prefix && <InputGroup.Text>{input.prefix}</InputGroup.Text>}
          <Form.Control
            {...inputProps}
            disabled={isDisabled}
            className={classnames('generic-input', inputProps.className)}
            type={inputType}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            value={inputValue}
            {...metadata}
          />
        </InputGroup>
        {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
      </Form.Group>
      {children}
      {acceptanceRules && <InputAcceptanceRules acceptanceRules={acceptanceRules} inputValue={inputValue} />}
      <Actionable
        className="generic-input-btn"
        variant="primary"
        type="submit"
        disabled={isDisabled || button.disabled || inputValue.length === 0 || isInputAcceptable()}
        {...button.metadata}
      >
        {button.text}
      </Actionable>
    </Form>
  );
};

export default GenericInputGroup;
