export const handleOnChange = (selectedType, bedroomPreference, setBedroomPreference) => () => {
  const updatedBedroomTypes = [...bedroomPreference.selected];
  const index = updatedBedroomTypes.indexOf(selectedType);
  if (index > -1) {
    updatedBedroomTypes.splice(index, 1);
  } else {
    updatedBedroomTypes.push(selectedType);
  }
  setBedroomPreference({
    ...bedroomPreference,
    selected: updatedBedroomTypes,
  });
};

export const getSelectedBedrooms = (selectedBedrooms, bedroomConfig, shouldShowRentCheckboxes) => {
  const { items, checkboxItems, allSelectedTitle } = bedroomConfig || {};

  const bedroomOptions = { ...items };

  /**
   * `shouldShowRentCheckboxes` would be true when we select `Rent` and with config `checkboxItems` in that case
   * all options are selected will be considered if all unit room options as well as room
   */
  if (shouldShowRentCheckboxes) {
    const rentRoomConfig = checkboxItems[1];
    bedroomOptions[rentRoomConfig.searchKey] = rentRoomConfig.value;
  }

  if (selectedBedrooms.length === Object.keys(bedroomOptions).length) {
    return allSelectedTitle;
  }

  if (shouldShowRentCheckboxes) {
    const rentRoomSearchKey = checkboxItems[1].searchKey;
    const isRoomSelected = selectedBedrooms.includes(rentRoomSearchKey);
    // case of 'entire unit'
    if (Object.keys(bedroomOptions).length - selectedBedrooms.length === 1 && !isRoomSelected) {
      return checkboxItems[0].value;
    }
    if (selectedBedrooms.length === 1 && isRoomSelected) {
      // case of 'room'
      return checkboxItems[1].value;
    }
  }

  selectedBedrooms.sort((a, b) => a - b);

  return selectedBedrooms.map((selection) => bedroomOptions[selection]).join(',');
};

export default { handleOnChange, getSelectedBedrooms };
