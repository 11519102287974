import classnames from 'classnames';
import uniq from 'lodash/uniq';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { SearchCategory } from 'root/widgets/constants';
import SearchWithFilterContext from 'root/widgets/search-with-filter/context';
import type { BaseProps } from 'root/widgets/types';

import './bedroom-filter.scss';
import { getSelectedBedrooms, handleOnChange } from './helper';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Typography } from 'root/common/atom-elements/typography';
import { Filter } from 'root/widgets/common-components/filter';

const BedroomFilter: React.FC<BaseProps> = ({ className }) => {
  const context = useContext(SearchWithFilterContext);
  const {
    setFilters,
    filters: selectedFilters,
    data: { filters },
    category,
  } = context;
  const bedroom = filters['bedroom'];

  const selectedBedrooms = selectedFilters?.bedroom?.selected?.[bedroom?.codeType] ?? [];

  const [bedroomPreference, setBedroomPreference] = useState<{
    selected: Array<string>;
  }>({
    selected: selectedBedrooms,
  });

  const filterDropdownRef = useRef<HTMLInputElement>(null);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const checkboxItems = bedroom?.checkboxItems;
  const shouldShowRentCheckboxes = category === SearchCategory.RENT && checkboxItems;
  const selectedText = useMemo(
    () => getSelectedBedrooms(selectedBedrooms, bedroom, shouldShowRentCheckboxes),
    [selectedBedrooms, shouldShowRentCheckboxes],
  );

  const bedroomOptions = useMemo(() => Object.keys(bedroom?.items ?? {}), [bedroom?.items]);

  useEffect(() => {
    if (checkboxItems && category !== SearchCategory.RENT) {
      const roomConfig = checkboxItems[1];
      const updatedSelected = bedroomPreference.selected.filter((option) => option !== roomConfig.searchKey);

      const selectedBeds = {};
      selectedBeds[bedroom?.codeType] = updatedSelected;
      setFilters({
        ...selectedFilters,
        bedroom: {
          selected: selectedBeds,
        },
      });
    }
  }, [category, checkboxItems]);

  const onClearCaptured = () => {
    setBedroomPreference({
      ...bedroomPreference,
      selected: [],
    });
  };

  const onApplyCaptured = () => {
    filterDropdownRef?.current?.click(); // closing the filter dropdown when applied successfully

    setBedroomPreference({
      ...bedroomPreference,
      selected: bedroomPreference.selected,
    });
    const selectedBeds = {};
    selectedBeds[bedroom?.codeType] = bedroomPreference.selected ?? [];
    setFilters({
      ...selectedFilters,
      bedroom: {
        selected: selectedBeds,
      },
    });
  };

  const handleToggleDropdown = (isOpen) => {
    if (isOpen) {
      setBedroomPreference({
        ...bedroomPreference,
        selected: selectedBedrooms,
      });
    }
  };

  const isEntireUnitSelected = useMemo(() => {
    let unselectedOptions = [...bedroomOptions];
    unselectedOptions = unselectedOptions.filter((option) => !bedroomPreference.selected.includes(option));

    return unselectedOptions.length === 0;
  }, [bedroomPreference.selected, bedroomOptions]);

  const handleEntireUnitSelect = (checked: boolean) => () => {
    let selected = [...bedroomPreference.selected];

    selected = checked
      ? selected.filter((option) => !bedroomOptions.includes(option))
      : uniq([...selected, ...bedroomOptions]);

    setBedroomPreference({
      ...bedroomPreference,
      selected,
    });
  };

  useEffect(() => {
    let unselectedOptions = [...bedroomOptions];
    unselectedOptions = unselectedOptions.filter((option) => !bedroomPreference.selected.includes(option));

    setIsIndeterminate(
      bedroomOptions.length > 0 && unselectedOptions.length > 0 && bedroomOptions.length > unselectedOptions.length,
    );
  }, [bedroomPreference]);

  return (
    bedroom && (
      <div className={classnames('bedroom-filter-root', className)}>
        <Filter
          dropdownTitle={bedroom.dropdownTitle}
          selectedValueStr={selectedText}
          toggleBtnText={bedroom.toggleBtnText}
          clearAction={{
            onClick: onClearCaptured,
            isDisabled: bedroomPreference.selected.length === 0,
            text: bedroom.clearText,
          }}
          applyAction={{ onClick: onApplyCaptured, isDisabled: false, text: bedroom.applyText }}
          dropdownRef={filterDropdownRef}
          onToggle={handleToggleDropdown}
        >
          {shouldShowRentCheckboxes && checkboxItems?.[0] && (
            <div className="clickable d-flex gap-2">
              <Checkbox
                indeterminate={isIndeterminate}
                className="entire-unit-checkbox"
                id="entire-unit-checkbox"
                checked={isEntireUnitSelected}
                onChange={handleEntireUnitSelect(isEntireUnitSelected)}
              />
              <Typography htmlFor="entire-unit-checkbox" element="label">
                {checkboxItems[0].value}
              </Typography>
            </div>
          )}
          <ul className="bedroom-select-body my-2">
            {bedroomOptions.map((key) => (
              <li key={key} className="bedroom-item">
                <input
                  type="button"
                  className={classnames('bedroom-type', { checked: bedroomPreference.selected.includes(key) })}
                  name={bedroom?.items[key] ?? ''}
                  value={bedroom?.items[key] ?? ''}
                  onClick={handleOnChange(key, bedroomPreference, setBedroomPreference)}
                />
              </li>
            ))}
          </ul>
          {shouldShowRentCheckboxes && checkboxItems?.[1] && (
            <div className="d-flex gap-2 mb-2">
              <Checkbox
                className="room-checkbox"
                id="room-checkbox"
                checked={bedroomPreference.selected.includes(checkboxItems[1].searchKey)}
                onChange={handleOnChange(checkboxItems[1].searchKey, bedroomPreference, setBedroomPreference)}
              />
              <Typography htmlFor="room-checkbox" element="label">
                {checkboxItems[1].value}
              </Typography>
            </div>
          )}
        </Filter>
      </div>
    )
  );
};

BedroomFilter.displayName = 'BedroomFilter';

export default BedroomFilter;
