/* eslint-disable @typescript-eslint/no-explicit-any */
import classnames from 'classnames';
import { FC, useRef, useState } from 'react';
import { ListGroup } from 'react-bootstrap';

import { SearchInput } from 'root/widgets/common-components/search-input';

import './price-range-filter.scss';
import { PriceRangeItemProps } from './types';
import { isNaN, parseNumber } from 'root/widgets/utils/number';

const PriceRangeItem: FC<PriceRangeItemProps> = ({
  prefix,
  placeholderText,
  labelText,
  data,
  selectedInput,
  setSelected,
  error,
}) => {
  const [isItemsHidden, setItemsHidden] = useState(true);
  const listRef = useRef<HTMLInputElement>(null);
  const handleFocus = () => {
    setItemsHidden(false);
  };
  const handleBlur = (e) => {
    if (listRef?.current && !listRef?.current?.contains(e.relatedTarget)) {
      setItemsHidden(true);
    }
  };

  const handleOnChange = (value) => {
    const re = /^[\d\b]+$/;
    if (value === '' || re.test(value)) {
      setSelected(value); // passing the selected value to its parent
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
  };

  const handleItemClicked = (item) => {
    setSelected(item); // passing the selected value to its parent
    setItemsHidden(true);
  };

  const displayValue = (value) => (!value || isNaN(value) ? '' : String(parseNumber(value)));

  return (
    <div className={classnames('price-range-item-root')}>
      <SearchInput
        onFocus={handleFocus}
        onBlur={handleBlur}
        onSubmit={handleOnSubmit}
        label={labelText}
        className={error ? 'error' : ''}
      >
        <SearchInput.Peripheral>{prefix}</SearchInput.Peripheral>
        <SearchInput.Editable
          value={displayValue(selectedInput)}
          onChange={handleOnChange}
          placeholder={placeholderText}
        />
      </SearchInput>
      <ListGroup ref={listRef} className={isItemsHidden ? '' : 'show'}>
        {data?.map((item) => (
          <ListGroup.Item
            action
            key={item}
            className={item === selectedInput ? 'active' : ''}
            onClick={() => handleItemClicked(item)}
          >
            <>
              {!isNaN(item) && prefix} {item}
            </>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default PriceRangeItem;
