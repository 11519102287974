import classNames from 'classnames';
import React from 'react';
import { Row } from 'react-bootstrap';

import './error-card.scss';
import { ErrorCardBody, ErrorCardButton, ErrorCardImg, ErrorCardTitle } from './sub-components';
import { ErrorCardProps } from './types';

const ErrorCard: React.FC<ErrorCardProps> = ({ children, className, metadata }) => (
  <Row className={classNames('error-card-root', 'text-center', className)} {...metadata}>
    {children}
  </Row>
);

export default Object.assign(ErrorCard, {
  Img: ErrorCardImg,
  Title: ErrorCardTitle,
  Body: ErrorCardBody,
  Button: ErrorCardButton,
});
