/* eslint-disable react/jsx-key */
import classNames from 'classnames';
import React, { useState } from 'react';

import { LINK_STACK } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import ItemStack from 'root/widgets/global-components/footer/sub-components/item-stack/item-stack';

import './link-stack.scss';
import { LinkStackProps } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const LinkStack: React.FC<LinkStackProps> = ({ context, links, viewMoreLabel }) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const [hasMore, setMore] = useState(false);

  const handleAnchorClick = (e) => {
    if (window.innerWidth < LINK_STACK.DISABLE_PARENT_CLICK) {
      setCollapsed(!isCollapsed);
      e.preventDefault();
    } else {
      emit(EVENT_NAMES.GLOBAL_FOOTER.SITEMAP_LINK_ON_CLICK, { ...context, data: e.target.textContent });
    }
  };

  return (
    <div className="link-stack-root">
      <h5>
        <ActionableLink
          target="_blank"
          href={links[0].href}
          className={classNames(`${links.length > 0 ? 'expandable' : ''} `, `${isCollapsed ? '' : 'expand-stack'}`)}
          onClick={handleAnchorClick}
        >
          {links[0].title}
          {links.length > 0 && <i className="pgicon-arrow-down" />}
        </ActionableLink>
      </h5>
      <div className={`link-stack-items ${isCollapsed ? '' : 'expand-stack'}`}>
        {links.slice(1).map((item, index) => (
          <div className="link-stack-item" key={`link-stack-item-${index}`}>
            {index === LINK_STACK.APPLY_VIEW_MORE && !hasMore && (
              <ActionableLink
                variant="link primary"
                onClick={() => {
                  setMore(true);
                }}
                className="view-more-links"
              >
                {viewMoreLabel}
              </ActionableLink>
            )}
            <ItemStack
              item={item}
              context={context}
              className={classNames(
                'expandable-item-stack',
                `${hasMore ? 'expand-stack' : ''}`,
                `${index > LINK_STACK.APPLY_VIEW_MORE - 1 ? 'hide-view-more' : ''}`,
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkStack;
