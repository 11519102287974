import { SCREEN_BREAKPOINTS } from 'root/widgets/constants';
import { validate as uuidValidate } from 'uuid';

export const isValidEmail = (input: string): boolean => {
  const re = /^[\w!#$%&*+./=?^`{|}~’-]+@[\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*$/;
  return re.test(input);
};

export const isInteger = (input: string): boolean => {
  const re = /^-?\d+$/;
  return re.test(input);
};

export const validateDependencies = (dependencies: Record<string, any>, expectedDependencies: Array<string> = []) => {
  expectedDependencies.forEach((expectedDependency) => {
    if (!dependencies[expectedDependency]) {
      throw new Error(`Expected dependency not provided: ${expectedDependency}`);
    }
  });

  return true;
};

export const isBrowser = (): boolean => typeof window !== 'undefined';

export const isMobile = (): boolean => isBrowser() && window.innerWidth < SCREEN_BREAKPOINTS.SM;

export const isTablet = (): boolean => isBrowser() && window.innerWidth < SCREEN_BREAKPOINTS.LG;

export const isDesktop = (): boolean => isBrowser() && window.innerWidth >= SCREEN_BREAKPOINTS.LG;

/**
 * Example of valid pgutid: 56c5937b-0b3d-4b27-9ba3-7fed37db672a
 * @param pgutid
 * @returns
 */
export const isValidPgutid = (pgutid: string) => uuidValidate(pgutid);

export const isValidUrl = (str: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(str);
    return true;
  } catch {
    return false;
  }
};

export default {};
