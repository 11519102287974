import { SentryProps } from 'root/widgets/types';

/**
 *
 * @param sentryConfig - object with props "instance": sentry instance; "project": metadata send to sentry like project.name, project.version, ...
 * @param origin - path of widget, component format: `WIDGET_NAME`, `WIDGETNAME.COMPONENT_NAME`, `COMPONENT_NAME.SUB_COMPONENT_NAME`
 * @param functionName - the name of fuction throw or log error
 * @param error - Error object or error message string
 * @returns
 */
export const captureExceptionSentry = (
  sentryConfig: SentryProps | undefined,
  origin: string,
  functionName: string,
  error: any,
) => {
  const errorObj = error instanceof Error ? error : new Error(JSON.stringify(error));

  const sentry = sentryConfig?.instance;
  const project = sentryConfig?.project;

  if (!sentry) {
    console.error('Sentry not available, using console instead', `${origin}.${functionName} ${error}`);
    return;
  }

  sentry?.setContext?.('details', {
    origin,
    functionName,
    stackTrace: errorObj.stack,
    project,
  });

  sentry?.setTags?.({
    origin,
    'function.name': functionName,
    'project.name': project?.name ?? '',
    'project.version': project?.version ?? '',
  });

  sentry?.captureException?.(errorObj);
};
