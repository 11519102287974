import { LOCALE, REGION } from 'root/widgets/constants';
import type { BrazeContentCardProps, BrazeContentCardType } from 'root/widgets/types';

const shouldShowCard = (card: BrazeContentCardProps, region: REGION, locale: LOCALE) =>
  card?.extras?.bu === 'consumer' &&
  card?.extras?.widgetType === 'notification' &&
  card?.extras?.web === 'true' &&
  card?.extras?.marketplace === region &&
  card?.extras?.locale === locale;

export const filterContentCards = (region: REGION, locale: LOCALE, rawCards: Array<BrazeContentCardProps> = []) =>
  rawCards.filter((card: BrazeContentCardProps) => shouldShowCard(card, region, locale));

export const processContentCards = (rawCards: Array<BrazeContentCardProps> = []) => {
  if (rawCards.length === 0) {
    return [];
  }

  const cards = rawCards.map((card: BrazeContentCardProps) => {
    /* eslint-disable @typescript-eslint/naming-convention */
    const { id, viewed, title, imageUrl, description, url, linkText, dismissed, Y, Oc } = card;
    /* eslint-enable @typescript-eslint/naming-convention */
    const cardType = (Y || Oc) as BrazeContentCardType;

    return {
      id,
      isViewed: viewed,
      title,
      imageUrl: imageUrl || null,
      description,
      url,
      linkText,
      isDismissed: dismissed,
      cardType,
    };
  });

  return cards;
};

export const mapContentCards = (rawCards: Array<BrazeContentCardProps> = []) => {
  const mappedCards: Record<string, BrazeContentCardProps> = {};
  rawCards.forEach((card: BrazeContentCardProps) => {
    mappedCards[card.id] = card;
  });
  return mappedCards;
};
