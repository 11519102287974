import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import ActionableLink from 'root/widgets/common-components/actionable-link';

import { NavbarVariant } from 'root/widgets/global-components/navbar/constants';
import type { MoreLinksDropdownProps } from 'root/widgets/global-components/navbar/types';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import MoreLinksDropdownItem from './more-links-dropdown-item';

const MoreLinksDropdown: React.FC<MoreLinksDropdownProps> = ({
  navDropdown,
  agentLinks,
  onToggleClick,
  onLinkClick,
  variant,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [activeUrl, setActiveUrl] = useState('');

  const handleToggleClick = () => {
    onToggleClick?.(navDropdown.title);
  };

  const getLinkClickHandler = (text: string) => () => {
    onLinkClick?.(text);
  };

  useEffect(() => {
    const found = navDropdown.items.filter((item) => item.url && window.location.pathname.startsWith(item.url));
    if (found.length > 0) {
      const currentLinkItem = found[0];
      setActiveUrl(currentLinkItem.url ?? '');
    } else {
      setActiveUrl('');
    }
  }, [navDropdown]);

  if (navDropdown?.items?.length === 0 && agentLinks?.items?.length === 0) {
    return null;
  }

  const handleOnMouseEnter = () => {
    if (variant === NavbarVariant.PG_FINANCE) {
      handleToggleClick();
      setIsActive(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (variant === NavbarVariant.PG_FINANCE) {
      handleToggleClick();
      setIsActive(false);
    }
  };

  return (
    <Dropdown bsPrefix="more-links-dropdown" show={isActive} onToggle={setIsActive}>
      <Dropdown.Toggle as="div" title={navDropdown.tooltip} {...extractDataAutomationId(navDropdown)}>
        <ActionableLink
          className="more-links-dropdown-button"
          onClick={handleToggleClick}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <span className={classNames('more-links-dropdown-button__text', { active: isActive })}>
            {navDropdown.title}
          </span>
          <i className="pgicon-arrow-down" />
        </ActionableLink>
      </Dropdown.Toggle>
      <Dropdown.Menu
        bsPrefix="more-links-dropdown-menu"
        align={variant === NavbarVariant.PG_FINANCE ? undefined : 'end'}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {navDropdown.items.map((item) => (
          <MoreLinksDropdownItem
            isActive={activeUrl === item.url}
            key={item.text}
            item={item}
            onClick={getLinkClickHandler(item.text)}
          />
        ))}
        {agentLinks && agentLinks?.items?.length > 0 && (
          <>
            {navDropdown?.items?.length > 0 && <div className="more-links-dropdown-divider" />}
            <Dropdown.Header bsPrefix="more-links-dropdown-header">{agentLinks?.title}</Dropdown.Header>
            {agentLinks?.items.map((item) => (
              <MoreLinksDropdownItem key={item.text} item={item} onClick={getLinkClickHandler(item.text)} />
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreLinksDropdown;
