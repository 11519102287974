import { FC } from 'react';

import './agent-metadata.scss';
import { AgentMetadataProps } from './types';

const AgentMetadata: FC<AgentMetadataProps> = (props) => {
  const { agentMetadata } = props;

  return (
    <span className="agent-metadata-root">
      {agentMetadata
        .map((field) => <span key={field}>{field}</span>)
        // eslint-disable-next-line unicorn/no-array-reduce
        .reduce(
          (prevJSX, currJSX) =>
            prevJSX === null
              ? [currJSX]
              : [
                  ...prevJSX,
                  <span key={`${currJSX}-delimiter`} className="d-none d-md-block">
                    •
                  </span>,
                  currJSX,
                ],
          null,
        )}
    </span>
  );
};

AgentMetadata.displayName = 'AgentMetadata';

export default AgentMetadata;
