import { FC } from 'react';

import { ImportedReviewProps } from './types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import './imported-review.scss';

const ImportedReview: FC<ImportedReviewProps> = (props) => {
  const { text, tooltipText, externalLink, agency } = props;

  return (
    <div className="imported-review-root">
      <OverlayTrigger
        overlay={
          <Popover>
            <Popover.Body>{tooltipText}</Popover.Body>
          </Popover>
        }
      >
        <i className="pgicon pgicon-info-o" />
      </OverlayTrigger>
      <p>
        {text}&nbsp;
        <a className="link-primary" href={agency.url} target="_blank" rel="noreferrer">
          {agency.displayName}
          <img src={externalLink.image} alt={externalLink.alt} />
        </a>
      </p>
    </div>
  );
};

export default ImportedReview;
