import classNames from 'classnames';
import React from 'react';

import NavLinkItem from 'root/widgets/global-components/navbar/sub-components/common/nav-link-item';
import { NavbarVariant } from 'root/widgets/global-components/navbar/constants';
import type { NavGroupProps, NavLinkProps, SideNavLinkListProps } from 'root/widgets/global-components/navbar/types';

const SideNavLinkList: React.FC<SideNavLinkListProps> = ({ context, navLinks, agentLinks, onLinkClick, variant }) => {
  const handleNavLinkClick = (text: string) => {
    onLinkClick(text);
  };

  return (
    <div className={classNames('side-nav-link-list', { 'pg-finance-variant': variant === NavbarVariant.PG_FINANCE })}>
      {navLinks.length > 0 && (
        <div className="nav-link-list">
          {navLinks.map((link) => {
            if (Object.prototype.hasOwnProperty.call(link, 'items')) {
              return (
                <div className="group-link-list" key={(link as NavGroupProps).title}>
                  <p className="group-link-list-title">{(link as NavGroupProps).title}</p>
                  {(link as NavGroupProps).items.map((item) =>
                    item.desktopOnly ? undefined : (
                      <NavLinkItem
                        key={item.text}
                        context={context}
                        className="agent-link-item"
                        link={item}
                        isOnSideNav
                        onClick={handleNavLinkClick}
                      />
                    ),
                  )}
                </div>
              );
            }
            return (
              <NavLinkItem
                key={(link as NavLinkProps).text}
                context={context}
                isSelected={(link as NavLinkProps).isSelected ?? false}
                isOnSideNav
                link={link as NavLinkProps}
                onClick={handleNavLinkClick}
              />
            );
          })}
        </div>
      )}

      {agentLinks && agentLinks?.items?.length > 0 && (
        <div className="agent-link-list">
          <p className="agent-link-list-title">{agentLinks?.title}</p>
          {agentLinks?.items.map((link) => (
            <NavLinkItem
              key={link.text}
              context={context}
              className="agent-link-item"
              link={link}
              isOnSideNav
              onClick={handleNavLinkClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SideNavLinkList;
