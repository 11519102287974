import classNames from 'classnames';
import React from 'react';

import { SvgIcon } from 'root/widgets/svg-icon';

import { useScrollEnquiryActions } from './hooks';
import './listing-enquiry-info.scss';
import { ListingInfo, getOnboardingPopover } from './sub-components';
import { ListingEnquiryInfoProps } from './types';
import ActionStrip from 'root/widgets/common-components/action-strip';
import Actionable from 'root/widgets/common-components/actionable';

/* eslint-disable react/no-danger */
const ListingEnquiryInfo: React.FC<ListingEnquiryInfoProps> = ({ listingInfo, actions, history, metadata }) => {
  const { scrollRef, scroll } = useScrollEnquiryActions();

  return (
    <div className="listing-enquiry-info-root" data-listing-id={listingInfo.id} {...metadata}>
      <div className="card-body">
        <a className="listing-link" href={listingInfo.url} title={listingInfo.title} target="_blank" rel="noreferrer">
          <ListingInfo {...listingInfo} />
        </a>
        <div className="actions-wrapper">
          <ActionStrip ref={scrollRef}>
            <Actionable variant="outline-secondary" className="left-arrow-button" onClick={() => scroll('left')}>
              <i className="pgicon pgicon-arrow-left" />
            </Actionable>

            {actions.map(({ icon, text, ...buttonProps }) => (
              <Actionable
                key={text}
                {...buttonProps}
                className={classNames('listing-enquiry-info-actionable', buttonProps.className)}
                variant={buttonProps.variant || 'link'}
              >
                {icon.className && <i className={classNames('pgicon', icon.className)} />}
                {icon.svgSrc && <SvgIcon src={icon.svgSrc} />}
                <span>{text}</span>
              </Actionable>
            ))}
            <Actionable variant="outline-secondary" className="right-arrow-button" onClick={() => scroll('right')}>
              <i className="pgicon pgicon-arrow-right" />
            </Actionable>
          </ActionStrip>
        </div>
      </div>

      <div className="card-footer">
        <div className="status-title">
          {history.icon && <SvgIcon className="status-icon" {...history.icon} shouldUseImage />}
          {history.text}
        </div>
        {history.description && <div className="status-description">{history.description}</div>}
      </div>
    </div>
  );
};

export default Object.assign(ListingEnquiryInfo, {
  getOnboardingPopover,
});
