import { useSize } from 'ahooks';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { MouseEventHandler, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AgentInfo } from 'root/widgets/agent-info';
import { EVENT_NAMES, emit } from 'root/widgets/events';

import './contact-agent-card.scss';
import { ContactAgentCardProps, ExtendedContactViewProps } from './types';
import ContactButton from './sub-components/contact-button';
import ExtendedContactView from './sub-components/extended-contact-view';
import { ANIMATION_DURATION } from './constants';
import QRCodeView from './sub-components/qr-code-view';
import ActionGroup from './sub-components/action-group';
import { ShareProfileCard } from 'root/widgets/share-profile-card';
import { CompletePreferencesNudge } from 'root/widgets/complete-preferences-nudge';
import { applyViewHeight } from 'root/widgets/utils/dom';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import HUIImage from 'root/widgets/common-components/hui-image';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';
import { HUIBadges } from 'root/widgets/common-components/hui-badges';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const ContactAgentCard = forwardRef<HTMLDivElement, ContactAgentCardProps>(
  (
    {
      agentInfoProps: agentInfo,
      richContactActions = [],
      contactActions,
      liveTour,
      agency,
      userIntent,
      termsAndPolicy,
      shareProfileCard,
      metadata,
      context,
      completePreferencesNudge,
      extendedContactView = null,
      setExtendedContactView = (_value: ExtendedContactViewProps | null) => {},
    },
    ref,
  ) => {
    const [shouldShowIntentAlert, setShowIntentAlert] = useState<boolean>(false);
    const [isExtendedViewOpen, setIsExtendedViewOpen] = useState<boolean>(false);

    const richContactGroupRef = useRef<HTMLDivElement>(null);
    const buttonGroupRef = useRef<HTMLDivElement>(null);
    const extendedViewRef = useRef<HTMLDivElement>(null);

    const elementSize = useSize(richContactGroupRef);

    const handleIntentClick: MouseEventHandler = (event) => {
      emit(EVENT_NAMES.CONTACT_AGENT_CARD.INTENT_CTA_CLICK, context);

      setShowIntentAlert(false);

      if (userIntent?.onClick) {
        userIntent.onClick?.(event);
      }
    };

    const handleDisableCTAClick: MouseEventHandler = useCallback(
      (event) => {
        if (userIntent?.alertText && isEmpty(userIntent.selected)) {
          event.preventDefault();
          setShowIntentAlert(true);
        }
      },
      [userIntent],
    );

    const intentBadgesProps = useMemo(() => {
      if (!userIntent) {
        return null;
      }

      const { badges, ...rest } = userIntent;

      return {
        ...rest,
        badges: badges.map((badge) => ({ ...badge, background: 'neutral' })),
      };
    }, [userIntent]);

    useEffect(() => {
      if (extendedContactView) {
        setIsExtendedViewOpen(true);
      }
    }, [extendedContactView]);

    const handleExtendedViewClose = (type) => {
      emit(EVENT_NAMES.CONTACT_AGENT.EXTENDED_VIEW_ON_CLOSE, { ...context, type: type });
      applyViewHeight(richContactGroupRef.current, buttonGroupRef.current);
      setIsExtendedViewOpen(false);
      // setTimeout is used to ensure the extended view is reset after animation is completed
      setTimeout(() => {
        if (!richContactGroupRef.current?.classList.contains('extended')) {
          setExtendedContactView(null);
        }
      }, ANIMATION_DURATION);
    };

    const getExtendedViewClickHandler = (type) => () => handleExtendedViewClose(type);

    useEffect(() => {
      applyViewHeight(
        richContactGroupRef.current,
        extendedContactView ? extendedViewRef.current : buttonGroupRef.current,
      );
    }, [elementSize?.width, extendedContactView]);

    return (
      <div
        ref={ref}
        className="contact-agent-card-root"
        {...filterMetadata(metadata)}
        {...extractDataAutomationId(metadata)}
      >
        <div className="card-header">
          {agency && (
            <div className="agency">
              {agency.avatar && <HUIImage className="agency-avatar" src={agency.avatar} alt={agency.name} />}
              {agency.name}
            </div>
          )}
          <AgentInfo {...agentInfo} context={context} />
        </div>
        <div className="card-body">
          {shouldShowIntentAlert && userIntent?.alertText && (
            <HUIAlert variant="warning" className={classNames('intent-alert')}>
              {userIntent?.alertText}
            </HUIAlert>
          )}
          {intentBadgesProps && <HUIBadges {...intentBadgesProps} onClick={handleIntentClick} />}
          {richContactActions.length > 0 && (
            <div
              ref={richContactGroupRef}
              className={classNames('rich-contact-group', { extended: isExtendedViewOpen })}
            >
              <div ref={buttonGroupRef} className="button-group">
                {richContactActions.map((action, index) => (
                  <ContactButton key={`rich-contact-agent-${index}`} {...action} />
                ))}
              </div>
              <div ref={extendedViewRef} className="extended-view-group">
                {extendedContactView && (
                  <ExtendedContactView
                    {...extendedContactView}
                    onClose={getExtendedViewClickHandler(extendedContactView.type)}
                  />
                )}
              </div>
            </div>
          )}
          <div
            className={classNames('contact-agent-actions', { disabled: userIntent && isEmpty(userIntent.selected) })}
            onClick={handleDisableCTAClick}
          >
            {contactActions.map((action, index) => (
              <ActionableLink key={`contact-agent-${index}`} className="btn" variant="secondary" {...action} />
            ))}
          </div>

          {shareProfileCard && (
            <ShareProfileCard
              className="share-profile-card"
              {...shareProfileCard}
              context={{ ...context, ...shareProfileCard.context }}
            />
          )}

          {completePreferencesNudge && (
            <CompletePreferencesNudge
              {...completePreferencesNudge}
              context={{ ...context, ...completePreferencesNudge.context }}
            />
          )}
          {termsAndPolicy && <div className="terms-and-policy">{termsAndPolicy}</div>}
        </div>
        {liveTour && (
          <div className="card-footer">
            <div className="live-tour">
              <div className="live-tour-badge">
                <i className="pgicon-video-call" />
                {liveTour.text}
              </div>
              {liveTour.description}
            </div>
          </div>
        )}
      </div>
    );
  },
);

ContactAgentCard.displayName = 'ContactAgentCard';

export default Object.assign(ContactAgentCard, { QRCodeView, ActionGroup });
