import React from 'react';
import { Dropdown } from 'react-bootstrap';

import ActionableLink from 'root/widgets/common-components/actionable-link';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { SvgIcon } from 'root/widgets/svg-icon';

import { LocaleSwitchProps } from './locale-switch/types';

const LocaleSwitch: React.FC<LocaleSwitchProps> = ({
  locales,
  onLocaleChange,
  dropDirection = 'down',
  currentLocaleKey,
  context,
}) => {
  const currentLocale = locales.find((locale) => locale.key === currentLocaleKey);
  const handleLocaleChange = (localeKey) => {
    emit(EVENT_NAMES.LOCALE_SWITCH.CHANGE, { ...context, localeKey });
    onLocaleChange(localeKey);
  };

  return (
    <>
      {locales.length > 1 && (
        <Dropdown className="locale-switch-dropdown" onSelect={handleLocaleChange} drop={dropDirection}>
          <Dropdown.Toggle as="div">
            <ActionableLink className="locale-switch-dropdown-button">
              {currentLocale?.icon && (
                <SvgIcon className="flag" src={currentLocale.icon} width={20} height={14} shouldUseImage />
              )}
              <i className="pgicon-arrow-down" />
            </ActionableLink>
          </Dropdown.Toggle>
          <Dropdown.Menu bsPrefix="locale-switch-dropdown-menu" align="end">
            {locales.map((locale) => (
              <Dropdown.Item
                bsPrefix="locale-switch-dropdown-item"
                key={locale.key}
                eventKey={locale.key}
                active={locale.key === currentLocale?.key}
              >
                {locale?.icon && <SvgIcon className="flag" src={locale.icon} width={20} height={14} shouldUseImage />}
                {locale.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default LocaleSwitch;
