import { FC } from 'react';

import { DrilldownInputOptionData } from 'root/widgets/drilldown-menu/types';
import { OptionType } from 'root/widgets/drilldown-menu/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';

import { ListingFeedbackReasons } from 'root/symbiosis-widgets/wrappers/feedback-reasons-view';
import { FeedbackSubmittedView } from 'root/symbiosis-widgets/wrappers/feedback-submitted-view';

import DeniedModal from './sub-components/hide-listing-denial/hide-listing-denial';
import { HideListingModalProps } from './types';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const HideListingModal: FC<HideListingModalProps> = (props) => {
  const { metadata, context, reasonsView, denialView, feedbackSubmittedView, dependencies } = props;

  const renderDenialView = () => {
    const { shouldShow: isDenied, title: deniedTitle, content: deniedContent, actions: deniedAction } = denialView;

    return (
      <DeniedModal
        shouldShow={isDenied}
        title={deniedTitle}
        content={deniedContent}
        actions={{ ...deniedAction, close: { onClick: deniedAction.close.onClick } }}
      />
    );
  };

  const renderReasonsView = () => {
    const { title: reasonsViewTitle, shouldShow, reasonsData, actions: reasonsViewActions, errorConfig } = reasonsView;
    const { logError } = dependencies;

    const handleSubmit: HideListingModalProps['reasonsView']['actions']['submit']['onClick'] = async (...args) => {
      const { value, lookupIndexPath } = args[0];

      let optionData: any = { data: reasonsData };
      let optionDataParent: DrilldownInputOptionData;
      let isInputType: boolean | undefined;

      lookupIndexPath.forEach((index) => {
        isInputType = optionData.data[index].type === OptionType.INPUT;
        if (isInputType) {
          optionDataParent = optionData;
        }
        optionData = optionData.data[index];
      });

      const options = {
        data: {
          ...(isInputType ? { tags: [optionDataParent!.value], responseText: value } : { tags: [value] }),
        },
      };

      try {
        await reasonsViewActions.submit.onClick(...args);

        emit(EVENT_NAMES.HIDE_LISTING_MODAL.SUBMIT_ON_SUCCESS, { ...context, payload: options });
      } catch (error: any) {
        logError(HideListingModal.name, handleSubmit.name, error);
        throw new Error(error.message);
      }
    };

    return (
      <ListingFeedbackReasons
        drillDownMenuProps={{
          title: reasonsViewTitle,
          onSave: handleSubmit,
          isOpen: shouldShow,
          data: reasonsData,
          onClose: reasonsViewActions.close.onClick,
          errorConfig,
        }}
      />
    );
  };

  return (
    <section className="hide-property-modal-root" {...filterMetadata(metadata)} {...extractDataAutomationId(metadata)}>
      {reasonsView?.shouldShow && renderReasonsView()}
      {denialView?.shouldShow && renderDenialView()}
      {feedbackSubmittedView?.shouldShow && <FeedbackSubmittedView {...feedbackSubmittedView} />}
    </section>
  );
};

HideListingModal.displayName = 'HideListingModal';

export default HideListingModal;
