import classnames from 'classnames';
import React from 'react';

import './hui-loader.scss';
import type { HUILoaderProps } from './types';

const HUILoader = ({ className, variant, hasBackdrop, text }: HUILoaderProps) => (
  <div className={classnames('hui-loader-root', variant, { backdrop: hasBackdrop }, className)}>
    <div className="loader-content">
      <i className="pgicon-loading animate-spin spinner" />
      {text && <p className="loader-text">{text}</p>}
    </div>
  </div>
);

HUILoader.displayname = 'HUILoader';

export default HUILoader;
