import React, { useContext } from 'react';

import { SearchInput } from 'root/widgets/common-components/search-input';

import { LocationStoreContext } from 'root/widgets/location-filter-modal/context';
import { SearchInputProps } from 'root/widgets/location-filter-modal/types';

import './search-section.scss';

const SearchableInput: React.FC<SearchInputProps> = ({ onSearchTextChange, value, onChange, placeholder }) => {
  const { dataAutomationId } = useContext(LocationStoreContext);

  const debounceInputOnChange = (searchValue) => {
    onSearchTextChange(searchValue);
  };

  return (
    <SearchInput className="location-filter-modal-search-root">
      <SearchInput.Editable
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        metadata={{
          dataAutomationId: dataAutomationId ? `${dataAutomationId}-txt` : '',
        }}
        debounceOptions={{
          callback: debounceInputOnChange,
          delay: 0,
          shouldUpdateState: true,
        }}
      />
      <SearchInput.Peripheral>
        <i className="pgicon-search" />
      </SearchInput.Peripheral>
    </SearchInput>
  );
};

export default SearchableInput;
