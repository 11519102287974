/* eslint-disable @typescript-eslint/no-explicit-any */

export const on = (eventName: string, callback: any, options?: AddEventListenerOptions | boolean): void => {
  document.addEventListener(eventName, callback, options ?? false);
};

export const off = (eventName: string, callback: any, options?: AddEventListenerOptions | boolean): void => {
  document.removeEventListener(eventName, callback, options ?? false);
};

export const emit = (eventName: string, context: any): void => {
  const customEvent = new CustomEvent(eventName, { detail: context });
  document.dispatchEvent(customEvent);
};

const Events = {
  on,
  off,
  emit,
};

export default Events;
