import classnames from 'classnames';
import { FC, useContext, useEffect, useState, useRef } from 'react';
import { useScroll } from 'ahooks';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';

import './ai-filter.scss';
import { ChipGroupInput } from 'root/widgets/chip-group-input';
import ToggleSwitch from 'root/widgets/common-components/toggle-switch/toggle-switch';
import { AI_SEARCH_TOOLTIP_KEY, DATA_AUTOMATION_ID } from 'root/widgets/constants';
import Popover from 'root/widgets/common-components/popover/popover';
import { OverlayTrigger } from 'react-bootstrap';
import { useLocalStorage } from 'usehooks-ts';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { AISearchProps } from './types';

const AIFilter: FC<AISearchProps> = ({ className, filterPanelRef }) => {
  const context = useContext(SearchWithFilterContext);
  const { setFilters, data, filters: selectedFilters } = context;
  const { filters } = data;
  const { aiSearch: initialConfig } = filters;

  const aiTooltipCointainerRef = useRef<HTMLDivElement>(null);

  // Store the current scroll position
  const scrollPositionRef = useRef<number>(0);

  // State for toggle button
  const [isToggled, setIsToggled] = useState<boolean>(selectedFilters.isAISearchEnabled);

  const [isTooltipVisible, setIsTooltipVisible] = useLocalStorage<boolean | false>(AI_SEARCH_TOOLTIP_KEY, false);
  const [shouldDisplayTooltip, setDisplayTooltip] = useState<boolean>(false);

  useScroll(
    () => filterPanelRef.current,
    (scrollPosition) => {
      scrollPositionRef.current = scrollPosition.left;
      if (shouldDisplayTooltip && scrollPosition.left > 0) {
        setDisplayTooltip(false);
        setIsTooltipVisible(true);
      }

      return false;
    },
  );

  useEffect(() => {
    if (!isTooltipVisible) {
      setDisplayTooltip(true);
    }
  }, [isTooltipVisible]);

  // Handle toggle switch change
  const handleToggleSwitchChange = () => {
    const isNewToggleState = !isToggled;
    setDisplayTooltip(false);
    setIsTooltipVisible(true);

    emit(EVENT_NAMES.SEARCH_FILTER.AI_TOGGLE_ON_CLICK, {
      ...context,
      customLabel: JSON.stringify({ toggle: isNewToggleState ? 'on' : 'off' }),
    });

    // applying delay to fix the fading effect
    setTimeout(() => {
      setIsToggled(isNewToggleState);
      // Update filter state
      setFilters((prevFilters) => ({
        ...prevFilters,
        isAISearchEnabled: isNewToggleState,
      }));
    }, 0);
  };

  // Handle suggestion selection
  const handleButtonGroupSelect = (item: string) => {
    if (shouldDisplayTooltip) {
      setDisplayTooltip(false);
      setIsTooltipVisible(true);
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      aiSuggestedItem: item,
    }));

    // After the state update, restore the scroll position
    if (filterPanelRef.current) {
      filterPanelRef.current.scrollLeft = scrollPositionRef.current;
    }
  };

  const handlePopoverDismiss = () => {
    setDisplayTooltip(false);
    setIsTooltipVisible(true);
  };

  return (
    <div className={classnames('ai-filter-root', className)}>
      <div className="ai-filter" ref={aiTooltipCointainerRef}>
        <div className="title">{initialConfig?.name}</div>
        <OverlayTrigger
          placement="bottom"
          show={shouldDisplayTooltip}
          container={aiTooltipCointainerRef}
          delay={0}
          transition={false}
          rootClose
          overlay={
            <Popover className="ai-filter-popover" variant="dark" closeButton handleClose={handlePopoverDismiss}>
              <Popover.Header className="pt-3" as="h3">
                {initialConfig?.tooltip.title}
              </Popover.Header>
              <Popover.Body>{initialConfig?.tooltip.description}</Popover.Body>
            </Popover>
          }
        >
          <div className="toggle-switch-wrapper">
            <ToggleSwitch
              isToggled={isToggled}
              onChange={handleToggleSwitchChange}
              metadata={{
                [DATA_AUTOMATION_ID]: 'toggle-switch-button',
                'data-value': isToggled ? 'on' : 'off',
              }}
            />
          </div>
        </OverlayTrigger>
        <span className="vertical-line" />
      </div>
      {isToggled && initialConfig?.suggestedItems && initialConfig.suggestedItems.length > 0 && (
        <div className="suggested-container">
          <div className="suggested-title">{initialConfig?.suggestedText}</div>
          <ChipGroupInput
            className="p-b-2"
            type="radio"
            variant="space-between"
            radioName="suggestion-button-group"
            items={initialConfig.suggestedItems ?? []} // Updated to use state
            selectedItems={['']}
            onChange={handleButtonGroupSelect}
          />
        </div>
      )}
    </div>
  );
};

AIFilter.displayName = 'AIFilter';

export default AIFilter;
