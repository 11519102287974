import { useEffect, useRef } from 'react';

export const useScrollEnquiryActions = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const adjustScrollButtonsDisplay = () => {
    const element = scrollRef.current;
    if (!element) return;
    const isAtStart = element.scrollLeft === 0;
    const isAtEnd = element.scrollLeft + element.clientWidth === element.scrollWidth;
    const isHorizontalScrollbarPresent = element.scrollWidth > element.clientWidth;
    const leftButton = element.querySelectorAll('.left-arrow-button')[0] as HTMLElement;
    const rightButton = element.querySelectorAll('.right-arrow-button')[0] as HTMLElement;
    if (isHorizontalScrollbarPresent) {
      leftButton.style.display = isAtStart ? 'none' : 'block';
      rightButton.style.display = isAtEnd ? 'none' : 'block';
    } else {
      leftButton.style.display = 'none';
      rightButton.style.display = 'none';
    }
  };

  useEffect(() => {
    // This is needed to adjust the scroll buttons display on initial render
    adjustScrollButtonsDisplay();
  }, []);

  useEffect(() => {
    const element = scrollRef.current;
    if (element) {
      element.addEventListener('scroll', adjustScrollButtonsDisplay);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', adjustScrollButtonsDisplay);
      }
    };
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += direction === 'right' ? 100 : -100;
    }
  };

  return { scrollRef, scroll };
};
