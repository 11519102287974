import { useDebounceFn } from 'ahooks';
import { countries, CountryInterface } from 'country-codes-flags-phone-codes';
import { FC, useMemo, useRef, useState } from 'react';
import { CountrySelectModalProps } from './types';
import classNames from 'classnames';
import './country-select-modal.scss';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import { HUIInput } from 'root/widgets/common-components/hui-input';

const DEBOUNCE_SEARCH_TIME = 200;

const CountrySelectModal: FC<CountrySelectModalProps> = (props) => {
  const { className, title, allCountriesTitle, preferredRegions, searchInput, onSelect, ...modalProps } = props;

  const [searchResult, setSearchResult] = useState<Array<CountryInterface> | null>(null);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { run: onSearchInputChange } = useDebounceFn(
    () => {
      const searchText = searchInputRef.current?.value.toLowerCase();
      if (searchText) {
        const result = countries.filter((country) => country.name.toLowerCase().includes(searchText));

        setSearchResult(result);
      } else {
        setSearchResult(null);
      }
    },
    { wait: DEBOUNCE_SEARCH_TIME },
  );

  const preferredCountries = useMemo(() => {
    if (!preferredRegions) {
      return null;
    }

    const list: Array<CountryInterface> = [];

    preferredRegions.forEach((region) => {
      const countryObject = countries.find((country) => country.code === region);

      if (countryObject) {
        list.push(countryObject);
      }
    });

    return list;
  }, []);

  const countriesList = useMemo(() => {
    if (!preferredRegions) {
      return countries.sort((a, b) => a.name.localeCompare(b.name));
    }

    const preferredCountriesMap = {};
    preferredRegions.forEach((country) => {
      preferredCountriesMap[country] = true;
    });

    return countries
      .filter((country) => !preferredCountriesMap[country.code])
      .sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  const getCountryClickHandler = (country: CountryInterface) => () => {
    onSelect?.(country);
    setSearchResult(null);
  };

  const handleModalHide = () => {
    setSearchResult(null);
    modalProps.onHide?.();
  };

  const renderCountries = (list: Array<CountryInterface>, titleCountry?: string) => (
    <div className="countries-list">
      <div className="list-title">{titleCountry}</div>
      {list.map((country) => (
        <div key={`country-${country.code}`} className="country-item" onClick={getCountryClickHandler(country)}>
          <span>
            {country.flag} {country.name}
          </span>
          <span className="dial-code">{country.dialCode}</span>
        </div>
      ))}
    </div>
  );

  return (
    <HUIModal
      {...modalProps}
      onHide={handleModalHide}
      fullscreen="sm-down"
      className={classNames('country-select-modal-root', className)}
    >
      <HUIModal.Header backButton={Boolean(modalProps.onBack)} closeButton={Boolean(modalProps.onHide)}>
        {title}
      </HUIModal.Header>
      <HUIModal.Body>
        <HUIInput
          prefix={<i className="pgicon-search" />}
          {...searchInput}
          ref={searchInputRef}
          className={classNames('search-input', searchInput?.className)}
          onInputChange={onSearchInputChange}
        />
        <div className="countries-group">
          {searchResult ? (
            renderCountries(searchResult)
          ) : (
            <>
              {preferredCountries && renderCountries(preferredCountries)}
              {renderCountries(countriesList, allCountriesTitle)}
            </>
          )}
        </div>
      </HUIModal.Body>
    </HUIModal>
  );
};

CountrySelectModal.displayName = 'CountrySelectModal';

export default CountrySelectModal;
