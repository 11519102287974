import parse from 'html-react-parser';
import { useEffect, useMemo, useState } from 'react';

import { storage } from 'root/widgets/storage';
import { CookieBanner } from 'root/widgets/cookie-banner';
import { COOKIE_BANNER } from 'root/symbiosis-widgets/constants';

import './cookie-banner-section.scss';
import type { CookieBannerData } from './types';

const REDIX = 10;

const CookieBannerSection = (props: CookieBannerData) => {
  const { acceptCookiesDate, buttonText, bannerText, metadata } = props;

  const [shouldShowBanner, setShowBanner] = useState(false);

  const onProceed = () => {
    storage.setItem(COOKIE_BANNER.STORAGE_KEY, JSON.stringify(acceptCookiesDate));
    setShowBanner(false);
  };

  const cookieBannerProps = useMemo(
    () => ({
      proceedAction: {
        text: buttonText,
        onClick: onProceed,
      },
      text: parse(bannerText),
      metadata,
    }),
    [buttonText, bannerText, metadata],
  );

  useEffect(() => {
    const acceptCookiesValue = storage.getItem(COOKIE_BANNER.STORAGE_KEY);

    const notAcceptedCookies = !acceptCookiesValue || Number.isNaN(acceptCookiesValue);
    const isAcceptanceOutdated = notAcceptedCookies || Number.parseInt(acceptCookiesValue, REDIX) < acceptCookiesDate;

    if (notAcceptedCookies || isAcceptanceOutdated) {
      setShowBanner(true);
    }
  }, []);

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <div className="cookie-banner-section-root">
      <CookieBanner {...cookieBannerProps} />
    </div>
  );
};

export default CookieBannerSection;
