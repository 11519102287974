import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { FC, useContext, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';

import { UnitPosition } from './constants';
import './range-filter.scss';
import RangeItem from './range-item';
import type { RangeCategoryType, RangeDataProps, RangeFilterProps, SelectedRangeValuesType } from './types';
import { parseNumber } from 'root/widgets/utils/number';
import { Filter } from 'root/widgets/common-components/filter';
import { isNaN } from 'root/widgets/utils/number';

const RangeFilter: FC<RangeFilterProps> = ({ className, rangeKey, onApplyFilter, onClearFilter }) => {
  const context = useContext(SearchWithFilterContext);

  const { setFilters, filters: selectedFilters, category, data } = context;

  const { filters: filtersConfig } = data;

  const rangeData = filtersConfig[rangeKey];

  let selectedInputValues: RangeDataProps['selectedValues'] | undefined;

  // Find only selected value from selected filter data
  if (isObject(selectedFilters) && rangeKey in selectedFilters) {
    const selectedFilterData = selectedFilters[rangeKey] as RangeDataProps;
    selectedInputValues = selectedFilterData?.selectedValues ?? undefined;
  }

  let inputData: RangeCategoryType | undefined;

  // Select between "buy" or "rent" category or etc.
  if (isObject(rangeData) && category in rangeData) {
    inputData = rangeData[category] as RangeCategoryType;
  }

  const [selectedValues, setSelectedValues] = useState({
    min: selectedInputValues?.min ?? '',
    max: selectedInputValues?.max ?? '',
  });

  const [displayText, setDisplayText] = useState('');
  const [hasError, setError] = useState(false);
  const filterDropdownRef = useRef<HTMLInputElement>(null);

  const onClearCaptured = () => {
    setSelectedValues({ min: '', max: '' });
    setError(false);
  };

  const numberFormat = (value: number) => new Intl.NumberFormat(rangeData?.numberFormat).format(value);

  /**
   * There are 4 possible cases to display the text
   * 1. Both min and max are empty => "" will be returned
   * 2. Only min is available => Show min value along with the range text ("Above", "More", ...)
   * 3. Only max is available => Show max value along with the range text ("Below", "Under", ...)
   * 4. Both min and max are available => Show range format with (E.g. "1,000 - 2,000 sqft" or "S$ 50,000 - 100,000")
   *
   * @param value
   */
  const evaluateDisplayText = (value: SelectedRangeValuesType) => {
    value = {
      min: isNaN(value?.min) ? '' : value.min,
      max: isNaN(value?.max) ? '' : value.max,
    };

    const hasMaxValue = parseNumber(value.max) > 0;
    const hasMinValue = parseNumber(value.min) > 0;

    // Have case such a case where unit position able to be in front or behind
    // This case, unit position should be defined,
    // otherwise, we will push unit position to the back by default
    //
    // E.g. "1,000 - 2,000 sqft" or "$S 50,000 - 100,000"
    const shouldHaveUnitInFront = rangeData?.unitPosition === UnitPosition.Front;

    if (!hasMinValue && !hasMaxValue) {
      return '';
    }

    let displayValue = '';

    let minValue = '';
    let maxValue = '';

    if (hasMinValue) {
      minValue = numberFormat(Number.parseInt(value.min, 10));
    }

    if (hasMaxValue) {
      maxValue = numberFormat(Number.parseInt(value.max, 10));
    }

    if (hasMinValue && !hasMaxValue) {
      displayValue =
        inputData?.min?.unitPosition === UnitPosition.Front
          ? `${inputData?.min?.unit} ${minValue}`
          : `${minValue} ${inputData?.min?.unit}`;

      return `${inputData?.min?.rangeText} ${displayValue}`;
    }

    if (hasMaxValue && !hasMinValue) {
      displayValue =
        inputData?.min?.unitPosition === UnitPosition.Front
          ? `${inputData?.max?.unit} ${maxValue}`
          : `${maxValue} ${inputData?.max?.unit}`;

      return `${inputData?.max?.rangeText} ${displayValue}`;
    }

    displayValue = `${minValue} - ${maxValue}`;

    return shouldHaveUnitInFront
      ? `${inputData?.max?.unit} ${displayValue}`
      : `${displayValue} ${inputData?.max?.unit} `;
  };

  const checkValidity = () =>
    selectedValues.min === '' ||
    selectedValues.max === '' ||
    isNaN(selectedValues.min) ||
    isNaN(selectedValues.max) ||
    parseNumber(selectedValues.min) <= parseNumber(selectedValues.max);

  const onApplyCaptured = () => {
    const isValid = checkValidity();

    if (isValid) {
      setDisplayText(evaluateDisplayText(selectedValues));

      const updatedRange = { ...selectedFilters[rangeKey] };
      updatedRange.selectedValues = { ...selectedValues };

      if (updatedRange.selectedValues && isNaN(updatedRange.selectedValues.min)) {
        updatedRange.selectedValues.min = '';
      }

      if (updatedRange.selectedValues && isNaN(updatedRange.selectedValues.max)) {
        updatedRange.selectedValues.max = '';
      }

      setFilters({
        ...selectedFilters,
        [rangeKey]: updatedRange,
      });

      if (hasError) {
        setError(false);
      }
      filterDropdownRef?.current?.click(); // closing the filter dropdown when applied successfully
    } else {
      setError(true);
    }
  };

  const handleToggleDropdown = (isOpen: boolean) => {
    if (!isOpen) {
      setSelectedValues({ min: selectedInputValues?.min ?? '', max: selectedInputValues?.max ?? '' });
    }
  };

  const setMinSelected = (value: string) => {
    setSelectedValues({ ...selectedValues, min: value });
  };

  const setMaxSelected = (value: string) => {
    setSelectedValues({ ...selectedValues, max: value });
  };

  const shouldBeDisabled = selectedValues.min === '' && selectedValues.max === '';

  if (isEmpty(rangeData)) {
    return null;
  }

  if (isEmpty(inputData)) {
    return null;
  }

  const dataMax = inputData.max ?? undefined;
  const dataMin = inputData.min ?? undefined;

  // Because this is range filter widget then we need both min and max input data available
  if (!dataMax || !dataMin) {
    return null;
  }

  return (
    <div className={classnames('range-filter-root', className)}>
      <Filter
        dropdownTitle={rangeData.dropdownTitle}
        selectedValueStr={displayText}
        toggleBtnText={rangeData.toggleBtnText}
        clearAction={{
          text: rangeData.clearButtonText,
          onClick: onClearFilter ?? onClearCaptured,
          isDisabled: shouldBeDisabled,
        }}
        applyAction={{ text: rangeData.applyButtonText, onClick: onApplyFilter ?? onApplyCaptured }}
        dropdownRef={filterDropdownRef}
        onToggle={handleToggleDropdown}
      >
        <Row>
          <Col>
            <RangeItem
              unit={dataMin.unit}
              placeholderText={dataMin.placeholderText}
              labelText={dataMin.labelText}
              data={dataMin.items}
              shouldShowUnitInFront={dataMin.unitPosition === UnitPosition.Front}
              setSelected={setMinSelected}
              selectedInput={selectedValues.min}
              hasError={hasError}
            />
          </Col>
          <Col>
            <RangeItem
              unit={dataMax.unit}
              placeholderText={dataMax.placeholderText}
              labelText={dataMax.labelText}
              data={dataMax.items}
              shouldShowUnitInFront={dataMax.unitPosition === UnitPosition.Front}
              setSelected={setMaxSelected}
              selectedInput={selectedValues.max}
              hasError={hasError}
            />
          </Col>
        </Row>
      </Filter>
    </div>
  );
};

RangeFilter.displayName = 'RangeFilter';

export default RangeFilter;
