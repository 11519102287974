import classNames from 'classnames';
import React, { MutableRefObject, forwardRef, useRef } from 'react';
import { Nav, Navbar } from 'react-bootstrap';

import { useAutoScrollToElement } from 'root/widgets/common-components/hooks';

import { SvgIcon } from 'root/widgets/svg-icon';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import { SPACER_CLASS } from './constants';
import './hui-nav-tab-group.scss';
import type { HUINavTabGroupProps } from './types';

const HUINavTabGroup = forwardRef((props: HUINavTabGroupProps, ref: MutableRefObject<HTMLElement | null>) => {
  const { navLinks, activeIndex, className, shouldDisableAutoScroll, metadata } = props;
  const navContainerRef = useRef(null);
  useAutoScrollToElement(activeIndex, ref || navContainerRef, SPACER_CLASS);

  return (
    <Navbar
      ref={shouldDisableAutoScroll ? undefined : ref || navContainerRef}
      className={classNames('hui-nav-tabs-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      {navLinks.map((link, index) => (
        <Nav.Link
          className={classNames('nav-list-item', { active: index === activeIndex }, link.className)}
          key={link.text}
          href={link.url}
          onClick={link.onClick}
          {...filterMetadata(link.metadata)}
          {...extractDataAutomationId(link.metadata)}
        >
          {link.icon && <i className={`pgicon ${link.icon?.className}`} />}
          {link.svgIcon && <SvgIcon className={`pgicon ${link.svgIcon?.className}`} src={link.svgIcon?.url} />}
          {link.text}
          {link.badgeText && <span>{link.badgeText}</span>}
        </Nav.Link>
      ))}
    </Navbar>
  );
});

HUINavTabGroup.displayName = 'HUINavTabGroup';

export default HUINavTabGroup;
