import classNames from 'classnames';
import { FC, createElement } from 'react';

import { TypographyDefaultMapping, TypographyProps } from './types';
import './typography.scss';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const Typography: FC<TypographyProps> = ({
  children,
  className,
  htmlFor,
  metadata,
  variant = 'body-s',
  decoration,
  element,
}) => {
  const TypographyRoot = createElement(
    element || TypographyDefaultMapping[variant],
    {
      className: classNames(
        `typography-root pg-font-${variant} ${decoration ? `typography--${decoration}` : ''}`,
        className,
      ),
      htmlFor: htmlFor,
      ...filterMetadata(metadata),
      ...extractDataAutomationId(metadata),
    },
    children,
  );
  return TypographyRoot;
};

export default Typography;
