import { StatusCodes } from 'http-status-codes';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { INPUT_TYPES } from 'root/widgets/common-components/generic-input-group/constants';

import { EMAIL_VALIDATION_PATTERN, SocialProvider } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import {
  PGLoginCustomHeaderValues,
  PromiseSettledResultStatus,
  SERVICE,
  VIEW,
  WIDGET_NAME,
} from 'root/widgets/pg-login/constants';
import PGLoginContext from 'root/widgets/pg-login/context';
import { getService } from 'root/widgets/pg-login/services';
import { AgentLoginPrompt, Footer } from 'root/widgets/pg-login/sub-components';
import { type ConsentChecksProps, type UserInfoLoggedInData, UserStatusCode } from 'root/widgets/pg-login/types';

import './login-view.scss';
import type { LoginViewProps } from './types';
import { captureExceptionSentry } from 'root/widgets/utils/logger';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Typography } from 'root/common/atom-elements/typography';
import { Masthead } from 'root/widgets/common-components/masthead';
import { HUILoader } from 'root/widgets/common-components/hui-loader';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';
import { GenericInputGroup } from 'root/widgets/common-components/generic-input-group';
import { Divider } from 'root/widgets/common-components/divider';
import { SocialLoginButton } from 'root/widgets/common-components/social-login-button';

const LoginView: React.FC<LoginViewProps> = ({
  masthead,
  socialLoginButtons,
  emailInput,
  buttonText,
  dividerTitle,
  agentLoginPrompt,
  errorTexts,
  googleLoginErrorInPrivateModeText,
  footer,
  checkboxPrompts,
}) => {
  const {
    otpRecipientId,
    email,
    shouldUseShortFlow,
    context,
    consentChecks,
    shouldShowLoginConsent,
    sentry,
    setEmail,
    changeView,
    setOtpId,
    onSuccess,
    setConsentChecks,
  } = useContext(PGLoginContext);

  const bffService = getService(SERVICE.BFF);
  const socialLoginService = getService(SERVICE.SOCIAL_LOGIN);
  const [serverError, setServerError] = useState('');
  const [inputError, setInputError] = useState('');

  const [shouldShowConsent, setShowConsent] = useState(Boolean(email));
  const [isLoading, setLoading] = useState(false);

  const captureException = (functionName: string, error) => {
    captureExceptionSentry(sentry, `${WIDGET_NAME}.${LoginView.name}`, functionName, error);
  };

  const generateOTP = async (emailAddress: string) => {
    const otpResponse = await bffService.generateOTP(otpRecipientId, emailAddress);
    setOtpId(otpResponse['otp_id']);
  };

  const handleSocialLogin = async (provider: SocialProvider) => {
    setLoading(true);

    emit(EVENT_NAMES.PG_LOGIN.LOGIN_ATTEMPT, { ...context, provider });
    try {
      let res: UserInfoLoggedInData;
      let socialLoginOrigin = '';
      setServerError('');
      switch (provider) {
        case SocialProvider.GOOGLE:
          res = await socialLoginService.loginUsingGoogle();
          socialLoginOrigin = PGLoginCustomHeaderValues.GOOGLE_LOGIN;
          break;
        case SocialProvider.FACEBOOK:
          res = await socialLoginService.loginUsingFacebook();
          socialLoginOrigin = PGLoginCustomHeaderValues.FACEBOOK_LOGIN;
          break;
        case SocialProvider.APPLE:
          res = await socialLoginService.loginUsingApple();
          socialLoginOrigin = PGLoginCustomHeaderValues.APPLE_LOGIN;
          break;
        default:
          res = {};
          break;
      }
      if (res) {
        await onSuccess(
          { accessToken: res.accessToken, refreshToken: res.refreshToken },
          { ...res },
          socialLoginOrigin,
          { ...context, provider },
        );
        emit(EVENT_NAMES.PG_LOGIN.LOGIN_SUCCESS, { ...context, provider });
      }
    } catch (error: any) {
      captureException(handleSocialLogin.name, error);
      const errorMessage =
        error?.details && (error.details as string).toLowerCase().includes('cookies')
          ? googleLoginErrorInPrivateModeText
          : errorTexts.socialLogin;
      setServerError(errorMessage);
    }
    setLoading(false);
  };

  const inputGroup = {
    inputError,
    input: {
      name: 'email-fld',
      placeholder: emailInput.placeholder,
      value: email,
      type: INPUT_TYPES.EMAIL,
      checkValidity: (text: string) => ({
        isInvalid: !new RegExp(EMAIL_VALIDATION_PATTERN).test(text),
        error: emailInput.validationMessage,
      }),
      onChange: (evt) => {
        setShowConsent(Boolean(evt.target.value));
      },
      metadata: {
        'data-automation-id': 'email-fld',
      },
    },
    button: {
      text: buttonText,
      metadata: {
        'data-automation-id': 'continue-btn',
      },
    },
    onSubmit: async (text: string) => {
      setLoading(true);

      emit(EVENT_NAMES.PG_LOGIN.LOGIN_ATTEMPT, context);
      setEmail(text);
      setServerError('');
      try {
        const res = await bffService.checkUserExistence(text);

        if (res.statusCode === UserStatusCode.DELETED) {
          setServerError(errorTexts.accountDeleted);
          /**
           * in case of deleted account we have to show highlighted input box
           * without any input feedback error message. For this reason setting
           * it with a space.
           */
          setInputError(' ');
          setLoading(false);
          return;
        }

        if (res.hasPassword) {
          changeView(VIEW.PASSWORD_LOGIN);
          return;
        }

        await generateOTP(text);
        changeView(VIEW.OTP_LOGIN);
      } catch (error) {
        const statusCode = (error as { statusCode?: StatusCodes }).statusCode;
        if (statusCode === StatusCodes.TOO_MANY_REQUESTS) {
          setServerError(errorTexts.otpGenerationLimit);
          captureException(inputGroup.onSubmit.name, error);
        } else if (statusCode !== StatusCodes.NOT_FOUND) {
          setServerError(errorTexts.server);
          captureException(inputGroup.onSubmit.name, error);
        } else if (shouldUseShortFlow) {
          try {
            await generateOTP(text);
            changeView(VIEW.VERIFY_EMAIL);
          } catch (error_) {
            const errorMessage =
              (error_ as { statusCode?: StatusCodes }).statusCode === StatusCodes.TOO_MANY_REQUESTS
                ? errorTexts.otpGenerationLimit
                : errorTexts.server;
            setServerError(errorMessage);
            captureException(inputGroup.onSubmit.name, error_);
          }
        } else {
          changeView(VIEW.SIGNUP);
        }
      }

      setLoading(false);
    },
  };

  useEffect(() => {
    (async () => {
      const promises = [
        socialLoginService.initGoogleAuth(),
        socialLoginService.initFacebookAuth(),
        socialLoginService.initAppleAuth(),
      ];
      const result = await Promise.allSettled(promises);

      result.forEach((response) => {
        if (response.status === PromiseSettledResultStatus.REJECTED) {
          const error = new Error(response?.reason?.details);
          captureException('useEffect', error);
        }
      });
    })();
  }, []);

  const consentCheckboxes = useMemo(
    () =>
      shouldShowLoginConsent &&
      checkboxPrompts &&
      Object.keys(checkboxPrompts).map((checkboxKey) => ({
        key: checkboxKey,
        isChecked: Boolean(consentChecks[checkboxKey]),
        text: checkboxPrompts[checkboxKey],
        onCheckChange: () => {
          setConsentChecks((checks: ConsentChecksProps) => ({ ...checks, [checkboxKey]: !consentChecks[checkboxKey] }));
        },
      })),
    [shouldShowLoginConsent, checkboxPrompts, consentChecks, setConsentChecks],
  );

  const renderConsentChecks = (): React.ReactNode => {
    if (consentCheckboxes && shouldShowConsent) {
      return consentCheckboxes.map((prompt) => (
        <div className="d-flex gap-2 mt-2" key={`sign-up-checkbox-${prompt.key}`}>
          <Checkbox
            id={`sign-up-checkbox-${prompt.key}`}
            className="sign-up-checkbox"
            checked={prompt.isChecked}
            onChange={prompt.onCheckChange}
            data-automation-id={`signup-checkbox-automation-${prompt.key}`}
          />
          <Typography htmlFor={`sign-up-checkbox-${prompt.key}`} element="label">
            {prompt.text}
          </Typography>
        </div>
      ));
    }

    return null;
  };

  return (
    <div className="login-view-root">
      <div className="pglogin-slide">
        <Masthead {...masthead} />
        {isLoading && <HUILoader variant="light" />}
        {serverError && <HUIAlert variant="danger">{serverError}</HUIAlert>}
        <div className="pglogin-viewport">
          <GenericInputGroup isDisabled={isLoading} {...inputGroup}>
            {renderConsentChecks()}
          </GenericInputGroup>
          {socialLoginButtons.length > 0 && (
            <>
              <Divider title={dividerTitle} />
              <div className="social-login-wrapper">
                {socialLoginButtons.map((buttonProps) => (
                  <SocialLoginButton
                    disabled={isLoading}
                    key={buttonProps.provider}
                    {...buttonProps}
                    onClick={handleSocialLogin}
                  />
                ))}
              </div>
            </>
          )}

          {agentLoginPrompt?.link.href && <AgentLoginPrompt {...agentLoginPrompt} />}
        </div>
      </div>
      <Footer>{footer}</Footer>
    </div>
  );
};

LoginView.displayName = 'LoginView';

export default LoginView;
