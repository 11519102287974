import classnames from 'classnames';
import { phone } from 'phone';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';
import { INPUT_TYPES } from 'root/widgets/common-components/generic-input-group/constants';

import { ContactInputType, EMAIL_VALIDATION_PATTERN } from 'root/widgets/constants';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import { VerifiedContactInputProps } from './types';
import './verified-contact-input.scss';
import { PhoneNumberInput, PhoneNumberInputProps } from './sub-components';
import { CountryInterface } from 'country-codes-flags-phone-codes';

const VerifiedContactInput: React.FC<VerifiedContactInputProps> = ({
  className,
  title,
  promptText,
  input,
  inputError,
  verifiedIcon,
  isVerified,
  isDisabled,
  actionTexts,
  onSubmit,
  onCancel,
  metadata,
  actionRef,
}) => {
  const { type, invalidInputText, value, metadata: inputMetadata, ...inputProps } = input;

  const [error, setError] = useState('');
  const [isInputFocused, setInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');
  const [isEditing, setEditing] = useState(false);
  const [isInputDisabled, setInputDisabled] = useState(Boolean(value) || isDisabled);
  const [countryCode, setCountryCode] = useState(type === ContactInputType.MOBILE ? input.countryCode : '');

  const inputRef = useRef<HTMLInputElement>();
  const shouldFocusInput = useRef(false);

  const checkValidity = (val: string) => ({
    isInvalid:
      type === ContactInputType.MOBILE
        ? !phone(`${countryCode}${val}`, { strictDetection: true }).isValid
        : !new RegExp(EMAIL_VALIDATION_PATTERN).test(val),
    error: invalidInputText,
  });

  const handleInputChange = (ev) => {
    setInputValue(ev.target.value);
    setEditing(true);
  };

  const handleEditClick = () => {
    setInputDisabled(false);
    setEditing(true);
  };

  const handleCancelClick = () => {
    setCountryCode(type === ContactInputType.MOBILE ? input.countryCode : '');
    setInputValue(value || '');
    setEditing(false);
    if (value) {
      setInputDisabled(true);
    }
    setError('');
    if (onCancel) {
      onCancel();
    }
  };

  const handleInputFocus = (event) => {
    setInputFocused(true);
    inputProps?.onFocus?.(event);
  };

  const handleInputBlur = (event) => {
    setInputFocused(false);
    inputProps?.onBlur?.(event);
  };

  const handleCountrySelect = (country: CountryInterface) => {
    setCountryCode(country.dialCode);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const { isInvalid: isInputInvalid, error: errorText } = checkValidity(inputValue);

    if (isInputInvalid) {
      setError(errorText);
    } else {
      setError('');
      if (onSubmit) {
        onSubmit(inputValue, type, countryCode)
          .then((response) => {
            setError(response?.errorMsg ?? '');

            return response;
          })
          .catch(() => {
            // TO-DO: Add error handling
          });
      }
    }
  };

  useImperativeHandle(
    actionRef,
    () => ({
      editText: () => {
        shouldFocusInput.current = true;
        handleEditClick();
      },
    }),
    [handleEditClick],
  );

  useEffect(() => {
    setError(inputError || '');
  }, [inputError]);

  useEffect(() => {
    if (isEditing && shouldFocusInput.current) {
      inputRef.current?.focus();
      shouldFocusInput.current = false;
    }
  }, [inputRef, isEditing]);

  const shouldShowEditButton = !isDisabled && !isEditing;
  const shouldShowVerifiedIcon = isVerified && !isEditing;
  const shouldShowVerifyButton = !isDisabled && isEditing;

  return (
    <Form
      onSubmit={handleSubmit}
      className={classnames('verified-contact-input-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <span className="verified-contact-input-title">{title}</span>
      {shouldShowEditButton && Boolean(inputValue) && (
        <Actionable
          className="verified-contact-input-edit"
          variant="link primary"
          onClick={handleEditClick}
          data-automation-id="edit-lnk"
        >
          <i className="pgicon-edit" />
          {actionTexts?.edit}
        </Actionable>
      )}
      <Form.Group className={classnames({ error: error }, 'form-group')}>
        {type === ContactInputType.MOBILE ? (
          <PhoneNumberInput
            {...(inputProps as PhoneNumberInputProps)}
            className={classnames('verified-contact-input-field', inputProps.className)}
            value={inputValue}
            onChange={handleInputChange}
            metadata={inputMetadata}
            disabled={isInputDisabled}
            ref={inputRef}
            countryCode={countryCode}
            countrySelectModal={{
              ...(inputProps as PhoneNumberInputProps).countrySelectModal,
              onSelect: handleCountrySelect,
            }}
          />
        ) : (
          <InputGroup className={classnames({ 'input-focus': isInputFocused }, 'verified-contact-input')}>
            <Form.Control
              {...inputProps}
              {...filterMetadata(inputMetadata)}
              {...extractDataAutomationId(inputMetadata)}
              type={INPUT_TYPES.EMAIL}
              className={classnames('verified-contact-input-field', inputProps.className)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              value={inputValue}
              disabled={isInputDisabled}
              ref={inputRef}
            />
          </InputGroup>
        )}
        {shouldShowVerifiedIcon && (
          <i className={classnames('pgicon', verifiedIcon || 'pgicon-ok-circled', 'verified')} />
        )}
        {!isVerified && !error && promptText && (
          <div className={classnames('verified-contact-input-description')}>{promptText}</div>
        )}
        {error && (
          <Form.Control.Feedback className="verified-contact-input-error" type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {shouldShowVerifyButton && (
        <div className="verify-btn-wrapper">
          <Actionable
            className="verified-contact-input-cancel-btn"
            variant="secondary"
            type="submit"
            onClick={handleCancelClick}
            data-automation-id="cancel-btn"
          >
            {actionTexts?.cancel}
          </Actionable>
          <Actionable
            className="verified-contact-input-btn"
            variant="primary"
            type="submit"
            data-automation-id="verify-btn"
          >
            {actionTexts?.save}
          </Actionable>
        </div>
      )}
    </Form>
  );
};

VerifiedContactInput.displayName = 'VerifiedContactInput';

export default VerifiedContactInput;
