import { FC } from 'react';
import { Button } from 'react-bootstrap';

import { HUIBottomSheet } from 'root/widgets/common-components/hui-bottom-sheet';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import { isMobile } from 'root/widgets/utils/validation';

import { DeniedModalProps } from 'root/symbiosis-widgets/wrappers/hide-listing-modal/types';

import './hide-listing-denial.scss';

const DeniedModal: FC<DeniedModalProps> = ({ shouldShow, title, content, actions }) => {
  const renderContent = () => (
    <>
      <div className="hide-listing-denial__header">
        <div className="hide-listing-denial__header-title">{title}</div>
        <i className="hide-listing-denial__close pgicon pgicon-cancel" onClick={actions.close.onClick} />
      </div>
      <div className="hide-listing-denial__body">
        {content}
        <Button
          className="hide-listing-denial__actionable"
          variant="outline-secondary"
          onClick={actions.submit.onClick}
        >
          {actions.submit.text}
        </Button>
      </div>
    </>
  );

  if (!shouldShow) {
    return null;
  }
  if (isMobile()) {
    return (
      <HUIBottomSheet
        onClose={actions.close.onClick}
        shouldShow={shouldShow}
        className="hide-listing-denial-root hide-listing-denial--bottomsheet"
      >
        {renderContent()}
      </HUIBottomSheet>
    );
  }
  return (
    <HUIModal className="hide-listing-denial-root" show={shouldShow} onHide={actions.close.onClick} centered>
      {renderContent()}
    </HUIModal>
  );
};

DeniedModal.displayName = 'DeniedModal';

export default DeniedModal;
