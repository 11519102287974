/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext, useState } from 'react';

import { INPUT_TYPES } from 'root/widgets/common-components/generic-input-group/constants';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { PGLoginCustomHeaderValues, SERVICE, WIDGET_NAME } from 'root/widgets/pg-login/constants';
import PGLoginContext from 'root/widgets/pg-login/context';
import { getService } from 'root/widgets/pg-login/services';

import './set-password-view.scss';
import { SetPasswordViewProps } from './types';
import { GenericInputGroup, GenericInputGroupProps } from 'root/widgets/common-components/generic-input-group';
import { captureExceptionSentry } from 'root/widgets/utils/logger';
import { Masthead } from 'root/widgets/common-components/masthead';
import Actionable from 'root/widgets/common-components/actionable';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';

const SetPasswordView: React.FC<SetPasswordViewProps> = ({
  masthead,
  passwordInputPlaceholder,
  submitBtnText,
  errorTexts,
  skipAction: { text, ...actionableProps },
}) => {
  const bffService = getService(SERVICE.BFF);
  const { userInfo, email, context, sentry, onSuccess } = useContext(PGLoginContext);
  const [serverError, setServerError] = useState('');

  const token = { accessToken: userInfo.accessToken, refreshToken: userInfo.refreshToken };

  const captureException = (functionName: string, error) => {
    captureExceptionSentry(sentry, `${WIDGET_NAME}.${SetPasswordView.name}`, functionName, error);
  };

  const inputGroup: GenericInputGroupProps = {
    input: {
      placeholder: passwordInputPlaceholder,
      type: INPUT_TYPES.PASSWORD,
      checkValidity: () => ({
        isInvalid: false,
        error: '',
      }),
      acceptanceRules: [
        {
          text: errorTexts.charLengthValidation,
          isAcceptable: (value) => value.length >= 8,
        },
        {
          text: errorTexts.alphaNumericValidation,
          isAcceptable: (value) => /[\dA-Za-z]/g.test(value),
        },
        {
          text: errorTexts.capitalLetterValidation,
          isAcceptable: (value) => /[A-Z]/g.test(value),
        },
        {
          text: errorTexts.specialCharValidation,
          isAcceptable: (value) => /[!#$%&@^]/g.test(value),
        },
      ],
      metadata: {
        'data-automation-id': 'password-fld',
      },
    },
    button: {
      text: submitBtnText,
      metadata: {
        'data-automation-id': 'save-password-btn',
      },
    },
    onSubmit: async (password) => {
      emit(EVENT_NAMES.PG_LOGIN.REGISTRATION_CREATE_PASSWORD_ATTEMPT, context);
      try {
        setServerError('');

        await bffService.setPassword(token, password, userInfo?.userApiId);
        await onSuccess(token, { ...userInfo, email }, PGLoginCustomHeaderValues.SET_PASSWORD);
        emit(EVENT_NAMES.PG_LOGIN.REGISTRATION_CREATE_PASSWORD_SUCCESS, context);
      } catch (error) {
        captureException(inputGroup.onSubmit.name, error);
        setServerError(errorTexts.server);
      }
    },
  };

  const handleSkip = async () => {
    await onSuccess(token, { ...userInfo, email }, PGLoginCustomHeaderValues.SET_PASSWORD_SKIP);
    emit(EVENT_NAMES.PG_LOGIN.REGISTRATION_SKIP_PASSWORD, context);
  };

  return (
    <div className="save-password-view-root text-center">
      <div className="pglogin-slide">
        <Masthead {...masthead} />
        <GenericInputGroup {...inputGroup} />
        <Actionable
          className="skip-text"
          metadata={{ 'data-automation-id': 'skip-password-lnk' }}
          variant="link primary icon-suffix"
          onClick={handleSkip}
          {...actionableProps}
        >
          {text}
        </Actionable>
        {serverError && <HUIAlert variant="danger">{serverError}</HUIAlert>}
      </div>
    </div>
  );
};

export default SetPasswordView;
