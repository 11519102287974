import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { HomeAlertCard } from 'root/widgets/home-alert-card';
import { validateDependencies } from 'root/widgets/utils/validation';
import { BrazeContentCardProps } from 'root/widgets/types';
import { useComponentInView } from 'root/widgets/common-components/hooks';

import { EVENT_NAMES, emit } from 'root/widgets/events';

import './new-home-alert-card-group.scss';
import type { NewHomeAlertCardGroupProps, RawNewHomeAlertData } from './types';
import { createAlertCards, findCardWithHomeAlertsById, getRawHomeAlertBag, populateRawHomeAlertBag } from './utils';
import { logCardClick, logCardImpressions } from 'root/widgets/utils/braze';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const newHomeAlertCardGroupDependencies = ['searchService', 'logError'];

const NewHomeAlertCardGroup: React.FC<NewHomeAlertCardGroupProps> = (props) => {
  const {
    label,
    className,
    cardConfig,
    districtConfig,
    regionConfig,
    context,
    metadata,
    dependencies,
    additionalEvents,
  } = props;
  validateDependencies(dependencies, newHomeAlertCardGroupDependencies);

  const { brazeSdk, logError } = dependencies;

  const newHomeAlertStorageData = getRawHomeAlertBag(logError);

  /**
   * A special braze content card holds the data for new home alerts. We need to preserve it for
   * impression tracking.
   */
  const [cardWithHomeAlerts, setCardWithHomeAlerts] = useState<BrazeContentCardProps | undefined>();
  const [rawHomeAlerts] = useState<Array<RawNewHomeAlertData>>(newHomeAlertStorageData?.alerts || []);
  const [isCardImpressionSent, setCardImpressionSent] = useState(false);

  const withBrazeSdkAndCard = useCallback(
    (fn) => {
      if (brazeSdk) {
        if (cardWithHomeAlerts) {
          fn(brazeSdk, cardWithHomeAlerts);
        } else {
          const foundCard = findCardWithHomeAlertsById(brazeSdk, newHomeAlertStorageData?.cardWithHomeAlerts.id);
          if (foundCard) {
            fn(brazeSdk, foundCard);
            setCardWithHomeAlerts(foundCard);
          }
        }
      }
    },
    [brazeSdk, cardWithHomeAlerts],
  );

  const handleCardGroupView = useCallback(() => {
    if (!isCardImpressionSent && brazeSdk) {
      withBrazeSdkAndCard(logCardImpressions);
      setCardImpressionSent(true);
    }
  }, [withBrazeSdkAndCard, brazeSdk]);

  const handleCardClick = useCallback(
    (event, alert, index) => {
      event.preventDefault();
      emit(EVENT_NAMES.NEW_HOME_ALERT_CARD.CLICK, { ...context, data: { homeAlert: alert, index } });
      withBrazeSdkAndCard(logCardClick);
      window.location.href = alert.redirectUrl;
    },
    [withBrazeSdkAndCard],
  );

  const cardGroupRef = useComponentInView(
    additionalEvents?.IN_VIEWPORT,
    useMemo(() => ({ ...context, homeAlerts: rawHomeAlerts }), [context, rawHomeAlerts]),
    handleCardGroupView,
  );

  const homeAlerts = useMemo(
    () =>
      createAlertCards(rawHomeAlerts, handleCardClick, {
        cardConfig,
        districtConfig,
        regionConfig,
      }),
    [rawHomeAlerts, handleCardClick],
  );

  /**
   * Trigger async process to fetch cards, for next loads.
   */
  useEffect(() => {
    if (brazeSdk) {
      void populateRawHomeAlertBag(dependencies);
    }
  }, [brazeSdk]);

  return (
    <>
      {homeAlerts.length > 0 && (
        <div
          id="new-home-alert-card-group-root"
          className={classNames('new-home-alert-card-group-root', className, { rendered: rawHomeAlerts.length > 0 })}
          {...filterMetadata(metadata)}
          {...extractDataAutomationId(metadata)}
        >
          <div className="card-group-title">{label}</div>
          <div ref={cardGroupRef} className="card-group-body">
            {homeAlerts.map((alert, index) => (
              <HomeAlertCard key={alert.id || `alert-card-${index}`} {...alert} context={context} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

NewHomeAlertCardGroup.displayName = 'NewHomeAlertCardGroup';

export default NewHomeAlertCardGroup;
