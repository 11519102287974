import classNames from 'classnames';
import { PropsWithChildren, useCallback } from 'react';

import type { BaseProps } from 'root/widgets/types';

import { HUIBottomSheet } from 'root/widgets/common-components/hui-bottom-sheet';
import { useSocialShareContext } from 'root/widgets/common-components/social-share-button-group/context';

import './social-share-bottom-sheet.scss';

const SocialShareBottomSheet = ({ children, className }: PropsWithChildren<BaseProps>) => {
  const context = useSocialShareContext();

  const handleClose = useCallback(() => {
    context?.setShow(false);
  }, [context]);

  return (
    <HUIBottomSheet
      className={classNames('social-share-bottom-sheet-root', className)}
      shouldShow={context?.isShowing || false}
      onClose={handleClose}
      variant="rounded-with-gutter"
    >
      <HUIBottomSheet.Body>{children}</HUIBottomSheet.Body>
    </HUIBottomSheet>
  );
};

SocialShareBottomSheet.displayName = 'SocialShareBottomSheet';

export default SocialShareBottomSheet;
