import { HIVE_STATIC_VERSION, MARKETPLACE, REGION } from 'root/widgets/constants';
import { PGLoginConfigData } from 'root/widgets/pg-login/types';

const commonConfig = {
  AUTH_API_HOST: 'https://auth.integration.guruestate.com',
  USERS_API_HOST: 'https://users-api.kgw.integration.guruestate.com',
  HOST: '',
  GOOGLE_CLIENT_ID: '367223124563-nqbh60veth48kr448f62mflkasd0kpmp.apps.googleusercontent.com',
  FACEBOOK_CLIENT_ID: '751052508373579',
  APPLE_CLIENT_ID: 'com.propertyguru.www',
  PG_LOGO_URL: `https://cdn-integration.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/logo/pg.svg`,
};
const config: Record<string, PGLoginConfigData> = {
  [`${REGION.SG}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '367223124563-n59p716f6qaltgr5e8cbrhe90ak5ijli.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '1704579266427760',
  },
  [`${REGION.SG}-${MARKETPLACE.CG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '185477762030-3h5vsctthv98gs3lupu3g15820pfmsas.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '182458812823492',
  },
  [`${REGION.MY}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '784740291048-070fsjo46tfq2ucj48im5vat4dur7694.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '576535004042802',
  },
  [`${REGION.MY}-${MARKETPLACE.IPP}`]: {
    ...commonConfig,
    APPLE_CLIENT_ID: 'com.iproperty.accounts.internal',
    GOOGLE_CLIENT_ID: '784740291048-070fsjo46tfq2ucj48im5vat4dur7694.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '576535004042802',
    PG_LOGO_URL: 'https://accounts.iproperty.com.my/assets/1730099992/img/ipp/logo-consumer.svg',
  },
  [`${REGION.TH}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '711575025382-fmini0pj3qkf60tvjac135mglsce33f9.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '726099721872634',
  },
};

export default config;
