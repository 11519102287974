import { COMMON_TRACKING } from 'root/global-wrappers/config';
import { getTrackingConfig, trackEvent } from 'root/global-wrappers/services/analytics';
import { GATrackingType } from 'root/global-wrappers/types/analytics';

export const handleGlobalFooterSocialClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleGlobalFooterSocialClick) {
    return;
  }

  const { gaCustomDimensions, data } = context.detail;

  const { dimension20 } = (ga.handleGlobalFooterSocialClick as GATrackingType).dimensions as Record<
    string,
    Record<string, string>
  >;

  const gaDimension20 = {
    ...dimension20,
    Type: `${dimension20.Type} ${data}`,
  };

  trackEvent({
    ga: {
      ...(ga.handleGlobalFooterSocialClick as GATrackingType),
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify(gaDimension20) },
    },
  });
};

export const handleGlobalFooterSitemapLinkClick = (context: any) => {
  const { ga } = getTrackingConfig(COMMON_TRACKING);

  if (!ga.handleGlobalFooterSitemapLinkClick) {
    return;
  }

  const { data, gaCustomDimensions } = context.detail;

  const { dimension20 } = (ga.handleGlobalFooterSitemapLinkClick as GATrackingType).dimensions as Record<
    string,
    Record<string, string>
  >;

  const gaDimension20 = {
    ...dimension20,
    Link: data,
  };

  trackEvent({
    ga: {
      ...(ga.handleGlobalFooterSitemapLinkClick as GATrackingType),
      dimensions: { ...gaCustomDimensions, dimension20: JSON.stringify(gaDimension20) },
    },
  });
};
