import { useInViewport } from 'ahooks';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { useEffect, useRef } from 'react';

import { emit } from 'root/widgets/events';
import type { ContextData } from 'root/widgets/types';

export const useComponentInView = (
  eventName: string | undefined,
  context: ContextData | undefined,
  onComponentView?: () => void,
) => {
  const componentRef = useRef(null);
  const [isSectionInViewport] = useInViewport(componentRef, { threshold: 0.5 });

  useEffect(() => {
    if (isSectionInViewport && eventName) {
      const isDependencyMissing = (dependencies: Array<string>) =>
        dependencies.some((dependency) => context && (!context[dependency] || some(context[dependency], isEmpty)));

      if (context?.dependencies && isDependencyMissing(context?.dependencies)) {
        return;
      }

      emit(eventName, context);

      onComponentView?.();
    }
  }, [isSectionInViewport, context, eventName, onComponentView]);

  return componentRef;
};
