export const OTP_LENGTH = 5;
export const INITIAL_OTP_INPUT_STATE = Array.from({ length: OTP_LENGTH }).fill('') as Array<string>;

export const RESEND_OTP_TIMER_DURATION = 60; // in seconds

export const RESEND_OTP_TIMER_DURATION_DELAY = 1000; // in milliseconds
export const RESEND_OTP_TIMER_DURATION_COUNT = 60; // just count

export const WIDGET_NAME = 'PGLogin';

export enum SERVICE {
  SOCIAL_LOGIN,
  BFF,
}

export enum AuthGrantType {
  OTP_LOGIN = 'otp_login',
  PASSWORD_LOGIN = 'password',
  SOCIAL_LOGIN = 'social_login',
  REFRESH_TOKEN_LOGIN = 'refresh_token',
}

export enum VIEW {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  SET_PASSWORD = 'SET_PASSWORD',
  OTP_LOGIN = 'OTP_LOGIN',
  PASSWORD_LOGIN = 'PASSWORD_LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export enum PGLoginCustomHeaderValues {
  APPLE_LOGIN = 'APPLE_LOGIN',
  FACEBOOK_LOGIN = 'FACEBOOK_LOGIN',
  GOOGLE_LOGIN = 'GOOGLE_LOGIN',
  OTP_LOGIN = 'OTP_LOGIN_VIEW',
  PASSWORD_LOGIN = 'PASSWORD_LOGIN_VIEW',
  SET_PASSWORD = 'SET_PASSWORD_VIEW',
  SET_PASSWORD_SKIP = 'SET_PASSWORD_VIEW_SKIP',
  VERIFY_EMAIL = 'VERIFY_EMAIL_VIEW',
}

export enum PromiseSettledResultStatus {
  REJECTED = 'rejected',
  FULFILLED = 'fulfilled',
}
