import React from 'react';

import type { BaseProps } from 'root/widgets/types';

const SecondaryIcon: React.FC<BaseProps> = ({ children, ...props }) => (
  <div className="secondary-icon market-icons" {...props}>
    {children}
  </div>
);

export default SecondaryIcon;
