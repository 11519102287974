import classNames from 'classnames';
import { useState } from 'react';

import { ReasonProps } from './types';

const Reason = ({ onClick, text }: ReasonProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onClick();
      setIsSubmitting(false);
    } catch {
      setIsSubmitting(false);
    }
  };

  const handleClick = () => {
    void handleSubmit();
  };

  return (
    <div
      className={classNames('listing-hidden-card__reason', isSubmitting && 'listing-hidden-card__reason--loading')}
      role="button"
      tabIndex={0}
      onClick={handleClick}
    >
      <div className="listing-hidden-card__reason__children">
        {text && <div className="listing-hidden-card__reason-text">{text}</div>}

        <div className="listing-hidden-card__reason-icon">
          <i className={classNames('pgicon', isSubmitting ? 'pgicon-loading animate-spin' : 'pgicon-ok')} />
        </div>
      </div>
    </div>
  );
};

export default Reason;
