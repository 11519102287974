export const DEFAULT_FORMAT_LOCALE = 'en-US';

/**
 * Used to parse number from string.
 *
 * @param value
 *
 * @returns number or NaN
 */
export const parseNumber = (value) => Number(value.replaceAll(/([,.])/g, ''));

/**
 * Return true if value is not a number
 *
 * Note: This function is a bit different from Number.isNaN() where it verifies only if exact `NaN` or not.
 *
 * @param value
 *
 * @returns boolean
 */
export const isNaN = (value: string) => Number.isNaN(parseNumber(value));

/**
 * Used to format number based on locale.
 * If locale is 'id-ID', it need some handling to convert base value to bigger unit representation like rb, jt, M.
 *
 * Example value - 20805000
 * shortBlocks - ["rb", "jt", "M"]
 * locale - "id-ID"
 * return 20,805 jt
 * @param value
 * * @param locale
 * @param shortBlocks
 * @returns
 */
export const formatNumberWithLocale = (
  value: number | null,
  locale = DEFAULT_FORMAT_LOCALE,
  shortBlocks?: Array<string>,
  decimal = 3,
) => {
  if (!value) {
    return '0';
  }

  if (locale === 'id-ID' && shortBlocks) {
    value = Math.round(value);
    for (let i = shortBlocks.length - 1; i >= 0; i--) {
      const thread = 10 ** ((i + 1) * 3);
      if (value >= thread) {
        const divided = value / thread;
        const result = new Intl.NumberFormat(locale, {
          maximumFractionDigits: decimal,
          minimumFractionDigits: 0,
          style: 'decimal',
        }).format(divided);

        return `${result} ${shortBlocks[i]}`;
      }
    }
  }

  return value.toLocaleString(locale);
};

/**
 * Format string into numbers only
 *
 * @param value
 *
 * @returns string
 */
export const formatNumbersOnly = (value: string): string => {
  if (!value) {
    return '';
  }
  return value.replace(/\D/g, '');
};

/**
 * Format string into two decimal places
 *
 * @param value
 *
 * @returns string
 */
export const formatTwoDecimals = (value: string): string => {
  if (!value) {
    return '';
  }

  const numValue = value.replace(/[^\d.]/g, '');

  if (!numValue.includes('.')) {
    return numValue;
  }

  const numValueArray = numValue.split('.');
  const limitDecimal = numValueArray[1].slice(0, 2);

  return `${numValueArray[0] || 0}.${limitDecimal}`;
};

/**
 * A helper to get the currency symbol
 * @param locale - the locale. e.g. en-SG, id-ID
 * @param currencyCode - the currency code. e.g. SGD, IDR
 * @param currencyDisplay - `symbol` for long symbol like S$, 'narrowSymbol' for short like $
 */
const getCurrencySymbol = (locale: string, currencyCode: string, currencyDisplay = 'symbol'): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay,
  });
  const parts = formatter.formatToParts(0);
  const currencyPart = parts.find((part) => part.type === 'currency');

  return currencyPart ? currencyPart.value : '';
};

/**
 * The default formatter for the currency to add space between parts
 *
 * S$100K -> S$ 100 K
 *
 * @param formattedValue - the value
 * @param config
 */
const getPrettyValue = (
  formattedValue: string,
  config: {
    targetSymbol?: string;
    symbol: string;
    unit: string;
  },
) => `${formattedValue.replace(config.symbol, `${config.targetSymbol || config.symbol} `)}${config.unit}`;

/**
 * To format the number
 * @param value - the number
 * @param locale - the locale (en-SG, id-ID, en-TH, etc)
 * @param currencyCode - the currency code (SGD, IDR, THB)
 * @param shortBlockUnits - the short blocks unit (['K', 'M', 'B'], ['rb', 'jt', 'M']
 * @param config - the Intl.NumberFormatOptions
 * Example1 value - 20805000
 *         locale - id-ID
 *         currencyCode - IDR
 *         shortBlocks - ["rb", "jt", "M"]
 *      return Rp 20,805 jt
 *
 * Example2 value - 20805000
 *         locale - en-SG
 *         currencyCode - SGD
 *      return $ 20,805
 */
export const formatCurrencyNumber = (
  value: number,
  locale = 'en-SG',
  currencyCode = 'SGD',
  shortBlockUnits: Array<string> = [],
  config?: Partial<Intl.NumberFormatOptions & { formatter?: typeof getPrettyValue; symbol?: string }>,
) => {
  const specialCurrencySymbolMapping: Record<string, string> = {
    SGD: 'S$',
    MYR: 'RM',
    THB: '฿',
    IDR: 'Rp',
  };
  let unit: string | undefined;
  let divisor: number | undefined;

  for (let i = shortBlockUnits.length - 1; i >= 0; i--) {
    if (value >= 1e3 ** (i + 1)) {
      unit = shortBlockUnits[i];
      divisor = 1e3 ** (i + 1);
      break;
    }
  }

  if (!unit) {
    unit = '';
  }

  if (!divisor) {
    divisor = 1;
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    ...config,
  });

  const formattedValue = formatter.format(value / divisor);
  const currencySymbol = getCurrencySymbol(locale, currencyCode, config?.currencyDisplay);
  const targetSymbol = config?.symbol || specialCurrencySymbolMapping[currencyCode] || currencySymbol;
  const customFormat = config?.formatter || getPrettyValue;

  return customFormat(formattedValue, { symbol: currencySymbol, targetSymbol, unit }).trim();
};
