import React from 'react';

import { TitleProps } from './types';

const Title: React.FC<TitleProps> = ({ children, headerTag = 'h6', ...props }) => {
  const TitleTag = headerTag as keyof JSX.IntrinsicElements;

  return <TitleTag {...props}>{children}</TitleTag>;
};

export default Title;
