import React from 'react';

const EnquirySkeletonLoading: React.FC = () => (
  <div className="enquiry-card-root loading">
    <div className="body-left">
      <div className="listing-enquiry-info-root">
        <div className="card-body">
          <div className="listing-info-root">
            <div className="listing-info-image hui-animated-loading" />
            <div className="info-wrapper">
              <div className="heading hui-animated-loading" />
              <div className="details hui-animated-loading" />
              <div className="body hui-animated-loading" />
            </div>
          </div>
          <div className="description hui-animated-loading" />
          <div className="action-strip">
            <div className="btn-wrapper">
              <div className="btn-share hui-animated-loading" />
              <div className="btn-calendar hui-animated-loading" />
              <div className="btn-add-note hui-animated-loading" />
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="history-text hui-animated-loading" />
        </div>
      </div>
    </div>
    <div className="divider" />
    <div className="body-right">
      <div className="agent-contact-info-root">
        <div className="agent-contact-info-body">
          <div className="avatar hui-animated-loading" />
          <div className="agent-body-info">
            <div className="agent-name hui-animated-loading" />
            <div className="agent-description hui-animated-loading" />
          </div>
        </div>
        <div className="horizontal-divider" />
        <div className="action-strip">
          <div className="actionable btn btn-whatsapp" />
          <div className="actionable btn btn-light hui-animated-loading btn-phone" />
          <div className="actionable btn btn-sms" />
        </div>
      </div>
    </div>
  </div>
);

const LoadingView: React.FC = () => (
  <>
    <EnquirySkeletonLoading />
    <EnquirySkeletonLoading />
    <EnquirySkeletonLoading />
  </>
);

export default LoadingView;
