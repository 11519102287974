import classnames from 'classnames';
import isObject from 'lodash/isObject';
import React, { useRef, useState } from 'react';
import { ListGroup } from 'react-bootstrap';

import { SearchInput } from 'root/widgets/common-components/search-input';

import './range-filter.scss';
import { RangeItemProps, RangeUnitItemType } from './types';
import { isNaN, parseNumber } from 'root/widgets/utils/number';

const RangeItem: React.FC<RangeItemProps> = (props) => {
  const {
    unit,
    placeholderText,
    labelText,
    data,
    selectedInput,
    setSelected,
    hasError,
    shouldShowUnitInFront = true,
  } = props;

  const [shouldShowItems, setShowItems] = useState(false);
  const listRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    setShowItems(true);
  };

  const handleBlur = (e) => {
    if (listRef?.current && !listRef?.current?.contains(e.relatedTarget)) {
      setShowItems(false);
    }
  };

  const handleOnChange = (value) => {
    const re = /^[\d\b]+$/;

    // Check if value is empty string or number
    if (value === '' || re.test(value)) {
      setSelected(value); // passing the selected value to its parent
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
  };

  const handleItemClicked = (item: RangeUnitItemType) => () => {
    // passing the selected value to its parent
    if (isObject(item)) {
      item = item.value;
    }

    setSelected(item);
    setShowItems(false);
  };

  const displayValue = (value) => (!value || isNaN(value) ? '' : String(parseNumber(value)));

  const getListValue = (item: RangeUnitItemType) => (isObject(item) ? item.value : item);

  const formatListItem = (item: RangeUnitItemType) => {
    // Check if item is object or primitive type
    let value = '';

    if (isObject(item)) {
      value = item.label;
    } else if (!isNaN(item) && unit) {
      value = `${unit} ${item}`;
    } else {
      value = item;
    }

    if (value) {
      return <>{value}</>;
    }

    return null;
  };

  return (
    <div className="range-item-root">
      <SearchInput
        onFocus={handleFocus}
        onBlur={handleBlur}
        onSubmit={handleOnSubmit}
        label={labelText}
        className={classnames({ 'unit-in-front': shouldShowUnitInFront, error: hasError })}
      >
        {shouldShowUnitInFront && <SearchInput.Peripheral>{unit}</SearchInput.Peripheral>}
        <SearchInput.Editable
          value={displayValue(selectedInput)}
          onChange={handleOnChange}
          placeholder={placeholderText}
        />
        {!shouldShowUnitInFront && <SearchInput.Peripheral>{unit}</SearchInput.Peripheral>}
      </SearchInput>
      <ListGroup ref={listRef} className={classnames({ show: shouldShowItems })}>
        {data?.map((item) => {
          // Key => min-500
          const listItemKey = `${placeholderText.toLowerCase()}-${getListValue(item)}`;

          return (
            <ListGroup.Item
              action
              key={listItemKey}
              className={item === selectedInput ? 'active' : ''}
              onClick={handleItemClicked(item)}
            >
              {formatListItem(item)}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};

export default RangeItem;
