import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';

import ActionableLink from 'root/widgets/common-components/actionable-link';

import type { NavLinkItemProps } from 'root/widgets/global-components/navbar/types';
import { SvgIcon } from 'root/widgets/svg-icon';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const NavLinkItem: React.FC<NavLinkItemProps> = ({ className, link, isSelected, onClick, isOnSideNav = false }) => {
  const { text, url, icon, tooltip, newBadgeText, target, svgIconUrl } = link;

  const [isActive, setActive] = useState(isSelected);

  const handleClick = () => {
    if (onClick) {
      onClick(text);
    }
  };

  useEffect(() => {
    if (url) {
      setActive(isSelected || window.location.pathname.startsWith(url));
    }
  }, []);

  let iconComponent;
  if (svgIconUrl) {
    iconComponent = <SvgIcon src={svgIconUrl} width={14} height={14} className="nav-link-item-svg-icon" />;
  } else if (icon) {
    iconComponent = <i className={classNames('nav-link-item-icon', icon)} />;
  }

  return (
    <Nav.Link
      as={ActionableLink}
      bsPrefix="nav-link-item"
      className={className}
      key={text}
      href={url}
      title={tooltip}
      target={target}
      badge={{
        text: newBadgeText,
        bgColor: 'success',
      }}
      onClick={handleClick}
      {...extractDataAutomationId(link)}
    >
      {isOnSideNav && iconComponent}
      <span className={classNames('nav-link-item-text', { active: isActive })}>{text}</span>
      {target && <i className={classNames('pgicon-link-ext', 'nav-link-item-icon')} />}
    </Nav.Link>
  );
};

export default NavLinkItem;
