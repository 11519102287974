import classnames from 'classnames';
import React from 'react';

import './market-segment.scss';
import PrimaryIcon from './primary-icon';
import SecondaryIcon from './secondary-icons';
import { MarketSegmentProps } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import HUIImage from 'root/widgets/common-components/hui-image';

const MarketSegment: React.FC<MarketSegmentProps> = ({ className, marketSegmentData }) => (
  <div className={classnames('market-strip-root', className)}>
    <div className="market-strip">
      {marketSegmentData.map((eachIcon, index) =>
        eachIcon.isFullWidth ? (
          <PrimaryIcon key={`market-icon-${index}`}>
            <ActionableLink target="_blank" href={eachIcon.href}>
              <HUIImage
                src={eachIcon.image}
                alt={eachIcon.alt}
                width={eachIcon.width}
                height={eachIcon.height}
                loading="lazy"
                fetchPriority="low"
              />
            </ActionableLink>
          </PrimaryIcon>
        ) : (
          <SecondaryIcon key={`market-icon-${index}`}>
            <ActionableLink target="_blank" href={eachIcon.href}>
              <HUIImage
                src={eachIcon.image}
                alt={eachIcon.alt}
                width={eachIcon.width}
                height={eachIcon.height}
                loading="lazy"
                fetchPriority="low"
              />
            </ActionableLink>
          </SecondaryIcon>
        ),
      )}
    </div>
  </div>
);

export default MarketSegment;
