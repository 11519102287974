import classNames from 'classnames';
import React from 'react';

import type { BaseProps } from 'root/widgets/types';

const HighlightKeyword: React.FC<BaseProps> = ({ className, children }) => (
  <span className={classNames('highlight-keyword-root', className)}>{children}</span>
);

export default HighlightKeyword;
