import { useDebounceFn } from 'ahooks';
import classnames from 'classnames';
import React, { KeyboardEvent, Suspense, SyntheticEvent, lazy, useContext, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { Hint, Menu, Typeahead } from 'react-bootstrap-typeahead';

import { HUITickerText } from 'root/widgets/common-components/hui-ticker-text';
import { SearchInput } from 'root/widgets/common-components/search-input';

import { DEBOUNCE_DELAY, HIVE_STATIC_ICON_PATH_V3, PropertyTypeMarket, REGION } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { LocationFilterModal } from 'root/widgets/location-filter-modal';
import type { LocationFilterParams } from 'root/widgets/location-filter-modal';
import SearchWithFilterContext from 'root/widgets/search-with-filter/context';
import type { RecentSearchProps } from 'root/widgets/search-with-filter/sub-components/recent-search/types';
import { SearchAccessory } from 'root/widgets/search-with-filter/sub-components/search-accessory';
import { SearchFullScreen } from 'root/widgets/search-with-filter/sub-components/search-fullscreen';
import type { SearchOptionType } from 'root/widgets/search-with-filter/sub-components/search-options/types';
import type { BaseProps } from 'root/widgets/types';

import { AccessoryViewType } from './constants';
import './search-panel.scss';
import { getPaddingStatus } from 'root/widgets/search-with-filter/utils';
import {
  convertLocationToSearchObject,
  FoundLocation,
  TypeaheadOption,
  TypeaheadOptionType,
} from 'root/widgets/common-components/found-locations-view';
import { isMobile } from 'root/widgets/utils/validation';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import SvgIcon from 'root/widgets/svg-icon/svg-icon';
import Actionable from 'root/widgets/common-components/actionable';

const MRTSearchModal = lazy(() =>
  import('root/widgets/mrt-search-modal').then((module) => ({ default: module.MRTSearchModal })),
);

const SearchPanel: React.FC<BaseProps & { inputDataAutomationId?: string; shouldIncludeAISearchFilter?: boolean }> = ({
  className,
  inputDataAutomationId,
  shouldIncludeAISearchFilter,
}) => {
  const context = useContext(SearchWithFilterContext);
  const {
    onSubmit,
    onQueryChange,
    data,
    dataAutomationId,
    filters: selectedFilters,
    setFilters,
    isFilterPanelHidden,
    foundLocations,
    recentSearchesItems,
    onRecentSearchSelect,
    onLocationSelect,
    onApplyLocationAccessory,
    dataResolver,
    category,
    stack,
    region,
    locale,
    logError,
    context: widgetContext,
  } = context;
  const { filters, searchPanel, recentSearches, recentSearchFiltersConfig } = data;
  const { searchInput: input, searchDropdown, searchOptions } = searchPanel;
  const { location: locationConfig, mrt: mrtConfig } = filters;
  const inputValue = selectedFilters['searchInput']['value'];
  const typeaheadRef = useRef<any>();

  const [isAccessoryModalVisible, setAccessoryModalVisible] = useState(false);
  const [accessoryData, setAccessoryData] = useState({
    show: false,
    key: '',
    type: '',
  });
  const [freetext, setFreetext] = useState<string>(inputValue ?? '');
  const [isMobileView, setMobileView] = useState(false);
  const [isClientSide, setClientSide] = useState(false);
  const [isTypeaheadMenuVisible, setTypeaheadMenuVisible] = useState(false);
  const [propertyLocation, setPropertyLocation] = useState<FoundLocation | null>(null);
  const [isFocused, setFocus] = useState(false);
  const shouldShowAnimatedPlaceholder = !freetext && Boolean(input?.animatedPlaceholder?.changeables?.length);

  const { run: debouncedSearch, cancel: cancelDebouncedSearch } = useDebounceFn(
    (value, propertyMarket) => {
      onQueryChange(value, propertyMarket);
    },
    { wait: DEBOUNCE_DELAY },
  );

  useEffect(() => {
    setMobileView(isMobile());
    setClientSide(true);

    return () => cancelDebouncedSearch();
  }, []);

  // this use effect handles the toggle for typeahead menu in case the tab does not have any search options or recent search items to show
  useEffect(() => {
    if (searchOptions?.length > 0 || recentSearchesItems?.length > 0) {
      return;
    }

    setTypeaheadMenuVisible(foundLocations?.length > 0);
  }, [foundLocations]);

  const onChangeHandler = (value): void => {
    const { propertyType, autocompleteLocation, isAISearchEnabled } = selectedFilters;
    const propertyMarket = propertyType?.market || PropertyTypeMarket.RESIDENTIAL;

    setFreetext(value);

    if ((shouldIncludeAISearchFilter && !isAISearchEnabled) || !shouldIncludeAISearchFilter) {
      setAccessoryModalVisible(true);
      debouncedSearch(value, propertyMarket);
    }

    if (autocompleteLocation && Object.keys(autocompleteLocation)?.length > 0) {
      setFilters({
        ...selectedFilters,
        autocompleteLocation: {},
      });
    }
  };

  const onSearchInputClick = (): void => {
    setFocus(true);
    if ((shouldIncludeAISearchFilter && !selectedFilters.isAISearchEnabled) || !shouldIncludeAISearchFilter) {
      if (isMobileView) {
        setAccessoryModalVisible(true);
        if (typeaheadRef.current) {
          typeaheadRef.current.blur();
        }
      } else if (searchOptions?.length > 0 || recentSearchesItems?.length > 0 || foundLocations?.length > 0) {
        setTypeaheadMenuVisible(true);
      }
    }
  };

  const handleSearchInputSubmit = (evt?: SyntheticEvent): void => {
    if (evt) {
      evt.preventDefault();
    }

    if (onSubmit) {
      onSubmit({
        freetext,
        propertyLocation,
        filters: selectedFilters,
        selectedSearchDropdown: searchDropdown?.selectedItem,
      });
      emit(EVENT_NAMES.SEARCH_SEGMENT.SEARCH_ON_CLICK, { ...widgetContext, data: category, propertyLocation });
    }

    if (isMobileView && input?.shouldDisallowFreeTextSearch) {
      setAccessoryModalVisible(false);
    }
  };

  const clearInput = () => {
    setFreetext('');
    typeaheadRef?.current?.clear?.();
    setFilters((prevFilters) => ({
      ...prevFilters,
      aiSuggestedItem: null,
    }));
  };

  useEffect(() => {
    clearInput();
  }, [selectedFilters.isAISearchEnabled]);

  const handleSearchFullScreenInputClose = (): void => {
    setAccessoryModalVisible(false);
    clearInput();
  };

  const updateTypeaheadInputValue = (value: string) => {
    if (typeaheadRef.current) {
      /**
       * to update "input" value of typeahead, we have to use this tricky way to select a fake item
       * to force the input value display our `freetext`.
       * method like `ref.current.value`, `document.selectElementById(<input-id>).value` or `set attribute to input`
       * won't work
       */
      // eslint-disable-next-line no-underscore-dangle
      typeaheadRef.current._handleActiveItemChange({ displayText: value, position: -1 });
    }
  };

  useEffect(() => {
    if (selectedFilters.aiSuggestedItem) {
      setFreetext(selectedFilters.aiSuggestedItem);
      updateTypeaheadInputValue(selectedFilters.aiSuggestedItem);
    }
  }, [selectedFilters.aiSuggestedItem]);

  const handleFoundLocationSelect = (location: FoundLocation) => {
    if (location) {
      emit(EVENT_NAMES.SEARCH_ACCESSORIES.LOCATION_OPTION_ON_SELECT, { ...widgetContext, data: location });
      setAccessoryModalVisible(false);
      if (typeaheadRef.current) {
        typeaheadRef.current.blur();
      }
      if (!isFilterPanelHidden) {
        setFilters({
          ...selectedFilters,
          autocompleteLocation: convertLocationToSearchObject(location, region === REGION.SG),
          location: {},
        });
      }
      setFreetext(location.displayText);
      updateTypeaheadInputValue(location.displayText);
      setPropertyLocation(location);
      onLocationSelect?.(location);
    }
  };

  const handleSearchOptionSelect = (option: SearchOptionType) => {
    emit(EVENT_NAMES.SEARCH_ACCESSORIES.SEARCH_OPTION_ON_SELECT, { ...widgetContext, data: option });
    if (typeaheadRef.current) {
      typeaheadRef.current.blur();
    }

    let accessoryViewType = option.key;

    if (locationConfig) {
      const locationKeys = Object.keys(locationConfig);
      if (locationKeys.includes(option.key)) {
        accessoryViewType = AccessoryViewType.Location;
      }
    }
    setAccessoryData({ show: true, type: accessoryViewType, key: option.key });
  };

  const handleRecentSearchSelect = (selectedRecentSearch: RecentSearchProps) => {
    const search = selectedRecentSearch.values;
    const searchParams = {};

    if (selectedRecentSearch?.titles?.freetext) {
      setFreetext(selectedRecentSearch.titles.freetext);
      updateTypeaheadInputValue(selectedRecentSearch.titles.freetext);
    }

    Object.keys(search).forEach((key) => {
      if (search[key] && search[key] !== 0 && search[key] !== '0') {
        searchParams[key] = search[key];
      }
    });

    emit(EVENT_NAMES.SEARCH_ACCESSORIES.RECENT_SEARCH_ON_SELECT, {
      ...widgetContext,
      data: searchParams,
    });

    selectedRecentSearch.values = searchParams;

    onRecentSearchSelect(selectedRecentSearch);
    setAccessoryModalVisible(false);
  };

  const handleSearchTextChange = (e) => {
    onChangeHandler(e.target.value);
  };

  const handleOnApplyLocationAccessory = (params: LocationFilterParams) => {
    const { freetext: locationString } = params;

    setAccessoryData({ ...accessoryData, show: false });
    setAccessoryModalVisible(false);
    updateTypeaheadInputValue(locationString);
    // use location filter text to trigger autocomplete API
    onChangeHandler(locationString);
    onApplyLocationAccessory?.(params);
  };

  const onSearchInputBlur = (): void => {
    setFocus(false);
    if (!isMobileView) {
      setTypeaheadMenuVisible(false);
    }
  };

  const handleOnCloseLocationAccessory = () => {
    setAccessoryData({ ...accessoryData, show: false });
  };

  const handleApplyMRTSearch = ({ ids, freetext: mrtFreetext }: { ids: Array<string>; freetext: string }) => {
    setAccessoryData({ ...accessoryData, show: false });
    setAccessoryModalVisible(false);
    updateTypeaheadInputValue(mrtFreetext);
    onChangeHandler(mrtFreetext);
    setFilters({
      ...selectedFilters,
      location: {},
      autocompleteLocation: {},
      mrt: {
        ids,
      },
    });
  };

  const handleCloseMRTSearchModal = () => {
    setAccessoryData({ ...accessoryData, show: false });
  };

  const handleTypeaheadKeyDown = (evt: KeyboardEvent) => {
    // submit by 'Enter' when there is no active item in typeahead (activated by arrow key)
    if (
      !input?.shouldDisallowFreeTextSearch &&
      evt.key === 'Enter' &&
      typeaheadRef.current?.state?.activeIndex === -1
    ) {
      handleSearchInputSubmit();
    }
  };

  const renderTypeaheadMenu = () => (
    <Menu id="search-typeahead-menu" maxHeight="339px">
      {((shouldIncludeAISearchFilter && !selectedFilters.isAISearchEnabled) || !shouldIncludeAISearchFilter) &&
        !isMobileView && (
          <SearchAccessory
            data={{
              searchKeyword: freetext,
              foundLocations,
              searchOptions,
              recentSearches: {
                title: recentSearches.title,
                items: recentSearchesItems,
              },
            }}
          />
        )}
    </Menu>
  );

  const handleTypeaheadSelection = (selected: Array<TypeaheadOption>) => {
    const option = selected[0];
    if (!option) {
      return;
    }
    switch (option.optionType) {
      case TypeaheadOptionType.FoundLocation:
        handleFoundLocationSelect(option as FoundLocation);
        break;
      case TypeaheadOptionType.SearchOption:
        handleSearchOptionSelect(option as SearchOptionType);
        break;
      case TypeaheadOptionType.RecentSearch:
        handleRecentSearchSelect(option as RecentSearchProps);
        break;
      default:
        break;
    }
  };

  const handleDropdownSelect = (selectedItem: string) => {
    setPropertyLocation(null);
    clearInput();
    setFilters({
      ...selectedFilters,
      searchBy: selectedItem,
    });
    searchDropdown?.onSelect?.(selectedItem);
  };

  const renderInput = ({ inputRef, referenceElementRef, ...inputProps }) => (
    <Hint>
      <Form.Control
        {...inputProps}
        ref={(node) => {
          inputRef(node);
          referenceElementRef(node);
        }}
        className={classnames('rbt-input-main', 'rbt-input', {
          'ai-enabled': shouldIncludeAISearchFilter && selectedFilters.isAISearchEnabled,
        })}
        data-automation-id={inputDataAutomationId ?? 'search-panel-input'}
      />
    </Hint>
  );

  useEffect(() => {
    if (input?.shouldDisallowFreeTextSearch && (propertyLocation || selectedFilters?.location?.freetext)) {
      handleSearchInputSubmit();
      setPropertyLocation(null);
      setFilters({
        ...selectedFilters,
        location: {},
      });
    }
  }, [input, propertyLocation, selectedFilters]);

  const hasNoResult = getPaddingStatus(
    foundLocations.length,
    searchOptions.length,
    recentSearchesItems.length,
    freetext.length,
  );

  return (
    <div className={classnames('search-panel-root', className, stack)}>
      <SearchInput className={isTypeaheadMenuVisible ? 'dropdown-shown' : ''} onSubmit={handleSearchInputSubmit}>
        {searchDropdown && (
          <div className="search-panel-dropdown-filter">
            <DropdownButton
              onSelect={handleDropdownSelect}
              variant="search-panel-dropdown"
              title={
                <div
                  className="search-panel-dropdown-filter-label"
                  {...extractDataAutomationId({
                    dataAutomationId: dataAutomationId?.searchDropdown ? `${dataAutomationId.searchDropdown}-btn` : '',
                  })}
                >
                  <span>{searchDropdown?.selectedItem}</span>
                  <span className="pgicon pgicon-arrow-down" />
                </div>
              }
              flip={false}
            >
              {searchDropdown.items.map((item, index) => (
                <Dropdown.Item
                  key={item}
                  eventKey={item}
                  {...extractDataAutomationId({
                    dataAutomationId: dataAutomationId?.searchDropdown
                      ? `${dataAutomationId?.searchDropdown}-lst-${index}`
                      : '',
                  })}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        )}
        {isClientSide ? (
          <>
            <Typeahead
              id="search-typeahead"
              ref={typeaheadRef}
              className={classnames('form-control', 'typeahead', hasNoResult && 'typehead--no-result')}
              options={[]}
              labelKey="displayText"
              allowNew
              positionFixed
              placeholder={shouldShowAnimatedPlaceholder ? undefined : input.placeholder}
              onInputChange={onChangeHandler}
              onKeyDown={handleTypeaheadKeyDown}
              onFocus={onSearchInputClick}
              onBlur={onSearchInputBlur}
              onChange={handleTypeaheadSelection}
              defaultInputValue={freetext}
              inputProps={{
                readOnly:
                  isMobileView &&
                  ((shouldIncludeAISearchFilter && !selectedFilters.isAISearchEnabled) || !shouldIncludeAISearchFilter),
              }}
              renderMenu={renderTypeaheadMenu}
              renderInput={renderInput}
            >
              {freetext && (
                <Actionable className="close-icon" variant="link" onClick={clearInput}>
                  <SvgIcon src={`${HIVE_STATIC_ICON_PATH_V3}/cross-small.svg`} height={24} width={24} />
                </Actionable>
              )}
            </Typeahead>
            {shouldShowAnimatedPlaceholder && input.animatedPlaceholder && !isFocused && (
              <HUITickerText
                prefix={input.animatedPlaceholder.prefix}
                changeables={input.animatedPlaceholder.changeables}
              />
            )}
          </>
        ) : (
          <Form.Control className="form-control" placeholder={input.placeholder} />
        )}

        {!input?.shouldDisallowFreeTextSearch && stack === 'vertical' && (
          <SearchInput.Peripheral className="search-panel-group-text">
            <Button variant="primary" onClick={handleSearchInputSubmit}>
              <div className="text">{input.buttonText}</div>
              <i className="pgicon-search" onClick={handleSearchInputSubmit} />
            </Button>
          </SearchInput.Peripheral>
        )}
      </SearchInput>
      {((shouldIncludeAISearchFilter && !selectedFilters.isAISearchEnabled) || !shouldIncludeAISearchFilter) &&
        isAccessoryModalVisible &&
        isMobileView && (
          <SearchFullScreen
            placeholder={input.mobilePlaceholder}
            shouldDisallowFreeTextSearch={input?.shouldDisallowFreeTextSearch}
            onSearchSubmit={handleSearchInputSubmit}
            submitButtonText={input.mobileButtonText}
            onClose={handleSearchFullScreenInputClose}
            onSearchTextChange={handleSearchTextChange}
            editInput={{
              value: freetext,
              onInputChange: onChangeHandler,
              onClick: onSearchInputClick,
              onClear: clearInput,
            }}
            foundLocations={foundLocations}
            onLocationSelect={handleFoundLocationSelect}
          >
            <SearchAccessory
              data={{
                searchKeyword: freetext,
                foundLocations,
                searchOptions,
                recentSearches: {
                  title: recentSearches.title,
                  items: recentSearchesItems,
                },
                recentSearchFiltersConfig,
              }}
              onSelectEvents={{
                onLocationSelect: handleFoundLocationSelect,
                onOptionSelect: handleSearchOptionSelect,
                onSearchSelect: handleRecentSearchSelect,
              }}
              metadata={{
                dataAutomationId: dataAutomationId?.searchAccessory || '',
              }}
            />
          </SearchFullScreen>
        )}
      {accessoryData.show && accessoryData.type === AccessoryViewType.Location && (
        <LocationFilterModal
          isShown
          type={accessoryData.key}
          onClose={handleOnCloseLocationAccessory}
          onApply={handleOnApplyLocationAccessory}
          dataResolver={dataResolver}
          metadata={{
            dataAutomationId: dataAutomationId?.locationFilterModal || '',
          }}
        />
      )}
      {accessoryData.show && accessoryData.type === AccessoryViewType.MRT && mrtConfig && (
        <Suspense>
          <MRTSearchModal
            config={{
              ...mrtConfig,
              dataResolver,
              region,
              locale,
              logError,
              onClose: handleCloseMRTSearchModal,
              onApply: handleApplyMRTSearch,
            }}
            data={{
              isShown: true,
              selectedIds: selectedFilters.mrt?.ids ?? [],
            }}
            metadata={{
              dataAutomationId: dataAutomationId?.mrtSearchModal || '',
            }}
          />
        </Suspense>
      )}
    </div>
  );
};

SearchPanel.displayName = 'SearchPanel';

export default SearchPanel;
