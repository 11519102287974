import React from 'react';
import { InputGroup } from 'react-bootstrap';

import { PeripheralProps } from 'root/widgets/common-components/search-input/types';

const Peripheral: React.FC<PeripheralProps> = ({ children, className, onClick }) => (
  <InputGroup.Text className={className} onClick={onClick}>
    {children}
  </InputGroup.Text>
);

Peripheral.displayName = 'Peripheral';

export default Peripheral;
