import { DATA_AUTOMATION_ID } from 'root/widgets/constants';

/**
 * Helper function to create 'dataAutomationId' metadata object.
 *
 * @param dataAutomationId - string to be used as 'dataAutomationId'
 * @returns an object with 'dataAutomationId' as key
 */
export const createDataAutomationId = (dataAutomationId) => {
  if (!dataAutomationId || typeof dataAutomationId !== 'string') {
    return {};
  }

  return { [DATA_AUTOMATION_ID]: dataAutomationId };
};

/**
 * This function help to extract 'dataAutomationId' from config
 * then format it to 'data-automation-id' as key name
 * It will check into second level if first layer do not have 'dataAutomationId' in place.
 *
 *
 * @param config - object from config repository
 * @returns
 */
export const extractDataAutomationId = (config) => {
  if (!config) {
    return {};
  }

  if (DATA_AUTOMATION_ID in config) {
    return {
      'da-id': config.dataAutomationId,
      // @deprecated - to remove once automation team has migrated to `da-id`
      'data-automation-id': config.dataAutomationId,
    };
  }

  if (config.metadata && DATA_AUTOMATION_ID in config.metadata) {
    return {
      'da-id': config.metadata.dataAutomationId,
      // @deprecated - to remove once automation team has migrated to `da-id`
      'data-automation-id': config.metadata.dataAutomationId,
    };
  }

  return {};
};
