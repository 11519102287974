import axios, { AxiosRequestConfig } from 'axios';

import { RESPONSE_TIMEOUT } from 'root/global-wrappers/config';
import { RequestMethod } from 'root/global-wrappers/config/http';
import { HttpHeader } from 'root/global-wrappers/config/http-header';
import { RequestProps } from 'root/global-wrappers/types/http';
import { logFrontendException } from 'root/global-wrappers/utils/logging';

const name = 'global-wrappers.services.http';

/**
 * The `request` function is a wrapper for http calls.
 *
 * @param url - The endpoint to be hit
 * @param method - The HTTP method i.e. GET, POST, PATCH or PUT
 * @param options - Additional axios options e.g. timeout, headers, etc.
 *
 * @returns `object` for valid calls and `undefined` for any error or timeout
 */
export const request = ({ url, method = RequestMethod.GET, options = {} }: RequestProps): any => {
  const defaultResponseTimeout = RESPONSE_TIMEOUT;

  /**
   * use any type instead of AxiosRequestHeaders, because is expecting to use 'Content-Type',
   * while HTTP/2 expecting header field names to be converted to lowercase.
   */
  const headers: any = {
    [HttpHeader.ContentType]: 'application/json',
    ...options?.headers,
  };

  options = { ...options, headers };

  const axiosConfig: AxiosRequestConfig = {
    timeout: options.timeout || defaultResponseTimeout,
    ...options,
  };

  return axios({ method, url, ...axiosConfig })
    .then((response) => response)
    .catch((error) => {
      logFrontendException(name, request.name, { error, url });
      throw error;
    });
};
