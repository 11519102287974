import classNames from 'classnames';
import React from 'react';
import { Accordion, Nav } from 'react-bootstrap';

import NavLinkItem from 'root/widgets/global-components/navbar/sub-components/common/nav-link-item';
import { NavbarVariant } from 'root/widgets/global-components/navbar/constants';
import { SideNavTopProps } from 'root/widgets/global-components/navbar/types';
import { SvgIcon } from 'root/widgets/svg-icon';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import Actionable from 'root/widgets/common-components/actionable';

const SideNavTopSection: React.FC<SideNavTopProps> = (props) => {
  const { loginDetail, myActivityConfig, myAccountLinks, onLoginClick, onMyAccountClick, onLogoutClick, variant } =
    props;
  const { isLoggedIn, login, logout } = loginDetail;

  const handleMyAccountClick = () => {
    if (onMyAccountClick) {
      onMyAccountClick(myAccountLinks?.title ?? '');
    }
  };

  const renderLoginOrLogout = () =>
    isLoggedIn ? (
      <ActionableLink className="logout-button" onClick={onLogoutClick} {...extractDataAutomationId(logout)}>
        {logout.sideNavText}
      </ActionableLink>
    ) : (
      <div className="login-block">
        <div className="separator" />
        <p className="login-block-title">{login.sideNavPrompt}</p>
        <Actionable className="login-button" onClick={onLoginClick} {...extractDataAutomationId(login)}>
          {login.sideNavText}
        </Actionable>
      </div>
    );

  const renderMyActivity = () =>
    myActivityConfig && (
      <Nav.Link className="my-activity-link accordion-item" href={myActivityConfig.href}>
        <SvgIcon className="my-activity-icon" src={myActivityConfig.icon} width={16} height={16} shouldUseImage />
        {myActivityConfig.text}
      </Nav.Link>
    );

  const shouldShowMyAccountLinks = isLoggedIn && myAccountLinks && myAccountLinks?.items?.length;

  return (
    <div className={classNames('side-nav-top', { 'pg-finance-variant': variant === NavbarVariant.PG_FINANCE })}>
      {myActivityConfig && renderMyActivity()}
      {shouldShowMyAccountLinks && (
        <>
          <div className="separator" />
          <Accordion bsPrefix="my-account-accordion" className="accordion-item">
            <Accordion.Item
              bsPrefix="my-account-accordion-item"
              eventKey="my-account-links"
              onClick={handleMyAccountClick}
            >
              <Accordion.Header bsPrefix="my-account-accordion-header" {...extractDataAutomationId(myAccountLinks)}>
                <span className="my-account-accordion-button-text">{myAccountLinks?.title}</span>
                <i className="pgicon-arrow-down" />
              </Accordion.Header>
              <Accordion.Body bsPrefix="my-account-accordion-body nav-link-list">
                {myAccountLinks?.items.map((link) => <NavLinkItem key={link.text} link={link} />)}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}
      {renderLoginOrLogout()}
    </div>
  );
};

export default SideNavTopSection;
