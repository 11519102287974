import classnames from 'classnames';
import React from 'react';

import './color-badge.scss';
import { ColorBadgeProps } from './types';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const ColorBadge: React.FC<ColorBadgeProps> = (props) => {
  const { colors, className, metadata } = props;

  /**
   * Create a badge of multiple colors,
   * ex. color 1 ['#FF9F18'] : linear-gradient(90deg, rgb(227, 34, 22) 0, rgb(227, 34, 22) 100%);
   * ex. color 3 ['#E32216', '#009437', '#9E27B5'] : linear-gradient(90deg, rgb(227, 34, 22) , rgb(227, 34, 22) 33%, rgb(0, 148, 55) 33%, rgb(0, 148, 55) 66%, rgb(158, 39, 181) 66% rgb(158, 39, 181) 100%)
   * @returns
   */
  const createBadge = () =>
    colors
      .map(
        (color, idx) =>
          `${color} ${(100 * (idx / colors.length)).toFixed(0)}% ${((100 * (idx + 1)) / colors.length).toFixed(0)}%`,
      )
      .join(',');

  return (
    <div
      className={classnames('color-badge-root', className)}
      style={{
        background: `linear-gradient(90deg, ${createBadge()})`,
      }}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    />
  );
};

ColorBadge.displayName = 'ColorBadge';

export default ColorBadge;
