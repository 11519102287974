import classNames from 'classnames';
import React from 'react';

import { InactiveListingViewProps } from 'root/widgets/past-enquiry-view/types';
import ActionStrip from 'root/widgets/common-components/action-strip';
import Actionable from 'root/widgets/common-components/actionable';

const InactiveListingView: React.FC<InactiveListingViewProps> = ({ body, action }) => (
  <div className="enquiry-card-inactive-listing-root">
    <p className="enquiry-card-inactive-listing-body hui-heading3">{body}</p>
    {action && (
      <ActionStrip>
        <Actionable
          variant="outline-secondary"
          {...action}
          className={classNames('enquiry-card-inactive-listing-btn', action.className)}
        >
          {action.value}
        </Actionable>
      </ActionStrip>
    )}
  </div>
);

export default InactiveListingView;
