import classnames from 'classnames';
import { Popover } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';

import Actionable from 'root/widgets/common-components/actionable';

import { SharingActionProps } from 'root/widgets/shortlist-share-button/types';

const getInvitePopover =
  (title: string, actions: SharingActionProps[], className: string, metadata = {}) =>
  // eslint-disable-next-line react/function-component-definition
  (props: OverlayInjectedProps) => (
    <Popover {...props} className={classnames('sharing-popover-root', className)} {...metadata}>
      <div className="sharing-popover-title">{title}</div>
      <div className="sharing-popover-actions">
        {actions.map(({ text, description, buttonProps }) => (
          <Actionable key={text} {...buttonProps} className={classnames(buttonProps?.className)}>
            <div className="sharing-popover-action-tile">
              <div className={classnames('sharing-popover-action-heading')}>{text}</div>
              <div className={classnames('sharing-popover-action-description')}>{description}</div>
            </div>
          </Actionable>
        ))}
      </div>
    </Popover>
  );
export default getInvitePopover;
