import classNames from 'classnames';
import React from 'react';

import { DividerProps } from './types';

const Divider: React.FC<DividerProps> = ({ title, className }) => (
  <div className={classNames('divider-root', className)}>
    <hr className="line" />
    {title && (
      <div className="title-wrapper">
        <div className="title">{title}</div>
      </div>
    )}
  </div>
);

Divider.displayName = 'Divider';

export default Divider;
