import React from 'react';

import { ENV, REGION } from 'root/widgets/constants';

import { VIEW } from './constants';
import { PGLoginContextType } from './types';

const defaultValue: PGLoginContextType = {
  env: ENV.INTEGRATION,
  region: REGION.SG,
  shouldUseShortFlow: false,
  view: VIEW.LOGIN,
  otpRecipientId: '',
  email: '',
  otpId: '',
  userInfo: {
    accessToken: '',
    refreshToken: '',
  },
  consentChecks: {},
  context: {},
  shouldShowLoginConsent: false,
  changeView: () => {},
  setEmail: () => {},
  setOtpId: () => {},
  setUserInfo: () => {},
  setConsentChecks: () => {},
  onSuccess: async () => {},
  onClose: () => {},
};

const PGLoginContext = React.createContext<PGLoginContextType>(defaultValue);

export default PGLoginContext;
