import classnames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import './rating-input.scss';
import { RatingInputProps } from './types';

const RatingInput: React.FC<RatingInputProps> = ({ metadata, className, labels, placeholder, onChange }) => {
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);

  const isFilled = (index: number) => hoveredRating >= index || (!hoveredRating && rating >= index);

  const updateRating = (selectedValue: number) => {
    setRating(selectedValue);
    onChange(selectedValue);
  };

  const getRatingText = () => {
    if (hoveredRating) {
      return labels[hoveredRating - 1];
    }

    return rating > 0 ? labels[rating - 1] : placeholder;
  };

  const stars = useMemo(
    () =>
      Array.from({ length: labels.length }).map((_, index) => {
        const idx = index + 1;
        return (
          <i
            key={idx}
            className={classnames('pgicon-star star', {
              filled: isFilled(idx),
            })}
            onClick={() => updateRating(idx)}
            onMouseEnter={() => setHoveredRating(idx)}
            onMouseLeave={() => setHoveredRating(0)}
            aria-hidden="true"
          />
        );
      }),
    [rating, hoveredRating],
  );

  return (
    <Row className={classnames('rating-input-root', className)} {...metadata}>
      <Col className="rating-div">{stars}</Col>
      <Col className={classnames('rating-indicator-text', { 'placeholder-text': rating === 0 })}>{getRatingText()}</Col>
    </Row>
  );
};

RatingInput.displayName = 'RatingInput';

export default RatingInput;
