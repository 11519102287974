import { useSize } from 'ahooks';
import classNames from 'classnames';
import { FC, useEffect, useRef } from 'react';

import { ExtendedViewProps } from 'root/widgets/contact-agent-card-v3/types';
import { ContactButton } from 'root/widgets/contact-button';
import { applyViewHeight } from 'root/widgets/utils/dom';

const ExtendedView: FC<ExtendedViewProps> = (props) => {
  const { triggerButton, actions } = props;

  const extendedViewRef = useRef<HTMLDivElement>(null);
  const extendedContentRef = useRef<HTMLDivElement>(null);

  const elementSize = useSize(extendedContentRef);

  useEffect(() => {
    applyViewHeight(extendedViewRef.current, extendedContentRef.current);
  }, [elementSize?.height]);

  return (
    <div className="extended-view-root" da-id="extended-view-btn">
      <ContactButton
        className={classNames('extend-view-trigger-point', { extended: actions.length > 0 })}
        {...triggerButton}
      />
      <div className="extended-view" ref={extendedViewRef}>
        <div className={classNames('extended-content', { visible: actions.length > 0 })} ref={extendedContentRef}>
          {actions.map((action, index) => (
            <ContactButton key={`extended-contact-agent-${index}`} {...action} />
          ))}
        </div>
      </div>
    </div>
  );
};

ExtendedView.displayName = 'ExtendedView';

export default ExtendedView;
