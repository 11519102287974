import React from 'react';
import { Popover as BSPopover } from 'react-bootstrap';

import { Header, Body } from './sub-components';
import { PopoverProps } from './types';
import classNames from 'classnames';
import Actionable from 'root/widgets/common-components/actionable';
import { SvgIcon } from 'root/widgets/svg-icon';
import { HIVE_STATIC_VERSION } from 'root/widgets/constants';

const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(
  ({ className, variant, children, closeButton, closeButtonAutomationId, handleClose, ...props }, ref) => (
    <BSPopover ref={ref} className={classNames(variant, className)} {...props}>
      <div className={classNames({ 'popover-with-close': closeButton })}>
        {children}
        {closeButton && (
          <Actionable
            variant="link"
            data-automation-id={closeButtonAutomationId}
            onClick={handleClose}
            className="popover-close"
          >
            <SvgIcon
              width={12}
              src={`https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/cancel.svg`}
            />
          </Actionable>
        )}
      </div>
    </BSPopover>
  ),
);
export default Object.assign(Popover, {
  Header,
  Body,
});
