import { HIVE_STATIC_VERSION, MARKETPLACE, REGION } from 'root/widgets/constants';
import { PGLoginConfigData } from 'root/widgets/pg-login/types';

const commonConfig = {
  AUTH_API_HOST: 'https://auth.guruestate.com',
  USERS_API_HOST: 'https://users-api.kgw.guruestate.com',
  GOOGLE_CLIENT_ID: '367223124563-nqbh60veth48kr448f62mflkasd0kpmp.apps.googleusercontent.com',
  FACEBOOK_CLIENT_ID: '751052508373579',
  APPLE_CLIENT_ID: 'com.propertyguru.www',
  PG_LOGO_URL: `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/logo/pg.svg`,
};
const config: Record<string, PGLoginConfigData> = {
  [`${REGION.SG}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '367223124563-is5hdjeal1rr7og4i8ii7t8imihr1dg1.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '1206925822667227',
    HOST: 'https://www.propertyguru.com.sg',
  },
  [`${REGION.SG}-${MARKETPLACE.CG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '185477762030-iqh4i9ugh5sqf9f6cl7jr8ul7bbdmqft.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '232041134466162',
    HOST: 'https://www.commercialguru.com.sg',
  },
  [`${REGION.MY}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '397676370154-1knr9c57ip1rq0rc5l8fjsdhjqu29svq.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '1627438264181015',
    HOST: 'https://www.propertyguru.com.my',
  },
  [`${REGION.MY}-${MARKETPLACE.IPP}`]: {
    ...commonConfig,
    APPLE_CLIENT_ID: 'com.iproperty.accounts.auth',
    GOOGLE_CLIENT_ID: '313711791759-7rglk89vncg2dbgk7i8oca0hts6lq7q5.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '428252861166972',
    HOST: 'https://www.iproperty.com.my',
    PG_LOGO_URL: 'https://accounts.iproperty.com.my/assets/1730099992/img/ipp/logo-consumer.svg',
    FORGOT_PASSWORD_URL: 'https://accounts.iproperty.com.my/forgot-password?client_id=cb4cec3b-cbfd-4cbd-81fa-0334b8427c1b',
  },
  [`${REGION.TH}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '711575025382-6p9eqj570n5ktuk9lmdsa4aj0jol9pnu.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '525398830968327',
    HOST: 'https://www.ddproperty.com',
  },
};

export default config;
