import classNames from 'classnames';
import { FC } from 'react';
import { Image } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';

import { DEFAULT_IMAGE } from 'root/widgets/constants';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './empty-view.scss';
import { EmptyViewProps } from './types';

const EmptyView: FC<EmptyViewProps> = ({ className, heading, body, button, image, metadata, ...props }) => (
  <div
    className={classNames('empty-view-root')}
    {...filterMetadata(metadata)}
    {...extractDataAutomationId(metadata)}
    {...props}
  >
    <div className="empty-view-image">
      {image && <Image {...image} fluid src={image.src || DEFAULT_IMAGE} alt={image.alt || 'error'} />}
    </div>
    <div className="empty-view-info">
      <h2 className="empty-view-heading">{heading}</h2>
      <p className="empty-view-body">{body}</p>
      {button && (
        <Actionable
          className={classNames('empty-view-button', button.className)}
          variant={button.variant || 'secondary'}
          onClick={button.onClick}
        >
          {button.value}
        </Actionable>
      )}
    </div>
  </div>
);

export default EmptyView;
