import classNames from 'classnames';
import React from 'react';

import { SvgIcon } from 'root/widgets/svg-icon';

import './agent-contact-info.scss';
import type { AgentContactInfoProps } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import { Divider } from 'root/widgets/common-components/divider';
import ActionStrip from 'root/widgets/common-components/action-strip';
import Actionable from 'root/widgets/common-components/actionable';

/* eslint-disable react/no-danger */
const AgentContactInfo: React.FC<AgentContactInfoProps> = (props) => {
  const { agent, actions, metadata } = props;
  const { id, avatar, name, rating, profileUrl } = agent;

  return (
    <div className="agent-contact-info-root" data-agent-id={id} {...metadata}>
      <div className="agent-profile-name-root">
        <a className="agent-link" href={profileUrl} title={name} target="_blank" rel="noreferrer">
          {avatar ? <img className="avatar" src={avatar} alt={name} /> : <i className="avatar pgicon pgicon-agent" />}
        </a>
        <div className="agent-name-rating-root">
          <a className="agent-link" href={profileUrl} title={name} target="_blank" rel="noreferrer">
            <p className="agent-name">{name}</p>
          </a>
          {rating && (
            <ActionableLink
              className={classNames('agent-rating')}
              href={rating.href}
              onClick={rating.onClick}
              target="_blank"
            >
              {rating.showScore && rating.score && <i className="pgicon-star" />}
              {rating.showScore && rating.score}
              {rating.text && <span className="rating-text">{rating.text}</span>}
              <i className="pgicon-arrow-right" />
            </ActionableLink>
          )}
        </div>
      </div>
      <Divider />
      <ActionStrip>
        {actions.map((action) => {
          const content = (
            <>
              {action.icon.className && (
                <i className={classNames('pgicon', action.icon.className, action.icon.color)} />
              )}
              {action.icon.svgSrc && <SvgIcon src={action.icon.svgSrc} shouldUseImage />}
              <span>{action.text}</span>
            </>
          );

          return (
            <Actionable
              key={action.text}
              variant={action.variant || 'outline-secondary'}
              onClick={action.onClick}
              className={action.isMobileOnly ? 'md-only' : ''}
              metadata={action.metadata}
            >
              {content}
            </Actionable>
          );
        })}
      </ActionStrip>
    </div>
  );
};

export default AgentContactInfo;
