import { RefObject, forwardRef, useCallback, useEffect, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';

import { LineRowProps } from 'root/widgets/mrt-search-modal/types';

import StationRow from './station-row';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Divider } from 'root/widgets/common-components/divider';
import { Badge } from 'root/widgets/common-components/badge';

/**
 * LineRow represent specific "MRT Line",
 * it has accordion list contain statins inside this line
 * use 'ref' for auto scrolling in mobile mode,
 * in mobile, when click 'Line button', the view would switch to list view,
 * and selected Line would be auto scroll into view
 */
const LineRow = forwardRef(
  (
    {
      line,
      onLineSelect,
      checked,
      onStationCheck,
      onStationClick,
      selectedStations,
      shouldShowFutureLines,
      futureStations,
    }: LineRowProps,
    ref: RefObject<HTMLDivElement>,
  ) => {
    const [isIndeterminate, setIsIndeterminate] = useState<boolean>(false);

    useEffect(() => {
      let lineStations = [...line.stations];
      if (futureStations?.length && !shouldShowFutureLines) {
        lineStations = lineStations.filter((station) => !futureStations.includes(station.id));
      }
      setIsIndeterminate(selectedStations.length > 0 && lineStations.length > selectedStations.length);
    }, [selectedStations, shouldShowFutureLines]);

    const shouldRenderStation = useCallback(
      (stationId: string) => {
        if (
          !futureStations ||
          shouldShowFutureLines ||
          (!shouldShowFutureLines && !futureStations?.includes(stationId))
        ) {
          return true;
        }
        return false;
      },
      [shouldShowFutureLines, futureStations],
    );

    return (
      <Accordion.Item ref={ref} eventKey={line.id} key={line.id}>
        <Form.Group className="line-row" da-id="mrt-line-row">
          <Accordion.Header as="h5" da-id="mrt-line-button">
            <div className="d-flex align-items-center justify-content-between flex-grow-1">
              <div className="d-flex align-items-center">
                <div
                  className="line-badge"
                  style={{
                    background: line.color,
                  }}
                >
                  <span className="line-name" da-id="mrt-line-badge">
                    {line.id}
                  </span>
                </div>
                {line.name}
              </div>
              {selectedStations.length > 0 && <Badge type="round" action="count" count={selectedStations.length} />}
            </div>
          </Accordion.Header>
        </Form.Group>
        <Accordion.Body>
          <Form.Group className="station-row" onClick={() => onLineSelect(line.id, checked)} da-id="mrt-station-row">
            <span className="station-name" da-id="mrt-station-name">
              All {line.name} Stations
            </span>
            <Checkbox
              onChange={() => onLineSelect(line.id, checked)}
              indeterminate={isIndeterminate}
              checked={checked}
              id={line.id}
              metadata={{ dataAutomationId: 'mrt-line-checkbox' }}
            />
          </Form.Group>
          <Divider />
          {line.stations.map(
            (station) =>
              shouldRenderStation(station.id) && (
                <StationRow
                  key={station.id}
                  station={station}
                  onStationCheck={onStationCheck}
                  onStationClick={onStationClick}
                  checked={selectedStations.includes(station.id)}
                />
              ),
          )}
        </Accordion.Body>
        <Divider />
      </Accordion.Item>
    );
  },
);

LineRow.displayName = 'LineRow';

export default LineRow;
