import classnames from 'classnames';
import { FC, FormEvent, Fragment, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Typography } from 'root/common/atom-elements/typography';

import { ExpandableListProps } from 'root/widgets/search-with-filter/sub-components/property-type-filter/types';

const ExpandableList: FC<ExpandableListProps> = ({ item, selectedType, setSelectedType }) => {
  const hasOptions = Boolean(item?.subTypeOptions?.length);
  // if selected the code, or code is null and non-selected (condition 'All')
  const isSelected = selectedType.subTypes[0] === item.code || (!item.code && selectedType.subTypes.length === 0);
  const options = item.subTypeOptions ?? [];

  const [isExpanded, setExpanded] = useState(isSelected && hasOptions);

  const handleCheckboxChange = (e: FormEvent, subOptionCode: string | null) => {
    e.stopPropagation();

    if (subOptionCode && selectedType.subTypeOptions.includes(subOptionCode)) {
      selectedType.subTypeOptions = selectedType.subTypeOptions.filter((code) => code !== subOptionCode);
    } else if (subOptionCode) {
      selectedType.subTypeOptions.push(subOptionCode);
    }
    setSelectedType({ ...selectedType, subTypeOptions: selectedType.subTypeOptions });
  };

  const handleClickRadioInput = () => {
    setExpanded(!isExpanded);
    const subTypes = item.code ? [item.code] : [];

    setSelectedType({
      ...selectedType,
      subTypes,
      subTypeOptions: item?.subTypeOptions?.map((subTypeOption) => subTypeOption.code) ?? [],
    });
  };

  useEffect(() => {
    if (selectedType.subTypes[0] !== item.code) {
      setExpanded(false);
    }
  }, [selectedType.subTypes]);

  const optionsSelected = selectedType.subTypeOptions.length;

  return (
    <Fragment key={item.code}>
      <div
        className={classnames('list-item', {
          selected: isSelected,
        })}
      >
        <Form.Group>
          <Form.Check
            onClick={handleClickRadioInput}
            onChange={handleClickRadioInput}
            className="radio"
            type="radio"
            id={item.name}
            checked={isSelected}
            label={item.name}
          />
        </Form.Group>
        {hasOptions && isSelected && optionsSelected > 0 && (
          <div className="mini-label">
            <div>{options.length === optionsSelected ? 'All' : `${optionsSelected} Selected`}</div>
          </div>
        )}
      </div>
      <div
        className={classnames('list-sub-types', {
          expanded: isExpanded,
        })}
      >
        {options?.map((subTypeOption, idx) => (
          <Form.Group className="list-item" key={subTypeOption.code}>
            <div className="sub-types-options d-flex align-items-center gap-2 py-2">
              <div className="mx-3">
                <Checkbox
                  className={classnames({
                    'last-item': idx === options.length - 1,
                  })}
                  checked={selectedType.subTypeOptions.includes(subTypeOption.code)}
                  id={subTypeOption.name}
                  onChange={(e) => handleCheckboxChange(e, subTypeOption.code)}
                />
              </div>
              <Typography htmlFor={subTypeOption.name} element="label" variant="label-xs">
                {subTypeOption.name}
              </Typography>
            </div>
          </Form.Group>
        ))}
      </div>
    </Fragment>
  );
};

ExpandableList.displayName = 'ExpandableList';

export default ExpandableList;
