import classnames from 'classnames';
import { useContext, FC } from 'react';
import { Dropdown } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';

import './filter.scss';
import { FilterProps } from './types';

const Filter: FC<FilterProps> = ({
  children,
  className,
  dropdownTitle,
  selectedValueStr,
  toggleBtnText,
  toggleBtn,
  clearAction,
  applyAction,
  dropdownRef,
  onToggle,
  isShown,
}) => {
  const { stack } = useContext(SearchWithFilterContext);
  const { isDisabled: isClearActionDisabled, ...clearActionProps } = clearAction || {};
  const { isDisabled: isApplyActionDisabled, ...applyActionProps } = applyAction || {};

  const handleToggleDropdown = (isOpen, event) => {
    // isTrusted is invoked false if it is invoked by the script, dont invoke callback
    if ('isTrusted' in event.originalEvent && event.originalEvent.isTrusted === false) return false;

    // if dropdown is closed by user invoke a callback
    onToggle?.(isOpen, event);

    return true;
  };

  return (
    <Dropdown
      ref={dropdownRef}
      className={classnames('filter-root', className, stack)}
      show={isShown}
      onToggle={handleToggleDropdown}
    >
      {toggleBtn || (
        <Dropdown.Toggle variant="outline-secondary" className="dropdown-toggle">
          <span className="toggle-btn-text">{selectedValueStr || toggleBtnText}</span>
          <i className="pgicon-arrow-down" />
        </Dropdown.Toggle>
      )}

      <Dropdown.Menu className="dropdown-menu">
        <div className="dropdown-title">{dropdownTitle}</div>
        <div className="dropdown-body">{children}</div>
        <div className="dropdown-actions">
          <Actionable disabled={isClearActionDisabled} variant="secondary" className="cancel" {...clearActionProps}>
            {clearAction.text}
          </Actionable>
          <Actionable disabled={isApplyActionDisabled} variant="dark" {...applyActionProps}>
            {applyAction.text}
          </Actionable>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

Filter.displayName = 'Filter';

export default Filter;
