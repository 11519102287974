import classnames from 'classnames';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './hui-range-input.scss';
import RangeSegmentInput from './sub-components/range-segment-input';
import { HUIRangeInputProps } from './types';

const HUIRangeInput: React.FC<HUIRangeInputProps> = ({ className, min, max, onChange, metadata }) => {
  const handleMinChange = (value) => {
    onChange({ min: value, max: max.value });
  };

  const handleMaxChange = (value) => {
    onChange({ min: min.value, max: value });
  };

  return (
    <div
      className={classnames('hui-range-input-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <Row>
        <Col>
          <RangeSegmentInput {...min} onChange={handleMinChange} />
        </Col>
        <Col>
          <RangeSegmentInput {...max} onChange={handleMaxChange} />
        </Col>
      </Row>
    </div>
  );
};

HUIRangeInput.displayName = 'HUIRangeInput';

export default HUIRangeInput;
