import { PGLoginTranslationData } from 'root/widgets/pg-login/types';

const translation: PGLoginTranslationData = {
  loginTitle: 'Welcome to DDproperty',
  loginDescription: 'Log in or sign up to get the most out of your DDproperty experience.',
  loginWithGoogle: 'Continue with Google',
  loginWithFacebook: 'Continue with Facebook',
  loginWithApple: 'Continue with Apple',
  emailInputPlaceholder: 'Email address',
  continueBtn: 'Continue',
  dividerTitle: 'Or',
  agentLoginPrompt: {
    label: 'Are you an agent?',
    linkTitle: 'Log In to Agent Net',
  },
  googleLoginErrorInPrivateMode:
    "Please update your browser's settings to allow cookies or try using without incognito/private mode to continue with social login.",
  signUpTitle: 'Sign Up For A New Account',
  signUpDescription:
    'Looks like you don’t have an account with that email address, sign up to get all of these benefits:',
  signUpInstructions: [
    'Track your past property enquiries with ease',
    'Gain quick access to your saved searches and shortlisted homes​',
    'Sync your property preferences across all your devices',
  ],
  verifyBtn: 'Verify My Email',
  signupCheckboxPrompts: {
    consent:
      'I accept AllProperty Media Co Ltd’s (‘APM’) Privacy Policy and your collection, use and disclosure of my personal data.',
    subscribe: 'Send me direct marketing from APM according to Privacy Policy.',
  },
  verifyEmailTitle: 'Verify Your Email',
  verifyEmailDescription: (email) => (
    <>
      We sent you a code at <strong>{email}</strong>. Please check and enter the code here to continue signing up.
    </>
  ),
  resendAction: 'Resend Code',
  setPasswordTitle: 'Your Account Has Been Successfully Created!​',
  setPasswordModalTitle: 'Account Created',
  setPasswordDescription:
    'We recommend that you create a password for better security and ease of access to your account in the future.​',
  passwordInputPlaceholder: 'Password',
  savePasswordBtn: 'Save My Password',
  skipAction: 'Skip For Now',
  otpLoginTitle: 'Log In With OTP',
  otpLoginDescription: (email) => (
    <>
      Hello <strong>{email}</strong>, we’ve just sent the code to your email. Please check and enter the code here to
      continue logging in.
    </>
  ),
  passwordLoginBtn: 'Log In With Password',
  otpLoginBtn: 'Log In With OTP',
  passwordLoginTitle: 'Log In With Password',
  passwordLoginDescription: (email) => (
    <>
      Hello <strong>{email}</strong>, please input your password to log in to DDproperty.
    </>
  ),
  invalidPassword: 'Please enter valid password',
  loginBtn: 'Log In',
  forgotPasswordAction: 'Forgot Password',
  forgotPasswordTitle: 'Reset Password',
  forgotPasswordDescription: 'Please enter your email address in the form below and we will send you a new password.',
  resetPasswordBtn: 'Reset My Password',
  checkSpamPrompt: (
    <>
      Didn’t receive email?
      <br />
      Please check your spam folder
    </>
  ),
  footer: (
    <>
      I agree to DDproperty&#39;s <a href="/terms-of-service">Terms of Service</a> and{' '}
      <a href="/privacy">Privacy Policy</a> including the collection, use and disclosure of my personal information.
    </>
  ),
  errorMessages: {
    emailValidation: 'Please input valid email address.',
    socialLogin: "Sorry, we're experiencing some issues with social login. Please try again.",
    server: "Sorry, we're experiencing some server issues. Please try again.",
    charLengthValidation: 'Must be at least 8 characters',
    alphaNumericValidation: 'Must contain alpha-numeric characters',
    capitalLetterValidation: 'Must contain capital letter',
    specialCharValidation: 'Must contain special character',
    invalidCode: 'Invalid code. Try again or request a new one.',
    otpGenerationLimit: "We're sorry but you cannot request another code now. Please try again after a few minutes.",
    otpSubmissionLimit: "We're sorry but we cannot process your request now. Please try again after a few minutes.",
    accountDeleted:
      'Sorry, this email address cannot be used right now. Please sign up for a new account with a different email address.',
  },
  verifyPhoneTitle: 'Verify Your Mobile Number',
  verifyEmailConsent: (email) => (
    <>
      We sent you a code at <strong>{email}</strong>. Please enter the code here to continue verifying.
    </>
  ),
  verifyPhoneConsent: (phone) => (
    <>
      We sent you a code via SMS to <strong>{phone}</strong>. Please enter the code here to continue verifying.
    </>
  ),
};

export default translation;
