import classnames from 'classnames';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import './search-input.scss';
import Editable from './sub-components/editable';
import Peripheral from './sub-components/peripheral';
import { SearchInputProps } from './types';

const SearchInput: React.FC<SearchInputProps> = ({
  className,
  children,
  onSubmit,
  onChange,
  onFocus,
  onBlur,
  label,
}) => (
  <Form
    className={classnames('search-input-root', className)}
    onFocus={onFocus}
    onBlur={onBlur}
    onChange={onChange}
    onSubmit={onSubmit}
  >
    <Form.Group>
      <Form.Text>{label}</Form.Text>
      <InputGroup>{children}</InputGroup>
    </Form.Group>
  </Form>
);

export default Object.assign(SearchInput, {
  Peripheral,
  Editable,
});
