import { FC } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { QRCodeViewProps } from 'root/widgets/contact-agent-card-v3/types';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';

const QRCodeView: FC<QRCodeViewProps> = ({ description, iconUrl, url, isLoading }) => (
  <div className="qr-code-view-root">
    <div className={classNames('qr-code-group', { loading: isLoading })}>
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <QRCodeSVG value={url} size={160} imageSettings={{ src: iconUrl, width: 40, height: 40, excavate: false }} />
      )}
    </div>
    <div className="qr-code-description">{description}</div>
  </div>
);

QRCodeView.displayName = 'QRCodeView';

export default QRCodeView;
