import classnames from 'classnames';
import React, { useState, useEffect } from 'react';

import './chip-group-input.scss';
import { ChipGroupInputProps } from './types';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { Badge } from 'root/widgets/common-components/badge';

export const ChipGroupInput: React.FC<ChipGroupInputProps> = ({
  selectedItems,
  type,
  items,
  radioName,
  variant = 'basic',
  isNotSelectable,
  onChange,
  metadata,
  className,
}) => {
  const [selectedValues, setSelectedValues] = useState(selectedItems);
  const handleCheckboxChange = (val) => {
    if (!isNotSelectable) {
      if (type === 'checkbox') {
        setSelectedValues(val);
      } else {
        setSelectedValues([val]);
      }
    }

    onChange(val);
  };

  useEffect(() => {
    setSelectedValues(selectedItems);
  }, [selectedItems]);

  const isSpaceBetween = variant !== 'basic';

  return (
    <div
      className={classnames('chip-group-input-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      {type === 'checkbox' ? (
        <ToggleButtonGroup
          className={classnames('chip-group', { 'bg-secondary gap-3': isSpaceBetween }, { 'p-1': !isSpaceBetween })}
          size="sm"
          key={radioName}
          type="checkbox"
          value={selectedValues}
          onChange={handleCheckboxChange}
          da-id="chip-group-input-root"
        >
          {items.map((item) => (
            <ToggleButton key={`${item.id}-${radioName}`} id={item.id} value={item.value} da-id={item.id}>
              {item.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      ) : (
        <ToggleButtonGroup
          className={classnames('chip-group', { 'bg-secondary gap-3': isSpaceBetween }, { 'p-1': !isSpaceBetween })}
          size="sm"
          key={radioName}
          type="radio"
          value={selectedValues[0]}
          name={radioName || 'radio-button-group-name'}
          onChange={handleCheckboxChange}
          da-id="chip-group-input-root"
        >
          {items.map((item) => (
            <ToggleButton
              key={`${item.id}-${radioName}`}
              className={classnames({ sticky: !isSpaceBetween })}
              id={item.id}
              value={item.value}
              da-id={item.id}
            >
              {item.label}
              {item.shouldHighlight && <Badge type="round" action="count" className="highlight" />}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </div>
  );
};
