import classnames from 'classnames';
import { forwardRef, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import Header from 'root/widgets/common-components/header';
import Label from 'root/widgets/common-components/label';
import Title from 'root/widgets/common-components/title';
import type { BaseProps } from 'root/widgets/types';

import './card-group.scss';
import Body from './sub-components/body';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const CardGroup = forwardRef((props: BaseProps, ref) => {
  const { children, className, metadata } = props;
  const localRef = useRef(null);
  const cardGroupRef = ref || localRef;

  return (
    <Container className="card-group-container-root">
      <Row className="justify-content-center">
        <Col
          ref={cardGroupRef}
          className={classnames('card-group-root', className)}
          {...filterMetadata(metadata)}
          {...extractDataAutomationId(metadata)}
        >
          {children}
        </Col>
      </Row>
    </Container>
  );
});

CardGroup.displayName = 'CardGroup';

export default Object.assign(CardGroup, {
  Header,
  Body,
  Title,
  Actionable,
  ActionableLink,
  Label,
});
