import classNames from 'classnames';
import { FC } from 'react';

import './agent-card.scss';
import LoadingView from './sub-components/agent-card-loading-view';
import { AgentMetaInfo } from './sub-components/agent-meta-info';
import { AgentCardProps } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { Badge } from 'root/widgets/common-components/badge';
import Title from 'root/widgets/common-components/title';
import { AgentRating } from 'root/widgets/common-components/agent-rating';
import { Divider } from 'root/widgets/common-components/divider';
import Actionable from 'root/widgets/common-components/actionable';
import { isMobile } from 'root/widgets/utils/validation';
import HUIImage from 'root/widgets/common-components/hui-image';

const AgentCard: FC<AgentCardProps> = (props) => {
  const {
    action,
    className,
    metadata,
    children,
    name,
    jobTitle,
    avatar,
    badge,
    profileUrl,
    onClick,
    agency: { name: agencyName, logo },
    rating,
    metaInfoHeading,
    shouldForceMobileView = false,
    shouldOpenUrlInNewTab = false,
  } = props;

  return (
    <article
      className={classNames('agent-card-root', className, {
        'show-placeholder-avatar': !avatar,
        'mobile-view': shouldForceMobileView,
      })}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <ActionableLink
        href={profileUrl}
        onClick={onClick}
        target={shouldOpenUrlInNewTab ? '_blank' : '_self'}
        className="agent-card-overlay-link"
      />
      <div className="agent-card-left-section">
        {badge && (
          <Badge type="small" bg="orange">
            {badge}
          </Badge>
        )}
        <div className="agent-card-left-section-content">
          <div className="agent-blur-image" style={{ backgroundImage: `url(${avatar})` }} />
          <figure className="agent-avatar">
            {avatar ? <HUIImage src={avatar} alt={name} /> : <i className="pgicon-agent" />}
          </figure>
          <div className="agent-avatar-overlay">
            <div className="agency-info-wrap">
              <figure className={classNames('agency-logo', { 'show-placeholder-logo': !logo })}>
                {logo ? <img src={logo} alt={agencyName} /> : <i className="pgicon-agent" />}
              </figure>
              <div className="agency-info">
                {jobTitle && <span className="job-title">{jobTitle}</span>}
                <span className="agency-name">{agencyName}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="agent-card-right-section">
        <div className="agent-card-info">
          <Title headerTag="h4" className="agent-name">
            {name}
          </Title>
          {rating && <AgentRating {...rating} shouldOpenUrlInNewTab={shouldOpenUrlInNewTab} />}
        </div>
        {(metaInfoHeading || children) && <Divider />}
        {metaInfoHeading && children && (
          <Title headerTag="h5" className="agent-meta-info-heading">
            {metaInfoHeading}
          </Title>
        )}
        <div className="agent-meta-info-fields">{children}</div>
        {action && (
          <Actionable
            {...extractDataAutomationId(action?.metadata)}
            className="agent-view-profile-btn"
            size={isMobile() || shouldForceMobileView ? 'lg' : 'sm'}
            href={action.href}
            target={shouldOpenUrlInNewTab ? '_blank' : '_self'}
            onClick={action.onClick}
          >
            {action?.text}
          </Actionable>
        )}
      </div>
    </article>
  );
};

AgentCard.displayName = 'AgentCard';

export default Object.assign(AgentCard, {
  MetaInfo: AgentMetaInfo,
  LoadingView,
});
