import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { AgentBadge } from 'root/widgets/agent-badge';
import { AGENT_DEFAULT_IMAGE, HIVE_STATIC_VERSION } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { SharingButton } from 'root/widgets/sharing-button';
import './agent-profile-info.scss';
import { AgentProfileInfoProps } from './types';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import Actionable from 'root/widgets/common-components/actionable';
import { AgentRating } from 'root/widgets/common-components/agent-rating';

const verifiedIcon = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/verified.svg`;

const AgentProfileInfo: FC<AgentProfileInfoProps> = ({
  actions,
  agent,
  agency,
  className,
  metadata,
  rating,
  shareProfile,
  socialLinks,
  context,
}) => {
  const handleContactCTAClick = (onClick) => {
    emit(EVENT_NAMES.AGENT_PROFILE_INFO.CONTACT_CTA_ON_CLICK, context);

    if (onClick) {
      onClick();
    }
  };

  const handleRateCTAClick = (onClick) => {
    emit(EVENT_NAMES.AGENT_PROFILE_INFO.RATE_CTA_ON_CLICK, context);

    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={classNames('agent-profile-info-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <div className="agent-profile-avatar">
        <figure className="avatar-figure">
          <img
            className="avatar"
            {...extractDataAutomationId({ dataAutomationId: 'agent-profile-avatar' })}
            src={agent.avatar ?? AGENT_DEFAULT_IMAGE}
            alt={agent.name}
          />
          {agent.verification?.isVerified && (
            <AgentBadge
              className="agent-verification-badge"
              icon={verifiedIcon}
              text={agent.verification.text}
              explanation={agent.verification.explanation}
            />
          )}
        </figure>
        <div className="rate-agent-btn d-block d-sm-none">
          {actions?.rateAgentCTA && (
            <Actionable
              className="rate-agent-action-btn"
              variant="secondary"
              size="sm"
              {...extractDataAutomationId(actions.rateAgentCTA?.metadata)}
              onClick={() => handleRateCTAClick(actions.rateAgentCTA?.onClick)}
            >
              {actions.rateAgentCTA.text}
            </Actionable>
          )}
        </div>
      </div>
      <div className="agent-profile-description-container">
        <div className="agent-profile-name">
          <h1 className="name" {...extractDataAutomationId({ dataAutomationId: 'agent-profile-name' })}>
            {agent.name}
          </h1>
          <div className="agent-profile-actions d-none d-sm-block">
            {actions?.rateAgentCTA && (
              <Actionable
                className="rate-agent-action-btn"
                variant="secondary"
                size="sm"
                {...extractDataAutomationId({ dataAutomationId: 'agent-detail-review-rating-btn' })}
                onClick={() => handleRateCTAClick(actions.rateAgentCTA?.onClick)}
              >
                {actions.rateAgentCTA.text}
              </Actionable>
            )}
            {actions?.contactAgentCTA && (
              <Actionable
                className="contact-agent-action-btn"
                variant="primary"
                size="sm"
                {...extractDataAutomationId({ dataAutomationId: 'agent-detail-contact-btn' })}
                onClick={() => handleContactCTAClick(actions.contactAgentCTA?.onClick)}
              >
                {actions.contactAgentCTA.text}
              </Actionable>
            )}
          </div>
        </div>
        {agent.headline && (
          <div className="agent-profile-headline">
            <div className="headline">{agent.headline}</div>
          </div>
        )}
        {!isEmpty(agency) && (
          <div className="agent-profile-position">
            {agency.logo && (
              <figure className="agency-logo-figure">
                <img className="agency-logo" src={agency.logo} alt="agency logo" />
              </figure>
            )}
            <div className="agent-profile-designation">
              {agent.jobTitle && (
                <div
                  className="agent-profile-job-title"
                  {...extractDataAutomationId({ dataAutomationId: 'agent-profile-job-title' })}
                >
                  {agent.jobTitle}
                </div>
              )}
              {agency.name && (
                <div
                  className="agent-profile-agency"
                  {...extractDataAutomationId({ dataAutomationId: 'agent-profile-agency' })}
                >
                  {agency.name}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="agent-profile-specification">
          {agent.specifications &&
            agent.specifications.map(
              (trait) =>
                trait.specification && (
                  <div key={trait.id} className="trait" {...extractDataAutomationId(trait.metadata)}>
                    {trait.specification}
                  </div>
                ),
            )}
        </div>
        <div className="agent-profile-social-links">
          {socialLinks &&
            socialLinks.map((item) => (
              <Actionable
                key={item.className}
                href={item.url}
                className="sharing-action-btn"
                target="_blank"
                variant="secondary"
                size="sm"
                {...extractDataAutomationId(item?.metadata)}
              >
                <i className={item.className} />
              </Actionable>
            ))}
          {socialLinks && socialLinks?.length !== 0 && <div className="vertical-divider" />}
          <SharingButton {...shareProfile} {...extractDataAutomationId(shareProfile?.metadata)} />
        </div>
        <div className="agent-profile-rating">{rating && <AgentRating {...rating} context={context} />}</div>
      </div>
    </div>
  );
};

AgentProfileInfo.displayName = 'AgentProfileInfo';

export default AgentProfileInfo;
