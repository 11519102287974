/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import parse from 'html-react-parser';
import { useEffect } from 'react';

import { GlobalFooter } from 'root/widgets/global-components/footer';

import { subscribeEvents, unsubscribeEvents } from 'root/global-wrappers/services/events/ga/events';

import { EVENT_MAP } from './event-map';
import type { FooterWrapperProps } from './types';

const GlobalFooterWrapper = (props: FooterWrapperProps) => {
  const { footerConfig, context } = props;

  useEffect(() => {
    subscribeEvents(EVENT_MAP);

    return () => {
      unsubscribeEvents(EVENT_MAP);
    };
  }, []);

  const handleSearch = (searchText: string) => {
    if (!footerConfig.search.href) {
      return;
    }

    const searchUrl = searchText.trim() ? `${footerConfig.search.href}/p/${searchText}` : footerConfig.search.href;
    window.location.assign(searchUrl);
  };

  const contactDetails = footerConfig && {
    searchItems: { onSubmit: handleSearch, placeholder: 'PropertyGuru Search' },
    contactLinks: footerConfig.contacts,
  };

  const enhancedFooterData = {
    commonData: footerConfig.common,
    contactSegment: contactDetails,
    sitemapSegment: footerConfig.sitemap,
    copyrightSegment: footerConfig.copyright,
    countrySelectSegment: footerConfig.countries,
    socialSegment: footerConfig.social,
    marketSegment: footerConfig.markets,
    seoSegment: {
      ...footerConfig.seo,
      primary: parse(footerConfig.seo.primary as string),
      secondary: parse(footerConfig.seo.secondary as string),
    },
  };

  return (
    <GlobalFooter
      metadata={footerConfig.metadata}
      data={enhancedFooterData}
      context={{ ...context, scope: 'GlobalFooter', gaCustomDimensions: context?.gaCustomDimensions }}
    />
  );
};

export default GlobalFooterWrapper;
