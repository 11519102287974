import classnames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import { ReactSvgPanZoomLoader, SvgLoaderSelectElement } from 'react-svg-pan-zoom-loader';

import { BUTTON_ZOOM_IN_FACTOR, BUTTON_ZOOM_OUT_FACTOR } from 'root/widgets/mrt-search-modal/constants';
import { MapProps } from 'root/widgets/mrt-search-modal/types';

import MapZoomButtons from './map-zoom-buttons';

const Map = (props: MapProps) => {
  const {
    shouldShowFutureLines,
    mapSvg,
    svgWidth,
    svgHeight,
    defaultMapPortion,
    stationToDetailMapping,
    selectedStations,
    onMapStationClick,
  } = props;
  const panzoomRef = useRef<UncontrolledReactSVGPanZoom>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });

  const { width, height } = containerDimensions;
  let startX;
  let startY;

  useEffect(() => {
    const divElement = containerRef.current;
    if (divElement) {
      setContainerDimensions({
        width: divElement.offsetWidth,
        height: divElement.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (panzoomRef.current) {
      const scaleW = width / (svgWidth * defaultMapPortion);
      const scaleH = height / (svgHeight * defaultMapPortion);
      const defaultZoomLevel = Math.min(scaleW, scaleH);
      panzoomRef.current.setPointOnViewerCenter(svgWidth / 2, svgHeight / 2, defaultZoomLevel);
    }
  }, [containerDimensions, panzoomRef]);

  /**
   * Picking up the touch start event to record the starting position of the touch
   */
  const getTouchStartHandler = (event) => {
    startX = event?.touches[0]?.clientX;
    startY = event?.touches[0]?.clientY;
  };

  /**
   * Ignoring the swipe event and only considering the tap event
   */
  const getTouchEndHandler = (id, isChecked) => (event) => {
    const endX = event?.changedTouches[0]?.clientX;
    const endY = event?.changedTouches[0]?.clientY;
    const deltaX = endX - startX;
    const deltaY = endY - startY;

    // Making sure it is tapped on mobile
    if (Math.abs(deltaX) < 5 && Math.abs(deltaY) < 5) {
      onMapStationClick(id, isChecked)(event);
    }
  };

  const onZoomButtonClick = (zoom: boolean) => () => {
    if (panzoomRef.current) {
      panzoomRef.current.zoomOnViewerCenter(zoom ? BUTTON_ZOOM_IN_FACTOR : BUTTON_ZOOM_OUT_FACTOR);
    }
  };

  const proxyCircleSelector = useMemo(
    () =>
      Object.keys(stationToDetailMapping).map((id) => {
        const isChecked = selectedStations.includes(id);

        return (
          <SvgLoaderSelectElement
            key={id}
            selector={`circle#${id.replace(/\//g, '\\/')}, path#${id.replace(/\//g, '\\/')}`}
            class={classnames('map-station', { checked: isChecked })}
            onClick={onMapStationClick(id, isChecked)}
            onTouchStart={getTouchStartHandler}
            onTouchEnd={getTouchEndHandler(id, isChecked)}
          />
        );
      }),
    [stationToDetailMapping, selectedStations],
  );

  const proxyTextSelector = useMemo(
    () =>
      Object.keys(stationToDetailMapping).map((id) => {
        const isChecked = selectedStations.includes(id);

        return (
          <SvgLoaderSelectElement
            key={stationToDetailMapping[id].name}
            selector={`text[id^="${id}-"]`}
            onClick={onMapStationClick(id, isChecked)}
            onTouchStart={getTouchStartHandler}
            onTouchEnd={getTouchEndHandler(id, isChecked)}
          />
        );
      }),
    [stationToDetailMapping, selectedStations],
  );

  return (
    <div
      className={classnames('map', { 'hide-future-lines': !shouldShowFutureLines })}
      ref={containerRef}
      da-id="mrt-map"
    >
      {containerRef.current && (
        <ReactSvgPanZoomLoader
          src={mapSvg}
          proxy={[...proxyCircleSelector, ...proxyTextSelector]}
          render={(content) => (
            <UncontrolledReactSVGPanZoom
              className="panzoom-container"
              width={width}
              height={height}
              detectAutoPan={false}
              tool="auto"
              background="white"
              scaleFactorMin={0.35}
              scaleFactorMax={3}
              miniatureProps={{
                position: 'none',
                width: 0,
                height: 0,
                background: 'none',
              }}
              toolbarProps={{
                position: 'none',
              }}
              ref={panzoomRef}
            >
              <svg width={svgWidth} height={svgHeight}>
                {content}
              </svg>
            </UncontrolledReactSVGPanZoom>
          )}
        />
      )}

      <MapZoomButtons onZoomIn={onZoomButtonClick(true)} onZoomOut={onZoomButtonClick(false)} />
    </div>
  );
};

Map.displayName = 'Map';

export default Map;
