import classnames from 'classnames';
import { FC } from 'react';

import { RatingView } from 'root/widgets/common-components/rating-view';

import { AgentMetadata } from './sub-components/agent-metadata';
import { ImportedReview } from './sub-components/imported-review';
import { UserReviewProps } from './types';
import './user-review.scss';
import { getAcronym } from 'root/widgets/utils/string';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { filterMetadata } from 'root/widgets/utils/filter';

const UserReview: FC<UserReviewProps> = (props) => {
  const {
    agentMetadata,
    className,
    metadata,
    user,
    ratingView,
    reviewDescription,
    importedReviewMetadata,
    replies = [],
  } = props;

  const userAcronymAvatar = getAcronym(user.name);

  return (
    <div
      className={classnames('user-review-with-replies-root', { 'has-reply': replies.length > 0 }, className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <article className="user-review-root">
        <div className="user-avatar">
          {user.profilePic ? (
            <img className="user-avatar-img" src={user.profilePic.src} alt={user.profilePic.alt} />
          ) : (
            userAcronymAvatar
          )}
        </div>
        <div>
          <h4 className="user-name">{user.name}</h4>
          {ratingView && <RatingView {...ratingView} />}
          {agentMetadata && <AgentMetadata agentMetadata={agentMetadata} />}
          {importedReviewMetadata && <ImportedReview {...importedReviewMetadata} />}
        </div>
        <p className="user-review-description">{reviewDescription}</p>
      </article>
      {replies.map((reply) => (
        <UserReview className="user-review-reply-root" {...reply} key={reply.key} />
      ))}
    </div>
  );
};

UserReview.displayName = 'UserReview';

export default UserReview;
