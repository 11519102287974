import { BaseProps } from 'root/widgets/types';

export enum ContactType {
  WHATSAPP = 'whatsapp',
  LINE = 'line',
  REVEAL_PHONE_NUMBER = 'revealPhoneNumber',
  PHONE_CALL = 'phoneCall',
  SEND_ENQUIRY = 'sendEnquiry',
  CONTACT_AGENT = 'contactAgent',
  QR_CODE = 'qrCode',
  MULTI_CONTACT_OPTIONS = 'multiContactOptions',
}

export interface ContactActionProps extends BaseProps {
  type: ContactType;
  icon?: string;
  text?: string;
  href?: string;
  isRevealed?: boolean;
  phoneNumber?: string;
}

export type ContactActionHandlerType = (type: ContactType, context?: Record<string, any>) => void;

export interface ContactAgentWrapperProps {
  isGatingEnabled?: boolean;
  contactActions: Array<ContactActionProps>;
  onContactActionClick: ContactActionHandlerType;
}

export interface ContactAgentWrapperV3Props {
  isGatingEnabled?: boolean;
  onContactActionClick: ContactActionHandlerType;
}
