import classnames from 'classnames';
import { FC } from 'react';

import './property-summary.scss';
import { PropertySummaryProps } from './types';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const PropertySummary: FC<PropertySummaryProps> = (props) => {
  const {
    propertyTypeBreakdown: { transactionCount, propertyTypeLabel, image },
    isSpaceBetween,
    metadata,
  } = props;

  return (
    <div
      className={classnames('property-summary-root', { 'space-between': isSpaceBetween })}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <div>
        <h3 className="property-summary-count">{transactionCount}</h3>
        <h5 className="property-summary-label">{propertyTypeLabel}</h5>
      </div>
      <img src={image.src} alt={image.alt} />
    </div>
  );
};

export default PropertySummary;
