import React from 'react';

import { AlertCardInfoData } from 'root/widgets/home-alert-card/types';

const InfoItem: React.FC<AlertCardInfoData> = (props) => {
  const { text, icon } = props;
  if (icon && !icon.placement) {
    icon.placement = 'right';
  }

  return (
    <span className="info-item">
      {icon?.name && icon.placement === 'left' && <i className={`pgicon ${icon?.name}`} />}
      {text}
      {icon?.name && icon.placement === 'right' && <i className={`pgicon ${icon?.name}`} />}
    </span>
  );
};

InfoItem.displayName = 'InfoItem';

export default InfoItem;
