import React from 'react';
import { Offcanvas } from 'react-bootstrap';

import LocaleSwitch from 'root/widgets/global-components/navbar/sub-components/common/locale-switch';
import { SideNavProps } from 'root/widgets/global-components/navbar/types';

import SideNavLinkList from './sub-components/side-nav-link-list';
import SideNavTopSection from './sub-components/side-nav-top';

const SideNav: React.FC<SideNavProps> = (props) => {
  const {
    isSideNavOpen,
    onSideNavToggle,
    context,
    navLinks,
    agentLinks,
    myAccountLinks,
    myActivityConfig,
    loginDetail,
    navDropdown,
    localeDetail,
    onNavLinkClick,
    onLoginClick,
    onLogoutClick,
    onMyAccountClick,
    variant,
  } = props;

  return (
    <Offcanvas
      bsPrefix="side-nav-offcanvas"
      show={isSideNavOpen}
      scroll={false}
      onHide={onSideNavToggle}
      id="global-side-nav"
      placement="start"
    >
      {loginDetail && (
        <SideNavTopSection
          loginDetail={loginDetail}
          myActivityConfig={myActivityConfig}
          myAccountLinks={myAccountLinks}
          onLoginClick={onLoginClick}
          onLogoutClick={onLogoutClick}
          onMyAccountClick={onMyAccountClick}
          variant={variant}
        />
      )}
      <SideNavLinkList
        navLinks={[...navLinks, ...navDropdown.items]}
        onLinkClick={onNavLinkClick}
        agentLinks={agentLinks}
        context={context}
        variant={variant}
      />
      {localeDetail && <LocaleSwitch {...localeDetail} context={context} dropDirection="up" />}
    </Offcanvas>
  );
};

export default SideNav;
