import { isMobile } from 'mobile-device-detect';

import { ActionableLinkProps } from 'root/widgets/common-components/actionable-link/types';

import { ContactActionProps, ContactActionHandlerType, ContactType } from 'root/symbiosis-widgets/types';

export const getContactActionProps = (
  action: ContactActionProps,
  handleClick: ContactActionHandlerType,
  isGatingEnabled = false,
): ActionableLinkProps => {
  switch (action.type) {
    case ContactType.WHATSAPP: {
      const whatsappLinkProps: ActionableLinkProps = {
        href: action.href,
        target: '_blank',
        rel: 'noopener noreferrer',
      };

      if (isMobile) {
        whatsappLinkProps.target = undefined;
      }

      return {
        ...(isGatingEnabled ? {} : whatsappLinkProps),
        children: (
          <>
            <img src={action.icon} alt="whatsapp" />
            <span className="label">{action.text}</span>
          </>
        ),
        metadata: action.metadata,
        onClick: () =>
          handleClick(action.type, { ...whatsappLinkProps, rel: whatsappLinkProps.rel?.replace(/ /g, ',') }),
      };
    }
    case ContactType.LINE: {
      const lineLinkProps: ActionableLinkProps = { href: action.href, target: '_blank', rel: 'noopener noreferrer' };

      return {
        ...(isGatingEnabled ? {} : lineLinkProps),
        children: (
          <>
            <img src={action.icon} alt="line" />
            <span className="label">{action.text}</span>
          </>
        ),
        metadata: action.metadata,
        onClick: () => handleClick(action.type, { ...lineLinkProps, rel: lineLinkProps.rel?.replace(/ /g, ',') }),
      };
    }
    case ContactType.REVEAL_PHONE_NUMBER:
      return action.isRevealed
        ? {
            className: 'actionable-text btn-outline-secondary btn phone-reveal phone-revealed',
            children: (
              <>
                <div className="reveal-animation-backgound" />
                <div className="phone-number">
                  <i className={action.icon} />
                  {action.text}
                </div>
              </>
            ),
            metadata: action.metadata,
          }
        : {
            className: 'actionable-text btn-outline-secondary btn phone-reveal',
            children: (
              <>
                <div className="reveal-animation-backgound" />
                <div className="phone-number">
                  <i className={action.icon} />
                  {action.text}
                </div>
              </>
            ),
            metadata: action.metadata,
            onClick: () => handleClick(action.type, { phoneNumber: action.phoneNumber }),
          };
    case ContactType.PHONE_CALL:
      return {
        href: isGatingEnabled ? undefined : action.href,
        children: (
          <>
            <i className={action.icon} />
            <span className="label">{action.text}</span>
          </>
        ),
        metadata: action.metadata,
        onClick: () => handleClick(action.type, { phoneNumber: action.phoneNumber }),
      };
    case ContactType.SEND_ENQUIRY:
      return {
        children: (
          <>
            <i className={action.icon} />
            <span className="label">{action.text}</span>
          </>
        ),
        metadata: action.metadata,
        onClick: () => handleClick(action.type),
      };
    case ContactType.CONTACT_AGENT:
      return {
        children: action.text,
        className: action.className,
        metadata: action.metadata,
        onClick: () => handleClick(action.type),
      };
    default:
      return action;
  }
};
