/* Introduced sub-types (earlier TRANSPORTATION) to make differentiation in v_dictionary
 * and prefix-search. For these types, we expect MRT_STATION param to make it consistent
 */
export const TRANSPORTATION_SPECIAL_TYPES = [
  'MRT Station',
  'BRT Station',
  'BTS Station',
  'Airport Link Station',
  'SRT Station',
];

export enum TypeaheadOptionType {
  FoundLocation = 'FoundLocation',
  SearchOption = 'SearchOption',
  RecentSearch = 'RecentSearch',
  FoundAgent = 'FoundAgent',
}
