/* eslint-disable no-debugger */
import classnames from 'classnames';
import React from 'react';

import type { BaseProps } from 'root/widgets/types';

const Wrapper: React.FC<BaseProps> = ({ className, children }) => (
  <div className={classnames('wrapper', className)}>{children}</div>
);

export default Wrapper;
