import { FC } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { QRCodeViewProps } from 'root/widgets/contact-agent-card/types';

const QRCodeView: FC<QRCodeViewProps> = ({ description, iconUrl, url }) => (
  <div className="qr-code-view-root">
    <div className="qr-code-description">{description}</div>
    <div className="qr-code-group">
      <QRCodeSVG value={url} size={140} imageSettings={{ src: iconUrl, width: 40, height: 40, excavate: false }} />
    </div>
  </div>
);

QRCodeView.displayName = 'QRCodeView';

export default QRCodeView;
