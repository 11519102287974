import classNames from 'classnames';
import omit from 'lodash/omit';
import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { EVENT_NAMES, emit } from 'root/widgets/events';

import './enquiry-form-view.scss';
import type { EnquiryFormViewProps } from './types';
import { SvgIcon } from 'root/widgets/svg-icon';
import { defaultAgentLogo, editProfileIcon, requiredDependencies } from './constants';
import { validateDependencies } from 'root/widgets/utils/validation';
import { captureExceptionSentry } from 'root/widgets/utils/logger';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';
import HUIImage from 'root/widgets/common-components/hui-image';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import Actionable from 'root/widgets/common-components/actionable';
import { Typography } from 'root/common/atom-elements/typography';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import { Checkbox } from 'root/common/atom-elements/checkbox';

const EnquiryFormView: FC<EnquiryFormViewProps> = ({
  contactContext,
  isInfoReadOnly,
  shouldShowEditButton = true,
  intent,
  detailTitle,
  firstName,
  lastName,
  email,
  mobileNumber,
  profileLink,
  consents,
  submitButton,
  errorMessage,
  dependencies,
  onSuccess,
  context,
}) => {
  validateDependencies(dependencies, requiredDependencies);

  const [selectedIntent, setSelectedIntent] = useState<string>('');
  const [inputs, setInputs] = useState([
    {
      inputName: 'firstName',
      config: firstName,
      value: (firstName.value || '') as string,
      error: '',
      disabled: isInfoReadOnly,
    },
    {
      inputName: 'lastName',
      config: lastName,
      value: (lastName.value || '') as string,
      error: '',
      disabled: isInfoReadOnly,
    },
    {
      inputName: 'mobileNumber',
      config: mobileNumber,
      value: (mobileNumber.value || '') as string,
      error: '',
      disabled: isInfoReadOnly,
    },
    {
      inputName: 'email',
      config: email,
      value: (email.value || '') as string,
      error: '',
      disabled: isInfoReadOnly,
    },
  ]);
  const [errorText, setErrorText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const consentCheckRef = useRef<Record<string, boolean>>({});

  const shouldDisableSubmitButton = (intent?.options && !selectedIntent) || inputs.some((input) => input.value === '');

  const handleProfileLinkClick: MouseEventHandler<HTMLElement> = (event) => {
    emit(EVENT_NAMES.SEND_ENQUIRY.MANAGE_PROFILE_ON_CLICK, context);
    profileLink?.onClick?.(event);
  };

  const getIntentClickHandler = (intentKey: string) => () => {
    setSelectedIntent(intentKey);
  };

  const getConsentClickHandler = (consentKey: string) => () => {
    consentCheckRef.current = { ...consentCheckRef.current, [consentKey]: !consentCheckRef.current[consentKey] };
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputs(
      inputs.map((input) => (input.inputName === event.target.name ? { ...input, value: event.target.value } : input)),
    );
  };

  const captureException = (functionName: string, error: any) => {
    if (!dependencies.sentry) {
      return;
    }

    captureExceptionSentry(dependencies.sentry, EnquiryFormView.name, functionName, error);
  };

  const validateInputs = () => {
    let isValid = true;
    const newInputs = inputs.map((input) => ({ ...input, error: '' }));
    setInputs(newInputs);

    newInputs.forEach((input) => {
      if (input.config.validation && !new RegExp(input.config.validation.regex).test(input.value)) {
        input.error = input.config.validation.message;
        isValid = false;
      }
    });

    setInputs([...newInputs]);

    return isValid;
  };

  const handleSubmitClick = async () => {
    const data = {
      intent: selectedIntent,
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      consent: consentCheckRef.current,
    };

    inputs.forEach((input) => {
      data[input.inputName] = input.value;
    });

    const eventContext = { ...context, ...data };

    emit(EVENT_NAMES.SEND_ENQUIRY.SUBMIT_ON_CLICK, eventContext);

    if (!validateInputs()) {
      return;
    }

    setLoading(true);

    try {
      await dependencies.enquiryService.sendEnquiry(data);
      emit(EVENT_NAMES.SEND_ENQUIRY.SUBMIT_ON_SUCCESS, eventContext);

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      captureException(handleSubmitClick.name, error);
      setErrorText(errorMessage.requestFailed);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isInfoReadOnly) {
      if (!firstName.value || !lastName.value || !email.value || !mobileNumber.value) {
        setErrorText(errorMessage.invalidInput);
      } else {
        setErrorText('');
      }
    }
  }, [email.value, errorMessage.invalidInput, mobileNumber.value, firstName.value, lastName]);

  return (
    <div className="aem-efv-root">
      <div className="aem-efv-body">
        <Form className="d-flex flex-column efv-form">
          {errorText && <HUIAlert variant="warning">{errorText}</HUIAlert>}
          {contactContext && (
            <div className="efv-contact-context">
              {contactContext.agentLogo && <HUIImage className="agent-logo" src={contactContext.agentLogo} />}
              {!contactContext.agentLogo && (
                <div className="agent-logo">
                  <SvgIcon src={defaultAgentLogo} color="#CCC" width={24} height={24} shouldUseImage />
                </div>
              )}
              <Typography>{contactContext.description}</Typography>
            </div>
          )}
          {intent && (
            <Form.Group className="efv-form-group">
              <Form.Label className="mb-3 efv-section-title">{intent.label}</Form.Label>
              <div className="efv-intent" {...extractDataAutomationId(intent.metadata)}>
                {intent.options.map((option) => (
                  <Actionable
                    key={option.value}
                    variant="outline-secondary"
                    className={classNames('intent-badge', { selected: option.value === selectedIntent })}
                    onClick={getIntentClickHandler(option.value)}
                  >
                    {option.text}
                  </Actionable>
                ))}
              </div>
            </Form.Group>
          )}
          <Form.Label className="mb-4 efv-section-title">
            <span>{detailTitle}</span>
            {shouldShowEditButton && (
              <ActionableLink
                className="btn-round btn btn-secondary btn-lg"
                {...profileLink}
                onClick={handleProfileLinkClick}
              >
                <HUIImage src={editProfileIcon} />
              </ActionableLink>
            )}
          </Form.Label>
          {inputs.map((input) => (
            <Form.Group
              key={`efv-form-group-${input.config.label}-${input.config.prefix}`}
              className={classNames('efv-form-group', { invalid: Boolean(input.error) })}
            >
              <Form.Label className="mb-3 efv-label">{input.config.label}</Form.Label>
              <div className="form-input-group">
                {input.config.prefix && <InputGroup.Text>{input.config.prefix}</InputGroup.Text>}
                <Form.Control
                  type="text"
                  required
                  name={input.inputName}
                  value={input.value}
                  onChange={handleInputChange}
                  {...omit(input, ['inputName', 'label', 'value', 'config', 'error', 'profileLink'])}
                  {...extractDataAutomationId(input.config.metadata)}
                />
              </div>
              {input.error && <Form.Text className="text-danger">{input.error}</Form.Text>}
            </Form.Group>
          ))}
          {consents?.map((consentGroup) => (
            <div
              key={`consent-group-${consentGroup.items.map((consent) => consent.name).join('-')}`}
              className="efv-consent"
              {...extractDataAutomationId(consentGroup.metadata)}
            >
              {consentGroup.items.map((consent) => (
                <div className="d-flex gap-2 mt-4" key={consent.name}>
                  <Checkbox
                    id={`sign-up-checkbox-${consent.name}`}
                    name={consent.name}
                    onChange={getConsentClickHandler(consent.name)}
                  />
                  <Typography htmlFor={`sign-up-checkbox-${consent.name}`} element="label">
                    {consent.text}
                  </Typography>
                </div>
              ))}
              {consentGroup.description && <div className="efv-consent-description">{consentGroup.description}</div>}
            </div>
          ))}
          <div className="action-group">
            <Actionable
              {...submitButton}
              shouldShowLoading={isLoading}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmitClick}
              disabled={shouldDisableSubmitButton}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

EnquiryFormView.displayName = 'EnquiryFormView';

export default EnquiryFormView;
