import classNames from 'classnames';
import React from 'react';

import ActionableLink, { ActionableLinkProps } from 'root/widgets/common-components/actionable-link';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import { ShareVariant } from './constant';
import { SocialShareContext, SocialShareProvider } from './context';
import './social-share-button-group.scss';
import SocialShareBottomSheet from './sub-components/social-share-bottom-sheet';
import SocialShareHeader from './sub-components/social-share-header';
import SocialShareTrigger from './sub-components/social-share-trigger';
import type { SocialShareButtonGroupProps } from './types';

const SocialShareButtonGroup = ({ socialButtons, className, metadata, context }: SocialShareButtonGroupProps) => {
  const getLinkClickHandler = (onClick, shareVariant?: ShareVariant) => (evt: React.MouseEvent<HTMLAnchorElement>) => {
    emit(EVENT_NAMES.SOCIAL_SHARE_BUTTON_GROUP.CLICK, { ...context, shareVariant });
    onClick?.(evt);
  };

  return (
    <div
      className={classNames('social-share-btn-group-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      {socialButtons.map(({ isFullWidth, metadata: itemMetadata, text, shareVariant, onClick, ...props }) => (
        <ActionableLink
          key={text}
          {...(props as ActionableLinkProps)}
          {...itemMetadata}
          className={classNames(props.className, { 'is-full-width': isFullWidth })}
          onClick={getLinkClickHandler(onClick, shareVariant)}
        >
          <span>{text}</span>
        </ActionableLink>
      ))}
    </div>
  );
};

export default Object.assign(SocialShareButtonGroup, {
  Header: SocialShareHeader,
  BottomSheet: SocialShareBottomSheet,
  Provider: SocialShareProvider,
  Trigger: SocialShareTrigger,
  Context: SocialShareContext,
});
