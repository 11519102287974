import { ENV } from 'root/widgets/constants';
import config from 'root/widgets/pg-login/config';
import endpoints from 'root/widgets/pg-login/config/endpoints';
import { PGLoginConfigData } from 'root/widgets/pg-login/types';

class BaseService {
  envConfig: PGLoginConfigData;

  shouldUseProxy: boolean;

  host: string;

  endpoints: Record<string, string>;

  constructor(env: ENV, marketplace: string, shouldUseProxy = false) {
    this.envConfig = config[env][marketplace];
    this.shouldUseProxy = shouldUseProxy;
    this.host = this.envConfig.HOST;
    this.endpoints = endpoints[shouldUseProxy ? 'symbiosis' : 'legacy'];
  }
}

export default BaseService;
