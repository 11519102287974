import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { EditableProps } from 'root/widgets/common-components/search-input/types';

import { DEBOUNCE_DELAY } from 'root/widgets/constants';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const Editable: React.FC<EditableProps> = ({ debounceOptions, required, value, metadata, onChange, ...props }) => {
  useEffect(() => {
    let timer!: NodeJS.Timeout;
    if (debounceOptions?.callback) {
      const callback = debounceOptions.callback;
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(value);
      }, debounceOptions.delay ?? DEBOUNCE_DELAY);
    }
    return () => clearTimeout(timer);
  }, [value]);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <Form.Control
      {...props}
      value={value}
      onChange={handleChange}
      required={required}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    />
  );
};

Editable.displayName = 'Editable';

export default Editable;
