import classnames from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import SearchWithFilterContext from 'root/widgets/search-with-filter/context';
import type { BaseProps } from 'root/widgets/types';

import './property-type-filter.scss';
import ExpandableList from './sub-components/expandable-list';
import type { PropertyTypeConfigProps, SelectedTypeProps, StateProps } from './types';
import { convertStateToTypes, convertTypesToState, getDisplayString } from './utils';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Typography } from 'root/common/atom-elements/typography';
import { Filter } from 'root/widgets/common-components/filter';

const PropertyTypeFilter: React.FC<BaseProps> = () => {
  const searchContext = useContext(SearchWithFilterContext);
  const { setFilters, data, filters: filtersState, context } = searchContext;

  // configs
  const { filters } = data;
  const propertyTypeData = filters['propertyType'];
  const { fixedMarket, typeOptions, dropdownTitle, toggleBtnText, clearButtonText, applyButtonText } =
    propertyTypeData as PropertyTypeConfigProps;
  // states from SWF
  const propertyState = filtersState['propertyType'] as StateProps;

  const [selectedType, setSelectedType] = useState<SelectedTypeProps>(
    convertStateToTypes(propertyState, typeOptions, fixedMarket),
  );
  const [displayString, setDisplayString] = useState('');

  const dropdownRef = useRef<HTMLInputElement>(null);

  // find typeOption contain subTypes, and the subType has subTypeOptions, only check once from config
  // hasSubTypeOptions is true will use radio item button and expend-list, otherwise just list of checkbox item
  const hasSubTypeOptions = useMemo(
    () =>
      typeOptions.findIndex(
        (option) =>
          Boolean(option?.subTypes?.length) &&
          option.subTypes.some((subType) => Boolean(subType?.subTypeOptions?.length)),
      ) > -1,
    [typeOptions],
  );

  const isClearDisabled =
    selectedType.type === typeOptions[0].code &&
    selectedType.subTypes.length + selectedType.subTypeOptions.length === 0;

  // Update display string once when landing
  useEffect(() => {
    const updatedString: string = getDisplayString(selectedType, typeOptions, hasSubTypeOptions);
    setDisplayString(updatedString);
  }, []);

  const handleMenuToggled = (isOpen) => {
    if (isOpen) {
      const selectedTypeFromState = convertStateToTypes(propertyState, typeOptions, fixedMarket);
      setSelectedType(selectedTypeFromState);
    }
  };

  const handleTypeChange = (code: string | null) => () => {
    const selectedTypeConfig = typeOptions.find((type) => type.code === code);
    if (selectedTypeConfig) {
      const hasSubTypeOption = selectedTypeConfig?.subTypes.some((subType) => Boolean(subType?.subTypeOptions?.length));
      let selectedSubTypes: Array<string> = [];
      selectedSubTypes = hasSubTypeOption
        ? selectedTypeConfig.subTypes?.[0].code
          ? [selectedTypeConfig.subTypes[0].code]
          : []
        : (selectedTypeConfig.subTypes.map((subType) => subType.code) as Array<string>);

      setSelectedType({
        ...selectedType,
        type: code,
        subTypes: selectedSubTypes,
        subTypeOptions:
          selectedTypeConfig.subTypes?.[0]?.subTypeOptions?.map((subTypeOption) => subTypeOption.code) ?? [],
      });
      emit(EVENT_NAMES.SEARCH_FILTER.PROPERTY_TYPE_ON_SELECT, { ...context, data: code });
    }
  };

  const handleSubTypesCheckboxChange = (selectedCode: string | null) => () => {
    let selectedSubTypes = [...selectedType.subTypes];
    if (selectedType.subTypes.includes(selectedCode || '')) {
      selectedSubTypes = selectedSubTypes.filter((code) => code !== selectedCode);
    } else if (selectedCode) {
      selectedSubTypes.push(selectedCode);
    }
    setSelectedType({ ...selectedType, subTypes: selectedSubTypes });
  };

  const handleClearAction = () => {
    setSelectedType({
      type: typeOptions[0].code,
      subTypes: [],
      subTypeOptions: [],
    });
  };

  const handleApplyAction = () => {
    const selectedState = convertTypesToState(selectedType, typeOptions, fixedMarket);
    const updatedString: string = getDisplayString(selectedType, typeOptions, hasSubTypeOptions);
    setDisplayString(updatedString);
    setFilters({
      ...filtersState,
      propertyType: selectedState,
    });
    dropdownRef.current?.click(); // closing the filter dropdown when applied successfully
  };

  const isSubTypeSelected = (subTypeCode: string | null, selectedSubTypeCodes: Array<string>, idx: number) => {
    if (subTypeCode) {
      return selectedSubTypeCodes.includes(subTypeCode);
    }
    return idx === 0;
  };

  return (
    <div className="property-type-filter-root">
      <Filter
        dropdownTitle={dropdownTitle}
        selectedValueStr={displayString}
        toggleBtnText={toggleBtnText}
        clearAction={{ text: clearButtonText, onClick: handleClearAction, isDisabled: isClearDisabled }}
        applyAction={{ text: applyButtonText, onClick: handleApplyAction, isDisabled: false }}
        dropdownRef={dropdownRef}
        onToggle={handleMenuToggled}
      >
        <div className="types-container">
          {typeOptions.map((type) => (
            <div
              key={type.code}
              className={classnames('type-item', {
                selected: type.code === selectedType.type,
              })}
              onClick={handleTypeChange(type.code)}
            >
              {type.name}
            </div>
          ))}
        </div>
        <div className="list-items-container">
          {typeOptions
            .find((type) => type.code === selectedType.type)
            ?.subTypes.map((subType, idx) => {
              if (hasSubTypeOptions) {
                return (
                  <ExpandableList
                    item={subType}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    key={subType.code}
                  />
                );
              }

              return (
                <Form.Group className="list-item d-flex align-items-center gap-2 py-2" key={subType.code}>
                  <div className="mx-3">
                    <Checkbox
                      className="checkbox-input"
                      checked={isSubTypeSelected(subType.code, selectedType.subTypes, idx)} // selectedType.subTypes.includes(subType.code || '')
                      id={subType.name}
                      onChange={handleSubTypesCheckboxChange(subType.code)}
                    />
                  </div>
                  <Typography htmlFor={subType.name} element="label">
                    {subType.name}
                  </Typography>
                </Form.Group>
              );
            })}
        </div>
      </Filter>
    </div>
  );
};

export default PropertyTypeFilter;
