import parse from 'html-react-parser';
import React, { FC, useMemo } from 'react';
import { Modal } from 'react-bootstrap';

import { ContactAgentCardV3 } from 'root/widgets/contact-agent-card-v3';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import './contact-agent-modal-v3.scss';
import { ContactAgentModalV3Props } from './types';

const ContactAgentModalV3: FC<ContactAgentModalV3Props> = ({
  heading,
  isGatingEnabled,
  contactActions,
  agentInfoProps: agentInfo,
  termsAndPolicy,
  onContactActionClick,
  shouldShow,
  onHide,
  onShow,
  ...rest
}) => {
  const termsAndPolicyElement = useMemo(
    () => (typeof termsAndPolicy === 'string' ? parse(termsAndPolicy) : termsAndPolicy),
    [termsAndPolicy],
  );

  return (
    <HUIModal show={shouldShow} className="contact-agent-modal-root" onHide={onHide} onShow={onShow} centered>
      <HUIModal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </HUIModal.Header>
      <HUIModal.Body>
        <ContactAgentCardV3
          agentInfoProps={agentInfo}
          contactActions={contactActions}
          termsAndPolicy={termsAndPolicyElement}
          {...rest}
        />
      </HUIModal.Body>
    </HUIModal>
  );
};

ContactAgentModalV3.displayName = 'ContactAgentModal';

export default ContactAgentModalV3;
