import classnames from 'classnames';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';
import type { BaseProps } from 'root/widgets/types';

import './room-type-filter.scss';
import { Filter } from 'root/widgets/common-components/filter';
import { CheckboxList } from 'root/widgets/common-components/checkbox-list';
import { CheckboxItemData, ColumnCount } from 'root/widgets/common-components/checkbox-list/types';

const RoomTypeFilter: React.FC<BaseProps> = ({ className }) => {
  const context = useContext(SearchWithFilterContext);

  const {
    setFilters,
    filters: selectedFilters,
    data: { filters },
  } = context;
  const roomType = filters['roomType'];

  const selectedRoomType = selectedFilters?.roomType?.selected?.[roomType?.codeType ?? ''] ?? [];

  const [roomTypePreference, setRoomTypePreference] = useState<string[]>(selectedRoomType);

  const filterDropdownRef = useRef<HTMLInputElement>(null);

  const selectedText = useMemo(
    () =>
      roomType?.items
        .filter((item) => selectedRoomType.includes(item.value))
        .map((item) => item.selectedText)
        .join(', '),
    [selectedRoomType],
  );

  const onClearCaptured = () => {
    setRoomTypePreference([]);
  };

  const onApplyCaptured = () => {
    filterDropdownRef?.current?.click(); // closing the filter dropdown when applied successfully

    const selectedRoomTypes = {};

    selectedRoomTypes[roomType?.codeType ?? ''] = roomTypePreference ?? [];

    setFilters({
      ...selectedFilters,
      roomType: {
        selected: selectedRoomTypes,
      },
    });
  };

  const handleCheckboxListSelectionChange = useCallback((values: string[]) => {
    setRoomTypePreference(values);
  }, []);

  return (
    <div className={classnames('room-type-filter-root', className)}>
      <Filter
        dropdownTitle={roomType?.dropdownTitle ?? ''}
        selectedValueStr={selectedText}
        toggleBtnText={roomType?.toggleBtnText ?? ''}
        clearAction={{
          onClick: onClearCaptured,
          isDisabled: roomTypePreference.length === 0,
          text: roomType?.clearText ?? '',
        }}
        applyAction={{ onClick: onApplyCaptured, isDisabled: false, text: roomType?.applyText ?? '' }}
        dropdownRef={filterDropdownRef}
      >
        <CheckboxList
          items={roomType?.items as CheckboxItemData[]}
          onSelectionChange={handleCheckboxListSelectionChange}
          value={roomTypePreference}
          columnCount={ColumnCount.ONE_COLUMN}
        />
      </Filter>
    </div>
  );
};

RoomTypeFilter.displayName = 'RoomTypeFilter';

export default RoomTypeFilter;
