import React from 'react';
import { Badge } from 'react-bootstrap';

import { LabelProps } from './types';

const Label: React.FC<LabelProps> = ({ variant, children }) => (
  <div className="label">
    <Badge bg={variant} className="label__content">
      {children}
    </Badge>
  </div>
);

Label.displayName = 'Label';

export default Label;
