import classNames from 'classnames';
import React from 'react';
import { Offcanvas } from 'react-bootstrap';

import HUIBottomSheetContext from './hui-bottom-sheet-context';
import './hui-bottom-sheet.scss';
import { Body, Header } from './sub-components';
import { HUIBottomSheetProps } from './types';

const HUIBottomSheet: React.FC<HUIBottomSheetProps> = ({ className, onClose, variant, shouldShow, ...props }) => {
  const handleClose = () => onClose?.();

  const bottomSheetContext = React.useMemo(
    () => ({
      onClose: handleClose,
    }),
    [handleClose],
  );
  return (
    <HUIBottomSheetContext.Provider value={bottomSheetContext}>
      <Offcanvas
        className={classNames(className, 'hui-bottom-sheet', variant || 'full-size')}
        onHide={handleClose}
        placement="bottom"
        {...props}
        show={shouldShow}
      />
    </HUIBottomSheetContext.Provider>
  );
};

export default Object.assign(HUIBottomSheet, {
  Header,
  Body,
  Title: Offcanvas.Title,
});
