import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import ActionableLink from 'root/widgets/common-components/actionable-link';
import { Badge } from 'root/widgets/common-components/badge';

import type { MyAccountDropdownProps } from 'root/widgets/global-components/navbar/types';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const MyAccountDropdown: React.FC<MyAccountDropdownProps> = ({
  myAccountLinks,
  onMyAccountClick,
  onItemClick,
  loginDetail,
  onLogoutClick,
}) => {
  if (myAccountLinks?.items?.length === 0) {
    return null;
  }

  const handleClick = () => {
    if (onMyAccountClick) {
      onMyAccountClick(myAccountLinks?.tooltip ?? '');
    }
  };

  const getItemClickHandler = (itemText: string) => () => {
    onItemClick?.(itemText);
  };

  return (
    <Dropdown bsPrefix="my-account-dropdown">
      <Dropdown.Toggle as="div" title={myAccountLinks?.title} {...extractDataAutomationId(myAccountLinks)}>
        <ActionableLink className="my-account-dropdown-button" onClick={handleClick}>
          <i className="pgicon-user" />
          <span className="my-account-dropdown-button-text">{myAccountLinks?.title}</span>
          <i className="pgicon-arrow-down" />
        </ActionableLink>
      </Dropdown.Toggle>
      <Dropdown.Menu bsPrefix="my-account-dropdown-menu" align="end">
        {myAccountLinks?.items?.map((item) => (
          <Dropdown.Item
            bsPrefix="my-account-dropdown-item"
            href={item.url}
            key={item.text}
            onClick={getItemClickHandler(item?.tooltip ?? '')}
            {...extractDataAutomationId(item)}
          >
            {item.text}
            {item.newBadgeText && (
              <div className="my-account-dropdown-item-badge">
                <Badge type="small" variant="primary" bg="green">
                  {item.newBadgeText}
                </Badge>
              </div>
            )}
          </Dropdown.Item>
        ))}
        <div className="my-account-dropdown-divider" />
        <Dropdown.Item
          bsPrefix="my-account-dropdown-item"
          title={loginDetail?.logout.defaultText}
          onClick={onLogoutClick}
          {...extractDataAutomationId(loginDetail?.logout)}
        >
          {loginDetail?.logout.defaultText}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MyAccountDropdown;
