import { useEventListener } from 'ahooks';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Navbar } from 'react-bootstrap';

import { EVENT_NAMES, emit } from 'root/widgets/events';

import './global-navbar-desktop.scss';
import './global-navbar-mobile.scss';
import SideNav from './sub-components/side-nav/side-nav';
import TopBar from './sub-components/top-bar/top-bar';
import type { GlobalNavbarProps } from './types';

const SHOWING_STATE_DISTANCE_THRESHOLD = 20;

const GlobalNavbar: React.FC<GlobalNavbarProps> = ({ data, context, metadata }) => {
  const {
    loginDetail,
    localeDetail,
    logoConfig,
    navLinks,
    navDropdown,
    agentLinks,
    myActivityConfig,
    myAccountLinks,
    notificationButton,
    variant,
  } = data;
  const [isSideNavOpen, setSideNavOpen] = useState(false);
  const [shouldShowNavbar, setShowNavbar] = useState(true);

  const handleSideNavToggle = () => {
    setSideNavOpen(!isSideNavOpen);
  };

  useEffect(() => {
    if (isSideNavOpen) {
      emit(EVENT_NAMES.GLOBAL_NAVBAR.SIDENAV_OPENED, { ...context, isSideNavOpen });
    }
  }, [isSideNavOpen]);

  const emitHideNavbarEvent = () => {
    emit(EVENT_NAMES.GLOBAL_NAVBAR.HIDE_ON_SCROLL, context);
  };

  const emitShowNavbarEvent = () => {
    emit(EVENT_NAMES.GLOBAL_NAVBAR.SHOW_ON_SCROLL, context);
  };

  const handleNotificationToggleClick = () => {
    emit(EVENT_NAMES.GLOBAL_NAVBAR.NOTIFICATION_ON_CLICK, context);

    if (notificationButton && notificationButton.onClick) {
      notificationButton.onClick();
    }
  };

  const handleMoreLinksToggleClick = (text: string) => {
    emit(EVENT_NAMES.GLOBAL_NAVBAR.MORE_DROPDOWN_ON_CLICK, { ...context, data: text });
  };

  const handleMyActivityClick = () => {
    emit(EVENT_NAMES.GLOBAL_NAVBAR.MY_ACTIVITY_LINK_ON_CLICK, context);
  };

  const handleMyAccountClick = (text: string) => {
    emit(EVENT_NAMES.GLOBAL_NAVBAR.MY_ACCOUNT_ON_CLICK, { ...context, data: text });
  };

  const handleMyAccountItemClick = (text: string) => {
    emit(EVENT_NAMES.GLOBAL_NAVBAR.MY_ACCOUNT_ITEM_ON_CLICK, { ...context, data: text });
  };

  const handleLoginClick = () => {
    emit(EVENT_NAMES.GLOBAL_NAVBAR.LOGIN_ON_CLICK, context);
    if (loginDetail?.login.onClick) {
      loginDetail?.login.onClick();
    }
  };

  // All the nav links click invokes this callback - topbar/sidenav both.
  const handleNavLinkClick = (text: string) => {
    emit(EVENT_NAMES.GLOBAL_NAVBAR.NAV_LINK_ON_CLICK, { ...context, data: text });
  };

  const handleLogoutClick = () => {
    if (loginDetail?.logout.onClick) {
      loginDetail?.logout.onClick();
    }
  };

  const scrollRef = useRef(0);

  const handleScroll = useCallback((scrollPosition: { top: number; left: number }) => {
    const distance = Math.abs(scrollPosition.top - scrollRef.current);

    if (distance > SHOWING_STATE_DISTANCE_THRESHOLD) {
      if (scrollRef.current < scrollPosition.top && scrollRef.current >= 0) {
        setShowNavbar(false);
        emitHideNavbarEvent();
      } else {
        setShowNavbar(true);
        emitShowNavbarEvent();
      }
      scrollRef.current = scrollPosition.top;
    }
  }, []);

  useEventListener(
    'scroll',
    (event: Event) => {
      const scrollingElement = (event.target as typeof document)?.scrollingElement;

      if (scrollingElement) {
        const { scrollTop: top, scrollLeft: left } = scrollingElement;
        requestAnimationFrame(() => handleScroll({ top, left }));
      }
    },
    { target: () => document },
  );

  return (
    <Navbar
      id="main-nav-menu"
      bsPrefix="global-navbar-root"
      className={classNames({ 'hidden-on-scroll-down': !shouldShowNavbar && !isSideNavOpen })}
      expand={false}
      {...metadata}
      data-automation-id="main-nav-menu"
    >
      <TopBar
        logoConfig={logoConfig}
        navLinks={navLinks}
        navDropdown={navDropdown}
        isSideNavOpen={isSideNavOpen}
        agentLinks={agentLinks}
        notificationButton={notificationButton}
        myActivityConfig={myActivityConfig}
        myAccountLinks={myAccountLinks}
        loginDetail={loginDetail}
        localeDetail={localeDetail}
        onNavLinkClick={handleNavLinkClick}
        onSideNavToggle={handleSideNavToggle}
        onMoreLinksToggleClick={handleMoreLinksToggleClick}
        onMyAccountClick={handleMyAccountClick}
        onMyAccountItemClick={handleMyAccountItemClick}
        onMyActivityLinkClick={handleMyActivityClick}
        onNotificationToggleClick={handleNotificationToggleClick}
        onLoginClick={handleLoginClick}
        onLogoutClick={handleLogoutClick}
        context={context}
        variant={variant}
      />
      <SideNav
        isSideNavOpen={isSideNavOpen}
        onSideNavToggle={handleSideNavToggle}
        navLinks={navLinks}
        navDropdown={navDropdown}
        agentLinks={agentLinks}
        myActivityConfig={myActivityConfig}
        myAccountLinks={myAccountLinks}
        loginDetail={loginDetail}
        localeDetail={localeDetail}
        onNavLinkClick={handleNavLinkClick}
        onMyAccountClick={handleMyAccountClick}
        onLoginClick={handleLoginClick}
        onLogoutClick={handleLogoutClick}
        context={context}
        variant={variant}
      />
    </Navbar>
  );
};

GlobalNavbar.displayName = 'GlobalNavbar';

export default GlobalNavbar;
