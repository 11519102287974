import classnames from 'classnames';
import React, { useContext } from 'react';

import SearchWithFilterContext from 'root/widgets/search-with-filter/context';

import './filter-panel.scss';
import { FilterPanelProps } from './types';

const FilterPanel: React.FC<FilterPanelProps> = ({ children, className, containerRef }) => {
  const { stack } = useContext(SearchWithFilterContext);
  return (
    <div className={classnames('filter-panel-root', className, stack)} ref={containerRef}>
      {children}
    </div>
  );
};

FilterPanel.displayName = 'FilterPanel';

export default FilterPanel;
