import { FC, useEffect, useRef } from 'react';

import type { CheckboxProps } from './types';
import classNames from 'classnames';
import { filterMetadata } from 'root/widgets/utils/filter';
import { Form } from 'react-bootstrap';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const Checkbox: FC<CheckboxProps> = ({ className, metadata, indeterminate, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkboxElement: HTMLInputElement | undefined = ref.current?.querySelector('.form-check-input') || undefined;
    if (checkboxElement) {
      checkboxElement.indeterminate = indeterminate ?? false;
    }
  }, [indeterminate]);
  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <div className="position-relative" ref={ref} onClick={(event) => handleOnClick(event)}>
      <Form.Check
        className={classNames('checkbox', className)}
        type="checkbox"
        {...props}
        {...filterMetadata(metadata)}
        {...extractDataAutomationId(metadata)}
      />
    </div>
  );
};

export default Checkbox;
