import classnames from 'classnames';
import React from 'react';

import { ICONS } from 'root/widgets/common-components/generic-input-group/constants';
import { AcceptanceRulesProps } from 'root/widgets/common-components/generic-input-group/types';

const InputAcceptanceRules: React.FC<AcceptanceRulesProps> = ({ acceptanceRules, inputValue }) => (
  <div className="acceptance-rules">
    {acceptanceRules?.map(({ text, isAcceptable }) => (
      <div key={text} className="rule-wrapper">
        <i className={classnames(isAcceptable(inputValue) ? ICONS.OK_CIRCLE : ICONS.RADIO, 'rule-icon')} />
        <p className="rule-text">{text}</p>
      </div>
    ))}
  </div>
);

export default InputAcceptanceRules;
