import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { PGLogin } from 'root/widgets/pg-login';
import { VIEW } from 'root/widgets/pg-login/constants';
import { PGLoginModalProps } from './types';
import './pg-login-modal.scss';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import { useUserExitIntent } from 'root/widgets/common-components/hooks/use-user-exit-intent';
import { LOGIN_MODAL_SEEN_KEY } from './constants';

const PGLoginModal: React.FC<PGLoginModalProps> = ({
  isLoggedIn,
  enableExitIntent = false,
  shouldShow = true,
  className,
  loginLeftPanelContent,
  size,
  ...props
}) => {
  const [isBackVisible, setBackVisible] = useState(false);
  const [isCloseVisible, setCloseVisible] = useState(true);
  const [title, setTitle] = useState('');

  const { context } = props;
  const [isModalOpen, setIsModalOpen] = useState(shouldShow);
  // ADDED: Use effect to stay in sync with the parent `shouldShow`
  // If the parent changes shouldShow, reflect that in our local state
  useEffect(() => {
    setIsModalOpen(shouldShow && !isLoggedIn);
  }, [shouldShow, isLoggedIn]);

  useUserExitIntent({
    enabled: enableExitIntent && !isLoggedIn && !isModalOpen,
    onExitIntent: () => setIsModalOpen(true),
    sessionKey: LOGIN_MODAL_SEEN_KEY,
  });

  const handleBack = () => {
    emit(EVENT_NAMES.PG_LOGIN.BACK, context);
  };

  const handleClose = () => {
    emit(EVENT_NAMES.PG_LOGIN.CLOSE, context);
    setIsModalOpen(false);
    props.onClose();
  };

  const onEdgeOfFlow = (isEdge: boolean) => {
    setCloseVisible(isEdge);
    setBackVisible(!isEdge);
  };

  useEffect(() => {
    if (isModalOpen) {
      sessionStorage.setItem(LOGIN_MODAL_SEEN_KEY, 'true');
      emit(EVENT_NAMES.PG_LOGIN.MODAL_VIEW_ACTIVE, context);
    }
  }, [isModalOpen, context]);

  const handleViewChange = (view: VIEW, translation: Record<string, string>) => {
    switch (view) {
      case VIEW.LOGIN:
        setTitle(translation.loginTitle);
        break;
      case VIEW.SIGNUP:
        setTitle(translation.signUpTitle);
        break;
      case VIEW.VERIFY_EMAIL:
        setTitle(translation.verifyEmailTitle);
        break;
      case VIEW.SET_PASSWORD:
        setTitle(translation.setPasswordModalTitle);
        break;
      case VIEW.OTP_LOGIN:
        setTitle(translation.otpLoginTitle);
        break;
      case VIEW.PASSWORD_LOGIN:
        setTitle(translation.passwordLoginTitle);
        break;
      case VIEW.FORGOT_PASSWORD:
        setTitle(translation.forgotPasswordTitle);
        break;
      default:
        return '';
    }
    return '';
  };

  return (
    <HUIModal
      className={classNames('pg-login-modal-root', className)}
      show={isModalOpen}
      onBack={handleBack}
      onHide={handleClose}
      size={size}
      centered
    >
      <HUIModal.Header backButton={isBackVisible} closeButton={isCloseVisible}>
        {title}
      </HUIModal.Header>
      <HUIModal.Body>
        <div className={classNames('modal-body-content')}>
          {loginLeftPanelContent && <div className="d-none d-lg-block">{loginLeftPanelContent}</div>}
          <PGLogin onViewChange={handleViewChange} {...props} shouldShowViewTitle={false} onEdgeOfFlow={onEdgeOfFlow} />
        </div>
      </HUIModal.Body>
    </HUIModal>
  );
};

export default PGLoginModal;
