export const COOKIE_BANNER = {
  STORAGE_KEY: 'accept-cookies',
};

export const APP_NUDGE = {
  PROCEED_COOKIE_KEY: 'smartbanner-installed',
  CLOSED_COOKIE_KEY: 'smartbanner-closed',
  PROCEED_COOKIE_EXPIRY_DAYS: 15,
  CLOSED_COOKIE_EXPIRY_DAYS: 90,
};

/* some client side API take longer than default 2 seconds, set it with 3 seconds timeout */
export const CLIENT_SIDE_TIMEOUT_3000 = 3000;

export const Listing = {
  SubType: {
    Project: 'PROJ',
  },
  Type: {
    Sale: 'sale',
    Rent: 'rent',
  },
};

// height in pixel
export const GLOBAL_NAV = {
  HEIGHT: {
    XL: 72,
    LG: 54,
  },
};

// height in pixel
export const MEGA_MENU_NAV = {
  HEIGHT: {
    XL: 88,
    LG: 64,
  },
};

export const NUMBER_SANITIZATION_PATTERN = /[,.]/g;

export const FLOOR_AREA_KEY_MAP = {
  min: 'minsize',
  max: 'maxsize',
};

export const RECENT_SEARCHES_STORAGE_KEY = 'recent_searches';

export const MAX_RECENT_SEARCH_COUNT = 3;

export enum AsyncProcessStatus {
  IN_PROGRESS = 'inProgress',
  SUCCESS = 'success',
  FAILURE = 'failure',
}
