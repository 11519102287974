import { HIVE_STATIC_VERSION, MARKETPLACE, REGION } from 'root/widgets/constants';
import { PGLoginConfigData } from 'root/widgets/pg-login/types';

const commonConfig = {
  AUTH_API_HOST: 'https://auth.integration.guruestate.com',
  USERS_API_HOST: 'https://users-api.kgw.integration.guruestate.com',

  /**
   * following google client id is of dev environment. If you want to test on local
   * (http://localhost:3000), then copy this client id and paste it in respected
   * region config.
   */
  GOOGLE_CLIENT_ID: '367223124563-nqbh60veth48kr448f62mflkasd0kpmp.apps.googleusercontent.com',
  FACEBOOK_CLIENT_ID: '751052508373579',
  APPLE_CLIENT_ID: 'com.propertyguru.www',
  PG_LOGO_URL: `https://cdn-integration.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/logo/pg.svg`,
};
const config: Record<string, PGLoginConfigData> = {
  [`${REGION.SG}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '367223124563-ti1jnu3njfef468olr82iku8j71jfkma.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '1704579266427760',
    HOST: 'https://www.integration.propertyguru.com.sg',
  },
  [`${REGION.SG}-${MARKETPLACE.CG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '185477762030-e61pcs1g9c1btomrfpmo0atk23s4efas.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '476495469688355',
    HOST: 'https://www.integration.commercialguru.com.sg',
  },
  [`${REGION.MY}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '115079994079-5p6d4ga4vttjnpq0b7gb31re4mob8k2c.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '844546780777099',
    HOST: 'https://www.integration.propertyguru.com.my',
  },
  [`${REGION.MY}-${MARKETPLACE.IPP}`]: {
    AUTH_API_HOST: 'https://auth.integration.guruestate.com',
    USERS_API_HOST: 'https://users-api.kgw.integration.guruestate.com',
    APPLE_CLIENT_ID: 'com.iproperty.accounts.internal',
    PG_LOGO_URL: `https://accounts.iproperty.com.my/assets/1730099992/img/ipp/logo-consumer.svg`,
    GOOGLE_CLIENT_ID: '313711791759-7dp0oivpv226ronjjdmstaqkhe9umbqc.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '598849274578961',
    HOST: 'https://dev.sg.iproperty.com.my',
    FORGOT_PASSWORD_URL: 'https://accounts.integration.iproperty.com.my/forgot-password?client_id=6ud3tfeoylfxkfw3miooz8bzd4',
  },
  [`${REGION.TH}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '711575025382-3vjahlfb1jm79f2bai57q7f7jdboqoeg.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '1220009886163826',
    HOST: 'https://www.integration.ddproperty.com',
  },
};

export default config;
