import React from 'react';

import './top-location-list.scss';
import { TopLocationListProps } from './types';

const TopLocationList: React.FC<TopLocationListProps> = (props) => {
  const { heading, locations, noDataText } = props;
  return (
    <div className="top-location-list-root">
      <h5 className="top-location-list-heading">{heading}</h5>
      <div className="top-location-list-wrap">
        {locations?.length > 0 ? (
          locations.map((location) => (
            <div className="top-location-list-item" key={location}>
              <span className="pgicon pgicon-circle" />
              {location}
            </div>
          ))
        ) : (
          <p className="top-location-list-empty">{noDataText}</p>
        )}
      </div>
    </div>
  );
};

export default TopLocationList;
