import classnames from 'classnames';
import React from 'react';

import Actionable from 'root/widgets/common-components/actionable';

import { TIMER_DELAY } from './constants';
import { TimedActionProps } from './types';

const formatNumber = (time: number) => (String(time).length === 1 ? `0${time}` : `${time}`);

const format = (time: number) => {
  const min = Math.floor(time / 60);

  const sec = time % 60;

  return `${formatNumber(min)}:${formatNumber(sec)}`;
};

const TimedAction: React.FC<TimedActionProps> = ({ text, timerDuration, ...actionableProps }) => {
  const [elapsedTime, setElapsedTime] = React.useState(timerDuration);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (elapsedTime > 0) {
        setElapsedTime(elapsedTime - 1);
      }
    }, TIMER_DELAY);
    return () => {
      clearTimeout(timer);
    };
  }, [elapsedTime]);

  const isDisabled = Boolean(elapsedTime);

  const handleOnClick = (event) => {
    setElapsedTime(timerDuration);
    if (actionableProps.onClick) {
      actionableProps.onClick(event);
    }
  };

  return (
    <Actionable variant="link primary" disabled={isDisabled} {...actionableProps} onClick={handleOnClick}>
      {text}
      {isDisabled && <span className={classnames('pg-login-elapsed-time')}>{` (${format(elapsedTime)})`}</span>}
    </Actionable>
  );
};

TimedAction.displayName = 'TimedAction';

export default TimedAction;
