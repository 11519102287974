export const getAcronym = (name: string, length = 2) =>
  name
    .split(' ')
    .slice(0, length)
    .map((word) => word.charAt(0).toUpperCase())
    .join('');

/**
 * Replace the string from locales with value.
 * NOTE: it is strongly advised to use i18next.t() for serverside, unless you are working with array of strings
 *
 * example
 * const str = "/{{propertyTypeSlug}}-{{listingTypeSlug}}/di-area-{{regionSlug}}-{{regionCode}}?code={{regionCode}}&data={{listingTypeSlug}}"
 * const data = {
 *   propertyTypeSlug: 1,
 *   listingTypeSlug: 2,
 *   regionSlug: 3,
 *   regionCode: 4,
 * };
 *
 * replaceVariables(str, data); // "/1-2/di-area-3-4?code=4&data=2"
 * @param str - the string
 * @param data - the data to replace
 */
export const replaceVariables = (str: string, data: Record<string, string | undefined | number | null>) =>
  str.replace(/{{([^{}]+)}}/g, (_, variable) => (data[variable] ? String(data[variable]) : `{{${variable}}}`));
