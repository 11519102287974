import classnames from 'classnames';
import React from 'react';

import { useComponentInView } from 'root/widgets/common-components/hooks';
import { HIVE_STATIC_IMAGES_PATH } from 'root/widgets/constants';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './profile-banner.scss';
import type { ProfileBannerProps } from './types';
import Title from 'root/widgets/common-components/title';
import Actionable from 'root/widgets/common-components/actionable';

const ProfileBanner: React.FC<ProfileBannerProps> = (props) => {
  const defaultImage = `${HIVE_STATIC_IMAGES_PATH}default-profile-banner.svg`;

  const {
    className,
    variant = 'primary-lightest',
    metadata,
    body,
    submitAction,
    backgroundImage,
    additionalEvents,
    context,
    customActionView,
  } = props;

  const bannerRef = useComponentInView(additionalEvents?.IN_VIEWPORT, context);

  const submitActionButton = submitAction && (
    <Actionable
      className="profile-banner-btn"
      variant={classnames({
        dark: variant === 'primary-lightest',
        light: variant === 'white' || variant === 'inactive' || variant === 'success',
      })}
      size="sm"
      {...submitAction}
    >
      {submitAction.title}
    </Actionable>
  );

  return (
    <div
      ref={bannerRef}
      className={classnames('profile-banner-root', className, variant)}
      style={{ backgroundImage: `url(${backgroundImage ?? defaultImage})` }}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <div className="profile-banner-text-body">
        <Title headerTag="h4" className="profile-banner-text-title">
          {body.title}
        </Title>
        {body?.description && <p className="profile-banner-text-description">{body.description}</p>}
      </div>
      {customActionView || submitActionButton}
    </div>
  );
};

ProfileBanner.displayName = 'ProfileBanner';

export default ProfileBanner;
