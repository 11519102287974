import { AuthGrantType } from 'root/widgets/pg-login/constants';
import type { UserInfoLoggedInData } from 'root/widgets/pg-login/types';

import BaseService from './base';
import { post } from './http';

const socialProviderScripts = {
  google: {
    src: 'https://accounts.google.com/gsi/client',
    id: 'hui-google-platform-script',
  },
  facebook: {
    src: 'https://connect.facebook.net/en_US/sdk.js',
    id: 'hui-facebook-sdk-script',
  },
  apple: {
    src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
    id: 'hui-apple-id-script',
  },
};

const loadScript = ({ src, id }: { src: string; id: string }) =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    script.id = id;

    document.body.append(script);
    script.addEventListener('load', () => resolve(true));
  });

const loadGoogleSDK = async () => {
  if (document.querySelector(`#${socialProviderScripts.google.id}`)) {
    return;
  }

  await loadScript({
    src: socialProviderScripts.google.src,
    id: socialProviderScripts.google.id,
  });
};

const loadFacebookSDK = async (clientId: string) => {
  if (document.querySelector(`#${socialProviderScripts.facebook.id}`)) {
    return;
  }

  await loadScript({
    src: socialProviderScripts.facebook.src,
    id: socialProviderScripts.facebook.id,
  });

  window.fbAsyncInit = () => {
    FB.init({
      appId: clientId,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v14.0',
      cookie: true,
    });
  };
};

const getGoogleProfile = async (accessToken: string) => {
  const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${accessToken}`;
  const response = await fetch(url);
  const responseData = await response.json();
  return responseData;
};

const loadAppleSDK = async (clientId: string, loginRedirectURI: string) => {
  if (document.querySelector(`#${socialProviderScripts.apple.id}`)) {
    return;
  }

  await loadScript({
    src: socialProviderScripts.apple.src,
    id: socialProviderScripts.apple.id,
  });

  AppleID.auth.init({
    clientId: clientId,
    scope: 'name email',
    redirectURI: `${window.location.origin}${loginRedirectURI}`,
    usePopup: true,
  });
};

class SocialLoginService extends BaseService {
  login = async (accountId: string, accessToken: string, provider: string): Promise<UserInfoLoggedInData> => {
    const data = {
      code: accountId,
      provider: provider,
    };
    data[this.shouldUseProxy ? 'grantType' : 'grant_type'] = AuthGrantType.SOCIAL_LOGIN;
    data[this.shouldUseProxy ? 'accessToken' : 'access_token'] = accessToken;

    return post(`${this.host}${this.endpoints.login}`, data) as Promise<UserInfoLoggedInData>;
  };

  // eslint-disable-next-line class-methods-use-this
  initGoogleAuth = async () => {
    await loadGoogleSDK();
  };

  initFacebookAuth = async () => {
    await loadFacebookSDK(this.envConfig.FACEBOOK_CLIENT_ID);
  };

  initAppleAuth = async () => {
    await loadAppleSDK(this.envConfig.APPLE_CLIENT_ID, this.endpoints.appleLoginRedirectURI);
  };

  loginUsingGoogle = async (): Promise<UserInfoLoggedInData> =>
    new Promise((resolve, reject) => {
      const handleGoogleLoginError = (error) => {
        reject(error);
      };

      const handleGoogleLogin = (res) => {
        (async () => {
          try {
            const profile = await getGoogleProfile(res.access_token);
            const response: UserInfoLoggedInData = await this.login(profile.id, res.access_token, 'google');
            resolve({ ...response, email: profile.email });
          } catch (error) {
            reject(error);
          }
        })();
      };
      google.accounts.oauth2
        .initTokenClient({
          client_id: this.envConfig.GOOGLE_CLIENT_ID,
          prompt: 'select_account',
          scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
          callback: handleGoogleLogin,
          error_callback: handleGoogleLoginError,
        })
        .requestAccessToken();
    });

  loginUsingFacebook = async (): Promise<UserInfoLoggedInData> =>
    new Promise((resolve, reject) => {
      FB.login(
        (response) => {
          if (!response.authResponse) {
            reject(response);
            return;
          }
          try {
            FB.api('/me', { fields: 'email' }, (userInfo: UserInfoLoggedInData) => {
              (async () => {
                if (response?.authResponse?.accessToken && response?.authResponse?.userID) {
                  const res: UserInfoLoggedInData = await this.login(
                    response.authResponse.userID,
                    response.authResponse.accessToken,
                    'facebook',
                  );

                  resolve({ ...res, email: userInfo.email });
                } else {
                  reject(new Error('Failed to login using Facebook'));
                }
              })();
            });
          } catch (error) {
            reject(error);
          }
        },
        { scope: 'email' },
      );
    });

  loginUsingApple = async (): Promise<UserInfoLoggedInData> => {
    const data = await AppleID.auth.signIn();

    const res: UserInfoLoggedInData = await this.login(data.authorization.code, data.authorization.id_token, 'apple');

    return { ...res, email: data.user?.email };
  };
}

export default SocialLoginService;
