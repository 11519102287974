import classnames from 'classnames';
import { forwardRef, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { countries, CountryInterface } from 'country-codes-flags-phone-codes';

import { INPUT_TYPES } from 'root/widgets/common-components/generic-input-group/constants';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import { PhoneNumberInputProps } from './types';
import './phone-number-input.scss';
import { HIVE_STATIC_VERSION } from 'root/widgets/constants';
import { CountrySelectModal } from 'root/widgets/country-select-modal';
import HUIImage from 'root/widgets/common-components/hui-image';

const checronDownIcon = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/font/v3/chevron-down-small.svg`;

const PhoneNumberInput = forwardRef(
  ({ countryCode, className, metadata, disabled, countrySelectModal, ...inputProps }: PhoneNumberInputProps, ref) => {
    const [isCountrySelectOpen, setIsCountrySelectOpen] = useState(false);

    const countryObject = useMemo(() => countries.find((country) => country.dialCode === countryCode), [countryCode]);

    const handleCountryCodeClick = () => {
      setIsCountrySelectOpen(true);
    };

    const handleCloseCountrySelect = () => {
      setIsCountrySelectOpen(false);
    };

    const handleSelectCountry = (country: CountryInterface) => {
      setIsCountrySelectOpen(false);
      countrySelectModal.onSelect?.(country);
    };

    return (
      <div className={classnames('phone-number-input-root', className, { disabled })}>
        <div className="country-code" onClick={handleCountryCodeClick}>
          <span>{countryObject?.flag}</span>
          <span>{countryObject?.dialCode}</span>
          <HUIImage src={checronDownIcon} />
        </div>
        <Form.Control
          {...filterMetadata(metadata)}
          {...extractDataAutomationId(metadata)}
          type={INPUT_TYPES.NUMBER}
          ref={ref}
          disabled={disabled}
          {...inputProps}
        />
        <CountrySelectModal
          {...countrySelectModal}
          show={isCountrySelectOpen}
          onHide={handleCloseCountrySelect}
          onSelect={handleSelectCountry}
        />
      </div>
    );
  },
);

PhoneNumberInput.displayName = 'PhoneNumberInput';

export default PhoneNumberInput;
