/**
 * Use this function to set the height of a view to height css variable for the container.
 * 
 * variable name: --element-height
 * use this variable for styling the container.
 * 
 * @param container 
 * @param view 
 * @returns 
 */
export const applyViewHeight = (container: HTMLDivElement | null, view: HTMLDivElement | null) => {
  if (!container || !view) {
    return;
  }

  const height = view.getBoundingClientRect().height;

  container.style.setProperty('--element-height', `${height}px`);
};
