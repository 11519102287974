import { ChangeEventHandler, FC, MouseEventHandler, useEffect, useState } from 'react';
import { Modal, Form, InputGroup } from 'react-bootstrap';
import { View } from './constants';
import { FinanceEnquiryModalProps, FinanceEnquiryViewProps } from './types';
import { SvgIcon } from 'root/widgets/svg-icon';
import { EVENT_NAMES, emit } from 'root/widgets/events';

import './finance-enquiry-view.scss';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import Actionable from 'root/widgets/common-components/actionable';
import { HUIModal } from 'root/widgets/common-components/hui-modal';

const FinanceEnquiryView: FC<FinanceEnquiryViewProps> = (props) => {
  const {
    userEmail,
    name,
    mobileNumber,
    title,
    subtitle,
    aboutTitle,
    checkBtnText,
    viewBtnText,
    talkBtnText,
    backBtnText,
    enquiryTitle,
    enquirySubtitle,
    successTitle,
    successSubtitle,
    submitBtnProps,
    submitBtnText,
    viewBtnProps,
    checkBtnProps,
    talkBtnProps,
    backBtnProps,
    nameInputProps,
    mobileInputProps,
    financeLogo,
    successIcon,
    apiError,
    context,
    metadata,
    dependencies: { mortgageService },
  } = props;
  const [view, setView] = useState(View.OPTIONS_VIEW);
  const [inputName, setInputName] = useState(name.value || '');
  const [inputMobile, setInputMobile] = useState(mobileNumber.value || '');
  const [errorText, setErrorText] = useState('');
  const [nameError, setNameError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [shouldDisableSubmitButton, setShouldDisableSubmitButton] = useState(true);
  const mobileNumberRegex = new RegExp(mobileNumber.validationRegEx);
  const nameRegex = new RegExp(name.validationRegEx);

  useEffect(() => {
    setShouldDisableSubmitButton(!inputName || !inputMobile);
  }, [inputName, inputMobile]);

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const inputValue = evt.target.value;
    setInputName(inputValue.startsWith(' ') ? inputValue.trimStart() : inputValue);
    nameInputProps.onChange?.(evt);
  };

  const handleMobileChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const { value } = evt.target;

    setInputMobile(value);
    mobileInputProps.onChange?.(evt);
  };

  const handleSubmitClick: MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    emit(EVENT_NAMES.FINANCE_ENQUIRY.CONFIRM, context);
    const isMobileValid = mobileNumberRegex.test(inputMobile);
    const isNameValid = nameRegex.test(inputName);
    if (!isMobileValid) {
      setMobileError(mobileNumber.error);
    }
    if (!isNameValid) {
      setNameError(name.error);
    }
    if (!isMobileValid || !isNameValid) {
      return;
    }
    setLoading(true);
    mortgageService
      .submitFinanceEnquiry(inputName, inputMobile, userEmail)
      .then((isSuccess) => {
        setLoading(false);
        if (isSuccess) {
          setView(View.SUCCESS_VIEW);
        }

        return true;
      })
      .catch(() => {
        setLoading(false);
        setErrorText(apiError);
      });
  };

  checkBtnProps.onClick = () => {
    emit(EVENT_NAMES.FINANCE_ENQUIRY.PREQUAL, context);
  };

  viewBtnProps.onClick = () => {
    emit(EVENT_NAMES.FINANCE_ENQUIRY.LOANS, context);
  };

  talkBtnProps.onClick = () => {
    setView(View.ENQUIRY_VIEW);
    emit(EVENT_NAMES.FINANCE_ENQUIRY.TALK_TO_US, context);
  };

  backBtnProps.onClick = () => {
    setView(View.OPTIONS_VIEW);
    emit(EVENT_NAMES.FINANCE_ENQUIRY.BACK, context);
  };

  return (
    <div className="finance-enquiry-view-root" {...filterMetadata(metadata)} {...extractDataAutomationId(metadata)}>
      <div className="finance-enquiry-view">
        <SvgIcon className="finance-enquiry-view__icon" width={40} height={40} src={successIcon} />
        <div className="finance-enquiry-view__title">{title}</div>
        <div className="finance-enquiry-view__subtitle">{subtitle}</div>
        <div className="finance-enquiry-view__body">
          {view === View.OPTIONS_VIEW && (
            <div className="finance-enquiry-view__about">
              <div className="finance-enquiry-view__about-title-logo">
                <div className="finance-enquiry-view__about-title">{aboutTitle}</div>
              </div>
              <div className="finance-enquiry-view__about-btn-group">
                <ActionableLink className="btn btn-secondary finance-enquiry-view__about-btn" {...checkBtnProps}>
                  {checkBtnText}
                </ActionableLink>
                <ActionableLink className="btn btn-secondary finance-enquiry-view__about-btn" {...viewBtnProps}>
                  {viewBtnText}
                </ActionableLink>
                <ActionableLink className="btn btn-secondary finance-enquiry-view__about-btn" {...talkBtnProps}>
                  {talkBtnText}
                </ActionableLink>
              </div>
            </div>
          )}
          {view === View.ENQUIRY_VIEW && (
            <div className="finance-enquiry-view__input">
              <div className="finance-enquiry-view__input-btn-logo">
                <ActionableLink className="finance-enquiry-view__input-back-btn" {...backBtnProps}>
                  <i className="pgicon-arrow-left" /> {backBtnText}
                </ActionableLink>
              </div>
              <div className="finance-enquiry-view__input-title">{enquiryTitle}</div>
              <Form className="finance-enquiry-view__form-group">
                <div className="finance-enquiry-view__name-mobile-group">
                  <Form.Group className="finance-enquiry-view__name-group">
                    <Form.Label className="finance-enquiry-view__name-label mb-3">{name.label}</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="name"
                      {...nameInputProps}
                      value={inputName}
                      onChange={handleNameChange}
                    />
                    {nameError && <div className="finance-enquiry-view__error">{nameError}</div>}
                  </Form.Group>
                  <Form.Group className="finance-enquiry-view__mobile-group">
                    <Form.Label className="finance-enquiry-view__mobile-label mb-3">{mobileNumber.label}</Form.Label>
                    <InputGroup>
                      {mobileNumber.prefix && <InputGroup.Text>{mobileNumber.prefix}</InputGroup.Text>}
                      <Form.Control
                        pattern="[0-9]*"
                        inputMode="numeric"
                        required
                        name="mobileNumber"
                        className="enquiry-number-input"
                        maxLength={8}
                        {...mobileInputProps}
                        onChange={handleMobileChange}
                        value={inputMobile}
                      />
                    </InputGroup>
                    {mobileError && <div className="finance-enquiry-view__error">{mobileError}</div>}
                  </Form.Group>
                </div>
                <div className="finance-enquiry-view__input-policy">{enquirySubtitle}</div>
                <Actionable
                  {...submitBtnProps}
                  shouldShowLoading={isLoading}
                  onClick={handleSubmitClick}
                  disabled={shouldDisableSubmitButton}
                >
                  {submitBtnText}
                </Actionable>
                {errorText && <div className="finance-enquiry-view__error">{errorText}</div>}
              </Form>
            </div>
          )}

          {view === View.SUCCESS_VIEW && (
            <div className="finance-enquiry-view__success">
              <div className="finance-enquiry-view__success-title-logo">
                <div className="finance-enquiry-view__success-title">{successTitle}</div>
              </div>
              <div className="finance-enquiry-view__success-subtitle">{successSubtitle}</div>
            </div>
          )}
          <SvgIcon className="finance-enquiry-view__logo" width={118} height={32} src={financeLogo} />
        </div>
      </div>
    </div>
  );
};

export const FinanceEnquiryModal: FC<FinanceEnquiryModalProps> = ({
  shouldShow,
  onHide,
  onShow,
  modalTitle,
  ...props
}) => (
  <HUIModal show={shouldShow} className="finance-enquiry-modal-root" onHide={onHide} onShow={onShow} centered>
    <HUIModal.Header closeButton>
      <Modal.Title>{modalTitle}</Modal.Title>
    </HUIModal.Header>
    <HUIModal.Body>
      <FinanceEnquiryView {...props} />
    </HUIModal.Body>
  </HUIModal>
);

FinanceEnquiryView.displayName = 'FintechEnquiryView';

export default FinanceEnquiryView;
