import { FC } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import ActionableLink from 'root/widgets/common-components/actionable-link';

import { AgentBadgeExplanationProps } from 'root/widgets/agent-badge/types';

const BadgePopover: FC<AgentBadgeExplanationProps> = ({ title, description, action, children }) => (
  <OverlayTrigger
    trigger="click"
    placement="bottom"
    rootClose
    overlay={
      <Popover className="agent-info-popover">
        <Popover.Header as="h3">{title}</Popover.Header>
        <Popover.Body>
          {description}
          <br />
          <ActionableLink className="agent-badge-btn btn btn-outline-secondary" {...action} />
        </Popover.Body>
      </Popover>
    }
  >
    {children as JSX.Element}
  </OverlayTrigger>
);

BadgePopover.displayName = 'BadgePopover';

export default BadgePopover;
