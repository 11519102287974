import classNames from 'classnames';
import { FC } from 'react';
import { Container } from 'react-bootstrap';

import HUIImage from 'root/widgets/common-components/hui-image';
import Title from 'root/widgets/common-components/title';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './hui-page-header.scss';
import { HUIPageHeaderProps } from './types';

const HUIPageHeader: FC<HUIPageHeaderProps> = (props) => {
  const { className, heading, description, image, metadata } = props;

  return (
    <div
      className={classNames('hui-page-header-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <Container fluid="md" className="hui-page-header-wrap">
        <div className="hui-page-header-left-wrap">
          <Title className="heading" headerTag="h2">
            {heading}
          </Title>
          {description && <p className="description">{description}</p>}
        </div>
        {image && <HUIImage className="hui-page-header-image" src={image?.src} alt={image?.alt} />}
      </Container>
    </div>
  );
};

export default HUIPageHeader;
