import { FC } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import type { ContactAgentActionsProps } from 'root/widgets/contact-agent-sheet/types';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const ContactAgentAction: FC<ContactAgentActionsProps> = ({
  onClick,
  type,
  icon,
  text,
  isConsentChecked,
  isIntentSelected,
  userIntent,
  renderIcon,
  ...linkProps
}) => {
  const handleChannelClick = () => {
    if (onClick) {
      onClick(isConsentChecked);
    }
  };

  return (
    <ActionableLink
      className={classNames({ disabled: !isEmpty(userIntent) && !isIntentSelected })}
      key={icon}
      variant="secondary btn"
      onClick={handleChannelClick}
      {...linkProps}
    >
      {renderIcon(type, icon)}
      {text}
    </ActionableLink>
  );
};

export default ContactAgentAction;
