/* eslint-disable react/jsx-no-constructed-context-values */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import classnames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';

import Wrapper from 'root/widgets/common-components/wrapper';

import { SearchCategory } from 'root/widgets/constants';

import SearchWithFilterContext from './context';
import './search-with-filter.scss';
import { SearchWithFilterProps } from './types';
import { getCategoryFilters, initFilters, processFilterStates } from './utils';

const SearchWithFilter: React.FC<SearchWithFilterProps> = ({
  children,
  className,
  stack,
  region,
  locale,
  metadata,
  dataAutomationId,
  selectedFilters,
  isFilterPanelHidden,
  onSubmit,
  onFilterChange,
  onQueryChange,
  onLocationSelect,
  onApplyLocationAccessory,
  data,
  foundLocations,
  recentSearchesItems,
  onRecentSearchSelect,
  dataResolver,
  category,
  logError,
  context,
}) => {
  const initalFilters = initFilters(selectedFilters, data);
  const [filters, setFilters] = useState(initalFilters);

  const initialFiltersByCategory = useMemo(() => {
    const result = {};

    Object.values(SearchCategory).forEach((key) => {
      result[key] = getCategoryFilters(filters);
    });

    return result;
  }, []);

  const prevCategoryRef = useRef(category);
  const filtersByCategoryRef = useRef(initialFiltersByCategory);

  // Effect to store filters by category before category change
  useEffect(() => {
    filtersByCategoryRef.current[prevCategoryRef.current] = getCategoryFilters(filters);

    setFilters({ ...filters, ...filtersByCategoryRef.current[category] });

    prevCategoryRef.current = category;
  }, [category]);

  useEffect(() => {
    onFilterChange(processFilterStates(filters, setFilters));
  }, [filters]);

  useEffect(() => {
    if (filters.isAISearchEnabled) {
      setFilters({ ...initalFilters, isAISearchEnabled: true });
    }
  }, [filters?.isAISearchEnabled]);

  return (
    <Col className={className} {...metadata}>
      <div className={classnames('search-with-filter-root', stack, className)}>
        <Wrapper>
          <SearchWithFilterContext.Provider
            value={{
              stack,
              region,
              locale,
              dataAutomationId,
              filters,
              setFilters,
              isFilterPanelHidden,
              onSubmit,
              onQueryChange,
              onLocationSelect,
              onApplyLocationAccessory,
              data,
              foundLocations,
              category,
              recentSearchesItems,
              onRecentSearchSelect,
              dataResolver,
              logError,
              context,
            }}
          >
            {children}
          </SearchWithFilterContext.Provider>
        </Wrapper>
      </div>
    </Col>
  );
};

SearchWithFilter.displayName = 'SearchWithFilter';

export default SearchWithFilter;
