import { FC } from 'react';
import { Divider } from 'root/widgets/common-components/divider';

const AgentCardLoadingView: FC = () => (
  <div className="agent-card-root is-loading">
    <div className="agent-card-left-section" />
    <div className="agent-card-right-section">
      <p className="agent-card-loading-text-1" />
      <div>
        <p className="agent-card-loading-text-3" />
      </div>
      <Divider />
      <p className="agent-card-loading-text-2" />
      <div className="agent-meta-info-fields">
        <div>
          <p className="agent-card-loading-text-4" />
          <p className="agent-card-loading-text-4" />
        </div>
        <div>
          <p className="agent-card-loading-text-5" />
        </div>
      </div>
      <p className="agent-card-loading-btn-1" />
    </div>
  </div>
);

export default AgentCardLoadingView;
