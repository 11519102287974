import { OptionType } from 'root/widgets/drilldown-menu/constants';
import {
  DrilldownInputOptionData,
  DrilldownMenuOptionData,
  DrilldownTreeOptionData,
} from 'root/widgets/drilldown-menu/types';

export const isTreeOptionData = (optionData: DrilldownMenuOptionData): optionData is DrilldownTreeOptionData =>
  'data' in optionData && optionData.data.length > 0;

export const isInputData = (optionData: DrilldownMenuOptionData): optionData is DrilldownInputOptionData =>
  optionData.type === OptionType.INPUT;
