import classNames from 'classnames';
import React from 'react';

import { EVENT_NAMES, emit } from 'root/widgets/events';

import './item-stack.scss';
import { ItemStackProps } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const ItemStack: React.FC<ItemStackProps> = ({ item, className, context }) => {
  const [isCollapsed, setCollapsed] = React.useState(true);
  const handleAnchorClick = (e) => {
    // preventing click
    if (
      (e.target.classList.contains('expandable-item-stack') && e.target.classList.contains('expandable')) ||
      e.target.classList.contains('pgicon-arrow-down')
    ) {
      setCollapsed(!isCollapsed);
      e.preventDefault();
    } else {
      emit(EVENT_NAMES.GLOBAL_FOOTER.SITEMAP_LINK_ON_CLICK, { ...context, data: e.target.textContent });
    }
  };
  return (
    <div
      className={classNames(
        'item-stack-root',
        className,
        item.onlyMobile ? 'only-mobile' : '',
        item.onlyDesktop ? 'only-desktop' : '',
      )}
    >
      <ActionableLink
        target={item.target ?? '_blank'}
        href={item.href}
        key={item.title.replace(' ', '-')}
        className={classNames(
          `${item?.links && item?.links.length > 0 ? 'expandable' : 'not-expandable'}`,
          `${isCollapsed ? '' : 'expand'}`,
          className,
        )}
        onClick={handleAnchorClick}
      >
        {item.title}
        {item?.links && item?.links.length > 0 && <i className="pgicon-arrow-down" />}
      </ActionableLink>
      <div className={classNames('link-action-strip-list-item', `${isCollapsed ? '' : 'expand'}`)}>
        {item?.links &&
          item?.links.length > 0 &&
          item.links.map((subItem) => (
            <ItemStack
              item={subItem}
              key={subItem.title.replace(' ', '-')}
              context={{ ...context, data: 'sitemap-link' }}
            />
          ))}
      </div>
    </div>
  );
};

export default ItemStack;
