import classNames from 'classnames';
import parse from 'html-react-parser';
import { FC, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import { SvgIcon } from 'root/widgets/svg-icon';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './dropdown-segment.scss';
import { DropdownSegmentProps } from './types';

const DropdownSegment: FC<DropdownSegmentProps> = (props) => {
  const {
    title,
    className,
    dropDirection = 'down',
    isDisabled,
    shouldShowLoading,
    dropdownOptions,
    metadata,
    selectedOption,
    placeholder,
    dropdownToggleIcon,
    dropdownOptionIcon,
    dropdownMenuTitle,
    onSelectionChange,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const preloadImage = (url: string) => {
    const img = new Image();
    img.src = url;
  };

  useEffect(() => {
    if (dropdownOptionIcon?.default?.svg?.url && dropdownOptionIcon?.selected?.svg?.url) {
      preloadImage(dropdownOptionIcon.default.svg.url);
      preloadImage(dropdownOptionIcon.selected.svg.url);
    }
  }, [dropdownOptionIcon]);

  const renderedTitle = useMemo(() => parse(title || ''), [title]);

  return (
    <div
      className={classNames('dropdown-segment-root', className, { disabled: isDisabled })}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      {title && (
        <span className="dropdown-segment-title">
          {renderedTitle}
          {shouldShowLoading && <span className="animate-spin pgicon pgicon-loading" />}
        </span>
      )}
      <Dropdown
        drop={dropDirection}
        onSelect={onSelectionChange}
        onToggle={(show) => {
          setIsCollapsed(!show);
        }}
        data-automation-id="dropdown-segment"
      >
        <Dropdown.Toggle
          variant="light"
          disabled={isDisabled}
          className={classNames('dropdown-segment-placeholder', {
            selected: Boolean(selectedOption?.value),
            collapsed: isCollapsed,
          })}
          da-id="dropdown-segment-toggle"
        >
          {dropdownToggleIcon?.svg && selectedOption?.value && (
            <SvgIcon
              className={classNames('dropdown-segment-icon value-icon', dropdownToggleIcon?.className)}
              src={dropdownToggleIcon.svg?.url}
              width={dropdownToggleIcon.svg?.size}
              height={dropdownToggleIcon.svg?.size}
              title="sort"
              shouldUseImage
            />
          )}
          <span className="dropdown-segment-text">{selectedOption?.text || placeholder}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu flip={false} da-id="dropdown-segment-list">
          {dropdownMenuTitle && <Dropdown.Header>{dropdownMenuTitle}</Dropdown.Header>}
          {dropdownOptions.length > 0 &&
            dropdownOptions.map((option) => (
              <Dropdown.Item key={option.value} eventKey={option.value} href={option.href}>
                {dropdownOptionIcon?.default && (
                  <SvgIcon
                    className={classNames('dropdown-segment-icon radio-icon')}
                    src={
                      (option.value === selectedOption?.value
                        ? dropdownOptionIcon.selected?.svg.url
                        : dropdownOptionIcon.default.svg.url) ?? ''
                    }
                    width={
                      option.value === selectedOption?.value
                        ? dropdownOptionIcon.selected?.svg.size
                        : dropdownOptionIcon.default.svg.size
                    }
                    height={
                      option.value === selectedOption?.value
                        ? dropdownOptionIcon.selected?.svg.size
                        : dropdownOptionIcon.default.svg.size
                    }
                    title="option"
                    shouldUseImage
                  />
                )}
                {option.text}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

DropdownSegment.displayName = 'DropdownSegment';

export default DropdownSegment;
