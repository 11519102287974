/* eslint-disable no-shadow, @typescript-eslint/no-shadow */
import { ENV, MARKETPLACE, REGION } from 'root/widgets/constants';
import { SERVICE } from 'root/widgets/pg-login/constants';

import BffService from './guruland';
import SocialLoginService from './social-login';
import type { ServiceMapType } from './types';

let environment: ENV;
let marketplaceKey: string;
let withProxy = false;

const instanceMap = {} as Record<string, ServiceMapType>;

const serviceMap = {
  [SERVICE.SOCIAL_LOGIN]: SocialLoginService,
  [SERVICE.BFF]: BffService,
};

type TuplesType<T> = T extends SERVICE ? [T, InstanceType<(typeof serviceMap)[T]>] : never;
type ClassType<A extends SERVICE> = Extract<TuplesType<SERVICE>, [A, any]>[1];

const setEnvironment = (env: ENV, region: REGION, marketplace: MARKETPLACE, shouldUseProxy = false) => {
  environment = env;
  marketplaceKey = `${region}-${marketplace}`;
  withProxy = shouldUseProxy;
};

const getService = <T extends SERVICE>(service: T): ClassType<T> => {
  if (!environment) {
    setEnvironment(ENV.INTEGRATION, REGION.SG, MARKETPLACE.PG);
  }

  if (!instanceMap[marketplaceKey]) {
    instanceMap[marketplaceKey] = {} as ServiceMapType;
  }

  if (!instanceMap[marketplaceKey][service]) {
    instanceMap[marketplaceKey][service] = new serviceMap[service](environment, marketplaceKey, withProxy);
  }

  return instanceMap[marketplaceKey][service];
};

export { getService, setEnvironment };
