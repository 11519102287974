import classnames from 'classnames';
import React from 'react';

import './nudge.scss';
import { NudgeProps } from './types';

const Nudge: React.FC<NudgeProps> = ({ className, iconClass, description, link, onClose, metadata }) => (
  <div className={classnames('nudge-root', className)} {...metadata}>
    <i className={classnames('icon-left pgicon', iconClass)} />
    <div className="nudge-body">
      {description && <p>{description}</p>}
      {link && (
        <a {...link.props} href={link.props?.href || '#'}>
          {link.text}
        </a>
      )}
    </div>
    <i className="pgicon pgicon-cancel icon-close" onClick={onClose} />
  </div>
);

Nudge.displayName = 'Nudge';

export default Nudge;
