import React from 'react';

import Actionable from 'root/widgets/common-components/actionable';

import './pg-legal-info.scss';
import { PGLegalInfoProps } from './types';

const SEOSegment: React.FC<PGLegalInfoProps> = (props) => {
  const { primaryText, secondaryText, actionableText } = props;
  const [isSecondaryVisible, setSecondaryVisible] = React.useState(false);
  const handleOnClick = () => setSecondaryVisible(!isSecondaryVisible);

  return (
    <div className="pg-legal-info-root">
      <p className="pg-legal-info pg-legal-info-primary">{primaryText}</p>
      {secondaryText && actionableText && (
        <>
          <Actionable
            variant="link"
            className={`read-more ${isSecondaryVisible ? '' : 'show'}`}
            onClick={handleOnClick}
          >
            {actionableText}
          </Actionable>
          <p className={`pg-legal-info pg-legal-info-secondary ${isSecondaryVisible ? 'show' : ''}`}>{secondaryText}</p>
        </>
      )}
    </div>
  );
};

export default SEOSegment;
