import { createContext } from 'react';

import { LOCALE, REGION, SearchCategory } from 'root/widgets/constants';

import type { SearchWithFilterContextType } from './types';

const defaultContext = {
  stack: 'horizontal',
  region: REGION.MY,
  locale: LOCALE.EN,
  filters: {},
  setFilters: () => {},
  onSubmit: () => {},
  onFilterChange: () => {},
  onQueryChange: () => {},
  data: {
    searchPanel: {
      searchInput: {
        placeholder: '',
        buttonText: '',
        changeDebounceDelay: 0,
        mobilePlaceholder: '',
        mobileButtonText: '',
        propertyTypeGroupExclude: {
          residential: [],
        },
        objectType: [],
      },
      searchOptions: [],
    },
    recentSearches: {
      title: '',
    },
    filters: {},
  },
  foundLocations: [],
  recentSearchesItems: [],
  onRecentSearchSelect: () => {},
  dataResolver: {
    fetchLocationItems: () => Promise.resolve([]),
  },
  category: SearchCategory.BUY,
  logError: () => {},
  context: {},
};

const SearchWithFilterContext = createContext<SearchWithFilterContextType>(defaultContext);

export default SearchWithFilterContext;
