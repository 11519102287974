import classnames from 'classnames';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Dropdown, Form, InputGroup } from 'react-bootstrap';
import './hui-input.scss';
import { HUIInputProps, ClearButtonProps } from './types';
import { BACKSPACE_CODE, DELETE_CODE } from './constants';
import { SvgIcon } from 'root/widgets/svg-icon';
import { HIVE_STATIC_ICON_PATH_V3 } from 'root/widgets/constants';

function getDropDownIcon(showChevron: boolean, isFocused: boolean, handleDropdownIconClick: () => void) {
  return showChevron ? (
    <i className={classnames('pgicon-expand', { 'is-focused': isFocused })} onClick={handleDropdownIconClick} />
  ) : null;
}

const ClearButton = ({ onInputChange }: ClearButtonProps) => {
  const handleOnClick = () => {
    onInputChange?.('');
  };
  return (
    <InputGroup.Text className={classnames('hui-input__clear-button hui-input-suffix p-0')} onClick={handleOnClick}>
      <SvgIcon src={`${HIVE_STATIC_ICON_PATH_V3}/cross-small.svg`} shouldUseImage />
    </InputGroup.Text>
  );
};

const HUIInput = forwardRef<HTMLInputElement | undefined, HUIInputProps>(
  ({ className, metadata, showChevron = true, ...otherProps }, ref) => {
    const {
      prefix,
      suffix,
      allowRegex,
      maxValue,
      maxLength,
      suggestions,
      onInputChange,
      hasError,
      title,
      helpText,
      value,
      children,
      isClearable = false,
      modifierKeys = [],
      shouldShowCharacterCount,
      ...inputProps
    } = otherProps;

    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleSelectSuggestion = (event: React.MouseEvent<HTMLDivElement>) => {
      onInputChange?.(String(event.currentTarget.dataset.value));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputChange?.(event.target.value);
      inputProps.onChange?.(event);
    };

    const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      inputProps.onFocus?.(event);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      inputProps.onKeyDown?.(event);

      if ([BACKSPACE_CODE, DELETE_CODE, ...modifierKeys].includes(event.key)) {
        return;
      }

      if (allowRegex && !allowRegex.test(event.key)) {
        event.preventDefault();

        return;
      }

      // this is a special case for maxLength = 1 it is used for OTP input https://pgurus.slack.com/archives/C060H632R2L/p1733132519164889?thread_ts=1733118546.386799&cid=C060H632R2L
      if (
        maxLength &&
        maxLength === 1 &&
        /^[\dA-Za-z]$/.test(event.key) &&
        event.currentTarget.value.length >= maxLength
      ) {
        let newValue = event.currentTarget.value;
        newValue = newValue.slice(0, Math.max(0, newValue.length - 1)) + event.key;

        event.currentTarget.value = newValue;
        onInputChange(newValue);
        event.preventDefault();

        return;
      }

      if (maxLength && event.currentTarget.value.length >= maxLength) {
        onInputChange(event.currentTarget.value.slice(0, maxLength));
        event.preventDefault();

        return;
      }

      const newValue = `${event.currentTarget.value}${event.key}`;
      if (maxValue && maxValue < Number.parseFloat(newValue)) {
        event.preventDefault();
      }
    };

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      inputProps.onBlur?.(event);
    };

    const handleDropdownIconClick = () => {
      setIsFocused(true);
      inputRef.current?.focus?.();
      inputProps.onPrefixSuffixClick?.();
    };

    const countInputCharacters = (input: string | number | string[] | undefined) => {
      if (input === undefined) return 0;
      let inputText = '';
      if (typeof input === 'number') inputText = String(input);
      else if (Array.isArray(input)) inputText = input.join('');
      else inputText = input;
      return inputText.length;
    };

    const updatedSuffix = suggestions ? getDropDownIcon(showChevron, isFocused, handleDropdownIconClick) : suffix;

    const inputGroup = (
      <>
        {title && <Form.Label className="hui-input-title mb-3">{title}</Form.Label>}
        <InputGroup
          className={classnames('hui-input', className, {
            error: hasError,
            'has-suggestions': suggestions,
          })}
          {...metadata}
        >
          {prefix && (
            <InputGroup.Text className="hui-input-prefix px-4 pe-1 py-0" onClick={handleDropdownIconClick}>
              {prefix}
            </InputGroup.Text>
          )}
          <Form.Control
            {...inputProps}
            ref={inputRef}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            value={value}
            className={classnames({
              'pe-0 border-right-0': updatedSuffix,
              'ps-0 border-left-0': prefix,
              'hover-events': suggestions,
            })}
          />
          {isClearable ||
            (updatedSuffix && (
              <div className="d-flex align-items-center pe-3">
                {isClearable && value && <ClearButton onInputChange={onInputChange} />}
                {updatedSuffix && (
                  <InputGroup.Text
                    className={classnames('hui-input-suffix p-0', {
                      'ms-1': suggestions,
                    })}
                    onClick={handleDropdownIconClick}
                  >
                    {updatedSuffix}
                  </InputGroup.Text>
                )}
              </div>
            ))}
        </InputGroup>
      </>
    );

    return (
      <div className="hui-input-root">
        {children || (suggestions && suggestions?.length > 0) ? (
          <Dropdown show={isFocused}>
            <Dropdown.Toggle as="div">{inputGroup}</Dropdown.Toggle>
            <Dropdown.Menu className="hui-input-suggestions" da-id="dropdown-menu">
              <div className="hui-input-suggestions hui-dropdown-inner">
                {suggestions &&
                  suggestions.map((option) => (
                    <Dropdown.Item key={option.value} data-value={option.value} onMouseDown={handleSelectSuggestion}>
                      {option.text}
                    </Dropdown.Item>
                  ))}
                {children}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          inputGroup
        )}
        {(helpText || shouldShowCharacterCount) && (
          <div className="hui-input-caption mt-3">
            {helpText && <Form.Label className="hui-input-helptext">{helpText}</Form.Label>}
            {shouldShowCharacterCount && maxLength && (
              <Form.Label className="hui-input-character-count">
                {countInputCharacters(value)}/{maxLength}
              </Form.Label>
            )}
          </div>
        )}
      </div>
    );
  },
);

HUIInput.displayName = 'HUIInput';

export default HUIInput;
