import classNames from 'classnames';
import React from 'react';

import { EVENT_NAMES, emit } from 'root/widgets/events';

import './social-segment.scss';
import { SocialItemProps, SocialSegmentProps } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const SocialItem: React.FC<SocialItemProps> = ({ data, isLink, onClick }) => {
  const { text, name, icon, href, rel, target } = data;

  const handleClick = () => {
    onClick(name);
  };

  const actionableLinkClass = isLink ? 'icon-link' : href ? 'icon-link' : 'icon-text';

  return (
    <div>
      <ActionableLink target={target} className={actionableLinkClass} href={href ?? ''} rel={rel} onClick={handleClick}>
        {icon && <i className={icon} />}
        {text && <div className="icon-text-social">{text}</div>}
      </ActionableLink>
    </div>
  );
};

const SocialSegment: React.FC<SocialSegmentProps> = ({ className, context, socialSegmentData }) => {
  const onSocialClick = (socialName) => {
    emit(EVENT_NAMES.GLOBAL_FOOTER.SOCIAL_ON_CLICK, { ...context, data: socialName });
  };

  return (
    <div className={classNames('social-segment-root', className)}>
      <div className="left-pane">
        {socialSegmentData.left.map((socialItem, index) => (
          <SocialItem key={`left-pane-${index}`} data={socialItem} onClick={onSocialClick} isLink />
        ))}
      </div>

      <div className="right-pane">
        {socialSegmentData.right.map((socialItem) => (
          <SocialItem
            key={`right-pane-${socialItem.text ? socialItem.text.replace(/\s/g, '') : socialItem.icon}`}
            data={socialItem}
            onClick={onSocialClick}
          />
        ))}
      </div>
    </div>
  );
};

export default SocialSegment;
