import classNames from 'classnames';
import parse from 'html-react-parser';
import { FC } from 'react';

import { AgentBadge } from 'root/widgets/agent-badge';
import { CERTIFIED_AGENT_ICON as certifiedIcon, HIVE_UI_CORE_URL } from 'root/widgets/constants';
import { Badge } from 'root/widgets/common-components/badge';

import './agent-info-v3.scss';
import { AgentInfoV3Props } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { SvgIcon } from 'root/widgets/svg-icon';
import HUIImage from 'root/widgets/common-components/hui-image';

const AgentInfoV3: FC<AgentInfoV3Props> = ({
  agent,
  agency,
  verification,
  rating,
  certificate,
  metadata,
  ...linkProps
}) => {
  const shouldTruncateName = agent.description || rating || certificate?.isCertified;

  return (
    <ActionableLink
      className={classNames('agent-info-v3-root', { corporate: agent.isCorporate })}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
      {...linkProps}
    >
      <div className={classNames('avatar-wrapper', { 'featured-agent': agent.isFeaturedAgent })}>
        {agent.avatar ? (
          <HUIImage className="avatar" src={agent.avatar} alt={agent.name} />
        ) : (
          <SvgIcon
            src={`${HIVE_UI_CORE_URL}icons/svgs/images-1-o.svg`}
            height={24}
            width={24}
            color="var(--icon-inactive-primary)"
            shouldUseImage
          />
        )}
        {agent.isFeaturedAgent && (
          <div className="crown-icon">
            <SvgIcon
              src={`${HIVE_UI_CORE_URL}icons/svgs/crown-f.svg`}
              height={16}
              width={16}
              color="var(--icon-active-tertiary)"
              shouldUseImage
            />
          </div>
        )}
      </div>
      <div className="details-wrapper">
        {verification?.isVerified && (
          <Badge type="small" bg="light-green" className="verification">
            {verification.text}
          </Badge>
        )}
        <div className={classNames('agent-name', { 'truncate-line': shouldTruncateName })}>{agent.name}</div>
        {rating && (
          <div className={classNames('agent-rating', { 'has-review': rating.hasReview })}>
            <i className="pgicon-star" />
            {rating.score}
            {rating.text && <span className="rating-text">{rating.text}</span>}
          </div>
        )}
        {agency && (
          <div className="agency">
            {agency.avatar && <HUIImage className="agency-avatar" src={agency.avatar} alt={agency.name} />}
            {agency.name}
          </div>
        )}
        {agent.description && (
          <div className="agent-description">
            {typeof agent.description === 'string' ? parse(agent.description) : agent.description}
          </div>
        )}
        {certificate?.isCertified && (
          <AgentBadge
            className="agent-certificate"
            icon={certifiedIcon}
            text={certificate.text}
            explanation={certificate.explanation}
          />
        )}
      </div>
      {Boolean(linkProps.href) && (
        <SvgIcon
          src={`${HIVE_UI_CORE_URL}icons/svgs/chevron-right-small-o.svg`}
          height={24}
          width={24}
          shouldUseImage
        />
      )}
    </ActionableLink>
  );
};

AgentInfoV3.displayName = 'AgentInfo';

export default AgentInfoV3;
