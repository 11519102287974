import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import './agent-review-modal.scss';
import { InputState } from './constants';
import { ContactDetailsForm } from './sub-components/contact-details-form';
import { RatingFactor } from './sub-components/rating-factor';
import { AgentReviewFormData, AgentReviewModalProps } from './types';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import Title from 'root/widgets/common-components/title';
import { RatingInput } from 'root/widgets/common-components/rating-input';

const AgentReviewModal: React.FC<AgentReviewModalProps> = ({ config, data, context }) => {
  const [overallRating, setOverallRating] = useState(0);
  const [overallExperience, setOverallExperience] = useState<string>('');
  const [fullName, setFullName] = useState<string | undefined>('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [inputState, setInputState] = useState<InputState | undefined>();
  const [ratingFactors, setRatingFactors] = useState({});
  const [isValidated, setValidated] = useState(false);

  const { shouldShow, onHide } = config;
  const {
    title,
    subTitle,
    bodyTitle,
    ratingFactorConfig,
    contactDetailConfig,
    footerConfig,
    experienceInputConfig,
    ratingInputConfig,
    handleFormSubmit,
  } = data;

  useEffect(() => {
    const defaultRatingFactors = Object.fromEntries(
      ratingFactorConfig.categories.map((category) => [category.code, 0]),
    );
    setRatingFactors(defaultRatingFactors);
  }, []);

  const updateInputState = (inputCharLength: number) => {
    setInputState(inputCharLength < experienceInputConfig.minCharLimit ? InputState.ERROR : InputState.SUCCESS);
  };

  const handleExperienceTextChange = (event): void => {
    setOverallExperience(event.target.value);
    updateInputState(event.target.value.length);
  };

  const handleBlur = () => {
    updateInputState(overallExperience?.length);
  };

  const handleRatingChange = ({ factorCode, ratingValue }) => {
    setRatingFactors({ ...ratingFactors, [`${factorCode}`]: ratingValue });
  };

  const handleContactChange = (fullNameValue, phoneNumberValue) => {
    setFullName(fullNameValue);
    setPhoneNumber(phoneNumberValue);
  };

  const collectFormData = (): AgentReviewFormData => ({
    overallRating,
    overallExperience,
    ratingFactors,
    fullName,
    phoneNumber,
  });

  const handleSubmit = (event) => {
    emit(EVENT_NAMES.AGENT_REVIEW_MODAL.FORM_SUBMIT, { ...context, formData: collectFormData() });

    const form = event.currentTarget;
    event.preventDefault();

    if (!form.checkValidity() || inputState !== InputState.SUCCESS) {
      event.stopPropagation();
    } else {
      handleFormSubmit(collectFormData());
    }
    setValidated(true);
  };

  const handleFormClose = () => {
    emit(EVENT_NAMES.AGENT_REVIEW_MODAL.FORM_CLOSE, { ...context, formData: collectFormData() });

    onHide(collectFormData());
  };

  const defaultFooterContent =
    'I agree to PropertyGuru’s <a className="link-primary" href="/terms-of-service">Terms of Service</a> and <a className="link-primary" href="/privacy"> Privacy Policy</a> including the collection, use and disclosure of my personal information.';

  return (
    <HUIModal className="agent-review-modal-root" show={shouldShow} onHide={handleFormClose} centered>
      <Form noValidate validated={isValidated} className="agent-review-form" onSubmit={handleSubmit}>
        <HUIModal.Header closeButton>{title}</HUIModal.Header>
        <HUIModal.Body>
          <div className="modal-sub-title">{subTitle}</div>
          <div className="overall-rating-container">
            <Title headerTag="h3">{bodyTitle}</Title>
            <RatingInput {...ratingInputConfig} onChange={setOverallRating} />
            <div className="experience-input-length">{overallExperience.length}</div>
            <Form.Group className="overall-experience" controlId="validate-overall-experience">
              <Form.Control
                required
                className="overall-experience-input"
                as="textarea"
                rows={experienceInputConfig.noOfLines}
                placeholder={experienceInputConfig.placeholder}
                onChange={handleExperienceTextChange}
                value={overallExperience}
                onBlur={handleBlur}
                isInvalid={inputState === InputState.ERROR}
                minLength={experienceInputConfig.minCharLimit}
              />
              <div
                className={classNames('experience-validation-text', {
                  'text-success': inputState === InputState.SUCCESS,
                  'text-danger': inputState === InputState.ERROR,
                })}
              >
                {inputState && <i className={inputState === InputState.SUCCESS ? 'pgicon-ok' : 'pgicon-warning-o'} />}
                <Form.Control.Feedback>{experienceInputConfig.minCharPrompt}</Form.Control.Feedback>
              </div>
            </Form.Group>
          </div>
          {overallRating > 0 && (
            <div className="rating-factors-container">
              <h4 className="rating-factor-heading">
                {ratingFactorConfig.title}
                <span className="optional-text">({ratingFactorConfig.headingDescriptionText})</span>
              </h4>
              <div className="rating-factor-body">
                {ratingFactorConfig.categories.map((category) => (
                  <RatingFactor
                    key={category.code}
                    code={category.code}
                    title={category.name}
                    tooltipText={category.tooltipText}
                    ratingInputConfig={ratingInputConfig}
                    onChange={handleRatingChange}
                    metadata={{ 'data-automation-id': category.automationId }}
                  />
                ))}
              </div>
            </div>
          )}
          {overallRating > 0 && overallRating < 3 && (
            <div className="contact-details-root">
              <ContactDetailsForm {...contactDetailConfig} onChange={handleContactChange} />
            </div>
          )}
        </HUIModal.Body>
        <HUIModal.Footer>
          <div
            className="terms-and-conditions"
            dangerouslySetInnerHTML={{ __html: footerConfig.footerContent || defaultFooterContent }}
          />
          <Button variant="primary" type="submit" disabled={!overallRating} data-automation-id="submit-btn">
            {footerConfig.buttonText}
          </Button>
        </HUIModal.Footer>
      </Form>
    </HUIModal>
  );
};

AgentReviewModal.displayName = 'AgentReviewModal';

export default AgentReviewModal;
