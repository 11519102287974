import classnames from 'classnames';
import { FC, useCallback, useMemo } from 'react';

import { PropertyType } from 'root/widgets/constants';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './property-transaction-summary.scss';
import { PropertySummary } from './sub-components/property-summary';
import { TopLocationList } from './sub-components/top-location-list';
import type { PropertyTransactionSummaryProps, PropertyTypeBreakdownData } from './types';
import { Divider } from 'root/widgets/common-components/divider';

const PropertyTransactionSummary: FC<PropertyTransactionSummaryProps> = (props) => {
  const {
    heading,
    transactions,
    topLocationText,
    noDataText,
    topDistricts,
    topHDBTowns,
    className,
    metadata,
    propertyTypeConfig,
    zeroTransactionText,
  } = props;

  const getPropertyTypeBreakdown = useCallback(
    (propertyType: PropertyType): PropertyTypeBreakdownData | undefined =>
      transactions.find((summaryItem) => summaryItem?.propertyType === propertyType),
    [transactions],
  );

  const propertyTypeBreakdown = useMemo(
    (): Record<string, ReturnType<typeof getPropertyTypeBreakdown>> => ({
      condo: getPropertyTypeBreakdown(PropertyType.CONDO),
      landed: getPropertyTypeBreakdown(PropertyType.LANDED),
      hdb: getPropertyTypeBreakdown(PropertyType.HDB),
    }),
    [getPropertyTypeBreakdown],
  );

  return (
    <div
      className={classnames('property-transaction-summary-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <h4 className="property-transaction-summary-heading">{heading}</h4>
      <div className="property-transaction-summary-body">
        <div className="district-summary">
          <div className="property-transaction-summary-wrap">
            {propertyTypeBreakdown.condo && (
              <PropertySummary
                propertyTypeBreakdown={{
                  transactionCount:
                    propertyTypeBreakdown.condo.transactionCount > 0
                      ? propertyTypeBreakdown.condo.transactionCount.toString()
                      : zeroTransactionText,
                  ...propertyTypeConfig.condo,
                }}
                {...extractDataAutomationId({ dataAutomationId: 'agent-past-transactions-condo-total' })}
              />
            )}
            {propertyTypeBreakdown.landed && (
              <PropertySummary
                propertyTypeBreakdown={{
                  transactionCount:
                    propertyTypeBreakdown.landed.transactionCount > 0
                      ? propertyTypeBreakdown.landed.transactionCount.toString()
                      : zeroTransactionText,
                  ...propertyTypeConfig.landed,
                }}
                {...extractDataAutomationId({ dataAutomationId: 'agent-past-transactions-landed-total' })}
              />
            )}
          </div>
          <Divider />
          <TopLocationList noDataText={noDataText} heading={topLocationText} locations={topDistricts} />
        </div>

        <Divider className="vertical-divider" />

        {propertyTypeBreakdown.hdb && (
          <div className="town-summary">
            <div className="property-transaction-summary-wrap">
              <PropertySummary
                isSpaceBetween
                propertyTypeBreakdown={{
                  transactionCount:
                    propertyTypeBreakdown.hdb.transactionCount > 0
                      ? propertyTypeBreakdown.hdb.transactionCount.toString()
                      : zeroTransactionText,
                  ...propertyTypeConfig.hdb,
                }}
                {...extractDataAutomationId({ dataAutomationId: 'agent-past-transactions-hdb-total' })}
              />
            </div>
            <Divider className="hdb-section-divider" />
            <TopLocationList noDataText={noDataText} heading={topLocationText} locations={topHDBTowns} />
          </div>
        )}
      </div>
    </div>
  );
};
PropertyTransactionSummary.displayName = 'PropertyTransactionSummary';

export default PropertyTransactionSummary;
