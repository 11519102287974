/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';

import { VARIANTS } from 'root/widgets/location-filter-modal/constants';
import LocationFilterModalContext from 'root/widgets/location-filter-modal/context';
import { BodyProps } from 'root/widgets/location-filter-modal/types';

import './body.scss';
import MultiLevelVariant from './multi-level-variant/multi-level-variant';
import SingleLevelVariant from './single-level-variant/single-level-variant';

const Body: React.FC<BodyProps> = ({ data, items }) => {
  const variant = useContext(LocationFilterModalContext);

  return (
    <Modal.Body className="location-filter-modal-body-root">
      {variant === VARIANTS.SINGLE_LEVEL ? (
        <SingleLevelVariant data={data} items={items} />
      ) : (
        <MultiLevelVariant data={data} items={items} />
      )}
    </Modal.Body>
  );
};

Body.displayName = 'Body';

export default Body;
