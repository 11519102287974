import React from 'react';

import './agent-login-prompt.scss';
import { AgentLoginPromptProps } from './types';

const AgentLoginPrompt: React.FC<AgentLoginPromptProps> = ({ label, link, metadata }) => (
  <div className="agent-login-prompt-root" {...metadata}>
    <div className="label">{label}</div>
    <a href={link.href} data-automation-id="login-agentnet-lnk">
      {link.title}
    </a>
  </div>
);

AgentLoginPrompt.displayName = 'AgentLoginPrompt';

export default AgentLoginPrompt;
