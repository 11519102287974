import React from 'react';

import type { BaseProps } from 'root/widgets/types';

import './footer.scss';

const Footer: React.FC<BaseProps> = ({ children }) => <div className="pg-login-footer-root">{children}</div>;

Footer.displayName = 'Footer';

export default Footer;
