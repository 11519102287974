import { SearchInput } from 'root/widgets/common-components/search-input';

import { SearchBarProps } from 'root/widgets/mrt-search-modal/types';
import { HIVE_STATIC_ICON_PATH_V3 } from 'root/widgets/constants';
import Actionable from 'root/widgets/common-components/actionable';
import { SvgIcon } from 'root/widgets/svg-icon';

const SearchBar = ({
  searchQuery,
  searchPlaceholder,
  onDebounceSearchChange,
  onClearQuery,
  onQueryChange,
  onFocus,
}: SearchBarProps) => (
  <SearchInput className="search-bar">
    <SearchInput.Peripheral>
      <SvgIcon
        className="icon-search"
        src={`${HIVE_STATIC_ICON_PATH_V3}/search-o.svg`}
        height={24}
        width={24}
        shouldUseImage
        color="#282C2E"
      />
    </SearchInput.Peripheral>
    <SearchInput.Editable
      placeholder={searchPlaceholder}
      value={searchQuery}
      onChange={onQueryChange}
      onFocus={onFocus}
      debounceOptions={{
        callback: onDebounceSearchChange,
        delay: 200,
        shouldUpdateState: true,
      }}
      metadata={{
        dataAutomationId: 'mrt-search-input',
      }}
    />
    {searchQuery.length > 0 && (
      <SearchInput.Peripheral>
        <Actionable className="hui-btn-close" variant="link" onClick={onClearQuery}>
          <SvgIcon src={`${HIVE_STATIC_ICON_PATH_V3}/cross-small.svg`} height={24} width={24} color="#0D1011" />
        </Actionable>
      </SearchInput.Peripheral>
    )}
  </SearchInput>
);

SearchBar.displayName = 'SearchBar';

export default SearchBar;
