import React from 'react';

import { AgentContactInfo } from 'root/widgets/agent-contact-info';
import { EmptyView } from 'root/widgets/empty-view';
import { EnquiryCard } from 'root/widgets/enquiry-card';
import { ErrorCard } from 'root/widgets/error-card';
import { FinanceBanner } from 'root/widgets/finance-banner';
import { ListingEnquiryInfo } from 'root/widgets/listing-enquiry-info';
import { ProfileBanner } from 'root/widgets/profile-banner';

import { ListingHiddenCard } from 'root/symbiosis-widgets/wrappers/listing-hidden-card';

import './past-enquiry-view.scss';
import { InactiveListingView, LoadingView } from './sub-components';
import { PastEnquiryViewProps } from './types';

const PastEnquiryView: React.FC<PastEnquiryViewProps> = ({
  empty,
  error,
  enquiries,
  inactiveListingView,
  profileBanner,
  financeBanner,
}) => {
  const LISTING_STATUS_CODE_ACT = 'ACT';

  if (error) {
    return (
      <ErrorCard>
        <ErrorCard.Img />
        <ErrorCard.Title>{error.title}</ErrorCard.Title>
        <ErrorCard.Body>{error.body}</ErrorCard.Body>
        <ErrorCard.Button onClick={error.buttonClick}>{error.buttonText}</ErrorCard.Button>
      </ErrorCard>
    );
  }

  if (empty) {
    return (
      <div className="past-enquiry-empty-wrapper">
        <EmptyView {...empty} />
      </div>
    );
  }

  if (enquiries) {
    return (
      <div className="past-enquiry-view-root">
        {enquiries.map((enquiry, index) => (
          <React.Fragment key={enquiry.listingEnquiryInfo.listingInfo.id}>
            {enquiry.isHidden && enquiry.hiddenListing ? (
              <ListingHiddenCard
                {...enquiry.hiddenListing}
                listingId={Number(enquiry.listingEnquiryInfo.listingInfo.id)}
              />
            ) : (
              <EnquiryCard metadata={{ 'data-automation-id': 'enquiry-card' }}>
                <EnquiryCard.LeftContent>
                  <ListingEnquiryInfo {...enquiry.listingEnquiryInfo} />
                </EnquiryCard.LeftContent>
                <EnquiryCard.Divider />
                <EnquiryCard.RightContent>
                  <AgentContactInfo {...enquiry.agentContactInfo} />
                </EnquiryCard.RightContent>
                {enquiry.listingEnquiryInfo.listingInfo.statusCode !== LISTING_STATUS_CODE_ACT &&
                  inactiveListingView && (
                    <InactiveListingView
                      body={
                        <>
                          <span className="text-primary">{enquiry.listingEnquiryInfo.listingInfo.title}</span>
                          <br />
                          {inactiveListingView.bodySuffix}
                        </>
                      }
                      action={inactiveListingView.action}
                    />
                  )}
              </EnquiryCard>
            )}
            {profileBanner && profileBanner.position === index && <ProfileBanner {...profileBanner} />}
            {financeBanner && financeBanner.position === index && (
              <FinanceBanner {...financeBanner} className="past-enquiry-finance-banner" />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return <LoadingView />;
};

export default PastEnquiryView;
