import type { Locale } from 'date-fns';
import { add } from 'date-fns';
import type { OptionsWithTZ } from 'date-fns-tz';
import { formatInTimeZone } from 'date-fns-tz';
import { ms, th } from 'date-fns/locale';

import { LOCALE, TimeZone } from 'root/widgets/constants';

const BUDDHIST_YEAR_OFFSET = 543;

const localeMapping: Record<string, Locale> = {
  [LOCALE.TH]: th,
  [LOCALE.MS]: ms,
};

/**
 * Get PG Standard Formatted Date string
 *
 * @param date - Date object
 * @param formatPattern - Output date pattern from https://date-fns.org/v2.30.0/docs/parse
 * @param locale - (Optional) Locale (default: 'en')
 * @param timeZone - (Optional) Output time zone format e.g. Asia/Bangkok (default: 'Asia/Singapore')
 *
 * @example
 *
 */
export const getFormattedDate = (
  date: Date,
  formatPattern: string,
  locale?: LOCALE | null,
  timeZone?: TimeZone | null,
) => {
  timeZone = timeZone || TimeZone.SINGAPORE;
  locale = locale || LOCALE.EN;

  const dateLocale = localeMapping[locale] ?? undefined;

  const options: OptionsWithTZ = {
    locale: dateLocale,
  };

  if (locale === LOCALE.TH) {
    // Hacky way to convert date to Thai Buddhist year since date-fns is not support Buddhist year yet
    // only day/month/year presentation looks like B.E. but it is actually "A.D"
    date = add(date, { years: BUDDHIST_YEAR_OFFSET });
  }

  return formatInTimeZone(date, timeZone, formatPattern, options);
};
