import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

import {
  RecentSearchProps,
  RecentSearchTitleProps,
} from 'root/widgets/search-with-filter/sub-components/recent-search';
import { SearchCategory } from 'root/widgets/constants';
import { SubTypeOptionProps, SubTypeProps } from 'root/widgets/search-with-filter/sub-components/property-type-filter';
import { storage } from 'root/widgets/storage';

import { RecentSearchValueProps } from 'root/widgets/search-with-filter';

import {
  FLOOR_AREA_KEY_MAP,
  Listing,
  MAX_RECENT_SEARCH_COUNT,
  NUMBER_SANITIZATION_PATTERN,
  RECENT_SEARCHES_STORAGE_KEY,
} from 'root/symbiosis-widgets/constants';
import remove from 'lodash/remove';
import slice from 'lodash/slice';
import { parseNumber } from 'root/widgets/utils/number';
import { clean } from 'root/widgets/utils/object';

const createRangeValue = (inputValue = '') => Number(inputValue.replace(NUMBER_SANITIZATION_PATTERN, ''));

const formatPriceRange = (selectedValue: { [key: string]: string }) => {
  const priceObj: { [key: string]: number } = {};
  Object.keys(selectedValue).forEach((key) => {
    const value = parseNumber(selectedValue[key]);
    if (!Number.isNaN(value) && selectedValue[key] !== '') {
      priceObj[key] = value;
    }
  });

  return Object.keys(priceObj).length > 0 ? priceObj : null;
};

const formatFloorAreaRange = (selectedValue: { [key: string]: string }) => {
  if (isEmpty(selectedValue)) {
    return null;
  }

  const floorObj: { [key: string]: number } = {};
  Object.keys(selectedValue).forEach((key) => {
    const value = parseNumber(selectedValue[key]);
    if (!Number.isNaN(value) && selectedValue[key] !== '') {
      floorObj[FLOOR_AREA_KEY_MAP[key]] = value;
    }
  });

  return Object.keys(floorObj).length > 0 ? floorObj : null;
};

/**
 * Get title for price value.
 * 'isNan' is used to filter out numbers with strings
 * if string values are present that has to be send as it is, ex "No Min" and "No Max"
 * if Numbers with(comma and dot) are passed as input values they are converted to Numbers
 *  and concatinated with currency and passed, ex:- "30,000"
 * @param inputValue
 * @param currency
 * @returns
 */
const createPriceTitle = (inputValue: string, currency: string) =>
  inputValue
    ? Number.isNaN(Number(inputValue.replace(NUMBER_SANITIZATION_PATTERN, '')))
      ? inputValue
      : `${currency} ${inputValue}`
    : '';

const createFloorTitle = (inputValue: string) =>
  inputValue
    ? Number.isNaN(Number(inputValue.replace(NUMBER_SANITIZATION_PATTERN, '')))
      ? inputValue
      : `${inputValue}`
    : '';

/**
 * This function gets the mapping of property type code with property type name
 * @param {Object} selectedPropertyTypes - The names of the property type
 * @param {Array} availablePropertyTypeCodes - The list of codes of the property type
 * @returns
 */
export const markSelectedPropertyTypes = (
  selectedPropertyTypes: Array<SubTypeProps> | Array<SubTypeOptionProps>,
  availablePropertyTypeCodes: Array<string>,
) => {
  if (!selectedPropertyTypes || availablePropertyTypeCodes.length === 0) {
    return '';
  }

  selectedPropertyTypes?.forEach((propertyCode: any) => {
    const propertyIndex = availablePropertyTypeCodes.indexOf(propertyCode.code);
    if (propertyIndex > -1) {
      availablePropertyTypeCodes[propertyIndex] = propertyCode.name;
    }
  });

  return availablePropertyTypeCodes.join(' / ');
};

/**
 * This function marks the one those are selected from available bedroom list
 * @param {Object} selectedBedrooms - The list of the selected bedrooms
 * @param {Array} availableBedrooms - The list of codes of the bedroom
 * @returns
 */
const markSelectedBedrooms = (selectedBedrooms: Array<string>, availableBedrooms: any) => {
  const markedBedrooms: Array<string> = [];

  selectedBedrooms.forEach((index: string) => {
    markedBedrooms.push(availableBedrooms.items[index]);
  });

  return `${markedBedrooms.join(' / ')} Bedrooms`;
};
/**
 * Accepts search state and transform into the
 * structure for recent searches to save the object in localStorage
 * @param filters
 * @param freetext
 * @param category
 * @returns encodedObject
 */
export const encodeSearch = ({
  filters,
  freetext,
  selectedCategory,
  query,
  isFilterPanelHidden = false,
  findAgentIntent = '',
}: any) => {
  if (isFilterPanelHidden) {
    return {
      listing_type: selectedCategory,
      freetext,
      query,
      find_agent_intent: findAgentIntent,
    };
  }

  const {
    bedroom,
    propertyType,
    location: { selectedCodes: selectedLocationCodes },
    priceRange,
    floorAreaRange,
    autocompleteLocation,
    mrt,
    roomType,
  } = filters;

  const encodedMinValue = createRangeValue(priceRange.selectedValues.minprice.replace(NUMBER_SANITIZATION_PATTERN, ''));
  const encodedMaxValue = createRangeValue(priceRange.selectedValues.maxprice.replace(NUMBER_SANITIZATION_PATTERN, ''));

  const encodedMinSize = createRangeValue(floorAreaRange?.selectedValues?.min.replace(NUMBER_SANITIZATION_PATTERN, ''));
  const encodedMaxSize = createRangeValue(floorAreaRange?.selectedValues?.max.replace(NUMBER_SANITIZATION_PATTERN, ''));

  return clean({
    'beds[]': bedroom?.selected ? bedroom?.selected['beds[]'] : null,
    listing_type: selectedCategory === SearchCategory.BUY ? Listing.Type.Sale : selectedCategory,
    ...selectedLocationCodes,
    ...propertyType,
    ...autocompleteLocation,
    freetext: freetext,
    minprice: Number.isNaN(encodedMinValue) ? '' : String(encodedMinValue),
    maxprice: Number.isNaN(encodedMaxValue) ? '' : String(encodedMaxValue),
    minsize: Number.isNaN(encodedMinSize) ? '' : String(encodedMinSize),
    maxsize: Number.isNaN(encodedMaxSize) ? '' : String(encodedMaxSize),
    'MRT_STATIONS[]': mrt?.ids ?? null,
    roomType: roomType?.selected ? roomType?.selected['roomType'] : null,
  });
};

/**
 * Prepares the titles for recent searches
 * @param {Object}: filters - Filter Object
 * @returns
 */
export const generateRecentSearchTitles = ({
  filters,
  freetext,
  selectedCategory,
  currency,
  filterData,
  isFilterPanelHidden = false,
  categoryText = '',
}: any): RecentSearchTitleProps => {
  if (isFilterPanelHidden) {
    return {
      listing_type: capitalize(selectedCategory),
      freetext,
      categoryText,
    };
  }

  const { bedroom, propertyType, priceRange, floorAreaRange } = filters;

  const fixedMarket = filterData?.propertyType?.fixedMarket;
  let selectedPropertyTypes;

  if (fixedMarket) {
    selectedPropertyTypes = filterData?.propertyType?.typeOptions?.find(
      (type: any) => type.code === propertyType.property_type,
    )?.subTypes;
  } else {
    const selectedPropertyTypeData = filterData?.propertyType?.typeOptions?.find(
      (type: any) => type.code === propertyType.market,
    );
    selectedPropertyTypes = selectedPropertyTypeData?.subTypes?.find(
      (type: any) => type.code === propertyType.property_type,
    )?.subTypeOptions;
  }

  return clean({
    listing_type: capitalize(selectedCategory),
    freetext: freetext,
    property_type_code: propertyType?.['property_type_code[]']
      ? markSelectedPropertyTypes(selectedPropertyTypes, [...(propertyType['property_type_code[]'] ?? '')])
      : '',
    beds:
      bedroom?.selected?.['beds[]']?.length > 0
        ? markSelectedBedrooms(bedroom.selected['beds[]'], filterData.bedroom)
        : '',
    minprice: createPriceTitle(priceRange.selectedValues.minprice, currency),
    maxprice: createPriceTitle(priceRange.selectedValues.maxprice, currency),
    minsize: createFloorTitle(floorAreaRange?.selectedValues?.min),
    maxsize: createFloorTitle(floorAreaRange?.selectedValues?.max),
  });
};

/**
 * Fetches recent searches from localStorage
 * @param key - localStorage key
 *
 * @returns Recent Searches
 */
export const getRecentSearches = (key: string) => {
  const storedRecentSearches = storage.getItem(key) ?? '';

  try {
    return storedRecentSearches
      ? JSON.parse(storedRecentSearches).filter((search) =>
          search.type === 'v2' ? search.titles.listingType : search.titles.listing_type,
        )
      : [];
  } catch {
    // If we found that JSON key invalid format then we need to clear storage key
    storage.removeItem(key);

    return [];
  }
};

/**
 * generate searchKey to prevent same search condition be saved to localStorage
 * @param titles - object consist of search condition like (filterKey: string, filterValue: string)
 * @returns - concat search rules key=value
 */
export const generateSearchKey = (
  titles: RecentSearchTitleProps | RecentSearchValueProps,
  shouldIgnorePois = false,
) => {
  const keys: Array<string> = [];

  Object.entries(titles).forEach(([key, value]) => {
    if (shouldIgnorePois && ['MRT_STATIONS', 'MRT_STATION', 'SCHOOL', 'distance'].includes(key)) return;
    if (value !== '' && `${value}` !== '0') {
      if (Array.isArray(value)) {
        keys.push(`${key.replace('[]', '')}=${value.sort().join(',')}`);
      } else {
        keys.push(`${key.replace('[]', '')}=${value}`);
      }
    }
  });

  return keys.sort().join('&');
};

/**
 * Store the current search object in the localStorage
 * @param value - Search Object contains data selected from searchWithFilter
 * @param storageKey - localStorage key to be used for storing current search object
 */
export const addToRecentSearches = (
  value: RecentSearchProps,
  storageKey: string = RECENT_SEARCHES_STORAGE_KEY,
  config?: {
    listingType?: string;
  },
) => {
  const recentSearches = getRecentSearches(storageKey) as Array<RecentSearchProps>;

  // prevent duplicate recent-search
  if (recentSearches?.some((searchEntity) => searchEntity.key === value.key)) {
    return;
  }

  if (config?.listingType) {
    // when the user is passing `listingType`, it means the component has to add the data based on the intent.
    // each intent should only has at max 3 recent search items.
    const listingTypeBuckets = recentSearches
      ? recentSearches?.reduce(
          (acc, currentValue, currentIndex) => {
            const listingType =
              currentValue.type === 'v2' ? currentValue.values.listingType : currentValue.values.listing_type || '';
            acc[listingType] = [...(acc[listingType] || []), { currentValue, currentIndex }];

            return acc;
          },
          {} as Record<string, Array<{ currentValue: RecentSearchProps; currentIndex: number }>>,
        )
      : {};

    const listingTypeBucket = listingTypeBuckets?.[config?.listingType || ''];
    const listingTypeBucketLength = listingTypeBucket?.length ?? 0;

    if (listingTypeBucketLength >= MAX_RECENT_SEARCH_COUNT) {
      const excessAmount = MAX_RECENT_SEARCH_COUNT - listingTypeBucketLength - 1; /* reserve 1 place for new item */
      const itemsToBeRemoved = slice(listingTypeBucket, excessAmount);
      remove(recentSearches, (item) => itemsToBeRemoved.map((i) => i.currentValue).includes(item));
    }
  } else if (recentSearches && recentSearches.length >= MAX_RECENT_SEARCH_COUNT) {
    const excessAmount = MAX_RECENT_SEARCH_COUNT - recentSearches.length - 1; /* reserve 1 place for new item */
    const itemsToBeRemoved = slice(recentSearches, excessAmount);
    remove(recentSearches, (item) => itemsToBeRemoved.includes(item));
  }

  recentSearches.unshift(value);
  storage.setItem(storageKey, JSON.stringify(recentSearches));
};

export const convertQueryObjectToString = (queryObj: RecentSearchValueProps) =>
  Object.keys(queryObj)
    .map((key) => {
      const element = queryObj[key];
      if (Array.isArray(element)) {
        return element.map((value) => `${key}=${value}`).join('&');
      }

      return `${key}=${element}`;
    })
    .filter((str) => str !== '')
    .join('&')
    .replace(/ /g, '+');

export const formatQuery = (search: any, selectedCategory, shouldIncludeAISearchFilter?: boolean): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/naming-convention
  const { filters, freetext, listing_type } = search;
  const {
    bedroom,
    floorAreaRange,
    propertyType,
    location,
    priceRange,
    autocompleteLocation,
    mrt,
    isAISearchEnabled,
    roomType,
  } = filters;

  const queryObject = {
    ...(bedroom.selected ?? null),
    ...(propertyType ?? null),
    ...(listing_type ?? null),
    ...(location?.selectedCodes ?? null),
    ...formatPriceRange(priceRange?.selectedValues),
    ...formatFloorAreaRange(floorAreaRange?.selectedValues),
    ...(autocompleteLocation ?? null),
    ...(mrt?.ids?.length > 0 ? { 'MRT_STATIONS[]': mrt.ids } : null),
    ...(isAISearchEnabled || shouldIncludeAISearchFilter ? { isAISearch: isAISearchEnabled } : null),
    ...(selectedCategory === SearchCategory.ROOM ? { bedrooms: -1 } : null),
    ...(roomType.selected ?? null),
  };

  if (freetext?.length) {
    queryObject.freetext = freetext;
  }

  return convertQueryObjectToString(queryObject);
};
