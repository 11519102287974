import classNames from 'classnames';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import ActionableLink from 'root/widgets/common-components/actionable-link';

import { MoreLinksDropdownItemProps } from 'root/widgets/global-components/navbar/types';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const MoreLinksDropdownItem: React.FC<MoreLinksDropdownItemProps> = ({ item, isActive, onClick }) => {
  const { text, url, target, rel, tooltip, newBadgeText } = item;

  const handleClick = () => {
    onClick(text);
  };

  if (url) {
    return (
      <Dropdown.Item
        key={text}
        as={ActionableLink}
        bsPrefix="more-links-dropdown-item"
        className={classNames({ active: isActive })}
        href={url}
        title={tooltip}
        rel={rel}
        target={target}
        badge={{
          text: newBadgeText,
          bgColor: 'success',
        }}
        onClick={handleClick}
        {...extractDataAutomationId(item)}
      >
        {text}
      </Dropdown.Item>
    );
  }
  if (text) {
    return <Dropdown.Header bsPrefix="more-links-dropdown-header">{text}</Dropdown.Header>;
  }

  return <div className="more-links-dropdown-divider" />;
};

export default MoreLinksDropdownItem;
