import classNames from 'classnames';
import { FC } from 'react';

import { TabIconProps } from 'root/widgets/in-page-tab-navigation/types';
import { SvgIcon } from 'root/widgets/svg-icon';

const TabIcon: FC<TabIconProps> = ({ iconClass, svg, isActive }) => {
  if (iconClass) {
    return <i className={classNames('tab-icon', iconClass)} />;
  }

  if (svg) {
    return isActive ? <SvgIcon src={svg.active} /> : <SvgIcon src={svg.inactive} shouldUseImage />;
  }

  return null;
};

TabIcon.displayName = 'TabIcon';

export default TabIcon;
