import classnames from 'classnames';
import capitalize from 'lodash/capitalize';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ENV, HIVE_STATIC_VERSION, LOCALE, MARKETPLACE, REGION, SocialProvider } from 'root/widgets/constants';
import { EVENT_NAMES, off, on } from 'root/widgets/events';
import type { PGLoginProps, UserInfoLoggedInData } from 'root/widgets/pg-login/types';

import config, { translation as translationConfig } from './config';
import { VIEW } from './constants';
import PGLoginContext from './context';
import { createLegacySession } from './helpers/auth';
import './pg-login.scss';
import { setEnvironment } from './services';
import {
  ForgotPasswordView,
  ForgotPasswordViewProps,
  LoginView,
  LoginViewProps,
  OTPLoginView,
  OTPLoginViewProps,
  PasswordLoginView,
  PasswordLoginViewProps,
  SetPasswordView,
  SetPasswordViewProps,
  SignUpView,
  SignUpViewProps,
  VerifyEmailView,
  VerifyEmailViewProps,
} from './views';

const PGLogin: React.FC<PGLoginProps> = ({
  env = ENV.INTEGRATION,
  region = REGION.SG,
  locale = LOCALE.EN,
  marketplace = MARKETPLACE.PG,
  logoUrl,
  translationOverrides,
  email: initialEmail,
  otpRecipientId,
  agentLoginHref,
  sentry,
  onEdgeOfFlow,
  onSuccess,
  onClose,
  shouldUseShortFlow = false,
  shouldShowLoginConsent = false,
  allowSocialLogin = false,
  isAutomationUser = false,
  shouldUseProxy = false,
  shouldShowViewTitle = true,
  context: eventContext,
  onViewChange,
}) => {
  const marketplaceKey = `${region}-${marketplace}`;
  if (!(marketplaceKey in translationConfig) || !(marketplaceKey in config[env])) {
    throw new Error(`No config found for ${marketplaceKey}`);
  }

  setEnvironment(env, region, marketplace, shouldUseProxy);
  const translation = { ...translationConfig[marketplaceKey][locale], ...translationOverrides };
  const logoSrc = logoUrl || config[env][marketplaceKey].PG_LOGO_URL;
  const forgotPasswordUrl = config[env][marketplaceKey].FORGOT_PASSWORD_URL;

  const [view, setView] = useState(VIEW.LOGIN);
  const [email, setEmail] = useState(initialEmail || '');
  const [otpId, setOtpId] = useState('');
  const [userInfo, setUserInfo] = useState<UserInfoLoggedInData>({ accessToken: '', refreshToken: '' });
  const [consentChecks, setConsentChecks] = useState({});
  const [isComingBack, setIsComingBack] = useState(false);

  const viewStack = useRef<Array<VIEW>>([VIEW.LOGIN]);

  const changeView = (nextView: VIEW, isBack = false) => {
    if (!isBack) {
      viewStack.current.push(nextView);
    }
    setIsComingBack(isBack);
    setView(nextView);
  };

  const getSocialLoginBtns = () => {
    const socialProviders = [SocialProvider.GOOGLE, SocialProvider.APPLE, SocialProvider.FACEBOOK];
    const aliasMap = {
      [SocialProvider.GOOGLE]: 'gmail',
      [SocialProvider.FACEBOOK]: 'fb',
    };

    return socialProviders.map((provider) => {
      const icon = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/logo/${provider}.svg`;
      const alias = provider in aliasMap ? aliasMap[provider] : provider;
      return {
        icon,
        title: translation[`loginWith${capitalize(provider)}`],
        provider,
        metadata: {
          'data-automation-id': `social-${alias}-btn`,
        },
      };
    });
  };

  const getLoginViewProps = (): LoginViewProps => ({
    masthead: {
      logo: { src: logoSrc },
      title: shouldShowViewTitle ? translation.loginTitle : undefined,
      description: translation.loginDescription,
    },
    socialLoginButtons: allowSocialLogin ? getSocialLoginBtns() : [],
    emailInput: {
      placeholder: translation.emailInputPlaceholder,
      validationMessage: translation.errorMessages.emailValidation,
    },
    buttonText: translation.continueBtn,
    dividerTitle: translation.dividerTitle,
    agentLoginPrompt: translation.agentLoginPrompt && {
      label: translation.agentLoginPrompt.label,
      link: { href: agentLoginHref, title: translation.agentLoginPrompt.linkTitle },
    },
    errorTexts: {
      server: translation.errorMessages.server,
      socialLogin: translation.errorMessages.socialLogin,
      otpGenerationLimit: translation.errorMessages.otpGenerationLimit,
      accountDeleted: translation.errorMessages.accountDeleted,
    },
    googleLoginErrorInPrivateModeText: translation.googleLoginErrorInPrivateMode,
    footer: translation.footer,
    checkboxPrompts: translation.signupCheckboxPrompts,
  });

  const getSignUpViewProps = (): SignUpViewProps => ({
    masthead: {
      logo: { src: logoSrc },
      title: shouldShowViewTitle ? translation.signUpTitle : undefined,
      description: translation.signUpDescription,
    },
    instructions: translation.signUpInstructions,
    emailInput: {
      placeholder: translation.emailInputPlaceholder,
      validationMessage: translation.errorMessages.emailValidation,
    },
    verifyBtnText: translation.verifyBtn,
    checkboxPrompts: translation.signupCheckboxPrompts,
    footer: translation.footer,
    errorTexts: {
      server: translation.errorMessages.server,
      otpGenerationLimit: translation.errorMessages.otpGenerationLimit,
    },
  });

  const getVerifyEmailViewProps = (): VerifyEmailViewProps => ({
    masthead: {
      logo: { src: logoSrc },
      title: shouldShowViewTitle ? translation.verifyEmailTitle : undefined,
      description: translation.verifyEmailDescription(email),
    },
    checkSpamPrompt: translation.checkSpamPrompt,
    resendActionText: translation.resendAction,
    errorTexts: {
      invalidCode: translation.errorMessages.invalidCode,
      server: translation.errorMessages.server,
      otpGenerationLimit: translation.errorMessages.otpGenerationLimit,
      otpSubmissionLimit: translation.errorMessages.otpSubmissionLimit,
    },
    isAutomationUser,
    checkboxPrompts: translation.signupCheckboxPrompts,
  });

  const getSetPasswordViewProps = (): SetPasswordViewProps => ({
    masthead: {
      logo: { src: logoSrc },
      title: shouldShowViewTitle ? translation.setPasswordTitle : undefined,
      description: translation.setPasswordDescription,
    },
    passwordInputPlaceholder: translation.passwordInputPlaceholder,
    submitBtnText: translation.savePasswordBtn,
    errorTexts: {
      charLengthValidation: translation.errorMessages.charLengthValidation,
      alphaNumericValidation: translation.errorMessages.alphaNumericValidation,
      capitalLetterValidation: translation.errorMessages.capitalLetterValidation,
      specialCharValidation: translation.errorMessages.specialCharValidation,
      server: translation.errorMessages.server,
    },
    skipAction: { text: translation.skipAction },
  });

  const getOtpLoginViewProps = (): OTPLoginViewProps => ({
    masthead: {
      logo: { src: logoSrc },
      title: shouldShowViewTitle ? translation.otpLoginTitle : undefined,
      description: translation.otpLoginDescription(email),
    },
    checkSpamPrompt: translation.checkSpamPrompt,
    resendActionText: translation.resendAction,
    errorTexts: {
      invalidCode: translation.errorMessages.invalidCode,
      server: translation.errorMessages.server,
      otpGenerationLimit: translation.errorMessages.otpGenerationLimit,
      otpSubmissionLimit: translation.errorMessages.otpSubmissionLimit,
    },
    footer: translation.footer,
    isAutomationUser,
  });

  const getPasswordLoginViewProps = (): PasswordLoginViewProps => ({
    masthead: {
      logo: { src: logoSrc },
      title: shouldShowViewTitle ? translation.passwordLoginTitle : undefined,
      description: translation.passwordLoginDescription(email),
    },
    dividerTitle: translation.dividerTitle,
    otpLoginBtnText: translation.otpLoginBtn,
    invalidPassword: translation.invalidPassword,
    passwordInputPlaceholder: translation.passwordInputPlaceholder,
    loginBtnText: translation.loginBtn,
    forgotPasswordActionText: translation.forgotPasswordAction,
    forgotPasswordUrl,
    errorTexts: {
      server: translation.errorMessages.server,
      otpGenerationLimit: translation.errorMessages.otpGenerationLimit,
    },
    footer: translation.footer,
  });

  const getForgotPasswordViewProps = (): ForgotPasswordViewProps => ({
    masthead: {
      logo: { src: logoSrc },
      title: shouldShowViewTitle ? translation.forgotPasswordTitle : undefined,
      description: translation.forgotPasswordDescription,
    },
    emailInput: {
      placeholder: translation.emailInputPlaceholder,
      validationMessage: translation.errorMessages.emailValidation,
    },
    resetBtnText: translation.resetPasswordBtn,
    serverErrorText: translation.errorMessages.server,
  });

  const getLayout = () => {
    switch (view) {
      case VIEW.LOGIN:
        return <LoginView {...getLoginViewProps()} />;
      case VIEW.SIGNUP:
        return <SignUpView {...getSignUpViewProps()} />;
      case VIEW.VERIFY_EMAIL:
        return <VerifyEmailView {...getVerifyEmailViewProps()} />;
      case VIEW.SET_PASSWORD:
        return <SetPasswordView {...getSetPasswordViewProps()} />;
      case VIEW.OTP_LOGIN:
        return <OTPLoginView {...getOtpLoginViewProps()} />;
      case VIEW.PASSWORD_LOGIN:
        return <PasswordLoginView {...getPasswordLoginViewProps()} />;
      case VIEW.FORGOT_PASSWORD:
        return <ForgotPasswordView {...getForgotPasswordViewProps()} />;
      default:
        return '';
    }
  };

  /* Show/hide back/close button for each view */
  useEffect(() => {
    if (onEdgeOfFlow) {
      onEdgeOfFlow(view === VIEW.LOGIN || view === VIEW.SET_PASSWORD);
    }

    onViewChange?.(view, translation);
  }, [view]);

  /* Handle back button click */
  useEffect(() => {
    const handleBack = () => {
      viewStack.current.pop();
      if (viewStack.current.length > 0) {
        changeView(viewStack.current[viewStack.current.length - 1], true);
      }
    };
    on(EVENT_NAMES.PG_LOGIN.BACK, handleBack);

    return () => {
      off(EVENT_NAMES.PG_LOGIN.BACK, handleBack);
    };
  }, []);

  const context = useMemo(
    () => ({
      env,
      region,
      marketplace,
      shouldUseShortFlow,
      view,
      otpRecipientId,
      email,
      otpId,
      userInfo,
      sentry,
      consentChecks,
      context: eventContext,
      shouldShowLoginConsent,
      changeView,
      setEmail,
      setOtpId,
      setUserInfo,
      setConsentChecks,
      onSuccess,
      onClose,
    }),
    [
      env,
      region,
      shouldUseShortFlow,
      otpRecipientId,
      shouldShowLoginConsent,
      view,
      email,
      otpId,
      userInfo,
      consentChecks,
    ],
  );

  return (
    <PGLoginContext.Provider value={context}>
      <div className={classnames('pg-login-root', { 'from-left': isComingBack })}>
        <div className="pg-login-body">{getLayout()}</div>
      </div>
    </PGLoginContext.Provider>
  );
};

PGLogin.displayName = 'PGLogin';

export default Object.assign(PGLogin, { authHelper: { createLegacySession } });
