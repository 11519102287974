import { PGLoginTranslationData } from 'root/widgets/pg-login/types';

const translation: PGLoginTranslationData = {
  loginTitle: 'ยินดีต้อนรับสู่ DDproperty',
  loginDescription: 'เข้าสู่ระบบ หรือสมัครสมาชิก เพื่อประสบการณ์การใช้งานที่ดีที่สุดจาก DDproperty',
  loginWithGoogle: 'ดำเนินการต่อด้วยบัญชี Google',
  loginWithFacebook: 'ดำเนินการต่อด้วยบัญชี Facebook',
  loginWithApple: 'ดำเนินการต่อด้วยบัญชี Apple',
  emailInputPlaceholder: 'อีเมล',
  continueBtn: 'ดำเนินการต่อ',
  dividerTitle: 'หรือ',
  agentLoginPrompt: {
    label: 'คุณเป็นตัวแทนอสังหาฯใช่หรือไม่?',
    linkTitle: 'เข้าสู่ระบบ AgentNet',
  },
  googleLoginErrorInPrivateMode:
    'กรุณาอัปเดตการตั้งค่าเบราเซอร์ของคุณให้สามารถใช้คุ้กกี้ได้ หรือลองใช้เบราเซอร์ในโหมดไม่ระบุตัวตน (incognito) เพื่อทำการล็อกอินด้วยบัญชีโซเชียลต่อไป',
  signUpTitle: 'สร้างบัญชีสมาชิกใหม่',
  signUpDescription:
    'ดูเหมือนว่าอีเมลนี้ยังไม่เคยใช้สำหรับสมัครสมาชิก สมัครสมาชิกตอนนี้เพื่อรับสิทธิประโยชน์ดังต่อไปนี้',
  signUpInstructions: [
    'ดูย้อนหลังรายการอสังหาฯ ที่คุณเคยแสดงความสนใจไว้',
    'เข้าดูรายการอสังหาฯ ที่คุณบันทึกค่าการค้นหาไว้ และดูรายการที่คุณเลือกไว้แล้วได้อย่างรวดเร็ว​',
    'เชื่อมต่อการตั้งค่าในการค้นหากับอุปกรณ์ทั้งหมดของคุณ',
  ],
  signupCheckboxPrompts: {
    consent:
      'ข้าพเจ้ายอมรับนโยบายความเป็นส่วนตัวของ บริษัท ออลพร็อพเพอร์ตี้ มีเดีย จำกัด (“ออลพร็อพเพอร์ตี้มีเดีย”) และการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า',
    subscribe:
      'อนุญาตให้ส่งข้อมูลการตลาดแบบตรงจากออลพร็อพเพอร์ตี้มีเดีย ตามนโยบายความเป็นส่วนตัว ของออลพร็อพเพอร์ตี้มีเดีย',
  },
  verifyBtn: 'ยืนยันอีเมลของคุณ',
  verifyEmailTitle: 'ยืนยันอีเมลของคุณ',
  verifyEmailDescription: (email) => (
    <>
      สวัสดีค่ะ คุณ <strong>{email}</strong> เราได้ส่งโค้ดยืนยันไปที่อีเมลของคุณแล้ว
      กรุณาตรวจสอบและใส่โค้ดยืนยันที่คุณได้รับที่นี่เพื่อดำเนินการสมัครสมาชิกต่อไป
    </>
  ),
  resendAction: 'ส่งรหัสใหม่อีกครั้ง',
  setPasswordTitle: 'บัญชีของคุณถูกสร้างขึ้นแล้ว!​',
  setPasswordModalTitle: 'สร้างบัญชีเรียบร้อยแล้ว',
  setPasswordDescription: 'คุณควรสร้างพาสเวิร์ด (รหัสผ่าน) เพื่อความปลอดภัยและป้องกันการเข้าสู่บัญชีโดยผู้อื่นในอนาคต',
  passwordInputPlaceholder: 'รหัสผ่าน',
  savePasswordBtn: 'จดจำรหัสผ่าน ',
  skipAction: 'สร้างรหัสผ่านภายหลัง  ',
  otpLoginTitle: 'ล็อกอินด้วยรหัส OTP',
  otpLoginDescription: (email) => (
    <>
      สวัสดีค่ะ คุณ <strong>{email}</strong> เราได้ส่งโค้ดยืนยันไปที่อีเมลของคุณแล้ว
      กรุณาตรวจสอบและใส่โค้ดยืนยันที่คุณได้รับที่นี่เพื่อดำเนินการเข้าสู่ระบบต่อไป
    </>
  ),
  passwordLoginBtn: 'เข้าสู่ระบบด้วยรหัสผ่าน',
  otpLoginBtn: 'ล็อกอินด้วยรหัส OTP​',
  passwordLoginTitle: 'เข้าสู่ระบบด้วยรหัสผ่าน',
  passwordLoginDescription: (email) => (
    <>
      สวัสดีค่ะ คุณ <strong>{email}</strong> กรุณาใส่รหัสผ่านเพื่อเข้าสู่ระบบของ DDproperty
    </>
  ),
  invalidPassword: 'กรุณาใส่รหัสผ่านที่ถูกต้อง',
  loginBtn: 'เข้าสู่ระบบ',
  forgotPasswordAction: 'ลืมรหัสผ่าน',
  forgotPasswordTitle: 'ตั้งค่ารหัสผ่านใหม่',
  forgotPasswordDescription: 'กรุณาใส่อีเมลของคุณในแบบฟอร์มด้านล่างนี้ และเราจะส่งรหัสผ่านใหม่่ไปให้คุณ',
  resetPasswordBtn: 'เปลี่ยนรหัสผ่านใหม่',
  checkSpamPrompt: (
    <>
      คุณยังไม่ได้รับอีเมลใช่ไหม?
      <br />
      กรุณาตรวจสอบในกล่องจดหมายขยะ (Spam folder) ของคุณ หรือเลือกทำการส่งโค้ดยืนยันอีกครั้ง
    </>
  ),
  footer: (
    <>
      ข้าพเจ้ายอมรับใน DDproperty&#39;s{' '}
      <a href="/ข้อตกลงและเงื่อนไขการใช้บริการเว็บไซต์">เงื่อนไขและข้อตกลงการใช้บริการ</a> และ{' '}
      <a href="/นโยบายความเป็นส่วนตัว">นโยบายความเป็นส่วนตัว</a> รวมไปถึงการเก็บรวบรวม, การนำไปใช้{' '}
      และการเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า
    </>
  ),
  errorMessages: {
    emailValidation: 'กรุณาใส่อีเมลที่ใช้งานได้จริง',
    socialLogin: 'ขออภัยค่ะ ไม่สามารถล็อกอินด้วยช่องทางโซเชียลได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง',
    server: 'ขออภัยค่ะ ขณะนี้ เรากำลังดำเนินการแก้ไขระบบเซิร์ฟเวอร์ที่ขัดข้อง กรุณาลองใหม่อีกครั้ง',
    charLengthValidation: 'ต้องมีอย่างน้อย 8 ตัวอักษร',
    alphaNumericValidation: 'ต้องมีทั้งตัวอักษร และตัวเลข',
    capitalLetterValidation: 'ต้องมีตัวอักษรพิมพ์ใหญ่',
    specialCharValidation: 'ต้องมีตัวอักขระพิเศษ',
    invalidCode: 'กรุณาใส่อีเมลที่ใช้งานได้จริง',
    otpGenerationLimit: 'ขออภัยค่ะ คุณไม่สามารถขอโค้ดใหม่ได้ในขณะนี้ กรุณารอสักครู่ แล้วลองใหม่อีกครั้ง',
    otpSubmissionLimit: 'ขออภัยค่ะ เราไม่สามารถทำตามที่คุณร้องขอในขณะนี้ได้ กรุณารอสักครู่ แล้วลองใหม่อีกครั้ง​',
    accountDeleted: 'ขออภัยค่ะ อีเมลนี้ไม่สามารถใช้ได้ กรุณาลงทะเบียนสมัครใหม่ด้วยอีเมลอื่นค่ะ',
  },
  verifyPhoneTitle: 'ยืนยันหมายเลขโทรศัพท์มือถือของคุณ',
  verifyEmailConsent: (email) => (
    <>
      เราได้ส่งรหัสถึงคุณที่ <strong>{email}</strong> กรุณาใส่รหัสที่นี่เพื่อดำเนินการยืนยันต่อไป
    </>
  ),
  verifyPhoneConsent: (phone) => (
    <>
      เราได้ส่งรหัสถึงคุณทาง SMS ที่ <strong>{phone}</strong> กรุณาใส่รหัสที่นี่เพื่อดำเนินการยืนยันต่อไป
    </>
  ),
};

export default translation;
