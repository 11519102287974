export const HIVE_STATIC_VERSION = 'v0.1.249';

export const HIVE_CORE_URL = 'https://cdn.pgimgs.com/hive-ui-core/static';
export const HIVE_UI_CORE_VERSION = 'v1.4';

export const HIVE_STATIC_URL = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/`;

export const HIVE_UI_CORE_URL = `https://cdn.pgimgs.com/hive-ui-core/static/${HIVE_UI_CORE_VERSION}/`;

export const HIVE_STATIC_ICON_PATH = `${HIVE_STATIC_URL}pg-icons/font/v2/`;

export const HIVE_STATIC_ICON_PATH_V3 = `${HIVE_STATIC_URL}pg-icons/font/v3`;
export const HIVE_STATIC_LOGO_PATH = `${HIVE_STATIC_URL}logo/`;

export const HIVE_STATIC_IMAGES_PATH = `${HIVE_STATIC_URL}images/`;

export const HIVE_STATIC_FLAGS_PATH = `${HIVE_STATIC_URL}flags/`;

export const DEFAULT_IMAGE = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/images/default-empty-view.svg`;
export const PROPERTY_DEFAULT_IMAGE_URL = `${HIVE_STATIC_URL}images/default-property-card.svg`;
export const AGENT_DEFAULT_IMAGE = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/account-profile-photo.svg`;
export const VERIFIED_AGENT_ICON = 'https://cdn.pgimgs.com/hive-ui-core/static/icons/svgs/badge-f.svg';
export const DEFAULT_AGENT_ICON = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/font/v3/images.svg`;
export const CERTIFIED_AGENT_ICON = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/certified.svg`;

export const HIVE_STATIC_JSON_ICON_LIST = `${HIVE_STATIC_URL}json/iconList.json`;

export const SCROLL_DEBOUNCE_DELAY = 100;
export const DEBOUNCE_DELAY = 200;
export const WIDTH_SM = 544;
export const LINK_STACK = {
  APPLY_VIEW_MORE: 3,
  DISABLE_PARENT_CLICK: WIDTH_SM,
};
export default {};

export const CONTAINER_WIDTHS = {
  XS: 344,
  SM: 544,
  MD: 696,
  LG: 960,
  XL: 1128,
};

export const SCREEN_BREAKPOINTS = {
  XS: 360,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

export const BREAKPOINTS = {
  XS: `${SCREEN_BREAKPOINTS.XS}px`,
  SM: `${SCREEN_BREAKPOINTS.SM}px`,
  MD: `${SCREEN_BREAKPOINTS.MD}px`,
  LG: `${SCREEN_BREAKPOINTS.LG}px`,
  XL: `${SCREEN_BREAKPOINTS.XL}px`,
};

export enum ENV {
  INTEGRATION = 'integration',
  STAGING = 'staging',
  PRODUCTION = 'production',
  SANDBOX = 'sandbox',
  LOCAL = 'local',
}

export enum REGION {
  SG = 'sg',
  MY = 'my',
  TH = 'th',
}

export enum MARKETPLACE {
  PG = 'pg',
  CG = 'cg',
  IPP = 'ipp',
}

export enum LOCALE {
  EN = 'en',
  MS = 'ms',
  TH = 'th',
}

export enum ListingStatus {
  ACTIVE = 'ACT',
  COMPLETED = 'COMP',
  EXPIRED = 'EXP',
  DELISTED = 'DEL',
  DRAFT = 'DRAFT',
  REMOVED = 'REM',
  SUSPENDED = 'SUSP',
  PAUSED = 'PAUSE',
}

export enum PropertyTypeMarket {
  RESIDENTIAL = 'residential',
  COMMERCIAL = 'commercial',
}

export enum KEYBOARD {
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  TAB = 'Tab',
}

export const EMAIL_VALIDATION_PATTERN =
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export enum SocialProvider {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

/**
 * Using "as const" ensures that the object properties are read-only,
 * preventing any accidental modifications to the object's properties.
 * This can be useful for ensuring immutability in code,
 * which can help prevent bugs and make code more predictable.
 */
export const COUNTRY_CODE = {
  [REGION.SG]: 65,
  [REGION.MY]: 60,
  [REGION.TH]: 66,
} as const;

export enum ContactInputType {
  EMAIL = 'email',
  MOBILE = 'mobile',
}

export const DATA_AUTOMATION_ID = 'dataAutomationId';

export const CONTACT_AGENT_USER_INTENT = 'contact_agent_user_intent';

export const condominiumTypes = ['Apartment', 'Condominium', 'Executive Condominium'];

export const hdbTypes = ['HDB'];

export const landedTypes = ['Terrace House', 'Semi-Detached House', 'Detached House'];

export enum PropertyType {
  CONDO = 'Condo',
  LANDED = 'Landed',
  HDB = 'HDB',
}

export enum PriceType {
  ASK = 'ASK',
  START = 'START',
  RANGE = 'RANGE',
}

export const propertyTypeMap = {
  [PropertyType.CONDO]: condominiumTypes,
  [PropertyType.HDB]: hdbTypes,
  [PropertyType.LANDED]: landedTypes,
};

export enum SearchCategory {
  BUY = 'buy',
  RENT = 'rent',
  SELL = 'sell',
  FIND_AGENT = 'find-agent',
  ROOM = 'room',
}

export enum TimeZone {
  SINGAPORE = 'Asia/Singapore',
  MALAYSIA = 'Asia/Kuala_Lumpur',
  THAILAND = 'Asia/Bangkok',
}

export enum HomeSellersAddonServicesName {
  SEND_HELPER = 'sendHelper',
  PG_FINANCE = 'pgFinance',
}

export enum UserIDType {
  PGUTID = 'pgutid',
  UMSTID = 'umstid',
}

export const GA3_GA4_DIMENSION_MAP = {
  dimension1: 'member_type',
  dimension2: 'page_type',
  dimension3: 'environment',
  dimension4: 'language',
  dimension5: 'beta_user',
  dimension6: 'page_type_dynamic',
  dimension7: 'listing_id_dynamic',
  dimension8: 'total_value_dynamic',
  dimension9: 'status_code',
  dimension10: 'experiment',
  dimension11: 'vwo',
  dimension12: 'customer_id',
  dimension13: 'country',
  dimension14: 'asset',
  dimension15: 'tier',
  dimension16: 'property_type',
  dimension17: 'intent_type',
  dimension18: 'search_query',
  dimension19: 'agent_id',
  dimension20: 'events_payload',
  dimension21: 'client_id',
  dimension22: 'item_listing_page_count',
  dimension23: 'item_paid_listings',
  dimension24: 'item_status_code',
  dimension25: 'item_agent_id',
  dimension26: 'scroll_depth',
  dimension27: 'new_launch',
  dimension28: 'query_params',
  dimension29: 'loan_type',
  dimension30: 'property_status',
  dimension31: 'rate_type',
  dimension32: 'loan_amount',
  dimension33: 'loan_tenure',
  dimension34: 'bank',
  dimension35: 'product_name',
  dimension36: 'current_bank',
  dimension37: 'current_interest',
  dimension38: 'webview',
  dimension39: 'audiences',
  dimension40: 'internal_campaign_banner_id',
  dimension41: 'clarity_playback_url',
  dimension42: 'mortgage_status',
  dimension43: 'pl_offset', // this field is used to store the reasons on why a listing is promoted on SRP page.
};

export const GA3_GA4_EVENT_NAME_MAP = {
  productImpression: 'view_item_list',
  productDetails: 'view_item',
  productClick: 'select_item',
  mapPinClick: 'select_item',
  addToCart: 'add_to_cart',
  purchase: 'purchase',
  Purchase: 'purchase',
  AdNativeImpression: 'view_item_list',
  AdNativeClick: 'select_item',
  viewFeaturedAgentCard: 'view_item_list',
  clickFeaturedAgentCard: 'select_item',
};

// The hardcoded keys have to be configure with BigQuery as well to take effect, it is temporary solution, and it will be removed.
export const GA4_KEYS_TO_SLICE = ['events_payload', 'search_query', 'query_params', 'customer_id', 'event_label'];

export const GA4_EVENT_VALUE_LIMIT = 100;
export const GLOBAL_NAVBAR_ID = 'global-navbar-root';
export const GLOBAL_FOOTER_ID = 'global-footer-root';

export const MODAL_TIMEOUT = 200;
export const RELOAD_TIMEOUT = 3 * 1000;

export const AI_SEARCH_TOOLTIP_KEY = 'aiSearchTooltip';
