import React from 'react';
import classNames from 'classnames';
import { Navbar } from 'react-bootstrap';

import NavLinkItem from 'root/widgets/global-components/navbar/sub-components/common/nav-link-item';
import { NavbarVariant } from 'root/widgets/global-components/navbar/constants';
import { NavGroupProps, NavLinkProps, TopBarProps } from 'root/widgets/global-components/navbar/types';

import MoreLinksDropdown from './sub-components/more-links-dropdown';
import RightButtonSet from './sub-components/right-button-set';

const TopBar: React.FC<TopBarProps> = (props) => {
  const {
    logoConfig,
    localeDetail,
    loginDetail,
    notificationButton,
    myActivityConfig,
    myAccountLinks,
    navLinks,
    navDropdown,
    agentLinks,
    isSideNavOpen,
    onNavLinkClick,
    onSideNavToggle,
    onMyAccountClick,
    onMyAccountItemClick,
    onMyActivityLinkClick,
    onLoginClick,
    onLogoutClick,
    onNotificationToggleClick,
    onMoreLinksToggleClick,
    variant,
  } = props;

  const { default: defaultLogo, desktop: desktopLogo, href } = logoConfig;

  const getNavLinkClickHandler = (text: string) => () => {
    onNavLinkClick(text);
  };

  const handleMoreDropdownLinkClick = (text: string) => {
    onNavLinkClick(text);
  };

  return (
    <div className={classNames('top-bar', { 'pg-finance-variant': variant === NavbarVariant.PG_FINANCE })}>
      <Navbar.Toggle className="side-nav-toggle" onClick={onSideNavToggle}>
        {isSideNavOpen ? <i className="pgicon pgicon-cancel" /> : <i className="pgicon pgicon-menu" />}
      </Navbar.Toggle>
      <Navbar.Brand bsPrefix="brand" href={href || '/'}>
        <img alt="pg-logo-mobile" className="logo-mobile" src={defaultLogo} />
        <img alt="pg-logo-desktop" className="logo-desktop" src={desktopLogo ?? defaultLogo} />
      </Navbar.Brand>
      <div className="nav-link-list">
        {navLinks.map((link) => {
          if (
            Object.prototype.hasOwnProperty.call(link, 'items') &&
            (link as NavGroupProps).items.some((item) => !item.mobileOnly)
          ) {
            return (
              <MoreLinksDropdown
                key={(link as NavGroupProps).title}
                navDropdown={link as NavGroupProps}
                variant={variant}
              />
            );
          }
          return (
            !(link as NavLinkProps).mobileOnly && (
              <NavLinkItem
                key={(link as NavLinkProps).text}
                isSelected={(link as NavLinkProps).isSelected ?? false}
                link={link as NavLinkProps}
                onClick={getNavLinkClickHandler((link as NavLinkProps).text)}
              />
            )
          );
        })}
        <MoreLinksDropdown
          navDropdown={navDropdown}
          agentLinks={agentLinks}
          onToggleClick={onMoreLinksToggleClick}
          onLinkClick={handleMoreDropdownLinkClick}
          variant={variant}
        />
      </div>
      <RightButtonSet
        loginDetail={loginDetail}
        localeDetail={localeDetail}
        myActivityConfig={myActivityConfig}
        myAccountLinks={myAccountLinks}
        notificationButton={notificationButton}
        onMyAccountClick={onMyAccountClick}
        onMyAccountItemClick={onMyAccountItemClick}
        onMyActivityLinkClick={onMyActivityLinkClick}
        onNotificationToggleClick={onNotificationToggleClick}
        onLoginClick={onLoginClick}
        onLogoutClick={onLogoutClick}
      />
    </div>
  );
};

export default TopBar;
