import classnames from 'classnames';
import React from 'react';

import { BoxInputProps } from 'root/widgets/common-components/otp-input-group/types';

const BoxInput: React.FC<BoxInputProps> = ({ index, isFocused, value, onFocus, onKeyDown }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleKeyDown = (evt) => {
    onKeyDown(evt, index);
  };

  const handleFocus = () => {
    onFocus(index);
  };

  // Placeholder event to bypass test warning, value changed is handle by handleKeyDown
  const handleChange = () => {};

  React.useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.click();
      inputRef.current.focus();
    }
  }, [isFocused]);

  return (
    <input
      ref={inputRef}
      className={classnames('box-input', { 'not-empty': Boolean(value) })}
      value={value}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onChange={handleChange}
      maxLength={1}
      type="number"
    />
  );
};

export default BoxInput;
