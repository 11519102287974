import classnames from 'classnames';
import React from 'react';

import { UserReview } from 'root/widgets/user-review';

import './agent-review-submitted-modal.scss';
import { ReviewStatus } from './constants';
import { AgentReviewSubmittedModalProps } from './types';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import Actionable from 'root/widgets/common-components/actionable';

const AgentReviewSubmittedModal: React.FC<AgentReviewSubmittedModalProps> = (props) => {
  const { className, metadata, title, status, description, userReview, buttonText = 'Close', config } = props;

  const { shouldShow, onClose } = config;

  const body =
    status === ReviewStatus.APPROVED && userReview ? (
      <UserReview {...userReview} />
    ) : (
      (status === ReviewStatus.SENT || status === ReviewStatus.WARNING) && (
        <p className="agent-review-submit-modal-description">{description}</p>
      )
    );

  return (
    <HUIModal
      show={shouldShow}
      onHide={onClose}
      className={classnames('agent-review-submit-modal-root', className)}
      {...metadata}
      centered
    >
      <HUIModal.Header closeButton>
        <p className="agent-review-submit-modal-title">{title}</p>
      </HUIModal.Header>
      <HUIModal.Body className="agent-review-submit-modal-body">
        {body}
        <Actionable onClick={onClose} className="agent-review-submit-modal-close-btn" variant="primary" size="sm">
          {buttonText}
        </Actionable>
      </HUIModal.Body>
    </HUIModal>
  );
};

AgentReviewSubmittedModal.displayName = 'AgentReviewSubmittedModal';

export default AgentReviewSubmittedModal;
