import React from 'react';

import { HIVE_STATIC_VERSION } from 'root/widgets/constants';
import { ErrorCardImgProps } from 'root/widgets/error-card/types';

const ErrorCardImg: React.FC<ErrorCardImgProps> = ({ src, alt, ...props }) => {
  const defaultImage = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/images/default-error-card.svg`;

  return (
    <div>
      <img src={src || defaultImage} alt={alt || 'error'} {...props} />
    </div>
  );
};

export default ErrorCardImg;
