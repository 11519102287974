import classNames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';

import { HUIModalFooterProps } from 'root/widgets/common-components/hui-modal/types';

const Footer: React.FC<HUIModalFooterProps> = ({ className, ...props }) => (
  <Modal.Footer className={classNames(className, 'gap-4')} {...props} />
);

export default Footer;
