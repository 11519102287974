import { useEffect, useCallback } from 'react';

export const useScrollTrigger = ({
  isEnabled = true,
  scrollThresholdPercent = 50,
  handler,
  upwardScrollPercentAfterThreshold = 0,
}: {
  isEnabled?: boolean;
  scrollThresholdPercent?: number; // minimum percentage of the page scrolled before exit intent is triggered
  handler: () => void;
  upwardScrollPercentAfterThreshold?: number; // minimum percentage of the page scrolled after hitting scrollThresholdPercent
}) => {
  let hasReachedScrollThreshold = false;
  let maxScrollAfterThreshold = 0;

  const handleScroll = useCallback(() => {
    const onScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const scrollPercent = (scrollTop / (scrollHeight - windowHeight)) * 100;

      if (!hasReachedScrollThreshold && scrollPercent >= scrollThresholdPercent) {
        // User has scrolled past the defined threshold (e.g., 50% of the page)
        // Mark that the threshold has been reached so we can track further scrolling behavior
        hasReachedScrollThreshold = true;

        // Store the scroll position at the moment the threshold is crossed
        // This helps in determining how far the user scrolls before starting to scroll up
        maxScrollAfterThreshold = scrollTop;
      }

      if (!hasReachedScrollThreshold) return;

      // If the user continues scrolling down, update maxScrollAfterThreshold
      if (scrollTop > maxScrollAfterThreshold) {
        maxScrollAfterThreshold = scrollTop;
      }
      // If the user starts scrolling up, calculate the percentage of scroll-up movement
      const scrollUpDiffPercent = ((maxScrollAfterThreshold - scrollTop) / (scrollHeight - windowHeight)) * 100;

      // Trigger exit intent only if the user has scrolled up by at least 5% of the total scrollable height
      if (scrollUpDiffPercent >= upwardScrollPercentAfterThreshold) {
        handler();
      }
    };

    if (typeof requestAnimationFrame === 'undefined') {
      onScroll();
    } else {
      requestAnimationFrame(onScroll);
    }
  }, []);

  useEffect(() => {
    if (!isEnabled)
      return () => {
        document.removeEventListener('scroll', handleScroll);
      };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isEnabled]);

  return {
    unsubscribe: () => document.removeEventListener('scroll', handleScroll),
  };
};
