import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import './contact-details-form.scss';
import { ContactDetailsFormProps } from './types';

const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({ title, subTitle, data, onChange }) => {
  const [fullName, setFullName] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<string>('');

  const handleFullNameOnChange = (event): void => {
    setFullName(event.target.value);
    onChange(event.target.value, mobileNumber);
  };

  const handleMobileNumberOnChange = (event): void => {
    setMobileNumber(event.target.value);
    onChange(fullName, event.target.value);
  };

  return (
    <Form.Group className="contact-details-form-root" controlId="validate-contact-form">
      <Form.Label>
        <h4>{title}</h4>
        <span className="label-elaboration">{subTitle}</span>
      </Form.Label>
      <div className="form-inputs">
        <div className="input-field">
          <Form.Control
            required
            className={data.fullNameInputConfig.className}
            type="text"
            placeholder={data.fullNameInputConfig.placeholder}
            onChange={handleFullNameOnChange}
            value={fullName}
          />
          <Form.Control.Feedback type="invalid">
            <i className="pgicon-warning-o" />
            {data.fullNameInputConfig.errorMessage}
          </Form.Control.Feedback>
        </div>
        <div className="input-field">
          <Form.Control
            required
            className={data.mobileNumberInputConfig.className}
            type="tel"
            pattern="\d*"
            placeholder={data.mobileNumberInputConfig.placeholder}
            onChange={handleMobileNumberOnChange}
            value={mobileNumber}
          />
          <Form.Control.Feedback type="invalid">
            <i className="pgicon-warning-o" />
            {data.mobileNumberInputConfig.errorMessage}
          </Form.Control.Feedback>
        </div>
      </div>
    </Form.Group>
  );
};

ContactDetailsForm.displayName = 'ContactDetailsForm';

export default ContactDetailsForm;
