import classNames from 'classnames';
import React from 'react';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import { ToggleText } from './constants';
import './toggle-switch.scss';
import { ToggleSwitchProps } from './types';

// @TO-DO: Tech debt - should change this widget, extends from React-Bootstrap's Form.Check: https://react-bootstrap.github.io/docs/forms/checks-radios
const ToggleSwitch: React.FC<ToggleSwitchProps> = (props) => {
  const { isToggled, onChange, metadata, variant, isDisabled, inputId } = props;
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={classNames('toggle-switch-root', variant || 'primary')}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <input
        className="toggle-input"
        type="checkbox"
        id={inputId}
        {...{ checked: isToggled }}
        onChange={onChange}
        disabled={isDisabled}
      />
      <div className="toggle-fill">
        <div className="toggle-circle">
          {/* Load both icon when rendered widget to have better user experience, else it will have split second empty icon when loading */}
          <i className="pgicon pgicon-ok" />
          <i className="pgicon pgicon-cancel" />
        </div>
        <div className="toggle-text">
          <span className="toggle-text__on">{ToggleText.ON}</span>
          <span className="toggle-text__off">{ToggleText.OFF}</span>
        </div>
      </div>
    </label>
  );
};

ToggleSwitch.displayName = 'ToggleSwitch';

export default ToggleSwitch;
