import { useCallback, useEffect, useRef } from 'react';
import { useExitIntent } from 'use-exit-intent';
import { isDesktop, isMobile, isTablet } from 'root/widgets/utils/validation';
import { useScrollTrigger } from './use-scroll-trigger';

export const useUserExitIntent = ({
  enabled = true,
  onExitIntent,
  sessionKey,
}: {
  enabled?: boolean;
  onExitIntent: () => void;
  sessionKey: string;
}): void => {
  const isMobileWeb = isMobile() || isTablet();
  const exitIntentEnabledRef = useRef(false);

  const { unsubscribe: unsubscribeFromSrcoll } = useScrollTrigger({
    isEnabled: enabled && isMobileWeb && sessionStorage.getItem(sessionKey) !== 'true',
    handler: useCallback(() => {
      onExitIntent();
      // Mark that we’ve triggered the exit-intent popup in this session
      sessionStorage.setItem(sessionKey, 'true');
      unsubscribeFromSrcoll();
    }, []),
    upwardScrollPercentAfterThreshold: 5,
  });

  const { registerHandler, resetState, isUnsubscribed, unsubscribe } = useExitIntent({
    cookie: {
      daysToExpire: 0.00347,
      key: sessionKey,
    },
    desktop: {
      triggerOnMouseLeave: true,
    },
  });

  useEffect(() => {
    exitIntentEnabledRef.current = enabled;
  }, [enabled]);

  useEffect(() => {
    if (enabled && isDesktop() && sessionStorage.getItem(sessionKey) !== 'true') {
      resetState();
      registerHandler({
        id: 'trigger-exit-intent',
        context: ['onDesktop'],
        handler: () => {
          if (exitIntentEnabledRef.current) {
            onExitIntent();
            unsubscribe();
            sessionStorage.setItem(sessionKey, 'true');
          }
        },
      });
    }
    return () => {
      if (!isUnsubscribed) {
        unsubscribe();
      }
    };
  }, [enabled]);
};
