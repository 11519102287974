/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import omit from 'lodash/omit';
import { useCallback, useEffect, useRef, useState } from 'react';

import { SaveStatus, defaultErrorConfig } from './constants';
import './drilldown-menu.scss';
import { InputOption, MenuOption } from './sub-components';
import {
  DrilldownInputOptionData,
  DrilldownMenuOptionData,
  DrilldownMenuProps,
  DrilldownMenuSelectedOptionProps,
  DrilldownTreeOptionData,
} from './types';
import { isInputData, isTreeOptionData } from './utils';

const DrilldownMenu: React.FC<DrilldownMenuProps> = ({
  data: originalData,
  title: originalTitle = '',
  onSave,
  isOpen,
  onClose,
  onSubmenuBack,
  className,
  errorConfig,
  shouldSaveAtIntermediateLevel = false,
  ...restProps
}: DrilldownMenuProps) => {
  const [currentDisplayedOptions, setCurrentDisplayedOptions] = useState<Array<DrilldownMenuOptionData>>(originalData);
  const [selectedOption, setSelectedOption] = useState<DrilldownMenuSelectedOptionProps | null>();
  const [selectedOptions, setSelectedOptions] = useState<Array<DrilldownMenuSelectedOptionProps>>([]);
  const [optionSaveStatus, setOptionSaveStatus] = useState<{ [key in string]: SaveStatus }>({});
  const [optionSaveError, setOptionSaveError] = useState<{ [key in string]: string }>({});
  const [menuTitle, setMenuTitle] = useState<string | null>(originalTitle);
  const [optionsTreePath, setOptionsTreePath] = useState<Array<number>>([]);
  const drilldownMenuRef = useRef<HTMLDivElement>(null);
  const animationDivRef = useRef<HTMLDivElement>(null);

  const shouldShowError = () => Object.values(optionSaveError).length > 0 && !errorConfig?.isHidden;

  const autoHideError = useCallback(() => {
    if (!selectedOption) {
      return;
    }
    if (!errorConfig?.isAutoHideDisabled && selectedOption.id) {
      setTimeout(
        () => {
          setOptionSaveError(omit(optionSaveError, [selectedOption.id]));
        },
        errorConfig?.duration ?? defaultErrorConfig.duration,
      );
    }
  }, [selectedOption, errorConfig, optionSaveError]);

  const handleClose = () => {
    onClose();
  };

  const resetSave = () => {
    setOptionSaveStatus({});
    setOptionSaveError({});
  };

  const resetToDefault = useCallback(() => {
    setCurrentDisplayedOptions(originalData);
    setSelectedOption(null);
    setSelectedOptions([]);
    setOptionsTreePath([]);
    setMenuTitle(originalTitle);
    resetSave();
  }, [originalData, originalTitle]);

  // get previous level options to be displayed
  const getPrevSelectedOption = useCallback(
    (prevOptionsTreePath: Array<number>) => {
      let prevDisplayData = originalData;
      let title = originalTitle;
      prevOptionsTreePath.forEach((selectedOptionIndex) => {
        prevDisplayData = (prevDisplayData[selectedOptionIndex] as DrilldownTreeOptionData).data;
        title = (prevDisplayData[selectedOptionIndex] as DrilldownTreeOptionData).title;
      });
      return {
        data: prevDisplayData,
        title,
      };
    },
    [originalData, originalTitle],
  );

  const handleGoBack = useCallback(() => {
    optionsTreePath.pop();
    const newOptionsTreePath = [...optionsTreePath];
    const { data, title } = getPrevSelectedOption(newOptionsTreePath);
    setOptionsTreePath(newOptionsTreePath);
    setCurrentDisplayedOptions([...data]);
    setMenuTitle(title);
    resetSave();
    if (onSubmenuBack) {
      onSubmenuBack();
    }
  }, [optionsTreePath, getPrevSelectedOption, onSubmenuBack]);

  /**
   * update display data on save success, if it is nested option
   * otherwise close the menu
   */
  const handleGoForward = useCallback(
    (newSelectedData: DrilldownMenuSelectedOptionProps, index: number) => {
      if (!currentDisplayedOptions || !newSelectedData) {
        return;
      }
      const newSelectedOption = currentDisplayedOptions[index];
      if (isTreeOptionData(newSelectedOption)) {
        setCurrentDisplayedOptions(newSelectedOption.data);
        setMenuTitle(newSelectedOption.title);
        setOptionsTreePath([...optionsTreePath, index]);
        resetSave();
      }
    },
    [currentDisplayedOptions, optionsTreePath],
  );

  // save selected option and update status and error
  const saveSelectedOption = useCallback(
    async (newSelectedData: DrilldownMenuSelectedOptionProps, index: number) => {
      if (!newSelectedData || !currentDisplayedOptions) {
        return;
      }

      const selectedMenuOption = currentDisplayedOptions[index];
      setOptionSaveStatus({ [newSelectedData.id]: SaveStatus.IN_PROGRESS });
      setOptionSaveError(omit(optionSaveError, newSelectedData.id));

      try {
        const newAllSelectedData = [...selectedOptions, newSelectedData] as Array<DrilldownMenuSelectedOptionProps>;
        if (shouldSaveAtIntermediateLevel || !isTreeOptionData(selectedMenuOption)) {
          await onSave(newSelectedData, newAllSelectedData);
        }
        setSelectedOption(newSelectedData);
        setSelectedOptions(newAllSelectedData);
        setOptionSaveStatus({ [newSelectedData.id]: SaveStatus.SUCCESS });
        if (isTreeOptionData(selectedMenuOption)) {
          handleGoForward(newSelectedData, index);
        } else {
          handleClose();
        }
      } catch (error: any) {
        setOptionSaveError({ [newSelectedData.id]: error?.message ?? defaultErrorConfig.message });
        autoHideError();
        setOptionSaveStatus({ [newSelectedData.id]: SaveStatus.ERROR });
      }
    },
    [currentDisplayedOptions, optionSaveError, selectedOptions, shouldSaveAtIntermediateLevel, onSave, handleGoForward],
  );

  const handleOptionClick = useCallback(
    (option: DrilldownMenuOptionData, index: number) => () => {
      const currentSelectedOption: DrilldownMenuSelectedOptionProps = {
        id: option.id,
        value: option.value,
        lookupIndexPath: [...optionsTreePath, index],
      };
      void saveSelectedOption(currentSelectedOption, index);
    },
    [saveSelectedOption, optionsTreePath],
  );

  const handleValueChange = useCallback(
    (inputOptionData: DrilldownInputOptionData, index: number) => (value: string) => {
      const newSelected: DrilldownMenuSelectedOptionProps = {
        id: inputOptionData.id,
        value,
        lookupIndexPath: [...optionsTreePath, index],
      };
      void saveSelectedOption(newSelected, index);
    },
    [saveSelectedOption, optionsTreePath],
  );

  const renderInputOptionComponent = useCallback(
    (inputOptionData: DrilldownInputOptionData, index: number) => {
      const status = optionSaveStatus[inputOptionData.id] ?? SaveStatus.NONE;
      return (
        <InputOption
          key={inputOptionData.id}
          {...inputOptionData}
          status={status}
          onSubmitButtonClick={handleValueChange(inputOptionData, index)}
        />
      );
    },
    [handleValueChange, optionSaveStatus],
  );

  const renderOptionComponent = useCallback(
    (item: DrilldownMenuOptionData, index: number) => {
      if (isInputData(item)) {
        return renderInputOptionComponent(item, index);
      }
      return (
        <MenuOption
          key={item.id}
          {...item}
          onSelect={handleOptionClick(item, index)}
          status={optionSaveStatus[item.id] ?? SaveStatus.NONE}
          hasChildOptions={isTreeOptionData(item)}
        />
      );
    },
    [handleOptionClick, optionSaveStatus, renderInputOptionComponent],
  );

  const renderOptions = useCallback(() => {
    if (!currentDisplayedOptions) {
      throw new Error('No options to display');
    }
    return (
      <div className="drill-down-menu--item-container">
        {currentDisplayedOptions.map((item, index) => renderOptionComponent(item, index))}
      </div>
    );
  }, [currentDisplayedOptions, renderOptionComponent]);

  // update displayed options
  useEffect(() => {
    resetToDefault();
  }, [resetToDefault]);

  // reset values on close
  useEffect(() => {
    if (!isOpen) {
      resetToDefault();
    }
  }, [isOpen, resetToDefault]);

  useEffect(() => {
    if (drilldownMenuRef.current && animationDivRef.current) {
      drilldownMenuRef.current.style.height = `${animationDivRef.current.getBoundingClientRect().height}px`;
    }
  }, [currentDisplayedOptions, optionSaveError]);

  if (!isOpen) {
    return null;
  }

  return (
    <div ref={drilldownMenuRef} className={classNames('drill-down-menu', className)} {...restProps}>
      <div ref={animationDivRef}>
        <div className="drill-down-menu--header">
          {optionsTreePath.length > 0 && (
            <i className={classNames('drill-down-menu--back', 'pgicon pgicon-arrow-left')} onClick={handleGoBack} />
          )}
          <div
            className={classNames('drill-down-menu--header-title', {
              'drill-down-menu--header-noback': optionsTreePath.length === 0,
            })}
          >
            {menuTitle}
          </div>
          <i className="drill-down-menu--close pgicon pgicon-cancel" onClick={handleClose} />
        </div>
        <div className="drill-down-menu--content">
          {shouldShowError() && (
            <div className="drill-down-menu--error-container">
              {Object.entries(optionSaveError).map(([errorId, errorMessage]) => (
                <div className="drill-down-menu--error" key={errorId}>
                  <div className="drill-down-menu--error-content">
                    <i className="drill-down-menu--error-alert pgicon pgicon-warning" />
                    <div className="drill-down-menu--error-text">{errorMessage}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="drill-down-menu--content-body">{renderOptions()}</div>
        </div>
      </div>
    </div>
  );
};

DrilldownMenu.displayName = 'DrilldownMenu';

export default DrilldownMenu;
