import classNames from 'classnames';
import { FC, useCallback } from 'react';

import { useSocialShareContext } from 'root/widgets/common-components/social-share-button-group/context';
import type { SocialShareTriggerProps } from 'root/widgets/common-components/social-share-button-group/types';

import './social-share-trigger.scss';

const SocialShareTrigger: FC<SocialShareTriggerProps> = ({ icon, className, children }) => {
  const context = useSocialShareContext();

  const handleOpen = useCallback(() => {
    context?.setShow(true);
  }, [context]);

  return (
    <div className={classNames('social-share-trigger-root', className)} tabIndex={0} role="button" onClick={handleOpen}>
      {children || <i className={icon || 'pgicon-share-o social-share-trigger--icon'} />}
    </div>
  );
};

SocialShareTrigger.displayName = 'SocialShareTrigger';

export default SocialShareTrigger;
