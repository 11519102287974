import { Events } from 'root/widgets/events';

const processEventHandlers = (eventMap: any, isSubscriber?: boolean, options?: AddEventListenerOptions) => {
  const context = isSubscriber ? 'on' : 'off';
  const events = Object.keys(eventMap);
  events.forEach((event) => Events[context](event, eventMap[event], options));
};

export const subscribeEvents = (EVENT_MAP: any) => {
  processEventHandlers(EVENT_MAP, true);
};

export const subscribeEventsOnce = (EVENT_MAP: any) => {
  processEventHandlers(EVENT_MAP, true, { once: true });
};

export const unsubscribeEvents = (EVENT_MAP: any) => {
  processEventHandlers(EVENT_MAP);
};

export default {
  subscribeEvents,
  unsubscribeEvents,
  subscribeEventsOnce,
};
