import { MULTI_VARIANT_LEVELS } from './constants';

export const initialState = {
  // code selection, for query, and state
  selectedCodes: {},
  // muliple level: current level
  level: MULTI_VARIANT_LEVELS.FIRST,
  // multiple level: second level list
  secondLevelItems: [],
};

export const reducer = (state, reducerAction) => {
  switch (reducerAction.type) {
    case 'level':
      return { ...state, level: reducerAction.value };
    case 'selectedItem': {
      const { item } = reducerAction;
      const { code, codeType } = item;
      const newSelectedCodes = { ...state.selectedCodes };
      // code selection
      if (codeType.includes('[]')) {
        // multiple selection type
        if (!newSelectedCodes[codeType]) {
          newSelectedCodes[codeType] = [];
        }
        newSelectedCodes[codeType] = newSelectedCodes[codeType].includes(code)
          ? newSelectedCodes[codeType].filter((selectedCode) => selectedCode !== code)
          : [...newSelectedCodes[codeType], code];
      } else {
        // single selection, replace code
        newSelectedCodes[codeType] = code;
      }

      return { ...state, selectedCodes: newSelectedCodes };
    }
    case 'batchSelectedItems': {
      const { items, codeType } = reducerAction;
      // clear same codeType

      if (items?.length === 0) {
        delete state.selectedCodes[codeType];
        return {
          ...state,
        };
      }
      const newSelectedCodes = { ...state.selectedCodes };
      if (!newSelectedCodes[codeType]) {
        newSelectedCodes[codeType] = [];
      }
      newSelectedCodes[codeType] = items.map((item) => item.code);

      return { ...state, selectedCodes: newSelectedCodes };
    }
    case 'keepFirstLevel': {
      // keep first level only
      const { firstLevelCodeType } = reducerAction;
      const newSelectedCodes = { [firstLevelCodeType]: state.selectedCodes[firstLevelCodeType] };
      return {
        ...state,
        selectedCodes: newSelectedCodes,
      };
    }
    case 'setSecondLevelItems':
      return { ...state, secondLevelItems: reducerAction.secondLevelItems };
    case 'clearAll':
      return {
        ...state,
        selectedCodes: {},
      };
    case 'setStateFromUpperLayer':
      return {
        ...state,
        selectedCodes: reducerAction.selectedCodes,
        level: reducerAction.isFirstLevel ? MULTI_VARIANT_LEVELS.FIRST : MULTI_VARIANT_LEVELS.SECOND,
        secondLevelItems: reducerAction.secondLevelItems,
      };
    default:
      throw new Error('Action must be valid');
  }
};
