import classnames from 'classnames';
import React from 'react';

import './enquiry-card.scss';
import Divider from './sub-components/divider';
import LeftContent from './sub-components/left-content';
import RightContent from './sub-components/right-content';
import { EnquiryCardProps } from './types';

const EnquiryCard: React.FC<EnquiryCardProps> = ({ children, className, metadata }) => (
  <div className={classnames('enquiry-card-root', className)} {...metadata}>
    {children}
  </div>
);

export default Object.assign(EnquiryCard, {
  LeftContent,
  RightContent,
  Divider,
});
