import classNames from 'classnames';
import { FC, useCallback } from 'react';

import { useSocialShareContext } from 'root/widgets/common-components/social-share-button-group/context';
import type { SocialButtonHeaderProps } from 'root/widgets/common-components/social-share-button-group/types';

import './social-share-header.scss';

const SocialShareHeader: FC<SocialButtonHeaderProps> = ({ onBack, title, className }) => {
  const context = useSocialShareContext();

  const handleClose = useCallback(() => {
    if (onBack) {
      onBack?.();
    } else {
      context?.setShow(false);
    }
  }, [context, onBack]);

  return (
    <div className={classNames('social-share-header-root', className)}>
      <div tabIndex={0} role="button" className="social-share-header--back-button" onClick={handleClose}>
        <i className="pgicon-arrow-left" />
      </div>
      {title && <div className="social-share-header--title">{title}</div>}
    </div>
  );
};

SocialShareHeader.displayName = 'SocialShareHeader';

export default SocialShareHeader;
