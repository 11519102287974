import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { MouseEventHandler, useState } from 'react';

import { Sheet as BottomSheet } from 'react-modal-sheet';

import ContactAgentAction from './sub-components/contact-agent-action';

import { ContactType } from 'root/symbiosis-widgets/types';

import './contact-agent-sheet.scss';
import type { ContactAgentSheetProps } from './types';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { ShareProfileCard } from 'root/widgets/share-profile-card';
import { CompletePreferencesNudge } from 'root/widgets/complete-preferences-nudge';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';
import { HUIBadges } from 'root/widgets/common-components/hui-badges';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Typography } from 'root/common/atom-elements/typography';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const ContactAgentSheet: React.FC<ContactAgentSheetProps> = ({
  className,
  consent,
  contactChannels,
  metadata,
  userIntent,
  shareProfileCard,
  completePreferencesNudge,
  shouldShow,
  alert,
  onHide,
  ...props
}) => {
  const [isConsentChecked, setConsentChecked] = useState(consent?.isChecked || false);

  const [isIntentSelected, setIntentSelected] = useState<boolean>(!isEmpty(userIntent?.selected));

  const [shouldShowIntentAlert, setShowIntentAlert] = useState<boolean>(false);

  const handleIntentClick: MouseEventHandler = (event) => {
    emit(EVENT_NAMES.CONTACT_AGENT_CARD.INTENT_CTA_CLICK, props.context);

    setIntentSelected(true);
    setShowIntentAlert(false);

    userIntent?.onClick?.(event);
  };

  const handleDisableCTAClick: MouseEventHandler = (event) => {
    if (userIntent?.alertText && !isIntentSelected) {
      event.preventDefault();
      setShowIntentAlert(true);
    }
  };

  const renderIcon = (type: ContactType | undefined, icon: string) => {
    switch (type) {
      case ContactType.WHATSAPP: {
        return <img src={icon} alt="whatsapp" />;
      }
      case ContactType.LINE: {
        return <img src={icon} alt="line" />;
      }
      default:
        return <i className={classNames('pgicon', icon)} />;
    }
  };

  const handleConsentCheckChange = () => {
    if (consent?.onCheckChange) {
      consent.onCheckChange(!isConsentChecked);
    }
    setConsentChecked(!isConsentChecked);
  };

  return (
    <BottomSheet
      isOpen={shouldShow}
      className={classNames('contact-agent-sheet-root bottom-sheet', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
      {...props}
      onClose={onHide}
      initialSnap={0}
    >
      <BottomSheet.Container className="bottom-sheet-container">
        {alert && (
          <HUIAlert
            variant={alert.variant || 'success'}
            className="bottom-sheet-alert"
            dismissible
            shouldAutoHide
            icon={{ className: 'pgicon-ok-circled-o' }}
            onClose={alert?.onClose}
          >
            {alert?.title && <div className="alert-title">{alert.title}</div>}
            {alert?.description && <div className="alert-description">{alert.description}</div>}
          </HUIAlert>
        )}
        <BottomSheet.Header />
        <BottomSheet.Content className="bottom-sheet-body">
          {shareProfileCard && (
            <ShareProfileCard
              className="share-profile-card"
              {...shareProfileCard}
              context={{ ...props.context, ...shareProfileCard.context }}
            />
          )}

          <div className={classNames('contact-agent-sheet-body', { 'with-consent': Boolean(consent) })}>
            {shouldShowIntentAlert && userIntent?.alertText && (
              <HUIAlert variant="warning" className={classNames('intent-alert')}>
                {userIntent?.alertText}
              </HUIAlert>
            )}
            {userIntent && <HUIBadges {...userIntent} onClick={handleIntentClick} />}
            {completePreferencesNudge && (
              <CompletePreferencesNudge
                {...completePreferencesNudge}
                context={{ ...props.context, ...completePreferencesNudge.context }}
              />
            )}
            <div className={classNames('contact-agent-actions')} onClick={handleDisableCTAClick}>
              {contactChannels.map(({ text, onClick, ...linkProps }) => (
                <ContactAgentAction
                  key={text}
                  text={text}
                  onClick={onClick}
                  isConsentChecked={isConsentChecked}
                  isIntentSelected={isIntentSelected}
                  userIntent={userIntent}
                  renderIcon={renderIcon}
                  {...linkProps}
                />
              ))}
            </div>
            {consent && (
              <div className="contact-agent-sheet-bottom">
                {consent.hasCheck ? (
                  <div className="d-flex gap-2">
                    <Checkbox
                      id="contact-agent-consent-checkbox"
                      className="contact-agent-consent-checkbox"
                      checked={isConsentChecked}
                      onChange={handleConsentCheckChange}
                    />
                    <Typography htmlFor="contact-agent-consent-checkbox" element="label">
                      {consent.text}
                    </Typography>
                  </div>
                ) : (
                  <p>{consent.text}</p>
                )}
              </div>
            )}
          </div>
        </BottomSheet.Content>
      </BottomSheet.Container>
      <BottomSheet.Backdrop onTap={onHide} />
    </BottomSheet>
  );
};

export default ContactAgentSheet;
