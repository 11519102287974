import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

export interface SocialShareContextType {
  isShowing: boolean;
  setShow: (value: boolean) => void;
}

export const SocialShareContext = createContext<SocialShareContextType | null>(null);

export const SocialShareProvider = (props: PropsWithChildren<Partial<SocialShareContextType>>) => {
  const [isShowing, setShow] = useState(false);

  const value = useMemo(
    () => ({
      isShowing,
      setShow,
    }),
    [isShowing],
  );

  return <SocialShareContext.Provider value={value}>{props.children}</SocialShareContext.Provider>;
};

export const useSocialShareContext = () => {
  const context = useContext(SocialShareContext);
  if (!context) {
    return null;
  }
  return context;
};
