import integration from './integration';
import local from './local';
import production from './production';
import sandbox from './sandbox';
import staging from './staging';

export { default as translation } from './translation';

export default {
  integration,
  staging,
  production,
  sandbox,
  local,
};
