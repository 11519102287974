import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Typography } from 'root/common/atom-elements/typography';

import { LocationStoreContext } from 'root/widgets/location-filter-modal/context';
import { ExpandableListProps } from 'root/widgets/location-filter-modal/types';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

const ExpandableList: React.FC<ExpandableListProps> = ({ item: radioItem, thirdLevelCodeType }) => {
  const { state, dataAutomationId, dispatch } = useContext(LocationStoreContext);
  const { code, codeType, value, className, items } = radioItem;
  const isSelectedRadio = (state?.selectedCodes && state.selectedCodes[codeType] === code) || false;
  const selectedThirdLevlCodes = (state?.selectedCodes && state.selectedCodes[thirdLevelCodeType]) || [];
  const [isExpanded, setExpanded] = useState(isSelectedRadio || false);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickRadioItem = () => {
    const expanded = isExpanded && isSelectedRadio;
    setExpanded(!expanded);

    dispatch({ type: 'selectedItem', item: radioItem });
    dispatch({ type: 'batchSelectedItems', items, codeType: thirdLevelCodeType });
  };
  const handleClickCheckBoxItem = (e, checkboxItem) => {
    e.stopPropagation();
    dispatch({ type: 'selectedItem', item: checkboxItem });
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.maxHeight = isExpanded && isSelectedRadio ? `${ref.current?.scrollHeight}px` : '0';
    }
  }, [isExpanded, isSelectedRadio]);

  return (
    <div className="clickable" onClick={() => handleClickRadioItem()}>
      <Form.Check
        onChange={() => handleClickRadioItem()}
        checked={isSelectedRadio}
        key={value}
        type="radio"
        label={value}
        className={className}
        {...extractDataAutomationId({
          dataAutomationId: dataAutomationId ? `${dataAutomationId}-rdo` : '',
        })}
      />
      <div ref={ref} className={classnames('expandable-list', { show: isExpanded && isSelectedRadio })}>
        {items?.map((checkboxItem, index) => (
          <div
            className="clickable d-flex gap-2"
            key={checkboxItem.value}
            onClick={(e) => handleClickCheckBoxItem(e, checkboxItem)}
          >
            <Checkbox
              readOnly
              checked={selectedThirdLevlCodes.includes(checkboxItem.code)}
              {...extractDataAutomationId({
                dataAutomationId: dataAutomationId ? `${dataAutomationId}-chk-${index}` : '',
              })}
            />
            <Typography>{checkboxItem.value}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

ExpandableList.displayName = 'ExpandableList';

export default ExpandableList;
