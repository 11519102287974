import classnames from 'classnames';
import React from 'react';
import { Col } from 'react-bootstrap';

import { CONTACT_AGENT_USER_INTENT, SearchCategory } from 'root/widgets/constants';
import { CardGroup } from 'root/widgets/card-group';

import { storage } from 'root/widgets/storage';
import { AgentCard } from 'root/widgets/agent-card';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './home-valuation-agent-section.scss';
import { AgentDetailProps, HomeValuationRecommendedAgentsSectionProps } from './types';
import Title from 'root/widgets/common-components/title';
import ActionableLink from 'root/widgets/common-components/actionable-link';

const HomeValuationRecommendedAgentsSection: React.FC<HomeValuationRecommendedAgentsSectionProps> = (props) => {
  const {
    agents,
    description,
    metadata,
    title,
    isGated,
    className,
    columnProps,
    viewAll,
    context,
    shouldShowViewAllCtaOnSide,
  } = props;

  const handleClick = (agentInfo: AgentDetailProps) => {
    storage.setItem(CONTACT_AGENT_USER_INTENT, JSON.stringify(SearchCategory.SELL));
    emit(EVENT_NAMES.HOME_VALUATION.REQUEST_CONTACT_INFO_ON_CLICK, { ...context, agentInfo });
  };

  const handleViewAllClick = () => {
    emit(EVENT_NAMES.HOME_VALUATION.VIEW_ALL_ON_CLICK, context);
    if (viewAll?.onClick) {
      viewAll?.onClick();
    }
  };
  const agentCards = agents.map((agentData: AgentDetailProps) => {
    const { metaInfo, ...agentInfo } = agentData;

    if (isGated) {
      agentInfo.profileUrl = '#';
    }

    return (
      <Col onClick={() => handleClick(agentInfo)} key={agentInfo.ceaLicenseNo} {...metadata} {...columnProps}>
        <AgentCard {...agentInfo}>
          {metaInfo?.map((info) => <AgentCard.MetaInfo key={info.caption} {...info} />)}
        </AgentCard>
      </Col>
    );
  });

  return (
    <div
      className={classnames('hs-agent-section-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <div className="hs-agent-section-header">
        <Title headerTag="h3" className="hs-agent-section-title">
          {title}
        </Title>
        {viewAll && !shouldShowViewAllCtaOnSide && (
          <ActionableLink className="hs-agent-section-link" onClick={handleViewAllClick} href={viewAll?.href}>
            {viewAll?.text}
            <i className="pgicon-arrow-right" />
          </ActionableLink>
        )}
      </div>
      {description && <div className="hs-agent-section-description">{description}</div>}
      {agents.length > 0 && (
        <CardGroup className={classnames('hs-agent-section-details', { gated: isGated })} metadata={metadata}>
          <CardGroup.Body smallScreenVariant="single-scroll">
            {agentCards}
            {viewAll && shouldShowViewAllCtaOnSide && (
              <Col className="hs-agent-section-viewall-cta">
                <ActionableLink
                  className="hs-agent-section-side-link"
                  onClick={handleViewAllClick}
                  href={viewAll?.href}
                >
                  <i className="pgicon-arrow-right-1" />
                  {viewAll?.text}
                </ActionableLink>
              </Col>
            )}
          </CardGroup.Body>
        </CardGroup>
      )}
    </div>
  );
};

HomeValuationRecommendedAgentsSection.displayName = 'HomeSellerAgentSection';

export default HomeValuationRecommendedAgentsSection;
