import classnames from 'classnames';
import React, { useState } from 'react';

import type { ImageProps } from 'root/widgets/common-components/types';
import { BREAKPOINTS } from 'root/widgets/constants';

import './hui-image.scss';

const HUIImage: React.FC<ImageProps> = (props) => {
  const { fallback, src, alt, className, loading, height, width, responsive, ...rest } = props;

  if (props.fetchPriority) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    rest.fetchpriority = props.fetchPriority;
    delete rest.fetchPriority;
  }

  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    if (fallback) {
      setImgSrc(fallback);
    }
  };

  const handleLoad = (evt: React.SyntheticEvent<HTMLImageElement>) => {
    if (evt.currentTarget.naturalWidth < 0) {
      handleError();
    }
  };

  const image = (
    <img
      {...(height && { height: height })}
      {...(width && { width: width })}
      {...(loading && { loading: loading })}
      className={classnames('hui-image-root', className)}
      src={imgSrc}
      alt={alt || 'Image'}
      onLoad={handleLoad}
      onError={handleError}
      {...rest}
    />
  );

  if (responsive) {
    const { lg, md, sm } = responsive;
    return (
      <picture>
        {lg && <source media={`(min-width: ${BREAKPOINTS.LG})`} srcSet={lg} />}
        {md && <source media={`(min-width: ${BREAKPOINTS.MD})`} srcSet={md} />}
        {sm && <source media={`(min-width: ${BREAKPOINTS.SM})`} srcSet={sm} />}
        {image}
      </picture>
    );
  }

  return image;
};

export default HUIImage;
