import { MARKETPLACE, REGION } from 'root/widgets/constants';
import { PGLoginConfigData } from 'root/widgets/pg-login/types';

import integrationConfig from './integration';

const LOCAL_HOST = '';

const config: Record<string, PGLoginConfigData> = {
  [`${REGION.SG}-${MARKETPLACE.PG}`]: {
    ...integrationConfig[`${REGION.SG}-${MARKETPLACE.PG}`],
    HOST: LOCAL_HOST,
  },
  [`${REGION.SG}-${MARKETPLACE.CG}`]: {
    ...integrationConfig[`${REGION.SG}-${MARKETPLACE.CG}`],
    HOST: LOCAL_HOST,
  },
  [`${REGION.MY}-${MARKETPLACE.PG}`]: {
    ...integrationConfig[`${REGION.MY}-${MARKETPLACE.PG}`],
    HOST: LOCAL_HOST,
  },
  [`${REGION.MY}-${MARKETPLACE.IPP}`]: {
    ...integrationConfig[`${REGION.MY}-${MARKETPLACE.IPP}`],
    HOST: LOCAL_HOST,
  },
  [`${REGION.TH}-${MARKETPLACE.PG}`]: {
    ...integrationConfig[`${REGION.TH}-${MARKETPLACE.PG}`],
    HOST: LOCAL_HOST,
  },
};

export default config;
