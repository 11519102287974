/* eslint-disable no-empty-pattern */
import React from 'react';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './global-footer.scss';
import { Contact } from './sub-components/contact';
import { Copyright } from './sub-components/copyright';
import { MarketSegment } from './sub-components/market-segment';
import { SEOSegment } from './sub-components/seo-segment';
import { Sitemap } from './sub-components/sitemap';
import { SocialSegment } from './sub-components/social-segment';
import { GlobalFooterProps } from './types';

const GlobalFooter: React.FC<GlobalFooterProps> = ({ data, context, metadata }) => (
  <footer className="global-footer-root" {...filterMetadata(metadata)} {...extractDataAutomationId(metadata)}>
    {data.socialSegment && (
      <SocialSegment socialSegmentData={data.socialSegment} context={context} className="footer-container" />
    )}
    <div className="divider" />
    <Contact
      contactSegmentData={data.contactSegment}
      countrySelectSegmentData={data.countrySelectSegment}
      context={context}
      commonData={data.commonData}
      className="footer-container"
    />
    <div className="divider" />
    <MarketSegment marketSegmentData={data.marketSegment} context={context} className="footer-container" />
    <SEOSegment seoSegmentData={data.seoSegment} context={context} />
    <Sitemap sitemapSegmentData={data.sitemapSegment} context={context} commonData={data.commonData} />
    <Copyright copyrightSegmentData={data.copyrightSegment} context={context} className="footer-container" />
  </footer>
);

GlobalFooter.displayName = 'GlobalFooter';

export default GlobalFooter;
