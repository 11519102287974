import { MouseEventHandler, forwardRef, useEffect, useMemo, useState } from 'react';
import { SvgIcon } from 'root/widgets/svg-icon';

import { AgentInfoV3 } from 'root/widgets/agent-info-v3';
import { EVENT_NAMES, emit } from 'root/widgets/events';

import './contact-agent-card-v3.scss';
import type { ContactAgentCardV3Props } from './types';
import QRCodeView from './sub-components/qr-code-view';
import { ShareProfileCard } from 'root/widgets/share-profile-card';
import { CompletePreferencesNudge } from 'root/widgets/complete-preferences-nudge';
import { ContactButton } from 'root/widgets/contact-button';
import { ExtendedView } from './sub-components';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import HUIImage from 'root/widgets/common-components/hui-image';
import { HUIBadges } from 'root/widgets/common-components/hui-badges';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';
import Actionable from 'root/widgets/common-components/actionable';

const SHOW_SCROLLBAR_DELAY_IN_MILLISECOND = 500;

const ContactAgentCardV3 = forwardRef<HTMLDivElement, ContactAgentCardV3Props>(
  (
    {
      agentInfoProps: agentInfo,
      contactActions,
      liveTour,
      agency,
      alert,
      userIntent,
      termsAndPolicy,
      shareProfileCard,
      metadata,
      context,
      completePreferencesNudge,
      extendedAction,
      enquiryStatus,
      isScrollable,
    },
    ref,
  ) => {
    const [shouldShowScrollBar, setShouldShowScrollBar] = useState(false);

    const handleIntentClick: MouseEventHandler = (event) => {
      emit(EVENT_NAMES.CONTACT_AGENT_CARD.INTENT_CTA_CLICK, context);

      if (userIntent?.onClick) {
        userIntent.onClick?.(event);
      }
    };

    const intentBadgesProps = useMemo(() => {
      if (!userIntent) {
        return null;
      }

      const { badges, ...rest } = userIntent;

      return {
        ...rest,
        badges: badges.map((badge) => ({ ...badge, background: 'neutral' })),
      };
    }, [userIntent]);

    useEffect(() => {
      // When expand the dropdown, browser only started to calculate whether the content exceed max-height,
      // at that time it shows the scrollbar immediately, when hidden again, which is not a good user experience.
      // To fix this, add delay for a while before showing the scrollbar
      let timer;

      if (extendedAction?.actions.length) {
        timer = setTimeout(() => {
          setShouldShowScrollBar(true);
        }, SHOW_SCROLLBAR_DELAY_IN_MILLISECOND);
      } else {
        setShouldShowScrollBar(false);
      }

      return () => clearTimeout(timer);
    }, [extendedAction?.actions.length]);

    return (
      <div
        ref={ref}
        className={classNames('contact-agent-card-v3-root', { scrollable: isScrollable && shouldShowScrollBar })}
        {...filterMetadata(metadata)}
        {...extractDataAutomationId(metadata)}
      >
        <div className="card-header">
          {agency && (
            <div className="agency">
              {agency.avatar && <HUIImage className="agency-avatar" src={agency.avatar} alt={agency.name} />}
              {agency.name}
            </div>
          )}
          <AgentInfoV3 {...agentInfo} context={context} />
        </div>
        <div className="card-body">
          {enquiryStatus && (
            <div className="enquiry-status">
              <div className="content">
                <SvgIcon {...enquiryStatus.icon} height={24} width={24} shouldUseImage />
                {enquiryStatus.text}
              </div>
              {enquiryStatus.description && <div className="description">{enquiryStatus.description}</div>}
            </div>
          )}
          {intentBadgesProps && <HUIBadges {...intentBadgesProps} onClick={handleIntentClick} />}
          {contactActions.length > 0 && (
            <div className="contact-agent-actions-group">
              {alert && (
                <HUIAlert className="mb-3" dismissible variant="danger">
                  <Row className="p-0 m-0">
                    <Col className="p-0">
                      <div className="alert-title">{alert.text}</div>
                      {alert.description}
                      {alert.button && (
                        <div className="mt-4">
                          <Actionable className="me-2" size="sm" variant="secondary" onClick={alert.button.onClick}>
                            {alert.button.text}
                          </Actionable>
                        </div>
                      )}
                    </Col>
                  </Row>
                </HUIAlert>
              )}
              {contactActions.map((action, index) => (
                <ContactButton key={`contact-agent-${index}`} {...action} />
              ))}
              {extendedAction && <ExtendedView {...extendedAction} />}
            </div>
          )}

          {shareProfileCard && (
            <ShareProfileCard
              className="share-profile-card"
              {...shareProfileCard}
              context={{ ...context, ...shareProfileCard.context }}
            />
          )}

          {completePreferencesNudge && (
            <CompletePreferencesNudge
              {...completePreferencesNudge}
              context={{ ...context, ...completePreferencesNudge.context }}
            />
          )}
          {termsAndPolicy && <div className="terms-and-policy">{termsAndPolicy}</div>}
        </div>
        {liveTour && (
          <div className="card-footer">
            <div className="live-tour">
              <div className="live-tour-badge">
                <i className="pgicon-video-call" />
                {liveTour.text}
              </div>
              {liveTour.description}
            </div>
          </div>
        )}
      </div>
    );
  },
);

ContactAgentCardV3.displayName = 'ContactAgentCard';

export default Object.assign(ContactAgentCardV3, { QRCodeView });
