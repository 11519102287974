import { GLOBAL_FOOTER_ID, GLOBAL_NAVBAR_ID } from 'root/widgets/constants';
import { renderWidget } from 'root/widgets/utils/render-helper';

import type { FooterWrapperProps, GlobalNavbarWrapperProps } from 'root/global-wrappers';
import GlobalFooterWrapper from 'root/global-wrappers/global-footer-wrapper/global-footer-wrapper';
import GlobalNavbarWrapper from 'root/global-wrappers/global-navbar-wrapper/global-navbar-wrapper';

/**
 * @param headerProps - props for the header
 * @param shouldReRender - only re-render if the props are different.
 *        For example, NotificationPanel Button in GlobalHeader with new feed to re-render to show red dot.
 */
export const renderGlobalHeader = (headerProps: GlobalNavbarWrapperProps, shouldReRender = false) => {
  renderWidget(GlobalNavbarWrapper, headerProps, GLOBAL_NAVBAR_ID, true, shouldReRender);
};

/**
 * @param footerProps
 */
export const renderGlobalFooter = (footerProps: FooterWrapperProps) => {
  renderWidget(GlobalFooterWrapper, footerProps, GLOBAL_FOOTER_ID, true);
};

/**
 * Hydrate the global header and footer in Guruland. Guruland has to prepare the html in the code.
 *
 * @param headerProps
 * @param footerProps
 * @param shouldReRender - Set to true if you want to re-render the header the widgets
 */
export const renderGlobalHeaderAndFooter = (
  headerProps: GlobalNavbarWrapperProps,
  footerProps: FooterWrapperProps,
  shouldReRender = false,
) => {
  renderGlobalHeader(headerProps, shouldReRender);
  renderGlobalFooter(footerProps);
};
