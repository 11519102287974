/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Checkbox } from 'root/common/atom-elements/checkbox';
import { Typography } from 'root/common/atom-elements/typography';

import Wrapper from 'root/widgets/common-components/wrapper';

import { LocationStoreContext } from 'root/widgets/location-filter-modal/context';
import SearchableInput from 'root/widgets/location-filter-modal/sub-components/body/search-section/search-section';
import { BodyProps, LocationFilterItemType } from 'root/widgets/location-filter-modal/types';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { withTextMatch } from 'root/widgets/utils/filter';

const SingleLevelVariant: React.FC<BodyProps> = ({ data, items }) => {
  const { selectAllText, firstLevelCodeType, searchPlaceholder } = data;
  const { state, dataAutomationId, dispatch } = useContext(LocationStoreContext);
  const { selectedCodes } = state;
  const [searchText, setSearchText] = useState('');

  const selectedLevelCodes = selectedCodes[firstLevelCodeType] || ([] as Array<string>);

  const [isIndeterminate, setIsIndeterminate] = useState(false);

  useEffect(() => {
    setIsIndeterminate(selectedLevelCodes.length > 0 && selectedLevelCodes.length !== items.length);
  }, [selectedLevelCodes]);

  const handleClickItem = (checkboxItem) => {
    dispatch({ type: 'selectedItem', item: checkboxItem });
  };

  const selectAll = () => {
    // select none;
    let updatedSelectItems: Array<LocationFilterItemType> = [];
    if (selectedLevelCodes.length < items.length) {
      // select all
      updatedSelectItems = items;
    }
    dispatch({ type: 'batchSelectedItems', items: updatedSelectItems, codeType: firstLevelCodeType });
  };

  const handleSearchInputChange = (value) => {
    setSearchText(value);
  };

  const matchedItems = withTextMatch(items, searchText, ['value']);

  return (
    <div className="single-level-variant-root">
      <Wrapper className="filter-modal-search-section">
        <SearchableInput
          value={searchText}
          placeholder={searchPlaceholder}
          onChange={setSearchText}
          onSearchTextChange={handleSearchInputChange}
        />
        <Form.Group>
          <div className="clickable d-flex gap-2" onClick={selectAll}>
            <Checkbox
              id="select-all"
              className="select-all"
              onChange={selectAll}
              indeterminate={isIndeterminate}
              checked={items.length > 0 && selectedLevelCodes.length === items.length}
              {...extractDataAutomationId({
                dataAutomationId: dataAutomationId ? `${dataAutomationId}-select-all-chk` : '',
              })}
            />
            <Typography htmlFor="select-all">{selectAllText}</Typography>
          </div>
        </Form.Group>
      </Wrapper>
      <Wrapper className="filter-modal-content">
        <Form.Group>
          {matchedItems.map((item, index) => (
            <div key={item.code} className="clickable d-flex gap-2" onClick={() => handleClickItem(item)}>
              <Checkbox
                checked={selectedLevelCodes.includes(item.code)}
                readOnly
                {...extractDataAutomationId({
                  dataAutomationId: dataAutomationId ? `${dataAutomationId}-chk-${index}` : '',
                })}
              />
              <Typography>{item.value}</Typography>
            </div>
          ))}
        </Form.Group>
      </Wrapper>
    </div>
  );
};

export default SingleLevelVariant;
