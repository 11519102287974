import React, { useContext, useState } from 'react';

import { INPUT_TYPES } from 'root/widgets/common-components/generic-input-group/constants';

import { EMAIL_VALIDATION_PATTERN } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { SERVICE } from 'root/widgets/pg-login/constants';
import PGLoginContext from 'root/widgets/pg-login/context';
import { getService } from 'root/widgets/pg-login/services';

import './forgot-password-view.scss';
import { ForgotPasswordViewProps } from './types';
import { Masthead } from 'root/widgets/common-components/masthead';
import { GenericInputGroup } from 'root/widgets/common-components/generic-input-group';
import { HUIAlert } from 'root/widgets/common-components/hui-alert';

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = ({
  masthead,
  emailInput,
  resetBtnText,
  serverErrorText,
}) => {
  const bffService = getService(SERVICE.BFF);
  const { context, onClose } = useContext(PGLoginContext);
  const [serverError, setServerError] = useState('');

  const inputGroup = {
    input: {
      placeholder: emailInput.placeholder,
      type: INPUT_TYPES.EMAIL,
      checkValidity: (text: string) => ({
        isInvalid: !new RegExp(EMAIL_VALIDATION_PATTERN).test(text),
        error: emailInput.validationMessage,
      }),
      metadata: {
        'data-automation-id': 'email-fld',
      },
    },
    button: {
      text: resetBtnText,
      metadata: {
        'data-automation-id': 'reset-password-btn',
      },
    },
    onSubmit: async (text: string) => {
      emit(EVENT_NAMES.PG_LOGIN.RESET_PASSWORD_ATTEMPT, context);
      try {
        await bffService.requestResetPassword(text);
        onClose();
        emit(EVENT_NAMES.PG_LOGIN.RESET_PASSWORD_SUCCESS, context);
      } catch {
        setServerError(serverErrorText);
      }
    },
  };

  return (
    <div className="forgot-password-view-root">
      <div className="pglogin-slide">
        <Masthead {...masthead} />
        <div className="pglogin-viewport">
          <GenericInputGroup {...inputGroup} />
          {serverError && <HUIAlert variant="danger">{serverError}</HUIAlert>}
        </div>
      </div>
    </div>
  );
};

ForgotPasswordView.displayName = 'ForgotPasswordView';

export default ForgotPasswordView;
