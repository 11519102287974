import classnames from 'classnames';
import React, { useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './hui-date-picker.scss';
import { HUIDatePickerProps } from './types';
import Actionable from 'root/widgets/common-components/actionable';
import { Divider } from 'root/widgets/common-components/divider';

const DatePicker = (ReactDatePicker as unknown as { default: typeof ReactDatePicker }).default ?? ReactDatePicker;

const defaultMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const HUIDatePicker: React.FC<HUIDatePickerProps> = ({
  shouldShowIcon = true,
  months = defaultMonths,
  metadata,
  ...props
}) => {
  const [hasYearDropdownOpen, setHasYearDropdownOpen] = useState<boolean>(false);
  const [hasOpenCalender, sethasOpenCalender] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(props.selected);

  const yearDropdownHandler = useCallback(() => {
    setHasYearDropdownOpen((prev) => !prev);
  }, []);

  return (
    <div
      className={classnames(
        'hui-date-picker form-control',
        { disabled: props.disabled },
        { shouldShowIcon: shouldShowIcon },
      )}
      {...metadata}
    >
      <DatePicker
        {...props}
        popperClassName="hui-date-picker-popover"
        shouldCloseOnSelect
        onKeyDown={(e) => e.preventDefault()}
        selected={selectedDate}
        open={hasOpenCalender}
        showYearPicker={hasYearDropdownOpen}
        onInputClick={() => sethasOpenCalender(true)}
        onClickOutside={() => {
          setHasYearDropdownOpen(false);
          sethasOpenCalender(false);
        }}
        onMonthChange={(date) => {
          setSelectedDate(date);
        }}
        onChange={(date, e) => {
          const selectedYear = date?.getFullYear();
          const prevYear = selectedDate?.getFullYear();

          setHasYearDropdownOpen(false);
          if (selectedYear === prevYear || !prevYear) {
            sethasOpenCalender(false);
          } else {
            sethasOpenCalender(true);
          }
          setSelectedDate(date);
          props.onChange(date, e);
        }}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <>
            <div className="custom-hui-date-picker-header">
              <div className="month-wrapper">
                <Actionable
                  variant="light"
                  className="month-navigation"
                  disabled={prevMonthButtonDisabled}
                  onClick={decreaseMonth}
                >
                  <i className="pgicon-arrow-left" />
                </Actionable>
                <div className="month"> {months[date.getMonth()]} </div>

                <Actionable
                  variant="light"
                  className="month-navigation"
                  disabled={nextMonthButtonDisabled}
                  onClick={increaseMonth}
                >
                  <i className="pgicon-arrow-right" />
                </Actionable>
              </div>
              <Actionable variant="secondary" className="year-wrapper" onClick={yearDropdownHandler}>
                {date.getFullYear()}
                <i className={hasYearDropdownOpen ? 'pgicon-arrow-up' : 'pgicon-arrow-down'} />
              </Actionable>
            </div>
            <Divider className="divider" />
          </>
        )}
      />
    </div>
  );
};

HUIDatePicker.displayName = 'HUIDatePicker';

export default HUIDatePicker;
