import React from 'react';

import { DrilldownInputOptionData, OptionType } from 'root/widgets/drilldown-menu';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { extractDataAutomationId } from 'root/widgets/utils/automation';
import { filterMetadata } from 'root/widgets/utils/filter';

import { ListingFeedbackReasons } from 'root/symbiosis-widgets/wrappers/feedback-reasons-view';
import { FeedbackSubmittedView } from 'root/symbiosis-widgets/wrappers/feedback-submitted-view';

import type { ReportListingModalProps } from './types';

const ReportListingModal: React.FC<ReportListingModalProps> = (props) => {
  const { metadata, context, reasonsView, feedbackSubmittedView, dependencies } = props;
  const { title, reasonsData, shouldShow, actions, errorConfig } = reasonsView;
  const { logError } = dependencies;

  const handleSubmit: ReportListingModalProps['reasonsView']['actions']['submit']['onClick'] = async (...args) => {
    const { value, lookupIndexPath } = args[0];

    let optionData: any = { data: reasonsData };
    let optionDataParent: DrilldownInputOptionData;
    let isInputType: boolean | undefined;

    lookupIndexPath.forEach((index) => {
      isInputType = optionData.data[index].type === OptionType.INPUT;
      if (isInputType) {
        optionDataParent = optionData;
      }
      optionData = optionData.data[index];
    });

    const options = {
      data: {
        ...(isInputType ? { tags: [optionDataParent!.value], responseText: value } : { tags: [value] }),
      },
    };

    try {
      await actions.submit.onClick(...args);

      emit(EVENT_NAMES.REPORT_LISTING_MODAL.SUBMIT_ON_SUCCESS, { ...context, payload: options });
    } catch (error: any) {
      logError(ReportListingModal.name, handleSubmit.name, error);
      throw new Error(error.message);
    }
  };

  return (
    <section className="report-listing-modal-root" {...filterMetadata(metadata)} {...extractDataAutomationId(metadata)}>
      <ListingFeedbackReasons
        drillDownMenuProps={{
          title,
          onSave: handleSubmit,
          isOpen: shouldShow,
          data: reasonsData,
          onClose: actions.close.onClick,
          errorConfig,
        }}
      />
      {feedbackSubmittedView && <FeedbackSubmittedView {...feedbackSubmittedView} />}
    </section>
  );
};

ReportListingModal.displayName = 'ReportListingModal';

export default ReportListingModal;
