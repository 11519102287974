/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCallback, useEffect } from 'react';

import { EVENT_NAMES, emit } from 'root/widgets/events';
import { NavLinkProps } from 'root/widgets/global-components/navbar/types';

import { Endpoints, UserType } from 'root/global-wrappers/config';
import { Segment } from 'root/global-wrappers/services';
import { subscribeEvents, unsubscribeEvents } from 'root/global-wrappers/services/events/ga/events';
import { request } from 'root/global-wrappers/services/http';
import { logFrontendException } from 'root/global-wrappers/utils/logging';
import { getLocalePathname } from 'root/global-wrappers/utils/url';

import { EVENT_MAP } from './event-map';
import { GlobalNavbarWrapperProps, LocaleSwitcherConfigType } from './types';
import { LOCALE, REGION, UserIDType } from 'root/widgets/constants';
import { removeUserIdFromStorage, setUserIdToStorage } from 'root/widgets/utils/users';
import { GlobalNavbar } from 'root/widgets/global-components/navbar';

const name = 'global-wrapper.global-navbar-wrapper.global-navbar-wrapper';

export const useGetLocalePathname = (region: REGION, localeSwitcherConfig?: LocaleSwitcherConfigType) =>
  useCallback(
    (locale: LOCALE) => {
      const localeSwitcherConfigPathname = localeSwitcherConfig?.[locale];
      const defaultHomepagePathname = getLocalePathname(locale, region);

      return localeSwitcherConfigPathname || defaultHomepagePathname;
    },
    [region, localeSwitcherConfig],
  );

const GlobalNavbarWrapper = (props: GlobalNavbarWrapperProps) => {
  const {
    metadata,
    isLoggedIn,
    shouldCallSymbiosisLogout,
    locale,
    region,
    userRole,
    localeSwitcherConfig,
    headerConfig,
    hasNewFeed,
    navigationKey,
    context,
  } = props;

  const evaluateLocalePathname = useGetLocalePathname(region, localeSwitcherConfig);

  const handleLocaleChange = (newLocale: LOCALE) => {
    const pathname = evaluateLocalePathname(newLocale);

    window.location.assign(pathname);
  };

  const handleLogoutClick = async (shouldTriggerSymbiosisLogout = false) => {
    if (shouldTriggerSymbiosisLogout) {
      try {
        const url = Endpoints.LOGOUT;
        const { data } = await request({ url });

        setUserIdToStorage(UserIDType.PGUTID, data.pgutid);

        emit(EVENT_NAMES.GLOBAL_NAVBAR.LOGOUT_ON_CLICK, { pgutid: data.pgutid });
      } catch (error) {
        logFrontendException(name, handleLogoutClick.name, error);
      }
    }

    removeUserIdFromStorage(UserIDType.UMSTID);
    Segment.reset();

    // Navigate to guruland /logout endpoint,
    // it helps to clear all related cookies and redirect back to homepage
    window.location.assign('/logout');
  };

  const enhancedHeaderData = {
    ...headerConfig,
    loginDetail: {
      ...headerConfig.loginDetail,
      isLoggedIn,
      login: {
        ...(headerConfig.loginDetail ? headerConfig.loginDetail.login : {}),
      },
      logout: {
        ...(headerConfig.loginDetail ? headerConfig.loginDetail.logout : {}),
        onClick: useCallback(() => handleLogoutClick(shouldCallSymbiosisLogout), [shouldCallSymbiosisLogout]),
      },
    },
    localeDetail: {
      locales: headerConfig.locales,
      currentLocaleKey: locale,
      onLocaleChange: handleLocaleChange,
    },
    notificationButton: headerConfig?.notificationPanel?.shouldRender
      ? {
          hasNewFeed,
        }
      : '',
    myAccountLinks: {
      ...headerConfig.myAccountLinks,
      items:
        userRole === UserType.AGENT
          ? headerConfig.myAccountLinks?.items
          : headerConfig.myAccountLinks?.items.filter((item) => item?.role !== UserType.AGENT.toLowerCase()),
    },
  };

  /**
   * Mark selected link, if that page is being rendered. Navigation key is coming
   * from page level config.
   */
  headerConfig.navLinks.forEach((link: NavLinkProps) => {
    if (link.url === navigationKey) {
      link.isSelected = true;
    }
  });

  useEffect(() => {
    subscribeEvents(EVENT_MAP);

    return () => {
      unsubscribeEvents(EVENT_MAP);
    };
  }, []);

  return <GlobalNavbar metadata={metadata} data={enhancedHeaderData} context={context} />;
};

export default GlobalNavbarWrapper;
