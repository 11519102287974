import classNames from 'classnames';
import omit from 'lodash/omit';
import { FC, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './seller-leads-prequalification-modal.scss';
import { SellerInfoProps, SellerLeadsPrequalificationModalProps } from './types';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import { Masthead } from 'root/widgets/common-components/masthead';
import Actionable from 'root/widgets/common-components/actionable';

const SellerLeadsPrequalificationModal: FC<SellerLeadsPrequalificationModalProps> = (props) => {
  const {
    className,
    context,
    metadata,
    config: { shouldShow, onClose },
    masthead,
    fields: { address, sellBy },
    cta,
    termsAndConditions,
  } = props;

  const [sellerInfo, setSellerInfo] = useState<SellerInfoProps>({
    address: address?.value || '',
    sellBy: sellBy?.value || '',
  });

  const handleAddressChange = (e) =>
    setSellerInfo({
      ...sellerInfo,
      address: e.target.value,
    });

  const handleSellByChange = (value: SellerInfoProps['sellBy']) =>
    setSellerInfo({
      ...sellerInfo,
      sellBy: value,
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    emit(EVENT_NAMES.PRE_QUALIFICATION_MODAL.SUBMIT, { ...context, ...sellerInfo });

    cta.onClick(sellerInfo);
  };

  return (
    <HUIModal
      className={classNames('seller-leads-prequalification-modal-root', className)}
      show={shouldShow}
      onHide={onClose}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <HUIModal.Header closeButton />
      <HUIModal.Body>
        <Masthead {...masthead} />
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="home-seller-selling-time">
            <Form.Label>{sellBy.label}</Form.Label>
            <div className="d-inline-flex gap-2 flex-wrap">
              {sellBy.options.map((value) => (
                <Badge
                  bg={value === sellerInfo.sellBy ? 'dark' : 'light'}
                  text={value === sellerInfo.sellBy ? 'light' : 'dark'}
                  className={classNames({
                    selected: value === sellerInfo.sellBy,
                  })}
                  onClick={() => handleSellByChange(value)}
                  key={value}
                >
                  {value}
                </Badge>
              ))}
            </div>
          </Form.Group>
          <Form.Group controlId="home-seller-address">
            <Form.Label>{address.label}</Form.Label>
            <Form.Control
              type="text"
              required
              value={sellerInfo.address}
              onChange={handleAddressChange}
              {...extractDataAutomationId(address.metadata)}
              {...omit(address, ['label', 'invalidFeedback', 'value'])}
            />
          </Form.Group>
          <Actionable type="submit" disabled={!sellerInfo.address}>
            {cta.text}
          </Actionable>
        </Form>
        {termsAndConditions && <div className="home-sellers-terms">{termsAndConditions}</div>}
      </HUIModal.Body>
    </HUIModal>
  );
};

SellerLeadsPrequalificationModal.displayName = 'SellerLeadsPrequalificationModal';

export default SellerLeadsPrequalificationModal;
