import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';

import Actionable from 'root/widgets/common-components/actionable';

import { NoticationPanelHeaderProps } from 'root/widgets/notification-panel/types';
import { requestContentCardsRefresh } from 'root/widgets/utils/braze';

const REFRESH_ANIMATION_TIME = 2000;

const NotificationPanelHeader: React.FC<NoticationPanelHeaderProps> = ({ brazeSDK, config }) => {
  const [isFeedRefreshing, setFeedRefreshing] = useState<boolean>(false);
  const [isRefreshDisabled, setRefreshDisabled] = useState<boolean>(false);

  const { defaultTexts, onHide } = config;

  const refreshFeed = () => {
    setRefreshDisabled(true);
    setFeedRefreshing(true);
  };

  useEffect(() => {
    let timer;
    if (isFeedRefreshing) {
      requestContentCardsRefresh(brazeSDK);
      timer = setTimeout(() => {
        setRefreshDisabled(false);
        setFeedRefreshing(false);
      }, REFRESH_ANIMATION_TIME);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isFeedRefreshing]);

  return (
    <Offcanvas.Header className={classNames('notification-panel-header')}>
      <Actionable className="drawer-close-btn" onClick={onHide}>
        <i className="pgicon-cancel" />
      </Actionable>
      <Offcanvas.Title>{defaultTexts.header}</Offcanvas.Title>
      <Actionable className="drawer-referesh-btn" onClick={refreshFeed} disabled={isRefreshDisabled}>
        <i className={classNames('pgicon-repeat', { 'animate-spin': isFeedRefreshing })} />
      </Actionable>
    </Offcanvas.Header>
  );
};

NotificationPanelHeader.displayName = 'NotificationPanelHeader';

export default NotificationPanelHeader;
