import classnames from 'classnames';
import { Popover } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';

import { SharingLinkProps } from 'root/widgets/sharing-button/types';

import { SocialShareButtonGroup } from 'root/widgets/common-components/social-share-button-group';

const getSharingPopover =
  (sharingTitle: string, links: SharingLinkProps[], shouldShowArrow = true, context = {}) =>
  // eslint-disable-next-line react/function-component-definition
  (props: OverlayInjectedProps) => (
    <Popover {...props} className={classnames('sharing-popover-root', shouldShowArrow ? '' : 'share-disable-arrow')}>
      <div className="title">{sharingTitle}</div>
      <SocialShareButtonGroup socialButtons={links} context={context} />
    </Popover>
  );
export default getSharingPopover;
