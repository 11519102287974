import classNames from 'classnames';
import { FC } from 'react';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './in-page-tab-navigation.scss';
import TabIcon from './sub-components/tab-icon';
import { InPageTabNavigationProps, PageTabData } from './types';
import ActionableLink from 'root/widgets/common-components/actionable-link';
import { Badge } from 'root/widgets/common-components/badge';

const InPageTabNavigation: FC<InPageTabNavigationProps> = ({ tabs, activeTabIndex, onTabClick, metadata }) => {
  const getTabClickHandler = (tab: PageTabData, index: number) => () => onTabClick && onTabClick(tab, index);

  return (
    <div className="in-page-tab-navigation-root" {...filterMetadata(metadata)} {...extractDataAutomationId(metadata)}>
      {tabs.map((tab, index) => {
        const { badgeText, icon, label, ...rest } = tab;
        const isActive = activeTabIndex === index;

        return (
          <ActionableLink
            key={`in-page-tab-navigation-${index}`}
            {...rest}
            className={classNames('tab', rest.className, { active: isActive })}
            onClick={getTabClickHandler(tab, index)}
          >
            <div className="icon-wrapper">
              <TabIcon {...icon} isActive={isActive} />
            </div>
            <span className="tab-text">{label}</span>
            {badgeText && (
              <Badge type="small" variant="primary" bg="green">
                {badgeText}
              </Badge>
            )}
          </ActionableLink>
        );
      })}
    </div>
  );
};

InPageTabNavigation.displayName = 'InPageTabNavigation';

export default InPageTabNavigation;
