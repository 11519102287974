import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { MenuItem } from 'react-bootstrap-typeahead';

import { SvgIcon } from 'root/widgets/svg-icon';

import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './search-options.scss';
import { SearchOptionItemProps, SearchOptionsProps } from './types';
import { TypeaheadOptionType } from 'root/widgets/common-components/found-locations-view';

const SearchOptionItem: React.FC<SearchOptionItemProps> = (props) => {
  const { className, option, position, metadata, onOptionSelect } = props;

  const handleClick = () => {
    if (onOptionSelect) {
      onOptionSelect(option);
    }
  };
  return (
    <MenuItem
      className={classnames('search-options-item-root', className)}
      position={position}
      option={{ ...option, displayText: '', optionType: TypeaheadOptionType.SearchOption }}
      onClick={handleClick}
      {...extractDataAutomationId(metadata)}
    >
      {!option.svgIconUrl && option.icon && <i className={option.icon} />}
      {option.svgIconUrl && (
        <SvgIcon className="search-options-item__icon" src={option.svgIconUrl} width={24} height={24} shouldUseImage />
      )}
      <span className="search-option-title" da-id="search-option-title">
        {option.title}
      </span>
      {option && option.label && <span className="search-option-label">{option.label}</span>}
    </MenuItem>
  );
};

const SearchOptions: React.FC<SearchOptionsProps> = ({
  className,
  metadata,
  onOptionSelect,
  positions,
  searchOptions,
}) => {
  if (!searchOptions || searchOptions.length === 0) {
    return null;
  }

  return (
    <div className="search-options-root" {...extractDataAutomationId(metadata)}>
      {searchOptions.map((option, idx) => (
        <SearchOptionItem
          className={className}
          key={option.key}
          option={option}
          position={positions[idx]}
          onOptionSelect={onOptionSelect}
          metadata={{
            dataAutomationId: metadata?.dataAutomationId ? `${metadata.dataAutomationId}-${kebabCase(option.key)}` : '',
          }}
        />
      ))}
    </div>
  );
};

SearchOptions.displayName = 'SearchOptions';

export default SearchOptions;
