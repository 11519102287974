import { useScroll } from 'ahooks';
import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';

import { ContactAgentStickyBar } from 'root/widgets/contact-agent-sticky-bar';

import { getContactActionProps } from 'root/symbiosis-widgets/utils';

import { MIN_ENQUIRY_CARD_DISPLAY_HEIGHT } from './constants';
import './sticky-enquiry-wrapper.scss';
import { StickyEnquiryWrapperProps } from './types';

const StickyEnquiryWrapper: FC<StickyEnquiryWrapperProps> = ({
  enquiryCardRef,
  isGatingEnabled,
  contactActions,
  onContactActionClick,
  ...rest
}) => {
  const [shouldHidden, setShouldHidden] = useState(false);
  const actions = contactActions.map((action) => getContactActionProps(action, onContactActionClick, isGatingEnabled));

  const handleScroll = useCallback(() => {
    if (!enquiryCardRef) {
      return;
    }

    const enquiryCardRect = enquiryCardRef.current?.getBoundingClientRect();

    setShouldHidden(
      Boolean(enquiryCardRect && window.innerHeight - enquiryCardRect.top >= MIN_ENQUIRY_CARD_DISPLAY_HEIGHT),
    );
  }, [enquiryCardRef?.current]);

  useScroll(
    () => document,
    (scrollPosition) => {
      if (enquiryCardRef?.current) {
        handleScroll();
        return scrollPosition.top > 0;
      }
      return false;
    },
  );

  return (
    <ContactAgentStickyBar
      className={classNames('sticky-enquiry-wrapper-root', { hidden: shouldHidden })}
      contactActions={actions}
      {...rest}
    />
  );
};

StickyEnquiryWrapper.displayName = 'StickyEnquiryWrapper';

export default StickyEnquiryWrapper;
