import classNames from 'classnames';
import React from 'react';
import { Row } from 'react-bootstrap';

import './gated-view.scss';
import { GatedViewHeading } from './sub-components';
import { GatedViewProps } from './types';

const GatedView: React.FC<GatedViewProps> = ({ className, children, metadata, ...props }) => (
  <Row className={classNames('gated-view-root', 'text-center', className)} {...metadata} {...props}>
    {children}
  </Row>
);

export default Object.assign(GatedView, {
  Heading: GatedViewHeading,
});
