import classnames from 'classnames';
import React, { useCallback } from 'react';
import { Container } from 'react-bootstrap';

import './tab-nav.scss';
import { TabNavProps } from './types';
import { HUINavTabGroup } from 'root/widgets/common-components/hui-nav-tab-group';

const TabNav: React.FC<TabNavProps> = (props) => {
  const { activeIndex, onSelect, tabItems, getStickyPositionTop, metadata } = props;
  const [isSticky, setIsSticky] = React.useState(false);
  const [stickyPositionTop, setStickyPositionTop] = React.useState(getStickyPositionTop ? getStickyPositionTop() : 0);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const initialPositionTop = ref.current?.offsetTop || 0;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const positionTop = getStickyPositionTop ? getStickyPositionTop() : 0;
      if (scrollTop > initialPositionTop - positionTop) {
        setStickyPositionTop(positionTop);
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSelect = useCallback(
    (index: number, key?: string) => () => {
      onSelect?.(index, key);
    },
    [onSelect],
  );

  return (
    <>
      {isSticky && <div className="tabs-navigator-placeholder" />}
      <div
        ref={ref}
        style={{ top: stickyPositionTop }}
        className={classnames('tabs-navigator-root', { sticky: isSticky })}
        {...metadata}
      >
        <Container className="tabs-navigator-body">
          <HUINavTabGroup
            navLinks={tabItems.map((link, index) => ({ ...link, onClick: handleSelect(index, link.tabKey) }))}
            activeIndex={activeIndex}
          />
        </Container>
      </div>
    </>
  );
};

TabNav.displayName = 'TabNav';

export default TabNav;
