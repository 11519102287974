import classnames from 'classnames';
import debounce from 'lodash/debounce';
import React from 'react';

import './text-area.scss';
import { TextAreaProps } from './types';

const TextArea: React.FC<TextAreaProps> = ({
  textAreaRef,
  placeholder,
  className,
  value,
  charLimit,
  debounceTime,
  onLimitReach,
}) => {
  const [isError, setError] = React.useState(false);

  const handleChange = (event) => {
    if (textAreaRef.current) {
      textAreaRef.current.value = event.target.value;
      setError(textAreaRef.current.value.length >= charLimit);
      onLimitReach(textAreaRef.current.value.length >= charLimit);
    }
  };

  const onTextChange = debounce(handleChange, debounceTime);

  return (
    <div className={classnames('text-area-root', className)}>
      <textarea
        ref={textAreaRef}
        maxLength={charLimit}
        className={classnames('text-area', isError ? 'error' : '')}
        placeholder={placeholder}
        onChange={onTextChange}
        value={value}
      />
    </div>
  );
};

export default TextArea;
