import { FC, useContext } from 'react';
import { Modal } from 'react-bootstrap';

import { MULTI_VARIANT_LEVELS, VARIANTS } from 'root/widgets/location-filter-modal/constants';
import LocationFilterModalContext, { LocationStoreContext } from 'root/widgets/location-filter-modal/context';
import { HeaderProps } from 'root/widgets/location-filter-modal/types';

import './header.scss';
import { HIVE_STATIC_VERSION } from 'root/widgets/constants';
import SvgIcon from 'root/widgets/svg-icon/svg-icon';
import Actionable from 'root/widgets/common-components/actionable';
import Wrapper from 'root/widgets/common-components/wrapper';

const Header: FC<HeaderProps> = ({ title, resetText, firstLevelCodeType, locationMap, onClose }) => {
  const variant = useContext(LocationFilterModalContext);
  const { state, dispatch } = useContext(LocationStoreContext);

  const shouldRenderCloseButton = !(variant === VARIANTS.MULTI_LEVEL && state.level === MULTI_VARIANT_LEVELS.SECOND);
  const firstlevelItemName = locationMap[state.selectedCodes[firstLevelCodeType]] || '';

  const isSecondLevel = variant === VARIANTS.MULTI_LEVEL && state.level === MULTI_VARIANT_LEVELS.SECOND;

  const handleBack = () => {
    if (isSecondLevel) {
      dispatch({ type: 'level', value: MULTI_VARIANT_LEVELS.FIRST });
      dispatch({ type: 'keepFirstLevel', firstLevelCodeType });
    }
  };

  const isResetDisabled = Object.keys(state.selectedCodes).length <= 1;

  const handleReset = () => {
    dispatch({ type: 'keepFirstLevel', firstLevelCodeType });
  };

  return (
    <Modal.Header className="location-filter-modal-header-root">
      <Wrapper className={isSecondLevel ? 'secondary' : 'primary'}>
        <Modal.Title>
          {isSecondLevel ? (
            <Actionable className="secondary-button" variant="link primary icon-suffix" onClick={handleBack}>
              <div className="header-icon">
                <i className="pgicon-arrow-left" />
              </div>
              <div className="header-text">
                {title?.secondary}
                <span className="header-subtext">{firstlevelItemName}</span>
              </div>
            </Actionable>
          ) : (
            title.primary
          )}
        </Modal.Title>
      </Wrapper>
      {isSecondLevel && (
        <Actionable disabled={isResetDisabled} onClick={handleReset} variant="link icon-suffix reset-button">
          {resetText}
        </Actionable>
      )}
      {shouldRenderCloseButton && (
        <Actionable className="hui-modal-header-action-btn hui-btn-close" variant="link" onClick={onClose}>
          <SvgIcon
            width={10}
            height={10}
            src={`https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/pg-icons/cancel.svg`}
          />
        </Actionable>
      )}
    </Modal.Header>
  );
};

Header.displayName = 'Header';

export default Header;
