import {
  ConvertStateToTypeFuncProps,
  ConvertTypeToStateFuncProps,
  GetDisplayStringFuncProps,
  SelectedTypeProps,
  SubTypeProps,
  TypeOptionProps,
} from './types';

export const getDisplayString: GetDisplayStringFuncProps = (selectedTypes, typeOptions, isThreeLayer) => {
  const { type, subTypes, subTypeOptions } = selectedTypes;

  if (!type) {
    return '';
  }

  const selectedType = typeOptions.find((option) => option.code === type);
  /*
   * 3 layer options: MY
   * if has subTypeOptions, use its parent subType name: ex Commercial, Industry.
   * if has no subTypeOptions, use first subType name: ex All commercial.
   */
  if (isThreeLayer) {
    if (subTypes.length === 1 && subTypeOptions.length > 0) {
      const subType = selectedType?.subTypes.find((option) => option.code === subTypes[0]);
      return subType?.name ?? (selectedType?.name as string);
    }
    return selectedType?.subTypes[0].name as string;
  }
  /*
   * 2 layer options: SG
   * if has subTypes, use its parent type name: ex Condo, Land.
   * if has no subType, return empty string, will display 'property'.
   */
  if (subTypes.length > 0) {
    return selectedType?.name ?? '';
  }
  return '';
};

export const convertTypesToState: ConvertTypeToStateFuncProps = (selectedTypes, typeOptions, fixMarket) => {
  const { type, subTypes, subTypeOptions } = selectedTypes;
  const state = { market: '' };
  let typeIndicator; // move through typeOptions(config) tree: type -> subTypes
  let subTypeIndicator;

  // for 2 layer options: SG, PGCG
  if (fixMarket) {
    state.market = fixMarket;
    typeIndicator = typeOptions.find((option) => option.code === type) as TypeOptionProps;

    if (typeIndicator) {
      state[typeIndicator.codeType] = type;

      if (subTypes.length > 0) {
        const subTypeCodeType: string = typeIndicator.subTypes[0].codeType;
        state[subTypeCodeType] = subTypes;
      } else {
        // if no subTypes, select first type option
        state[typeIndicator.codeType] = typeOptions[0].code;
      }
    }
    return state;
  }
  // for 3 layer options, MY
  if (type) {
    typeIndicator = typeOptions.find((option) => option.code === type) as TypeOptionProps;

    if (typeIndicator) {
      state[typeIndicator.codeType] = type;

      if (subTypes.length > 0) {
        subTypeIndicator = typeIndicator.subTypes.find((option) => option.code === subTypes[0]) as SubTypeProps;
        state[subTypeIndicator.codeType] = subTypes[0];
      } else {
        subTypeIndicator = typeIndicator.subTypes[0];
      }

      if (subTypeOptions.length > 0) {
        const subTypeOptionCodeType: string = subTypeIndicator.subTypeOptions[0].codeType;
        state[subTypeOptionCodeType] = subTypeOptions;
      } else {
        // if no subTypeOptions, select first subType option
        state[subTypeIndicator.codeType] = typeIndicator.subTypes[0].code;
      }
    }
    return state;
  }
  return state;
};

// convert state to selectedTypes: {market, property_type, property_type_code[]} => {type, subTypes, subTypeOptions}
export const convertStateToTypes: ConvertStateToTypeFuncProps = (selectedState, typeOptions, fixMarket) => {
  const selectedType: SelectedTypeProps = { type: '', subTypes: [], subTypeOptions: [] };

  let currentIndicator;

  if (fixMarket) {
    /*
     * 2 layer options , SG.
     * set property_type to type,
     * property_type_code[] to subTypes
     */
    currentIndicator = typeOptions.find((option) => option.code === selectedState.property_type);
    selectedType.type = currentIndicator?.code ?? null;

    if (selectedState['property_type_code[]']?.length) {
      selectedType.subTypes = selectedState['property_type_code[]'];
    }
  } else if (selectedState.market) {
    /*
     * 3 layer options, MY.
     * set market to type,
     * set property_type to subTypes,
     * set property_type_code[] to subTypeOptions
     */
    currentIndicator = typeOptions.find((option) => option.code === selectedState.market);

    if (currentIndicator) {
      selectedType.type = currentIndicator.code;
    }

    if (currentIndicator && selectedState.property_type) {
      currentIndicator = currentIndicator.subTypes.find((option) => option.code === selectedState.property_type);

      if (currentIndicator) {
        selectedType.subTypes = [currentIndicator.code];
      }
    }

    if (selectedState['property_type_code[]']?.length) {
      selectedType.subTypeOptions = selectedState['property_type_code[]'];
    }
  }

  return selectedType;
};
