import { HIVE_STATIC_VERSION, MARKETPLACE, REGION } from 'root/widgets/constants';
import { PGLoginConfigData } from 'root/widgets/pg-login/types';

const commonConfig = {
  AUTH_API_HOST: 'https://auth.staging.guruestate.com',
  USERS_API_HOST: 'https://users-api.kgw.staging.guruestate.com',
  GOOGLE_CLIENT_ID: '367223124563-nqbh60veth48kr448f62mflkasd0kpmp.apps.googleusercontent.com',
  FACEBOOK_CLIENT_ID: '751052508373579',
  APPLE_CLIENT_ID: 'com.propertyguru.www',
  PG_LOGO_URL: `https://cdn-staging.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/logo/pg.svg`,
};
const config: Record<string, PGLoginConfigData> = {
  [`${REGION.SG}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '367223124563-qrc1uo9idbccc697glapnaf7lcqd6jh3.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '1493336990969744',
    HOST: 'https://www.staging.propertyguru.com.sg',
  },
  [`${REGION.SG}-${MARKETPLACE.CG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '185477762030-7l11il66to9h3nours87e2oo4dfcvf9o.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '1488923501265140',
    HOST: 'https://www.staging.commercialguru.com.sg',
  },
  [`${REGION.MY}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '593435227081-ekv2tve0psovos0ibdm0hrdgsapsq6vc.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '1798199040984291',
    HOST: 'https://www.staging.propertyguru.com.my',
  },
  [`${REGION.MY}-${MARKETPLACE.IPP}`]: {
    ...commonConfig,
    APPLE_CLIENT_ID: 'com.iproperty.accounts.internal',
    GOOGLE_CLIENT_ID: '313711791759-6ph6bhlaaf6lep37r78m6lnb0m1a21f8.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '598849274578961',
    HOST: 'https://stg.sg.iproperty.com.my',
    PG_LOGO_URL: 'https://accounts.iproperty.com.my/assets/1730099992/img/ipp/logo-consumer.svg',
    FORGOT_PASSWORD_URL: 'https://accounts.staging.iproperty.com.my/forgot-password?client_id=i4c9tp3xr78dyl3umb6ifa5tvq',
  },
  [`${REGION.TH}-${MARKETPLACE.PG}`]: {
    ...commonConfig,
    GOOGLE_CLIENT_ID: '711575025382-2l4p4olfv5mkrqak673e6u38ike9lcei.apps.googleusercontent.com',
    FACEBOOK_CLIENT_ID: '1220009886163826',
    HOST: 'https://www.staging.ddproperty.com',
  },
};

export default config;
