import classnames from 'classnames';
import { FC } from 'react';
import { Col, Nav, NavProps } from 'react-bootstrap';

import { SearchCategory } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './search-segment.scss';
import { SearchSegmentProps } from './types';

const SearchSegment: FC<SearchSegmentProps> = (props) => {
  const { children, className, metadata, context, tabs, activeTab, handleTabClick } = props;

  const handleNavSelect: NavProps['onSelect'] = (selectKey, evt) => {
    if (selectKey) {
      handleTabClick(selectKey as SearchCategory);

      const clickedNavItem = evt.target as HTMLElement;

      const activeNavItem: HTMLElement | null | undefined = clickedNavItem.parentElement?.querySelector(
        `.search-segment-nav-item[data-rr-ui-event-key="${activeTab}"]`,
      );

      if (activeNavItem) {
        const x = `${activeNavItem.getBoundingClientRect().x - clickedNavItem.getBoundingClientRect().x}px`;
        const activeElementWidth = window.getComputedStyle(activeNavItem).width;

        clickedNavItem.style.setProperty('--translate-x-value', x);
        clickedNavItem.style.setProperty('--active-element-width', activeElementWidth);
      }
    }
    emit(EVENT_NAMES.SEARCH_SEGMENT.TAB_ON_CLICK, { ...context, data: selectKey });
  };

  return (
    <div
      className={classnames('search-segment-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <div className="search-segment-container">
        <Col>
          <div className="search-segment-nav">
            <Nav activeKey={activeTab} as="ul" onSelect={handleNavSelect}>
              {tabs.map((item) => (
                <Nav.Link
                  className="search-segment-nav-item"
                  {...filterMetadata({ 'data-automation-id': `home-search-filter-widget-${item.tabKey}-lnk` })}
                  eventKey={item.tabKey}
                  key={`search-segment-nav-${item.title}`}
                >
                  {item.title}
                </Nav.Link>
              ))}
            </Nav>
          </div>
        </Col>
        {children}
      </div>
    </div>
  );
};

SearchSegment.displayName = 'SearchSegment';

export default SearchSegment;
