import classnames from 'classnames';
import phone from 'phone';
import { FC, useEffect, useState } from 'react';

import { COUNTRY_CODE, HIVE_STATIC_VERSION, REGION } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';
import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './mobile-number-verification-modal.scss';
import { MobileNumberVerificationModalProps } from './types';
import { HUIPhoneInput, HUIPhoneInputProps } from 'root/widgets/common-components/hui-phone-input';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import Actionable from 'root/widgets/common-components/actionable';

const MobileNumberVerificationModal: FC<MobileNumberVerificationModalProps> = ({
  metadata,
  context,
  className,
  config,
  body,
  input,
}) => {
  const defaultImage = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/images/verify-mobile-number.svg`;

  const { onClose, shouldShow } = config;
  const { title, description, mobileNumberLabel, image, button } = body;
  const { onSubmit, text } = button;
  const { regions, value, placeholder, errors, selectedRegion } = input;

  const [region, setRegion] = useState<REGION>(selectedRegion);
  const [phoneNumber, setPhoneNumber] = useState(value ? `+${COUNTRY_CODE[region]}${value}` : '');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    const { isValid, countryCode } = phone(phoneNumber, { strictDetection: true });

    if (!isValid) {
      setErrorMessage(errors.input);
      return;
    }

    const number = phoneNumber.replace(countryCode, '');
    setErrorMessage('');
    onSubmit(number, region);
    emit(EVENT_NAMES.MOBILE_NUMBER_VERIFICATION.SUBMIT_ON_CLICK, { ...context, countryCode: region });
  };

  const phoneInputProps: HUIPhoneInputProps = {
    onlyCountries: regions,
    country: region,
    placeholder,
    onCountryChange: (updatedCountryCode: string) => {
      setRegion(updatedCountryCode as REGION);
    },
    onNumberChange: (updatedNumber: string) => {
      setPhoneNumber(updatedNumber);
    },
    value: phoneNumber,
    className: classnames({ error: Boolean(errorMessage) }),
  };

  useEffect(() => {
    if (shouldShow) {
      emit(EVENT_NAMES.MOBILE_NUMBER_VERIFICATION.VIEW, context);
    }
  }, [shouldShow]);

  useEffect(() => {
    if (!phoneNumber && value) {
      setPhoneNumber(`+${COUNTRY_CODE[region]}${value}`);
    }
  }, [phoneNumber, value, region]);

  return (
    <HUIModal
      className={classnames('mobile-number-verification-root', className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
      show={shouldShow}
      onHide={onClose}
    >
      <HUIModal.Header className={classnames('mobile-number-verification-header')} closeButton />
      <HUIModal.Body className={classnames('mobile-number-verification-body', 'text-center')}>
        <img className="mobile-number-verification-image" src={image.src || defaultImage} alt={image.alt || title} />
        <h3 className="mobile-number-verification-title">{title}</h3>
        <p className="mobile-number-verification-description">{description}</p>
        <p className="verify-mobile-input-title">{mobileNumberLabel}</p>
        <HUIPhoneInput {...phoneInputProps} />
        {errorMessage && <p className="verify-mobile-input-error">{errorMessage}</p>}
        <Actionable
          type="submit"
          disabled={!phoneNumber}
          onClick={handleSubmit}
          className="mobile-number-verification-button"
        >
          {text}
        </Actionable>
      </HUIModal.Body>
    </HUIModal>
  );
};

MobileNumberVerificationModal.displayName = 'MobileNumberVerificationModal';

export default MobileNumberVerificationModal;
