import classNames from 'classnames';
import React from 'react';

import { IllustratorModalProps } from 'root/widgets/illustrator-modal/types';
import { SvgIcon } from 'root/widgets/svg-icon';

import './illustrator-modal.scss';
import { HUIModal } from 'root/widgets/common-components/hui-modal';
import { Masthead } from 'root/widgets/common-components/masthead';
import Actionable from 'root/widgets/common-components/actionable';

const IllustratorModal: React.FC<IllustratorModalProps> = ({ masthead, action, image, className, ...props }) => (
  <HUIModal className={classNames('hui-illustrator-modal-root', className)} {...props}>
    <HUIModal.Header closeButton />
    <HUIModal.Body>
      <Masthead {...masthead} />
      <div className="hui-illustrator-modal-actionable">
        <Actionable {...action} />
      </div>
      <div className="hui-illustrator-modal-img">
        <SvgIcon src={image} width={300} height={250} />
      </div>
    </HUIModal.Body>
  </HUIModal>
);

IllustratorModal.displayName = 'IllustratorModal';

export default IllustratorModal;
