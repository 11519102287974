import React from 'react';
import { Alert } from 'react-bootstrap';

import { AlertHeadingProps } from 'root/widgets/common-components/hui-alert/types';

const HUIAlertHeading: React.FC<AlertHeadingProps> = ({ children }) => (
  <Alert.Heading className="clip-two-line">{children}</Alert.Heading>
);

export default HUIAlertHeading;
