import classNames from 'classnames';
import React from 'react';

import './rating-view.scss';
import { RatingViewProps } from './types';

const MAX_RATING = 5;

const RatingView: React.FC<RatingViewProps> = (props) => {
  const { rating, maxRating = MAX_RATING, label, className, metadata } = props;

  const fullStars = Math.floor(rating);

  const starsArray = Array.from({ length: maxRating }, (_, index) => index);

  const getFillClass = (index: number): string => {
    const isFractionalRating = Boolean(rating % 1);
    if (index < fullStars) {
      return 'filled';
    }
    if (index === fullStars && isFractionalRating) {
      return 'partially-filled';
    }
    return '';
  };

  return (
    <figure className={classNames('rating-view-root', className)} {...metadata}>
      <div className="rating-view-stars">
        {starsArray.map((number, index) => (
          <i className={classNames('pgicon-star', getFillClass(index))} key={number}>
            {getFillClass(index) === 'partially-filled' && (
              <i className="pgicon-star" style={{ width: `${(rating % 1) * 100}%` }} />
            )}
          </i>
        ))}
      </div>
      <figcaption>{label}</figcaption>
    </figure>
  );
};

RatingView.displayName = 'RatingView';

export default RatingView;
