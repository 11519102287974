import classnames from 'classnames';
import React from 'react';

import Actionable from 'root/widgets/common-components/actionable';

import './share-shortlist-button.scss';
import getInvitePopover from './sub-components';
import { ShareShortlistButtonProps } from './types';

const ShareShortlistButton: React.FC<ShareShortlistButtonProps> = ({ icons, overlay, onClick, metadata }) => (
  <Actionable
    {...metadata}
    className={classnames('shortlist-share-button')}
    variant="default"
    onClick={onClick}
    popover={{
      placement: 'bottom-start',
      trigger: 'click',
      rootClose: true,
      overlay,
    }}
  >
    <div className={classnames('shortlist-sharing')}>
      <div className={classnames('shortlist-sharing-root')}>
        <i className={classnames('pgicon shortlist-sharing-icon', icons.user)} />
      </div>
      <div className={classnames('shortlist-sharing-root-plus')}>
        <i className={classnames('pgicon shortlist-sharing-plus', icons.plus)} />
      </div>
    </div>
  </Actionable>
);

ShareShortlistButton.displayName = 'ShareShortlistButton';

export default Object.assign(ShareShortlistButton, {
  getInvitePopover,
});
