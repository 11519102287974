import classNames from 'classnames';
import React, { Fragment, useMemo } from 'react';

import { HUIProgress } from 'root/widgets/common-components/hui-progress';

import { filterMetadata } from 'root/widgets/utils/filter';
import { extractDataAutomationId } from 'root/widgets/utils/automation';

import './progress-group-view.scss';
import { ProgressGroupViewProps } from './types';

const ProgressGroupView = (props: ProgressGroupViewProps) => {
  const { className, metadata, heading, groupData, maxValue, hasBorders = true } = props;

  const groupValuesSum = useMemo(() => groupData?.reduce((acc, curr) => acc + curr.value, 0), [groupData]);

  const renderProgressGroupViewRows = () => {
    if (!groupData || groupData.length === 0) {
      return null;
    }
    return groupData.map((row) => {
      const progressBarFillWidth = (row.value / (maxValue || groupValuesSum)) * 100;

      return (
        <Fragment key={row.label}>
          <span className="progress-group-view-row-label">{row.label}</span>
          <HUIProgress percentComplete={progressBarFillWidth < 100 ? progressBarFillWidth : 100} variant="primary" />
          <span className="progress-group-view-row-value">{row.value}</span>
        </Fragment>
      );
    });
  };

  return (
    <div
      className={classNames('progress-group-view-root', { 'has-borders': hasBorders }, className)}
      {...filterMetadata(metadata)}
      {...extractDataAutomationId(metadata)}
    >
      <h4 className="progress-group-view-heading">{heading}</h4>
      <div className="progress-group-view-rows">{renderProgressGroupViewRows()}</div>
    </div>
  );
};

ProgressGroupView.displayName = 'ProgressGroupView';

export default ProgressGroupView;
