import capitalize from 'lodash/capitalize';
import ReactGA from 'react-ga';

import { GAECommerceData, GAEcommerceCommand } from 'root/global-wrappers/config';
import { staticEcommerceData } from 'root/global-wrappers/services/events/ga/ga-global-data';
import { GAEcommerceType, GAPageViewType, GATrackingType } from 'root/global-wrappers/types/analytics';
import { logFrontendException } from 'root/global-wrappers/utils/logging';
import { Listing } from 'root/symbiosis-widgets/constants/common';

const fileName = 'global-wrappers.services.analytics.ga-tracking';

/**
 * `initialize` will initialize the GA instance on the page
 *
 * @param trackingId - The tracking ID of the GA property
 */
const initialize = (trackingId: string) => {
  if (trackingId) {
    ReactGA.initialize(trackingId);
    ReactGA.plugin.require('ec');
  }
};

const getClientId = async () => {
  let clientId = '';

  return new Promise((resolve) => {
    ReactGA.ga((tracker: any) => {
      clientId = tracker.get('clientId');

      return resolve(clientId);
    });
  });
};

const clearCustomDimensions = (customDimensions: Record<string, any> | undefined) => {
  if (!customDimensions) {
    return;
  }

  const dimensions = { ...customDimensions } as Record<string, any>;

  Object.keys(customDimensions).forEach((key) => {
    dimensions[key] = null;
  });

  ReactGA.set(dimensions);
};

/**
 * `trackPageView` will send pageview events to GA
 *
 * @param page - The page name or path that will be sent with PageView Event
 * @param dimensions - GA Custom Dimensions
 */
const trackPageView = (data: GAPageViewType) => {
  if (data.dimensions) {
    ReactGA.set({ ...data.dimensions });
  }

  ReactGA.pageview(data.page);
  clearCustomDimensions(data.dimensions);
};

/**
 * `trackEvent` will send the relevant data to GA

 */
const trackEvent = (data: GATrackingType) => {
  if (data.dimensions) {
    ReactGA.set({ ...data.dimensions });
  }
  ReactGA.set({ ...staticEcommerceData });

  ReactGA.event({ ...data });

  clearCustomDimensions(data.dimensions);
};

const extractEcommerceFeatures = (product: Record<string, string>) => {
  const productVariants: Array<string> = [];
  if (!product) {
    return productVariants;
  }
  if (product.isSpotlight || product.isRankedSpotlight) {
    productVariants.push('spotlight');
  }
  if (product.isTurbo) {
    productVariants.push('Turbo');
  }

  return productVariants;
};

const buildListingECommerceData = ({
  listing,
  index,
  price,
  total,
  page = 1,
}: {
  listing: Record<string, any>;
  price: string | number;
  index: number;
  total: number;
  page?: number;
}) => {
  try {
    const id = listing.id ?? '';
    const name = (listing.localizedTitle || listing.property?.name || listing.developmentName) ?? '';
    const category = (listing.property?.typeText || listing.propertyType) ?? '';
    const brand = (listing.property?.developer || listing.developerName) ?? '';
    const variant = (listing.typeCode && capitalize(listing.typeCode?.toLowerCase())) ?? '';
    const position = index + 1;
    const dimension22 = JSON.stringify({
      page,
      limit: total,
      rank: position,
    });
    const dimension23 = JSON.stringify({ Product: extractEcommerceFeatures(listing.products) });
    const dimension24 = listing.statusCode ?? '';
    const dimension25 = (listing.agent?.id || listing.userId) ?? '';
    const dimension40 = listing.newProject ? 'newProject=1' : '';

    let listingPrice = '';
    if (
      listing.subTypeCode === Listing.SubType.Project &&
      Array.isArray(listing.unitTypes) &&
      listing.unitTypes?.length > 0
    ) {
      listingPrice = listing.unitTypes[0].minPrice?.value ?? '';
    }

    return {
      id,
      name,
      price: (price || listingPrice) ?? '',
      category,
      brand,
      variant,
      position,
      dimension19: dimension25,
      dimension22,
      dimension23,
      dimension24,
      dimension25,
      dimension40,
    };
  } catch (error) {
    logFrontendException(fileName, buildListingECommerceData.name, error);
  }

  return {};
};

const trackEventEnhancedEcommerce = (
  trackingData: GAEcommerceType,
  additionalConfig?: Record<string, string | undefined>,
) => {
  const ga = ReactGA.ga();
  ga('require', 'ec');

  const { command, productsDetail, ...data } = trackingData;

  switch (command) {
    case GAEcommerceCommand.ADD_IMPRESSION:
      if (productsDetail?.length) {
        productsDetail.forEach((product) => {
          if (!product) {
            return;
          }

          ga(GAEcommerceCommand.ADD_IMPRESSION, product);
        });
      }
      break;
    case GAEcommerceCommand.ADD_PRODUCT:
      if (productsDetail?.length) {
        productsDetail.forEach((product) => {
          if (!product) {
            return;
          }

          const { id, name, category, brand, variant, price, quantity, currencyCode, dimensions } = product;
          ga('set', GAECommerceData.CURRENCY_CODE, currencyCode);
          ga(GAEcommerceCommand.ADD_PRODUCT, {
            id,
            name,
            category,
            brand,
            variant,
            price,
            quantity,
            ...dimensions,
          });
        });
      }

      break;
    case GAEcommerceCommand.SET_ACTION:
      if (productsDetail?.length) {
        productsDetail.forEach((product) => {
          if (!product) {
            return;
          }

          const { currencyCode, list, additionalInfo } = product;
          ga('set', GAECommerceData.CURRENCY_CODE, currencyCode);
          ga(GAEcommerceCommand.ADD_PRODUCT, product);
          ga(GAEcommerceCommand.SET_ACTION, additionalConfig?.setActionType || 'click', { list, ...additionalInfo });
        });
      }
      break;
    default:
      break;
  }

  trackEvent(data);
};

export default {
  initialize,
  getClientId,
  trackPageView,
  trackEvent,
  trackEventEnhancedEcommerce,
  buildListingECommerceData,
};
