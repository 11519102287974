import { UserIDType } from 'root/widgets/constants';
import storage from 'root/widgets/storage/storage';
import { isValidPgutid } from 'root/widgets/utils/validation';
import { v4 as uuidv4 } from 'uuid';

export const getUserIdFromStorage = (userIdType: UserIDType) => storage.getItem(userIdType);

export const setUserIdToStorage = (userIdType: UserIDType, userId: string) => {
  storage.setItem(userIdType, userId);
};

export const removeUserIdFromStorage = (userIdType: UserIDType) => {
  storage.removeItem(userIdType);
};

export const getNewPgutid = (): string => uuidv4();

/**
 * If cookie do not have the pgutid, or the pgutid is invalid, generate a new one using uuidv4
 * @param cookies - cookies object
 * @returns Promise<string>
 */
export const getPgutid = (cookies: Record<string, string>): string => {
  const pgutid = cookies[UserIDType.PGUTID];

  if (pgutid && isValidPgutid(pgutid)) {
    return pgutid;
  }

  return getNewPgutid();
};

/**
 * Send umstId/pgutId to GAM (Google Ad Manager)
 *
 * You could pass the userId manually,
 * else will try to get the id from localStorage
 */
export const sendUserIdToGAM = (userId?: string) => {
  if (!googletag) {
    return;
  }

  const sendPpidToGam = (id: string) => {
    googletag.cmd.push(() => {
      googletag.pubads().setPublisherProvidedId(id);
    });
  };

  if (userId && typeof userId === 'string') {
    sendPpidToGam(userId);
  } else {
    const umstId = getUserIdFromStorage(UserIDType.UMSTID);
    const pgutId = getUserIdFromStorage(UserIDType.PGUTID);

    const gamUserId = umstId || pgutId;
    if (gamUserId) {
      sendPpidToGam(gamUserId);
    }
  }
};
