import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Form, OverlayTrigger, Popover, Stack } from 'react-bootstrap';

import { HIVE_STATIC_VERSION } from 'root/widgets/constants';
import { EVENT_NAMES, emit } from 'root/widgets/events';

import './property-preference-card.scss';
import { PropertyPreferenceCardProps } from './types';
import { isInteger } from 'root/widgets/utils/validation';
import Actionable from 'root/widgets/common-components/actionable';
import { HUIDatePicker } from 'root/widgets/common-components/hui-date-picker';
import { HUIInput } from 'root/widgets/common-components/hui-input';

const PropertyPreferenceCard: React.FC<PropertyPreferenceCardProps> = ({ data, config, metadata, context }) => {
  const madeForYouImage = `https://cdn.pgimgs.com/hive-ui/static/${HIVE_STATIC_VERSION}/images/made-for-you.svg`;

  const {
    title,
    helpPrompt,
    viewLessPrompt,
    viewMorePrompt,
    description,
    moveInDateLabel,
    budgetInputLabel,
    minInput,
    maxInput,
    errorMessage,
    buttonText,
  } = data;
  const { shouldExpand = false, onSubmit } = config;

  const [moveInDate, setMoveInDate] = useState<null | Date>(null);
  const [minBudget, setMinBudget] = useState('');
  const [maxBudget, setMaxBudget] = useState('');
  const [isExpanded, setExpanded] = useState(shouldExpand);
  const [hasMinInputError, setMinInputError] = useState(false);
  const [hasMaxInputError, setMaxInputError] = useState(false);
  const [budgetError, setBudgetError] = useState('');
  const shouldDisableSubmit = Boolean(budgetError) || !(moveInDate || minBudget || maxBudget);

  const handleExpandedChange = () => {
    setExpanded(!isExpanded);
    if (!isExpanded) {
      emit(EVENT_NAMES.SRP_RENT_PROFILE_NUDGE.PREFERENCES_CLICK_VIEW_MORE, context);
    }
  };

  useEffect(() => {
    emit(EVENT_NAMES.SRP_RENT_PROFILE_NUDGE.PREFERENCES_ON_LOAD, context);
  }, []);

  useEffect(() => {
    const isMinBudgetValid = !minBudget || isInteger(minBudget);
    const isMaxBudgetValid = !maxBudget || isInteger(maxBudget);

    setMinInputError(!isMinBudgetValid);
    setMaxInputError(!isMaxBudgetValid);

    if (!isMinBudgetValid || !isMaxBudgetValid) {
      setBudgetError(errorMessage.budgetValue);
      return;
    }

    if (Number.parseInt(maxBudget, 10) < Number.parseInt(minBudget, 10)) {
      setBudgetError(errorMessage.budgetRange);
      return;
    }

    setBudgetError('');
  }, [minBudget, maxBudget]);

  const handleSubmit = () => {
    const rentPreferenceData = {
      moveInDate: moveInDate?.toISOString() || '',
      min: minBudget,
      max: maxBudget,
    };

    emit(EVENT_NAMES.SRP_RENT_PROFILE_NUDGE.PREFERENCES_CLICK_SAVE, { ...context, rentPreferenceData });
    onSubmit(rentPreferenceData);
  };

  const helpPromptPopover = useMemo(
    () => (
      <Popover>
        <Popover.Body>{helpPrompt}</Popover.Body>
      </Popover>
    ),
    [helpPrompt],
  );

  const renderHeader = () => (
    <Card.Header>
      <Card.Img src={madeForYouImage} />
      <div className="property-preference-card-header">
        <Card.Title>
          {title}{' '}
          <OverlayTrigger trigger={['focus', 'hover']} placement="auto" overlay={helpPromptPopover}>
            <i className="pgicon pgicon-help-o" />
          </OverlayTrigger>
        </Card.Title>
        <Actionable
          variant="link primary icon-suffix"
          onClick={handleExpandedChange}
          metadata={{
            'data-automation-id': isExpanded ? 'made-for-you-tune-view-less' : 'made-for-you-tune-view-more',
          }}
        >
          <i className={classnames('pgicon', { 'pgicon-arrow-up': isExpanded, 'pgicon-arrow-down': !isExpanded })} />{' '}
          {isExpanded ? viewLessPrompt : viewMorePrompt}
        </Actionable>
      </div>
    </Card.Header>
  );

  const renderBody = () => {
    if (!isExpanded) {
      return null;
    }

    return (
      <Card.Body>
        <Card.Text>{description}</Card.Text>
        <Form.Group className="preference-input-group">
          <Form.Label>{moveInDateLabel}</Form.Label>
          <HUIDatePicker
            placeholderText="dd mm yy"
            dateFormat="dd MMM yy"
            selected={moveInDate}
            onChange={setMoveInDate}
            minDate={new Date()}
            metadata={{ 'data-automation-id': 'made-for-you-move-in-date-dtp' }}
          />
        </Form.Group>
        <Form.Group className="preference-input-group">
          <Form.Label>{budgetInputLabel}</Form.Label>
          <Stack direction="horizontal" gap={3}>
            <HUIInput
              prefix={minInput.unit}
              suggestions={minInput.options}
              onInputChange={setMinBudget}
              hasError={hasMinInputError}
              placeholder={minInput.placeholder}
              value={minBudget}
              metadata={{ 'data-automation-id': 'made-for-you-budget-min' }}
            />
            <HUIInput
              prefix={maxInput.unit}
              suggestions={maxInput.options}
              onInputChange={setMaxBudget}
              hasError={hasMaxInputError}
              placeholder={maxInput.placeholder}
              value={maxBudget}
              metadata={{ 'data-automation-id': 'made-for-you-budget-max' }}
            />
          </Stack>
          {budgetError && <Form.Text className="form-error">{budgetError}</Form.Text>}
        </Form.Group>
        <Actionable
          className="save-btn"
          disabled={shouldDisableSubmit}
          onClick={handleSubmit}
          metadata={{ 'data-automation-id': 'made-for-you-save-preference-btn' }}
        >
          {buttonText}
        </Actionable>
      </Card.Body>
    );
  };

  return (
    <Card className="property-preference-card-root" {...metadata}>
      {renderHeader()}
      {renderBody()}
    </Card>
  );
};

PropertyPreferenceCard.displayName = 'PropertyPreferenceCard';

export default PropertyPreferenceCard;
