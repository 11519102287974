import classnames from 'classnames';
import React from 'react';

import './action-strip.scss';
import { ActionStripProps } from './types';

const ActionStrip = React.forwardRef<HTMLDivElement, ActionStripProps>(({ className, children }, ref) => (
  <div className={classnames('action-strip', className)} ref={ref}>
    {children}
  </div>
));
export default ActionStrip;
