import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { HIVE_STATIC_ICON_PATH_V3 } from 'root/widgets/constants';
import { SvgIcon } from 'root/widgets/svg-icon';

import './hui-alert.scss';
import { Heading } from './sub-components';
import { HUIAlertProps } from './types';
import { ALERT_DISMISS_TIME_IN_MILLISECONDS, DEFAULT_ALERT_ICONS } from './constants';

const HUIAlert: React.FC<HUIAlertProps> = (props) => {
  const {
    className,
    children,
    icon,
    shouldShowInitially = true,
    shouldAutoHide,
    autoDimissTime = ALERT_DISMISS_TIME_IN_MILLISECONDS,
    onClose,
    shouldUseSvgIcon,
    transition,
    ...remainProps
  } = props;
  const [shouldShow, setShow] = useState(shouldShowInitially);

  const defaultIconClassName = {
    danger: 'alert-icon pgicon-warning-o',
    warning: 'alert-icon pgicon-warning-o',
  };

  const shouldShowIcon = icon && props.variant !== 'education';
  const isEducationAlert = props.variant === 'education';

  const handleCloseBtnClick = useCallback(() => {
    setShow(false);
    onClose?.(null, null);
  }, [onClose]);

  const closeButton = useMemo(
    () =>
      (isEducationAlert || remainProps?.dismissible) && (
        <div className="alert-close-btn">
          <button type="button" className="close-btn-icon" aria-label="Close alert" onClick={handleCloseBtnClick}>
            <SvgIcon src={`${HIVE_STATIC_ICON_PATH_V3}/cross-small.svg`} width={24} height={24} />
          </button>
        </div>
      ),
    [isEducationAlert, remainProps?.dismissible, handleCloseBtnClick],
  );

  useEffect(() => {
    if (!shouldShow || !shouldAutoHide) {
      return;
    }

    const timer = setTimeout(() => {
      handleCloseBtnClick();
    }, autoDimissTime);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timer);
    };
  }, [shouldShow, shouldAutoHide, autoDimissTime]);

  return (
    <Alert
      show={shouldShow}
      className={classNames('hui-alert-root', className, transition)}
      {...remainProps}
      dismissible={false}
      onClose={onClose}
    >
      {shouldUseSvgIcon ? (
        <div>
          <SvgIcon
            src={props.variant ? DEFAULT_ALERT_ICONS[props.variant] : DEFAULT_ALERT_ICONS['warning']}
            title="alert-icon"
            className="alert-icon"
          />
        </div>
      ) : (
        <>
          {shouldShowIcon && <i {...icon} className={classNames('alert-icon', icon.className)} />}
          {!icon && props.variant && !isEducationAlert && <i className={defaultIconClassName[props.variant]} />}
        </>
      )}

      <div>{children}</div>

      {closeButton}
    </Alert>
  );
};

export default Object.assign(HUIAlert, {
  Heading,
  Link: Alert.Link,
});
