import { BaseProps } from 'root/widgets/types';

export type TypographyHTMLType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'label';
export type TypographyVariantType =
  | 'display'
  | 'headline'
  | 'title-xl'
  | 'title-l'
  | 'title-m'
  | 'title-s'
  | 'title-xs'
  | 'body-l'
  | 'body-m'
  | 'body-s'
  | 'body-xs'
  | 'label-l'
  | 'label-m'
  | 'label-s'
  | 'label-xs'
  | 'caption'
  | 'caption-s'
  | 'caption-xs';
export type TypographyDecorationType = 'strikethrough' | 'underline';

export const TypographyDefaultMapping: {
  [variants in TypographyVariantType]: TypographyHTMLType;
} = {
  display: 'span',
  headline: 'span',
  'title-xl': 'h1',
  'title-l': 'h2',
  'title-m': 'h3',
  'title-s': 'h4',
  'title-xs': 'h5',
  'body-l': 'p',
  'body-m': 'p',
  'body-s': 'p',
  'body-xs': 'p',
  'label-l': 'span',
  'label-m': 'span',
  'label-s': 'span',
  'label-xs': 'span',
  caption: 'span',
  'caption-s': 'span',
  'caption-xs': 'span',
};

export interface TypographyProps extends BaseProps {
  variant?: TypographyVariantType;
  decoration?: TypographyDecorationType;
  element?: TypographyHTMLType;
  htmlFor?: string;
}
